import { Component } from '@angular/core';

@Component({
  selector: 'app-kalpa-icon',
  templateUrl: './kalpa-icon.component.html',
  styleUrls: [ './kalpa-icon.component.scss' ],
})
export class KalpaIconComponent {

}
