import {
  Component, Inject, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-update-status-modal',
  templateUrl: './update-status-modal.component.html',
  styleUrls: [ './update-status-modal.component.scss' ],
})
export class UpdateStatusModalComponent implements OnDestroy {
  status: any;
  courseRegistrationSubscription: any;

  constructor(
    private coursesService: CoursesService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<UpdateStatusModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  closeStatusModal = () => {
    this.dialogRef.close();
  }

  submitStatus = () => {
    let statusKid = '';
    switch(this.status) {
    case 'taken':
      statusKid = 'COMPLETED';
      break;
    case 'not-taken':
      statusKid = 'NOT_TAKEN';
      break;
    case 'cancel':
      statusKid = 'UNREGISTERED';
      break;
    default:
      statusKid = 'REGISTERED';
      break;
    }
    const updateStatusData = { id: this.data.id, statusKid: statusKid };

    this.courseRegistrationSubscription = this.coursesService.updateCourseRegistration(updateStatusData).subscribe({
      next: (response: any) => {
        this.dialogRef.close(true);
        // some delay to allow the database to update. specifically for the survey status update to unregistered

        this.toastService.setToast({ text: 'Status Updated', type: 'success' })
      },
      error: (error: any) => {
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong updating the status', text: error.error.message,
          },
        })
      },
    });
  }

  checkCanComplete = (data: any) => {
    const {
      validationKid, sessionEndDate, dateOffered,
    } = data;

    if (validationKid !== 'HONOR') {
      return false;
    }

    if (dateOffered === 'ASYNC') {
      return true;
    }

    const date = new Date(dateOffered);
    if (date instanceof Date && !isNaN(date.getTime())) {
      return new Date() > new Date(sessionEndDate);
    }

    return false;
  }

  ngOnDestroy(): void {
    if(this.courseRegistrationSubscription) {
      this.courseRegistrationSubscription.unsubscribe();
    }
  }
}
