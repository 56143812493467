import { Component } from '@angular/core';

@Component({
  selector: 'app-registration-conflict-modal',
  templateUrl: './registration-conflict-modal.component.html',
  styleUrls: [ './registration-conflict-modal.component.scss' ],
})
export class RegistrationConflictModalComponent {

}
