<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Course Details</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <div class="section">
      <span class="klabel-huge">Course Offering</span>
      <div class="row">
        <div class="switch-detail" *ngIf="!course.isAnytime">
          <label class="switch">
            <input
              type="checkbox"
              [(ngModel)]="course.isAsync"
              (change)="asyncToggle($event)"
            />
            <span class="slider round"></span>
          </label>
          <span class="kbody-medium">Async</span>
        </div>
        <div class="switch-detail">
          <label class="switch">
            <input type="checkbox" [(ngModel)]="course.virtualFlag" />
            <span class="slider round"></span>
          </label>
          <span class="kbody-medium">Virtual</span>
        </div>
        <div
          class="switch-detail"
          *ngIf="!course.isAsync"
          (change)="updateAnytime($event)"
        >
          <label class="switch">
            <input type="checkbox" [(ngModel)]="course.isAnytime" />
            <span class="slider round"></span>
          </label>
          <span class="kbody-medium">Anytime</span>
        </div>
      </div>
    </div>
    <div class="section">
      <span class="klabel-huge">Location, Date & Time</span>
      <div class="row" *ngIf="course.virtualFlag">
        <app-input
          [label]="'Meeting ID*'"
          [value]="this?.course?.location"
          [placeholder]="'Enter a virtual meeting room URL'"
          (updatedValue)="updateCourseParameter($event, 'location')"
          [required]="true"
        ></app-input>
      </div>
      <div class="row" *ngIf="!course.virtualFlag">
        <app-input
          [label]="'Location*'"
          [value]="this?.course?.location"
          [placeholder]="'Enter location'"
          (updatedValue)="updateCourseParameter($event, 'location')"
          [required]="true"
        ></app-input>
        <app-input
          [label]="'Room Number'"
          [value]="this?.course?.roomNumber"
          [placeholder]="'Enter room number'"
          (updatedValue)="updateCourseParameter($event, 'roomNumber')"
        ></app-input>
      </div>
      <div class="row" *ngIf="!course.isAsync">
        <div class="detail-column">
          <span class="klabel-medium label">Course date*</span>
          <input
            type="date"
            class="kinput"
            [value]="course.dateOfferedFormatted"
            (change)="updateCourseParameter($event, 'dateOffered')"
          />
        </div>
        <div class="detail-column" *ngIf="!course.isAnytime">
          <span class="klabel-medium label">Course start time*</span>
          <input
            type="time"
            class="kinput"
            value="{{ course.sessionStartDateFormatted }}"
            (change)="updateCourseParameter($event, 'sessionStartDate')"
          />
        </div>
        <div class="detail-column" *ngIf="!course.isAnytime">
          <span class="klabel-medium label">Course end time*</span>
          <input
            type="time"
            class="kinput"
            [value]="course.sessionEndDateFormatted"
            (change)="updateCourseParameter($event, 'sessionEndDate')"
          />
        </div>
      </div>
    </div>
    <div class="section">
      <span class="klabel-huge">Catalog Availability</span>
      <span class="klabel-medium label"
        >Select a date range for this course to be displayed in the
        catalog*</span
      >
      <mat-form-field class="kinput">
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Start date"
            [(ngModel)]="course.regStartDate"
            (update)="updateCourseParameter($event, 'regStartDate')"
          />
          <input
            matEndDate
            placeholder="End date"
            [(ngModel)]="course.regEndDate"
            (change)="updateCourseParameter($event, 'regEndDate')"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <div class="buttons" *ngIf="!isEditMode">
    <app-button
      (click)="stepBackward()"
      [text]="'Previous Step'"
      [icon]="{ materialIcon: 'arrow_back' }"
      [class]="'white'"
    ></app-button>
    <div class="actions">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="closeDialog()"
      ></app-button>
      <app-button
        [disabled]="checkDisabled()"
        [icon]="{ materialIcon: 'arrow_forward' }"
        [class]="'icon-right'"
        [text]="'Next Step'"
        (click)="updateCourse()"
      ></app-button>
    </div>
  </div>
</div>
