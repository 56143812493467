<div class="modal">
  <span class="ktitle-huge">Course Request</span>
  <span class="kbody-medium">Submitted by {{data.submittedBy}}</span>
  <span class="kbody-medium">Submitted on {{data.submittedOn}}</span>
  <span class="kbody-medium">Course Name: {{data.courseName}}</span>
  <span class="kbody-medium">Course Hours: {{data.hours}}</span>
  <span class="kbody-medium">Course Date: {{data.date}}</span>
  <span class="kbody-medium">Course Time: {{data.time}}</span>
  <span class="kbody-medium">Course Description: {{data.description}}</span>
  <span class="kbody-medium">Course Location: {{data.location}}</span>
  <div class="buttons">
    <app-button class="red" [icon]="{ materialIcon: 'close' }" text="Deny Request"></app-button>
    <app-button [icon]="{ materialIcon: 'add' }" text="Approve Request"></app-button>
  </ div>
</div>