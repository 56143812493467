import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: [ './chip.component.scss' ],
})
export class ChipComponent {
  @Input() text: string;
  @Input() class: string;
  @Input() icon: any;
  @Input() closable: boolean;
  @Input() dot: string;
  @Input() disabled: boolean;
  @Output() closeChip = new EventEmitter<object>();

  close = () => {
    if(this.disabled) return;
    this.closeChip.emit();
  }

}
