import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TagsService {
  private _tagsData$ = new BehaviorSubject<any>(null);
  public readonly tagsData$ = this._tagsData$.asObservable();
  _user$: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    this.tagsData$.subscribe(data =>
      links = data.links)
    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/tags/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._tagsData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getTags = ({
    tagNameFilter, activeFlag = 1, sortColumn = 'tag', sortDirection = 'ASC', limit, offset = 0,
  }: {
    tagNameFilter: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
    offset: number;
    }) => {
    const params: {
      loginName: string;
      activeFlag: 1 | 0;
      tagNameFilter?: string;
      sortColumn?: string;
      sortDirection?: 'ASC' | 'DESC';
      limit?: number;
      offset?: number;
    } = {
      loginName: this._user$.loginName,
      activeFlag,
    }

    if (tagNameFilter) {
      params.tagNameFilter = tagNameFilter;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection;
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    return this.http.get(`${environment.expressUrl}/maintenance/tags`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._tagsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })

  }

  getSortedTags = (
    direction: string, showActiveItems: 1 | 0, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/tags`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'offset': offset, 'activeFlag': showActiveItems ? 1 : 0, 'sortDirection': direction,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._tagsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getInactiveTags = (limit = 25, offset = 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/tags`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'offset': offset, 'activeFlag': 0,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._tagsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getTagsFilteredByName = (searchText: string, showActiveItems: 1 | 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/tags`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': 25, 'activeFlag': showActiveItems ? 1 : 0, 'tagNameFilter': searchText,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._tagsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  toggletagActivation = (tag: any, isActivating: boolean) => {
    const activeEndpoint = isActivating ? 'activate' : 'deactivate';
    return this.http.put(
      `${environment.expressUrl}/maintenance/tags/${tag.id}/${activeEndpoint}`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  addTag = (tag: any) => {
    return this.http.post(
      `${environment.expressUrl}/maintenance/tags`,
      {
        'loginName': this._user$.loginName,
        'tag': tag.name,
        'entityId': parseFloat(this._user$.entityId),
        'track': tag.track,
        'conversion': parseFloat(tag.conversion),
        'color': tag.color,
        'isVisibleFlag': tag.isVisibleFlag ? 1 : 0,
        'description': tag.description,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  updateTag = (tag: any) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/tags/${tag.id}`,
      {
        'loginName': this._user$.loginName,
        'tag': tag.name,
        'entityId': parseFloat(this._user$.entityId),
        'track': tag.track,
        'conversion': parseFloat(tag.conversion),
        'color': tag.color,
        'isVisibleFlag': tag.isVisibleFlag ? 1 : 0,
        'description': tag.description,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

}

