import {
  Injectable, NgZone, isDevMode,
} from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, from } from 'rxjs';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { LoadingSpinnerService } from './loading-spinner.service';
import { ToastService } from './toast.service';
// import awsconfig from '../../aws-exports';

// Auth.configure({
//   ...awsconfig,
//   storage: localStorage,
//   multifactor: {
//     mode: 'optional',
//     totp: true,
//   },
// })


@Injectable({ providedIn: 'root' })
export class AuthService {
  private _error$ = new BehaviorSubject<boolean | string>(false)
  public readonly error$ = this._error$.asObservable();

  private _loggedIn$ = new BehaviorSubject<boolean>(false)
  public readonly loggedIn$ = this._loggedIn$.asObservable();

  private _user$ = new BehaviorSubject<any>(null);
  public readonly user$ = this._user$.asObservable();

  private _entity$ = new BehaviorSubject<any>(null);
  public readonly entity$ = this._entity$.asObservable();

  private _permissions$ = new BehaviorSubject<any>(null);
  public readonly permissions$ = this._permissions$.asObservable();

  constructor(
    private http: HttpClient, private router: Router, private ngZone: NgZone, private loadingSpinnerService: LoadingSpinnerService,
    private toast: ToastService,
  ) { }

  clearError() {
    this._error$.next('')
  }

  firstSetPassword = 'ai!jD$2@3!!dke';

  setLoggedInStatus = (status: boolean) => {
    this._loggedIn$.next(status)
  }

  // #region USER CREATION
  addUserToAWS = async (username: any) => {
    try {
      const cognitoUser = await Auth.signUp({
        username: username?.email || username,
        password: this.firstSetPassword,
      })

      return cognitoUser;
    } catch (error: any) {
      this._error$.next(error?.error?.message || error?.message)
    }
    return null;
  }
  // #endregion

  // #region LOGIN
  logout = async () => {
    this._permissions$.next({})
    this._loggedIn$.next(false)
    this._entity$.next(null)
    this._user$.next(null)
    localStorage.removeItem('user')
    sessionStorage.removeItem('user')

    from(this.ngZone.run(async () => {
      return await this.router.navigate([ '/' ])
    }))
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async autoLogin() {
    const {
      user, userFromLocalStorage, userFromSessionStorage, username,
    } = await this.getCurrentUser();

    const userInfo = this._user$.value;

    const isLoggedInNoAction = !!userInfo || !!userFromLocalStorage || !!userFromSessionStorage;

    const parsedLocal = userFromLocalStorage;
    const parsedSession = userFromSessionStorage;
    const userObject = userInfo || parsedSession || parsedLocal;

    const userLogin = username;

    if (isLoggedInNoAction && userLogin) {
      this._loggedIn$.next(true);
      this._user$.next(userObject)
      this._entity$.next(userObject.Entity)
      this._permissions$.next(userObject.Permissions)

      const url = this.router.url

      return;
    }

    if (userLogin) {
      this.getUser({
        loginData: { email: userLogin },
        rememberMe: parsedLocal,
        cognitoUserObject: userObject,
      })
    }
    // this checks for google federated login
    const googleFederatedInfo = localStorage.getItem('aws-amplify-federatedInfo')
    const parsedInfo = JSON.parse(googleFederatedInfo || '{}')
    const federatedUser = parsedInfo.user || null;
    if (federatedUser) {
      this.getUser({
        loginData: { email: federatedUser.email },
        rememberMe: true,
        cognitoUserObject: federatedUser,
      })
    }
  }

  getCurrentUser = async () => {
    const returnObj: any = {
      user: null, username: null, session: null, userFromLocalStorage: null, userFromSessionStorage: null,
    }
    try {
      const userFromSessionStorage = sessionStorage.getItem('user');
      const sessionObj = userFromSessionStorage ? JSON.parse(userFromSessionStorage) : null;

      const userFromLocalStorage = localStorage.getItem('user');
      const localObj = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;

      const username = localObj?.loginName || sessionObj?.loginName;
      returnObj.username = username;

      returnObj.userFromSessionStorage = sessionObj;
      returnObj.userFromLocalStorage = localObj;

      const user = await Auth?.currentAuthenticatedUser();
      const session = await Auth?.currentSession() || null;

      //TODO - once user flow is done we can use user sessions

      return {
        user, username, session, userFromLocalStorage: localObj, userFromSessionStorage: sessionObj,
      }
    } catch (err) {
      if (isDevMode()) {
        return returnObj;
      }
      return {
        user: null, username: null, session: null, userFromLocalStorage: null, userFromSessionStorage: null,
      }
    }
  }

  // this is the login with email handler that sends the request to aws cognito and handles the success or error response
  handleEmailLogin = async (loginData: { email: string, password: string, rememberMe: boolean }, rememberMe: any) => {
    try {
      this.loadingSpinnerService.setIsLoading(true);
      const response = await Auth.signIn(loginData.email, loginData.password);

      return response;

    } catch (err: any) {
      this._error$.next(err.message),
      this.loadingSpinnerService.setIsLoading(false);
      return err;
    }
  }

  // this is the login with google federated handler that sends out the authenticated google users token to our backend
  handleGoogleLogin = (response: any, rememberMe: boolean) => {
    // deconstruct the token from a successful google log in
    const token = jwt_decode(response.credential) as any;
    const user = {
      email: token.email,
      name: token.name,
    };

    // attempt to log into aws cognito
    Auth.federatedSignIn(
      'google',
      { token: response.credential, expires_at: token.exp },
      user,
    )
      .then((res) => {
        // get user from express app upon successful federated log in
        this.getUser({
          loginData: { email: user.email }, rememberMe, cognitoUserObject: res,
        })
      })
      .catch((error) => {
        console.log('federated login error:', error),
        this._error$.next(error.error.message || error.message),
        this.loadingSpinnerService.setIsLoading(false);
      });
  }

  // this gets the user from the api and sets the user and logged in status
  getUser({
    loginData, rememberMe, cognitoUserObject, route = 'dashboard', fromSignIn = false,
  }
    :
    { loginData: { email: string }, rememberMe: boolean, cognitoUserObject: any, route?: string, fromSignIn?: boolean }) {
    const userAlreadyLoggedIn = this._loggedIn$.value;

    return this.http
      .get(`${environment.expressUrl}/users/search`, { headers: { 'Content-Type': 'application/vnd.api+json' }, params: { 'loginName': loginData.email } })
      .subscribe({
        next: (response: any) => {
          this._loggedIn$.next(true);
          this._user$.next({ ...cognitoUserObject, ...response.data })
          this._entity$.next(response.data.Entity)
          this._permissions$.next(response.data.Permissions)


          // if remember me is checked, store the user in local storage
          sessionStorage.setItem('user', JSON.stringify(this._user$.value))
          localStorage.setItem('user', JSON.stringify(this._user$.value))


          const storedUsername = localStorage.getItem('username');
          if (rememberMe && !storedUsername) {
            localStorage.setItem('username', loginData.email)
          } else if (!rememberMe && storedUsername) {
            localStorage.removeItem('username')
          }

          if (!userAlreadyLoggedIn || fromSignIn) {
            from(this.ngZone.run(async () => {
              this.loadingSpinnerService.setIsLoading(false);

              await this.router.navigate([ '/', route || 'profile' ])
              this.loadingSpinnerService.setIsLoading(false);
            }))
          }
        },
        error: (error) => {
          console.log('🚀 - error:', error);
          (this._error$.next(error.error.message || error.message)),
          this.loadingSpinnerService.setIsLoading(false);
          // todo - maybe bring this back
          this.logout();
        },
      })
  }
  // #endregion

  // #region FIRST TIME LOGIN
  sendNewCodeToUser = (email: string) => {
    return Auth.resendSignUp(email)
      .then((res) => {
        this.loadingSpinnerService.setIsLoading(false);

        this.toast.setToast({
          text: 'Code sent successfully. Please check your email.',
          type: 'success',
          icon: true,
          dismissible: true,
        });
        return res;
      })
      .catch((error) => {
        console.log('🚀 - error:', error);

        (this._error$.next(error?.error?.message || error?.message)),
        this.toast.setToast({
          text: 'Error sending code. Please check your email and try again.',
          type: 'error',
          icon: true,
          dismissible: true,
        });
        // this.logout()
        this.loadingSpinnerService.setIsLoading(false);
        // from(this.ngZone.run(() => {
        //   return this.router.navigate([ '/signin' ])
        // }))
      })
  }

  handleClaimAccount = (username: string, code: string) => {
    console.log('claim account')
    return Auth.confirmSignUp(username, code)
      .then((response) => {
        this.loadingSpinnerService.setIsLoading(false);
        return response;
      })
      .catch((error) => {
        console.log('🚀 - error:', error);
        (this._error$.next(error?.error?.message || error?.message)),
        this.toast.setToast({
          text: 'Account Confirmation Failed', type: 'error', icon: true,
        })
        this.logout()
        this.loadingSpinnerService.setIsLoading(false);
        from(this.ngZone.run(() => {
          return this.router.navigate([ '/signin' ])
        }))
      })
  }

  handleSetFirstTimePassword = async (username: string, password: string) => {
    const user = await Auth.signIn(username, this.firstSetPassword);

    return await Auth.changePassword(
      user, this.firstSetPassword, password,
    )
      .then((response) => {
        this.loadingSpinnerService.setIsLoading(false);
        this.toast.setToast({
          text: 'Password Set Successful', type: 'success', icon: true, dismissible: true,
        })
        return response;
      })
      .catch((error) => {
        (this._error$.next(error?.error?.message || error?.message)),
        this.toast.setToast({
          text: 'Password Set Failed', type: 'error', icon: true,
        })
        this.logout()
        this.loadingSpinnerService.setIsLoading(false);
        from(this.ngZone.run(() => {
          return this.router.navigate([ '/signin' ])
        }))
        throw error;
      })
  }



  // #endregion

  // #region FORGOT PASSWORD

  // NOTE - this is used when a user is created manually via cognito/amplify and should be rare
  handleCompletePassword = async (
    username: string, oldPassword: string,  newPassword: string,
  ) => {
    const user = await Auth.signIn(username, oldPassword);

    return Auth.completeNewPassword(user, newPassword)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        (this._error$.next('An error occurred changing password. Please try again.')),
        this.loadingSpinnerService.setIsLoading(false);
      });
  }

  handleChangePassword =async  (
    username: string, oldPassword: string, password: string,
  ) => {
    const user = await Auth.signIn(username, oldPassword);

    return Auth.changePassword(
      user, oldPassword, password,
    )
      .then((response) => {
        if (response) {
          this.toast.setToast({
            text: 'Password Changed Successfully', type: 'success', icon: true, dismissible: true,
          })
          return response;
        }
        throw new Error('Error changing password');
      })
      .catch((error) => {
        (this._error$.next('An error occurred changing password. Please try again.')),
        this.loadingSpinnerService.setIsLoading(false);
      });
  }

  handleForgotPasswordGetCode = (username: string) => {
    return Auth.forgotPassword(username, {})
      .then((response) => {
        return response;

      })
      .catch((error) => {
        console.log('🚀 - error:', error);
        (this._error$.next(error?.error?.message || error?.message)),
        this.logout()
        this.loadingSpinnerService.setIsLoading(false);
        from(this.ngZone.run(() => {
          return this.router.navigate([ '/signin' ])
        }))
      });
  }

  handleForgotPasswordCodeSubmit = (
    username: string, password: string, code: string,
  ) => {
    this.loadingSpinnerService.setIsLoading(true);
    return Auth.forgotPasswordSubmit(
      username, code, password,
    )
      .then((response) => {
        return response
      })
      .catch((error) => {
        (this._error$.next(error?.message || error.error.message)),
        this.logout()
        this.loadingSpinnerService.setIsLoading(false);

        from(this.ngZone.run(() => {
          return this.router.navigate([ '/signin' ])
        }))
        throw error
      });
  };

  // #endregion
}
