<div class="update-component">
  <div class="title-row">
    <div class="row">
      <mat-icon>category</mat-icon>
      <span class="ktitle-medium">{{ data ? "Edit Tag" : "Add New Tag" }}</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeDialog()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <div class="row">
      <div class="detail">
        <span class="klabel-medium label">Tag Name*</span>
        <input
          class="input kinput"
          type="text"
          placeholder="Tag Name"
          [(ngModel)]="this.newTag.name"
          [disabled]="data && !data?.activeFlag"
        />
      </div>
      <div class="detail color-container">
        <span class="klabel-medium label">Tag Color*</span>
        <div class="color-details">
          <input
            [disabled]="data && !data?.activeFlag"
            id="color-input"
            class="color-input"
            type="color"
            [(ngModel)]="this.newTag.color"
            placeholder="#BE00DF"
          />
          <span>Select Color</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="detail">
        <span class="klabel-medium label">Tag Description</span>
        <textarea
          [disabled]="data && !data?.activeFlag"
          class="input"
          rows="5"
          type="text"
          [(ngModel)]="this.newTag.description"
          placeholder="Enter your tag description here..."
          >{{ data?.description }}</textarea
        >
      </div>
    </div>

    <!-- TODO - remove or add back in depending on discussion -->
    <!-- <div class="row">
      <div class="detail">
        <span class="klabel-medium label">Tracking Type</span>
        <div class="input-select-container">
          <input
            class="kinput input-select"
            type="text"
            placeholder="Select tracking type"
            name="tracks"
            list="tracks"
            [disabled]="data && !data?.activeFlag"
          />
          <datalist id="tracks" class="datalist">
            <option *ngFor="let item of trackList">{{ item }}</option>
          </datalist>
          <mat-icon class="chevron">chevron_left</mat-icon>
        </div>
      </div>

      <div class="detail">
        <div class="conversion-row">
          <span class="klabel-medium label">Conversion</span>
          <!-- TODO - implement help tooltip -->
    <!-- <mat-icon class="help-icon">help</mat-icon> -->
    <!-- </div>
        <input
          [disabled]="data && !data?.activeFlag"
          class="kinput input"
          type="text"
          placeholder="Enter Conversion Here"
          [(ngModel)]="this.newTag.conversion"
        />
      </div>
    </div> -->

    <div class="row">
      <div class="detail">
        <span class="klabel-medium label">Additional Details</span>
        <div class="check-row">
          <input
            [disabled]="data && !data?.activeFlag"
            type="checkbox"
            [(ngModel)]="this.newTag.isVisibleFlag"
          />
          <span>Visible to teachers </span>
          <!-- TODO - implement help tooltip -->
          <!-- <mat-icon>help</mat-icon> -->
        </div>
      </div>
    </div>
  </div>

  <span class="error" *ngIf="error !== ''">{{ error }}</span>

  <div class="buttons" [ngClass]="{ end: !data }">
    <app-button
      *ngIf="data?.activeFlag"
      [class]="'grey'"
      (click)="setActivation(false)"
      [text]="'Deactivate Tag'"
    ></app-button>
    <app-button
      *ngIf="data && !data?.activeFlag"
      (click)="setActivation(true)"
      [class]="'grey'"
      [text]="'Activate Tag'"
    ></app-button>
    <div class="actions">
      <app-button text="Cancel" [class]="'white'" (click)="closeDialog()"
        >Cancel</app-button
      >
      <app-button
        *ngIf="!data || data?.activeFlag"
        (click)="saveChanges()"
        text="{{ data ? 'Save Changes' : 'Add Tag' }}"
        [icon]="{ type: 'success' }"
      ></app-button>
    </div>
  </div>
</div>
