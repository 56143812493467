<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Additional Details</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="klabel-huge">Registration & Validation</span>
    <div class="row">
      <app-select
        [label]="'Select approval level needed for users to register*'"
        [options]="approvalLevels"
        value="{{ setApprovalId || approvalDefaultId }}"
        [placeholder]="'Select Approval Level'"
        (selectedValue)="updateLocalCourseParameter($event, 'approvalId')"
      ></app-select>
      <app-select
        [label]="'Select validation type'"
        [options]="validationTypes"
        value="{{ setValidationId || validationDefaultId }}"
        [placeholder]="'Select Validation Type'"
        (selectedValue)="updateLocalCourseParameter($event, 'validationId')"
      ></app-select>
      <!-- <div class="detail skinny">
        <span class="label klabel-medium">Survey</span>
        <div class="row left">
          <input type="checkbox" [(ngModel)]="course.surveyFlag" />
          <span class="kbody-medium">Require Survey</span>
        </div>
      </div> -->
    </div>
    <!-- TODO - add survey once functionality exists -->
    <!-- <div class="row" *ngIf="course.surveyFlag">
      <app-input
        [label]="'Select a survey to attach to this course:'"
        [value]="this?.course?.survey"
        [placeholder]="'Select a survey'"
        (updatedValue)="updateLocalCourseParameter($event, 'survey')"
      ></app-input>
    </div> -->
    <!-- TODO - add pin back in -->
    <!-- <div class="row">
      <div class="row left">
        <input type="checkbox" [(ngModel)]="course.requirePin">
        <span class="kbody-medium">Require a registration PIN</span>
        <input *ngIf="course.requirePin" type="text" class="kinput" [(ngModel)]="course.registrationPin" placeholder="5-10 digit number">
      </div>
    </div> -->
    <span class="klabel-huge">Certificate Requirement</span>
    <div class="row left">
      <input type="checkbox" [(ngModel)]="course.certificateFlag" />
      <span class="kbody-medium"
        >Hours eligible for certificate renewal for teachers in intended
        audience</span
      >
    </div>
    <!-- TODO - add back in for state requirement -->
    <!-- <span *ngIf="showStateReportingRequirements" class="klabel-huge"
      >State Requirement</span
    > -->
    <!-- <app-select
      *ngIf="showStateReportingRequirements"
      [label]="'Select a state reporting requirement*'"
      [options]="stateReportingRequirements"
      [value]="this?.course?.stateRequirementId"
      [placeholder]="'Select a state reporting requirement'"
      (selectedValue)="
        updateLocalCourseParameter($event, 'stateReportingRequirement')
      "
    ></app-select> -->
    <span class="klabel-huge">Outside Course</span>
    <span class="kbody-medium"
      >If this course is checked as an ‘outside course’, then upon registration,
      users will see a notification that this is an outside course as well as
      the message that is entered in ‘registration notice’ within System
      Settings.</span
    >
    <div class="row left">
      <input type="checkbox" [(ngModel)]="course.outsideCourseFlag" />
      <span class="kbody-medium">This is an outside course.</span>
    </div>
  </div>
  <div class="buttons" *ngIf="!isEditMode">
    <app-button
      (click)="stepBackward()"
      [text]="'Previous Step'"
      [icon]="{ materialIcon: 'arrow_back' }"
      [class]="'white'"
    ></app-button>
    <div class="actions">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="closeDialog()"
      ></app-button>
      <app-button
        [icon]="{ materialIcon: 'arrow_forward' }"
        [class]="'icon-right'"
        [text]="'Next Step'"
        (click)="updateCourse()"
      ></app-button>
    </div>
  </div>
</div>
