import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-general-info',
  templateUrl: './user-general-info.component.html',
  styleUrls: [ './user-general-info.component.scss' ],
})
export class UserGeneralInfoComponent implements OnInit {
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() isEditMode: any;
  @Input() permissions: any;
  @Input() editedUser: any;
  @Input() roles: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }
  @Input() isAdminUser: boolean;

  formattedRoles: any;
  localEditedUser: any;
  localUserRoleId: any;


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.localEditedUser = this.editedUser;
    this.localUserRoleId = this.localEditedUser?.Roles ? this.localEditedUser?.Roles[0]?.id : '';
    this.formatRoles();
  }

  ngOnChanges(changes: any) {
    if (changes.roles) {
      this.formatRoles();
    }
  }

  formatRoles = () => {
    this.formattedRoles = this.roles?.map((role: any) => {
      return {
        text: role.roleName,
        value: role.id,
      }
    });
  }

  updateUserParameter = (
    event: any, parameter: string, type?: string,
  ) => {
    if (type === 'select' && parameter === 'userRole') {
      const role = this.roles.find((role: any) => {
        return role.id == event;
      });
      this.localEditedUser.userRole = role;
      return;
    } else if (type === 'select') {
      this.localEditedUser[parameter] = event;
      return
    }
    this.localEditedUser[parameter] = event?.target?.value;
    this.step.modified = true;
  }

  checkDisabled = () => {
    // This checks to make sure that email is an email and not a dinasour
    if(this.localEditedUser.email) {
      const email = this.localEditedUser.email;
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if(!emailPattern.test(email)) {
        return true
      }
    }
    return false
  }

  updateUser = () => {
    if (this.localEditedUser.firstName === '' || this.localEditedUser.lastName === '' || this.localEditedUser.email === '' || this.localEditedUser.userRole === '') {
      return
    }
    this.updateUserObject.emit(this.localEditedUser);
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

}
