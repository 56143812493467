import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BuildingsService {
  private _buildingsData$ = new BehaviorSubject<any>(null);
  public readonly buildingsData$ = this._buildingsData$.asObservable();

  _user$: any;


  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getBuildingDetails = (buildingId: number, includedAssociations: string) => {
    return this.http.get(`${environment.expressUrl}/maintenance/buildings/${buildingId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'includedAssociations': includedAssociations,
        },
      })
  }

  getBuildings(limit = 25, offset = 0) {
    return this.http.get(`${environment.expressUrl}/maintenance/buildings`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': limit,
          'activeFlag': '1',
          'offset': offset,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._buildingsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    let pagination = {
      curPage: 0, totalItems: 0, totalPages: 0,
    };
    this.buildingsData$.subscribe(data => {
      links = data.links;
      pagination = data.pagination;
    });

    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/buildings/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._buildingsData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getSortedBuilding = (
    title: string, sortDirection: string, activeFlag: boolean, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/buildings`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': limit,
          'offset': offset,
          'sortColumn1': title,
          'sortColumn1Direction': sortDirection,
          'activeFlag': activeFlag ? 1 : 0,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._buildingsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getBuildingsFilteredByName = (
    buildingName: string, activeFlag = 1, sortColumn: string, sortDirection: 'ASC' | 'DESC' = 'ASC', limit: number,
    offset = 0,
  ) => {
    const params: {
      loginName: string,
      buildingNameFilter?: string,
      activeFlag: number,

      limit?: number,
      offset?: number,

      sortColumn1?: string,
      sortColumn1Direction?: 'ASC' | 'DESC',
    } = {
      loginName: this._user$.loginName,
      activeFlag: activeFlag || 1,
    }

    if (buildingName) {
      params.buildingNameFilter = buildingName;
    }

    if (sortColumn) {
      params.sortColumn1 = sortColumn;
      params.sortColumn1Direction = sortDirection || 'ASC';
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }


    return this.http.get(`${environment.expressUrl}/maintenance/buildings`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._buildingsData$.next(response.data || {});
        },
        error: (error) => {
          this._buildingsData$.next(error);
          console.log('error', error);
        },
      })
  }

  getInactiveBuildings = (limit = 25, offset = 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/buildings`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'activeFlag': 0, 'offset': offset,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._buildingsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  addBuilding = (building: any) => {
    return this.http.post(
      `${environment.expressUrl}/maintenance/buildings`,
      {
        'loginName': this._user$.loginName,
        'entityId': parseFloat(this._user$.entityId),
        'buildingName': building?.buildingName,
        'buildingType': building.buildingType,
        'buildingNumber': parseFloat(building.buildingNumber),
        'street1': building.street1,
        'street2': null,
        'city': building.city,
        'zipcode': building.zipcode,
        'stateCode': building.stateCode, // must be state abbreviation...
        'telephone': building.telephone?.replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/gm, ''), // nasty regex to remove all special characters and white space
        'fax': building.fax?.replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/gm, ''), // nasty regex to remove all special characters and white space
        'supervisorId': this._user$.user_id, // this is tbd
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  updateBuilding = (building: any) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/buildings/${building.id}`,
      {
        'loginName': this._user$.loginName,
        'entityId': parseFloat(this._user$.entityId),
        'buildingName': building?.buildingName,
        'buildingType': building.buildingType,
        'buildingNumber': parseFloat(building.buildingNumber),
        'street1': building.street1,
        'street2': null,
        'city': building.city,
        'zipcode': building.zipcode,
        'stateCode': building.stateCode, // must be state abbreviation...
        'telephone': building.telephone?.replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/gm, ''), // nasty regex to remove all special characters and white space
        'fax': building.fax?.replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/gm, ''), // nasty regex to remove all special characters and white space
        // 'supervisorId': this._user$.user_id, // this is tbd
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  toggleBuildingActivation = (building: any, isActivating: boolean) => {
    const activeEndpoint = isActivating ? 'activate' : 'deactivate';
    return this.http.put(
      `${environment.expressUrl}/maintenance/buildings/${building.id}/${activeEndpoint}`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  batchStaffReassign = (
    staffList: Array<any>, currentBuildingId: any, newBuildingId: any,
  ) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/buildings/reassign-users`,
      {
        'loginName': this._user$.loginName,
        'maintainBuildingAssignment': false,
        'overwritePrimaryBuilding': true,
        'userIds': staffList.map((number: any) => parseFloat(number)),
        'curBuildingId': parseFloat(currentBuildingId),
        'newBuildingId': parseFloat(newBuildingId),
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

}
