<div class="dialog">
  <div class="title-row">
    <div class="row">
      <mat-icon class="case-icon">work</mat-icon>
      <span class="ktitle-medium">Position Details</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>

  <div class="content">
    <div class="detail-row">
      <div class="detail">
        <span class="klabel-medium label">Position Name</span>
        <span class="kbody-medium">{{data.positionTitle}}</span>
      </div>
      <div class="container">
      </div>
    </div>
    <div class="detail">
      <span class="klabel-medium label">Active Users</span>
      <span class="kbody-medium">{{position?.UserCount?.activeUsers || '0'}}</span>    </div>
    <div *ngIf=" data.isVisibleFlag" class="detail">
      <span class="klabel-medium label">Additional Details</span>
      <app-chip [text]="'visible to teacher'" [icon]="{type: 'checkmark'}"></app-chip>
    </div>
  </div>
 

</div>