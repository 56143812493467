<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon fontSet="material-icons-round" class="icon">key</mat-icon>
      <span class="kbody-huge">Get new code</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>

  <app-loading-spinner *ngIf="isLoading" />

  <div class="content" *ngIf="!isLoading">
    <p class="">
      Enter the email address associated with your account to retrieve a new
      code.
    </p>

    <div class="primary-options">
      <form class="form" #newCodeForm="ngForm">
        <label for="email">Email Address*</label>
        <input
          id="email"
          type="email"
          placeholder="Enter your district email"
          class="text-input"
          name="email"
          autocomplete="email"
          required
          #email="ngModel"
          email
          ngModel
          [ngClass]="{
            inputError: email.invalid && email.errors && email.touched
          }"
        />
        <div class="nav-row">
          <app-button type="submit" class="white" [text]="'Cancel'" />
          <app-button
            (click)="sendNewCodeRequest($event, newCodeForm)"
            class="green"
            [text]="'Get New Code'"
            [disabled]="!newCodeForm.valid"
          />
        </div>
      </form>
    </div>
  </div>
</div>
