<div class="my-course-requests">
  <div class="title-row">
    <span class="ktitle-medium">Course Requests</span>
    <app-button
      (click)="toggleUpdateModal()"
      text="Submit Course Request"
    ></app-button>
  </div>
  <div class="table">
    <div class="titles-row">
      <span class="klabel-medium label" *ngFor="let title of tableTitles">{{
        title.name
      }}</span>
    </div>
    <div class="items">
      <div class="item" *ngFor="let course of courseList">
        <span class="kbody-medium detail name">{{ course.courseName }}</span>
        <span class="kbody-medium detail"
          >{{ course.date }}, {{ course.time }}
        </span>
        <span class="kbody-medium detail">{{ course.submissionDate }}</span>
        <span class="kbody-medium detail" [ngClass]="course.requestStatus">{{
          course.requestStatus
        }}</span>
      </div>
    </div>
  </div>
</div>
