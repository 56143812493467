import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

// This is the basic guard to check if the user has the correct permission to validate course attendance
export const ManageStaffGuard = () => {
  const authService = inject(AuthService)
  const router = inject(Router)
  let permissions: any;

  const authSubscription = authService.permissions$
    .subscribe((userPermissions: any) => {
      permissions = userPermissions
    })

  if(permissions.USER_ACCOUNTS) {
    return true;
  }
  authSubscription.unsubscribe();
  return router.parseUrl('/dashboard')
}
