import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingSpinnerService {
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  public readonly isLoading$ = this._isLoading$.asObservable();

  constructor() { }

  setIsLoading = (isLoading: boolean) => {
    this._isLoading$.next(isLoading)
  }

}
