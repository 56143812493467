<div class="update-component">
  <div class="title-row">
   <div class="row">
    <mat-icon class="case-icon">work</mat-icon>
    <span class="klabel-huge">{{ data ? 'Edit Position' : 'Add new position' }}</span>
   </div>
   <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>
  <div class="content">
    <div class="details-row">
      <app-input [disabled]="data?.activeFlag === 0 ? true : false" class="detail" label="Position Title" value="{{position.positionTitle}}" placeholder="Enter position title" (updatedValue)="updatePositionParameters($event, 'positionTitle')"></app-input>
      <div *ngIf="data?.activeFlag" class="users" [ngClass]="{'end': !data}">
        <div >
          <span class="klabel-medium label">Staff Count</span>
        </div>
        <div>
          <span class="kbody-medium">{{position?.UserCount?.activeUsers || '0' }} Users</span>
        </div>
      </div>
    </div>
    <span class="error" *ngIf="error !== ''">{{ error }}</span>
    <div class="buttons" [ngClass]="{'end': !data}">
      <div class="actions">
        <app-button *ngIf="data?.activeFlag" [icon]="{type: 'close circle'}" (click)="setActivation(false)" [class]="'grey'"  [text]="'Deactivate'"></app-button>
        <app-button *ngIf="data && !data?.activeFlag" [icon]="{type: 'close circle'}" (click)="setActivation(true)" [class]="'grey'" [text]="'Activate'"></app-button>
      </div>
      <div class="actions">
        <app-button text="Cancel" [class]="'white'" (click)="closeDialog()">Cancel</app-button>
        <app-button (click)="savePosition()" text="{{ data ? 'Edit Position' : 'Add Position'}}" [icon]="data ? {type: 'edit'} : {type: 'success'}"></app-button>
      </div>
    </div>
  </div>
</div>