import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-course-validation-details',
  templateUrl: './course-validation-details.component.html',
  styleUrls: [ './course-validation-details.component.scss' ],
})
export class CourseValidationDetailsComponent implements OnInit, OnDestroy {
  tableTitles = [
    { name: 'Full Name' },
    { name: 'Email', class: 'email' },
    { name: 'Hours' },
    { name: 'Status', class: 'status' },
  ]
  isLoading = true;
  sessionValidationDetails: any;
  validationSubscription: any;
  selectedSession: any
  updatedValidations: any = [];
  bulkSubscription: any;

  constructor(
    private validationService: ValidationService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CourseValidationDetailsComponent>,
  ) { }

  ngOnInit(): void {
    this.selectedSession = this.data
    this.validationService.clearSessionValidationDetails();
    this.validationService.getSessionValidationDetails(this.data);
    this.validationSubscription = this.validationService.validationSessionData$.subscribe((response: any) => {
      this.sessionValidationDetails = response;
      this?.sessionValidationDetails?.rows.forEach((user: any) => {
        this.updateIndividualValidation(
          user, user.currentStatusKid, user.currentStatusKid,
        );
      })
    })
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  updateIndividualValidation = (
    user: any, validation: any, originalStatusKid?: any,
  ) => {
    // if the user is already in the updatedValidations array, remove it
    const userIndex = this.updatedValidations.findIndex((updatedValidation: any) => updatedValidation.user.loginName === user.loginName);
    if (userIndex !== -1) {
      this.updatedValidations.splice(userIndex, 1);
    }
    // if the user is unmarked, don't add them to the updatedValidations array
    if (validation === null) {
      return;
    }
    // add the updated user to the updatedValidations array
    const updatedValidation = {
      user: user,
      validation: validation,
      originalStatusKid: originalStatusKid || user.originalStatusKid,
    }
    // add the updated user to the updatedValidations array
    this.updatedValidations.push(updatedValidation);
  }

  approveAll = () => {
    this.sessionValidationDetails.rows.forEach((user: any) => {
      this.updateIndividualValidation(user, 'COMPLETED');
    })
  }

  unmarkAll = () => {
    this.sessionValidationDetails.rows.forEach((user: any) => {
      this.updateIndividualValidation(user, 'REGISTERED');
    })
  }

  denyAll = () => {
    this.sessionValidationDetails.rows.forEach((user: any) => {
      this.updateIndividualValidation(user, 'NOT_TAKEN');
    })
  }


  // these are functions for styling the buttons
  isApproved = (user: any) => {
    const isApprovedArray = this?.updatedValidations?.filter((validatedUser: any) => validatedUser.user.loginName === user.loginName && validatedUser.validation === 'COMPLETED');
    return isApprovedArray.length > 0;
  }

  isUnmarked = (user: any) => {
    const isUnmarkedArray = this?.updatedValidations?.filter((validatedUser: any) => validatedUser.user.loginName === user.loginName);
    return !(isUnmarkedArray.length > 0);
  }

  isDenied = (user: any) => {
    const isDeniedArray = this?.updatedValidations?.filter((validatedUser: any) => validatedUser.user.loginName === user.loginName && validatedUser.validation === 'NOT_TAKEN');
    return isDeniedArray.length > 0;
  }

  // this styles the select all buttons
  styleButton = (status: any) => {
    if (status === 'COMPLETED') {
      const markedCompleted = this.updatedValidations.filter((validatedUser: any) => validatedUser.validation === 'COMPLETED');
      const isSelected = markedCompleted.length > 0 && markedCompleted.length === this.sessionValidationDetails?.rows?.length;
      return isSelected ? true : false;
    } else if (status === 'NOT_TAKEN') {
      const markedDenied = this.updatedValidations.filter((validatedUser: any) => validatedUser.validation === 'NOT_TAKEN');
      const isSelected = markedDenied.length > 0 && markedDenied.length === this.sessionValidationDetails?.rows?.length;
      return isSelected ? true : false;
    }
    return
  }

  // this saves the updated validations
  saveValidationUpdates = () => {
    // here we are formatting the data to send to the backend
    const formattedUpdatedValidations = this.updatedValidations.map((updatedValidation: any) => {
      return {
        id: parseFloat(updatedValidation.user.userCourseId),
        currentStatusKid: updatedValidation.validation,
        adjustedValue: parseFloat(updatedValidation.user.adjustedValue),
        originalStatusKid: updatedValidation.originalStatusKid,
      }
    })
    // here we remove any validations that haven't been updated
    const filteredValidations = formattedUpdatedValidations.filter((validation: any) => validation.currentStatusKid !== validation.originalStatusKid);
    // here we are packaging the data to send to the backend
    const validationPackage = {
      courseId: this.selectedSession.courseId,
      courseItemId: this.selectedSession.courseItemId,
      updateArr: filteredValidations,
    }
    this.isLoading = true;
    this.bulkSubscription = this.validationService.bulkValidate(validationPackage).subscribe({
      next: (response: any) => {
        this.dialogRef.close('result');
        this.isLoading = false;
        this.toastService.setToast({
          text: 'Validations updated successfully', type: 'success', icon: true,
        })
      },
      error: (error: any) => {
        console.log('error', error);
      },
    })

  }

  ngOnDestroy(): void {
    this.validationSubscription.unsubscribe();
    this.bulkSubscription?.unsubscribe();
  }
}
