<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Requirements</span>
    </div>
    <span class="kbody-large description">Check all requirements to apply to this users PD Plan. You can adjust the
      hours needed for each requirement for this user.</span>



    <!--TODO Add Rollover Field-->
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
      style="width: 100%; display: flex; flex-direction: column; gap: 16px;"
      [ngClass]="['treeChecklist', 'mat-tree', 'kbody-large']">
      <!-- Leaf node -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
        [ngClass]="{'spread': (node.nodeType === 'SINGLE' || node.nodeType === 'GROUP_PARENT'), 'short-row': (node.nodeType === 'GROUP_NODE' || node.nodeType === 'GROUP_LEAF')}"
        [disabled]="checkDisabled(node)">
        <div class="row">
          <mat-checkbox font="body" [checked]="checklistSelection.isSelected(node)"
            [disabled]="checkboxDisabled(node)" (change)="ItemSelectionToggle(node)">
            <span [ngClass]="{'kbody-large': (node.nodeType === 'GROUP_NODE' || node.nodeType === 'GROUP_LEAF'), 'kbody-large-strong': (node.nodeType !== 'GROUP_NODE' && node.nodeType !== 'GROUP_LEAF')}">{{node.requirement}}</span>
          </mat-checkbox>
        </div>

        <div>
          <label class="CHlabel kbody-small-strong">{{node.label}}</label>
          <input [value]="checkValue(node)" type="number" class="input-bordered" placeholder="0.00" min="0" step="0.25"
            (change)="updateCreditHours($event, node)" [disabled]="inputDisabled(node)">
          <span *ngIf="creditError && (node.nodeType !== 'SINGLE' && node.nodeType !== 'GROUP_PARENT')"
            class="error kbody-small">{{ node.error }}</span>
        </div>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding [disabled]="checkDisabled(node)">
      </mat-tree-node>

      <!-- expandable node -->
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="48"
        [ngClass]="{'spread': (node.nodeType === 'SINGLE' || node.nodeType === 'GROUP_PARENT'), 'short-row': (node.nodeType === 'GROUP_NODE' || node.nodeType === 'GROUP_LEAF')}"
        [disabled]="checkDisabled(node)">
        <div class="row">
          <mat-checkbox font="body" [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)" [disabled]="checkboxDisabled(node)"
            (change)="ItemSelectionToggle(node)">
            <span [ngClass]="{'kbody-large': (node.nodeType === 'GROUP_NODE' || node.nodeType === 'GROUP_LEAF'), 'kbody-large-strong': (node.nodeType !== 'GROUP_NODE' && node.nodeType !== 'GROUP_LEAF')}">{{node.requirement}}</span>
          </mat-checkbox>
        </div>

        <div>
          <label class="CHlabel kbody-small-strong">{{node.label}}</label>
          <input [value]="checkValue(node)" type="number" class="input-bordered" placeholder="0.00" min="0" step="0.25"
            (change)="updateCreditHours($event, node)" [disabled]="inputDisabled(node)">
          <span *ngIf="creditError && (node.nodeType !== 'SINGLE' && node.nodeType !== 'GROUP_PARENT')"
            class="error kbody-small">{{ node.error }}</span>
        </div>

      </mat-tree-node>
    </mat-tree>



  </div>

  <div class="buttons">
    <app-button (click)="stepBackward()" [text]="'Previous Step'" [icon]="{materialIcon: 'arrow_back'}"
      [class]="'white'"></app-button>
    <div class="actions">
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeDialog()"></app-button>
      <app-button [disabled]="false" [icon]="{ materialIcon: 'arrow_forward' }" [class]="'icon-right'"
        [text]="'Next Step'" (click)="updateUser()"></app-button>
    </div>
  </div>
</div>