<div class="dialog">
  <div class="title-row">
    <div class="container">
      <mat-icon fontSet="material-icons-round">location_city</mat-icon>
      <span class="klabel-huge">Building Details</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeDialog()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <div class="details-row">
      <div class="detail-container">
        <div class="detail left-align">
          <span class="klabel-medium label">Building Name</span>
          <span class="kbody-medium">{{ data.buildingData.buildingName }}</span>
        </div>
        <div class="detail left-align">
          <span class="klabel-medium label">Building Type</span>
          <span class="kbody-medium">{{
            data.buildingData.buildingType || "no building type info"
          }}</span>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail">
          <span class="klabel-medium label">Building Number</span>
          <span class="kbody-medium">{{
            data.buildingData.buildingNumber || "-"
          }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Staff Count</span>
          <span class="kbody-medium"
            >{{ this.buildingDetails?.UserCount.activeUsers || 0 }} people</span
          >
        </div>
      </div>
    </div>
    <div class="section">
      <span class="klabel-medium label">Location</span>
      <div class="detail-row">
        <mat-icon fontSet="material-icons-round">location_on</mat-icon>
        <span class="kbody-medium"
          >{{ data.buildingData.street1 || "no street info"
          }}{{ data.buildingData.city && ", " }}{{ data.buildingData?.city
          }}{{ data.buildingData.stateCode && ", "
          }}{{ data.buildingData?.stateCode
          }}{{ data.buildingData.zipcode && ", "
          }}{{ data.buildingData?.zipcode }}</span
        >
      </div>
    </div>
    <div class="section">
      <span class="klabel-medium label">Contact Information</span>
      <div class="detail-row">
        <mat-icon fontSet="material-icons-round">person</mat-icon>
        <span class="klabel-medium top">Supervisor</span>
        <span class="kbody-medium">{{
          this.buildingDetails?.supervisorName
        }}</span>
      </div>
      <div class="detail-row">
        <mat-icon fontSet="material-icons-round">phone</mat-icon>
        <span class="klabel-medium top">Primary Phone</span>
        <span class="kbody-medium">{{
          data.buildingData.telephone || "no phone info"
        }}</span>
      </div>
      <div class="detail-row">
        <mat-icon fontSet="material-icons-round">fax</mat-icon>
        <span class="klabel-medium top">Primary Fax</span>
        <span class="kbody-medium">{{
          data.buildingData.fax || "no fax info"
        }}</span>
      </div>
    </div>
  </div>

  <div class="buttons">
    <div class="options">
      <app-button
        *ngIf="data.buildingData && !data.buildingData?.activeFlag"
        (click)="setActivation(true)"
        [class]="'grey'"
        [text]="'Activate Building'"
      ></app-button>
      <app-button
        *ngIf="data.buildingData?.activeFlag"
        [icon]="{ materialIcon: 'highlight_off' }"
        [class]="'grey'"
        (click)="setActivation(false)"
        [text]="'Deactivate Building'"
      ></app-button>
    </div>
    <div class="options">
      <app-button
        (click)="closeDialog()"
        [class]="'white'"
        [text]="'Close'"
      ></app-button>
      <app-button
        (click)="showEditModal()"
        [icon]="{ materialIcon: 'edit' }"
        text="Edit Building"
      ></app-button>
    </div>
  </div>
</div>
