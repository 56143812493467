<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon fontSet="material-icons-round" class="icon">send</mat-icon>
      <span class="kbody-huge">Push Course</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <div class="primary-push-options" *ngIf="!selectedPrimaryPushType">
      <span class="klabel-huge">Choose push type:</span>
      <div
        class="option-row"
        (click)="selectedPrimaryPushType = 'Push to Group'"
        *ngIf="user.Roles[0].accessLevel === 'MANAGE_ALL_AUTHORITY'"
      >
        <span class="ksubtitle-huge">Push to Group</span>
        <mat-icon fontSet="material-icons-round" class="icon"
          >chevron_right</mat-icon
        >
      </div>
      <!-- TODO - cleanup - uncomment as functionality is released -->
      <!-- <div class="option-row">
        <span class="ksubtitle-huge">Push to all staff I manage</span>
        <mat-icon fontSet="material-icons-round" class="icon">chevron_right</mat-icon>
      </div>
      <div class="option-row">
        <span class="ksubtitle-huge">Push to Individual People</span>
        <mat-icon fontSet="material-icons-round" class="icon">chevron_right</mat-icon>
      </div>
      <div class="option-row">
        <span class="ksubtitle-huge">Quick People Push</span>
        <mat-icon fontSet="material-icons-round" class="icon">chevron_right</mat-icon>
      </div>
      <div class="option-row" *ngIf="permissions.COURSE_ADD">
        <span class="ksubtitle-huge">Copy and Push</span>
        <mat-icon fontSet="material-icons-round" class="icon">chevron_right</mat-icon>
      </div>
      <div class="option-row">
        <span class="ksubtitle-huge">Upload Spreadsheet</span>
        <mat-icon fontSet="material-icons-round" class="icon">chevron_right</mat-icon>
      </div> -->
    </div>

    <!-- this is where all the primary push options will go -->
    <app-push-to-group
      [completeCourseSessionDetails]="completeCourseSessionDetails"
      (backToPrimaryPushList)="selectedPrimaryPushType = null"
      class="component"
      *ngIf="selectedPrimaryPushType === 'Push to Group'"
    ></app-push-to-group>

    <div *ngIf="!selectedPrimaryPushType" class="nav-row">
      <div class="left-nav">
        <app-button
          (click)="closeModal()"
          [text]="'Back to course details'"
          [class]="'white'"
          [icon]="{ materialIcon: 'arrow_back' }"
        ></app-button>
      </div>
      <app-button
        (click)="closeModal()"
        [text]="'Exit Window'"
        [class]="'white'"
      ></app-button>
    </div>
  </div>
</div>
