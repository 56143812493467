import { Component } from '@angular/core';

@Component({
  selector: 'app-catalog-suggestions-widget',
  templateUrl: './catalog-suggestions-widget.component.html',
  styleUrls: [ './catalog-suggestions-widget.component.scss' ],
})
export class CatalogSuggestionsWidgetComponent {

  courses = [
    {
      name: 'Angular', date: '10/24/23', author: 'John Doe',
    },
    {
      name: 'React', date: '10/24/23', author: 'Jane Smith',
    },
    {
      name: 'Vue', date: '10/24/23', author: 'Alex Johnson',
    },
    {
      name: 'Angular', date: '10/24/23', author: 'John Doe',
    },
    {
      name: 'React', date: '10/24/23', author: 'Jane Smith',
    },
    {
      name: 'Vue', date: '10/24/23', author: 'Alex Johnson',
    },
    {
      name: 'Angular', date: '10/24/23', author: 'John Doe',
    },
    {
      name: 'React', date: '10/24/23', author: 'Jane Smith',
    },
    {
      name: 'Vue', date: '10/24/23', author: 'Alex Johnson',
    },
    {
      name: 'Angular', date: '10/24/23', author: 'John Doe',
    },
    {
      name: 'React', date: '10/24/23', author: 'Jane Smith',
    },
    {
      name: 'Vue', date: '10/24/23', author: 'Alex Johnson',
    },
    {
      name: 'Angular', date: '10/24/23', author: 'John Doe',
    },
    {
      name: 'React', date: '10/24/23', author: 'Jane Smith',
    },
    {
      name: 'Vue', date: '10/24/23', author: 'Alex Johnson',
    },
    {
      name: 'Angular', date: '10/24/23', author: 'John Doe',
    },
    {
      name: 'React', date: '10/24/23', author: 'Jane Smith',
    },
    {
      name: 'Vue', date: '10/24/23', author: 'Alex Johnson',
    },

  ]
}
