import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-registration-notice',
  templateUrl: './registration-notice.component.html',
  styleUrls: [ './registration-notice.component.scss' ],
})
export class RegistrationNoticeComponent {
  @Input() defaults: any;
  @Output() setNewDefaults = new EventEmitter<object>();


  updateRegistrationNotice = (event: any, key: any) => {
    const newDefaults = { [key]: event.target.value };
    this.setNewDefaults.emit(newDefaults);
  }
}
