import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-update-my-course-request',
  templateUrl: './update-my-course-request.component.html',
  styleUrls: [ './update-my-course-request.component.scss' ],
})
export class UpdateMyCourseRequestComponent {
  newCourse: any = {
    name: '',
    creditHours: '',
    courseType: '',
    instructor: '',
  }

  constructor(
    public dialogRef: MatDialogRef<UpdateMyCourseRequestComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeDialog = () => {
    this.dialogRef.close();
  }

  updateCourseParameter = (event: any, parameter: string) => {
    this.newCourse[parameter] = event.target.value;
  }

  updateCourse = () => {
    console.log('this.newCourse', this.newCourse);
  }

}
