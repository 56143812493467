<div class="registration-container">
  <span class="klabel-huge">Registration Notice</span>
  <div class="section">
    <span class="klabel-medium label"
      >Message displayed to teachers after they register for an outside
      course:</span
    >
    <textarea
      class="kinput input"
      type="text"
      rows="5"
      [value]="
        defaults?.registrationNotice || defaults?.Config?.registrationNotice
      "
      (keyup)="updateRegistrationNotice($event, 'registrationNotice')"
      placeholder="Enter your registration notice here"
    ></textarea>
  </div>
</div>
