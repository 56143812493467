<div class="dashboard">
  <span class="ktitle-medium">Dashboard</span>
  <div #dropListContainer class="widgets" cdkDropListGroup>
    <div
      *ngFor="let widget of widgets; let i = index"
      cdkDropList
      [cdkDropListData]="i"
      class="widget-container"
    >
      <div
        cdkDrag
        [cdkDragData]="i"
        (cdkDragEntered)="dragEntered($event)"
        (cdkDragMoved)="dragMoved($event)"
        (cdkDragDropped)="dragDropped($event)"
        class="widget"
      >
        <div class="title-row">
          <div class="title">
            <mat-icon fontSet="material-icons-round" cdkDragHandle class="handle">drag_indicator</mat-icon>
            <span class="klabel-huge">{{ widget.title }}</span>
            <app-tooltip [tip]="widget.tip"></app-tooltip>
          </div>
          <mat-icon class="expand-icon" fontSet="material-icons-round" (click)="widget.showContent = !widget.showContent">expand_more</mat-icon>
        </div>
        <div *ngIf="widget.showContent" class="content">
          <app-status-widget *ngIf="widget.component === 'status'"></app-status-widget>
          <app-calendar-widget *ngIf="widget.component === 'calendar'"></app-calendar-widget>
          <app-bulletin-widget *ngIf="widget.component === 'bulletin'"></app-bulletin-widget>
          <app-validation-widget *ngIf="widget.component === 'validation'"></app-validation-widget>
          <app-catalog-suggestions-widget *ngIf="widget.component === 'suggestions'"></app-catalog-suggestions-widget>
          <app-course-requests-widget *ngIf="widget.component === 'requests'"></app-course-requests-widget>
          <app-waitlist-widget *ngIf="widget.component === 'waitlist'"></app-waitlist-widget>
        </div>
      </div>
    </div>
  </div>
</div>