<div class="form-header">
  <ng-container *ngIf="tabs.codeConfirmation">
    <h1>Welcome to Kalpa!</h1>
    <p>Enter your email & invite code.</p>
  </ng-container>
  <ng-container *ngIf="tabs.passwordChange">
    <h1>Set Password</h1>
    <p>
      Enter a new password. Your password must be at least 8 characters long and
      include upper and lowercase letters, numbers, and at least one special
      character.
    </p>
  </ng-container>
</div>

<form
  *ngIf="tabs.codeConfirmation"
  class="form"
  #codeForm="ngForm"
  (ngSubmit)="submitConfirmationCodeRequest(codeForm)"
>
  <label for="email">District Email*</label>
  <input
    id="email"
    type="email"
    placeholder="Enter your district email"
    class="text-input"
    name="email"
    autocomplete="email"
    autofocus
    required
    #email="ngModel"
    email
    ngModel
    [ngClass]="{
      inputError: email.invalid && email.errors && email.touched
    }"
  />

  <div class="code-container">
    <input
      *ngFor="let item of [].constructor(confirmationCodeLength); let i = index"
      [id]="'num-' + i"
      type="text"
      class="num-input"
      maxlength="1"
      placeholder="0"
      class="code-input"
      [name]="'number-' + i"
      #num="ngModel"
      ngModel
      required
      (keyup)="onCodeKeyPress(codeForm, $event)"
      (paste)="onCodePaste(codeForm, $event)"
      [ngClass]="{
        inputError: num.invalid && num.errors && num.touched
      }"
    />
  </div>
  <div class="btn-container code">
    <button
      type="submit"
      class="submit-btn"
      [ngClass]="{ disabled: !codeForm.valid }"
      [disabled]="!codeForm.valid"
    >
      Submit Code
    </button>
  </div>
  <div class="center-text">
    <p>
      Code expired, or don’t have one?
      <a (click)="openCodeModal(codeForm)" class="link green-link">
        <strong> Send New Code </strong>
      </a>
    </p>
  </div>
</form>

<app-password-change
  *ngIf="tabs.passwordChange"
  (passwordSubmitEvent)="submitPasswordChangeRequest($event)"
></app-password-change>

<div class="debug" *ngIf="debugMode">
  <button (click)="toggleTabs('codeConfirmation')">Code Confirmation</button>
  <button (click)="toggleTabs('passwordChange')">Password Change</button>
</div>
