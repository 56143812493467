import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApprovalService {
  private _approvalData$ = new BehaviorSubject<any>(null);
  public readonly approvalData$ = this._approvalData$.asObservable();

  user: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => {
      if (!user) return;
      this.user = user;
    });
  }

  getApprovalLevels = () => {
    return this.http.get(`${environment.expressUrl}/global/approval-levels`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this.user.loginName },
      }).subscribe({
      next: (response: any) => {
        this._approvalData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }
}
