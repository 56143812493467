import {
  Component, Output, EventEmitter, Input,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';

@Component({
  selector: 'app-course-requirements-filters',
  templateUrl: './course-requirements-filters.component.html',
  styleUrls: [ './course-requirements-filters.component.scss' ],
})
export class CourseRequirementsFiltersComponent {
  @Input() mainFilter: CourseFilter;
  @Input() requirementList: any;
  @Output() updateMainFilter = new EventEmitter<object>();

  constructor() { }

  checkIncludesRequirement(req: any) {
    return !!this.mainFilter.requirements.find((requirement: any) => requirement.id === req.id);
  }

  updateCheckboxFilter(event: any, requirement: any): void {
    const isChecked = event?.target?.checked;

    if (isChecked) {
      this.mainFilter.requirements.push(requirement);
    } else {
      this.mainFilter.requirements = this.mainFilter.requirements.filter((req: any) => req.id !== requirement.id);
    }
  }

  selectAllRequirementsFilter(event: any): void {
    if (event.target.checked) {
      this.mainFilter.requirements = this.requirementList;
    } else {
      this.mainFilter.requirements = [];
    }
  }

  clearRequirementsFilter(): void {
    this.mainFilter.requirements = [];
  }

}
