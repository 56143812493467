<div class="system-settings">
  <span class="ktitle-huge">System Settings</span>
  <div class="sub-tabs">
    <div 
      *ngFor="let tab of tabList" 
      (click)="selectTab(tab.name)" 
      class="sub-tab" 
      [ngClass]="{'selected': tab.name === selectedTab }">
      <mat-icon class="icon" fontSet="material-icons-round">{{ tab.icon }}</mat-icon>
      <span class="klabel-large">{{ tab.name }}</span>
    </div>
  </div>
  <div class="small-select">
    <span class="klabel-small">Select Setting</span>
    <select class="select" #settings (change)="selectTab(settings.value)" [value]="selectedTab">
      <option *ngFor="let tab of tabList" [value]="tab.name">{{ tab.name }}</option>
    </select>
  </div>
  <div class="tab-content">
    <app-buildings *ngIf="selectedTab === 'Buildings'"></app-buildings>
    <app-positions *ngIf="selectedTab === 'Positions'"></app-positions>
    <app-requirements *ngIf="selectedTab === 'Requirements'"></app-requirements>
    <app-tags *ngIf="selectedTab === 'Tags'"></app-tags>
    <app-defaults *ngIf="selectedTab === 'Defaults'"></app-defaults>
    <app-course-types *ngIf="selectedTab === 'Course Types'"></app-course-types>
    <app-categories *ngIf="selectedTab === 'Categories'"></app-categories>
    <app-user-roles *ngIf="selectedTab === 'User Roles'"></app-user-roles>
  </div>
</div>
