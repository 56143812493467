import { Component } from '@angular/core';

@Component({
  selector: 'app-line-icon',
  templateUrl: './line-icon.component.html',
  styleUrls: [ './line-icon.component.scss' ],
})
export class LineIconComponent {

}
