import { Component } from '@angular/core';

@Component({
  selector: 'app-kalpa-red',
  templateUrl: './kalpa-red.component.html',
  styleUrls: [ './kalpa-red.component.scss' ],
})
export class KalpaRedComponent {

}
