import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoursesService } from 'src/app/services/courses.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { RequirementsService } from 'src/app/services/requirements.service';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: [ './my-courses.component.scss' ],
})
export class MyCoursesComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() activeTab: string;
  requirements: any;
  requirementGroups: any;
  requirementSingles: any;
  courses: any;
  courseSubscription: Subscription;
  popup: any;
  table = {
    columnTitles: [
      // { title: 'ID', class: 'skinny' },
      { title: 'Course', class: 'wide' },
      { title: 'Date & Time', class: 'date-time' },
      { title: 'Location', class: 'only-large' },
      { title: 'Hours', class: 'only-large skinny' },
      { title: 'Tags', class: 'only-large skinny' },
      { title: 'Status', class: '' },
      { title: 'Action', class: 'skinny' },
    ],
  }
  formattedCourses: any[] = [];
  formattedRequirements: any;
  portfolioCourses: { Courses: any[]; } = { Courses: [] };
  isLoading = false;
  error: any;
  formattedCourse: any;
  viewingOthersProfile: any;
  permissions: any;
  permissionSubscription: any;

  constructor(
    public dialog: MatDialog,
    private coursesService: CoursesService,
    private requirementsService: RequirementsService,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    // this.isLoading = true;
    // here we get the user permissions from the auth service
    this.permissionSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
    })
    // here we check if the user is viewing their own profile or someone else's
    this?.route?.snapshot?.params['id'] ? this.viewingOthersProfile = true : this.viewingOthersProfile = false

    // const userRequirements = this.requirementsService.getUserRequirements(this.user?.id, true)

    // userRequirements.subscribe((requirements: any) => {
    //   console.log('requirements', requirements)
    //   this.requirements = requirements.data.rows;
    //   console.log('🚀 - this.requirements:', this.requirements);
    //   this.requirementGroups = this.requirements.GROUPS;
    //   this.requirementSingles = this.requirements.SINGLE;

    //   this.isLoading = false;
    // })

    // this.coursesService.getCourseRegistrations({ userId: this.user?.id, includedAssociations: 'Requirement, Course, CourseItem' })
    // this.courseSubscription = this.coursesService.coursesData$.subscribe((courses: any) => {
    //   this.formattedRequirements = this.user?.Requirements.map((requirement: any) => { return { ...requirement, Courses: [] } });
    //   this.portfolioCourses = { Courses: [] };
    //   if(courses?.error) {
    //     this.isLoading = false;
    //     this.error = 'No Courses Found'
    //   }
    //   // loop through the courses.rows and push into the corresponding requirement in formattedRequirements
    //   courses?.rows?.forEach((course: any) => {
    //     // here we format the time for display


    //     // here we find the requirement that matches the course
    //     const requirement = this.formattedRequirements?.find((requirement: any) => requirement?.id === course?.Requirement?.id);
    //     // if the requirement exists, we push the course into the requirement's Courses array
    //     if(requirement) {
    //       this.formattedCourse = course
    //       this.formattedCourse.CourseItem.dateOffered = new Date(course?.CourseItem?.dateOffered).toLocaleDateString();
    //       const requirementIndex = this.formattedRequirements.findIndex((formattedRequirement: any) => formattedRequirement?.id === requirement?.id)
    //       this.formattedRequirements[requirementIndex].Courses.push(this.formattedCourse);
    //     } else {
    //       // if the requirement doesn't exist, we push the course into the portfolioCourses array
    //       const formattedCourse = course
    //       formattedCourse.CourseItem.dateOffered = new Date(course?.CourseItem?.dateOffered).toLocaleDateString();
    //       this.portfolioCourses?.Courses.push(formattedCourse);
    //     }
    //   })
    //   courses?.rows ? this.isLoading = false : null;
    // })
  }

  setPopup = (popup: any) => {
    if(this.popup === popup) {
      this.popup = '';
      return;
    }
    this.popup = popup;
  }

  ngOnDestroy(): void {
    // this.courseSubscription.unsubscribe();
    this.permissionSubscription.unsubscribe();
  }

}
