import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: [ './attachments.component.scss' ],
})
export class AttachmentsComponent implements OnInit {
  @Output() updateCourseObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Input() course: any;
  @Input() isEditMode: any;
  updatedCourse: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.updatedCourse = this.course;
  }

  updateLocalCourseParameter = (event: any, parameter: string) => {
    this.updatedCourse[parameter] = event;
  }

  updateCourse = () => {
    this.updateCourseObject.emit(this.updatedCourse);
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.closeDialogEmitter.emit();
  }

}
