<div class="app">
  <app-sidebar *ngIf="loggedIn$"></app-sidebar>
  <div class="content">
    <app-header></app-header>
    <app-breadcrumbs *ngIf="loggedIn$" class="breadcrumbs"></app-breadcrumbs>
    <router-outlet></router-outlet>
    <div class="app-footer">
      <app-footer *ngIf="!loggedIn$"></app-footer>
    </div>
  </div>
  <app-toast></app-toast>
</div>
<div class="spinner-container" *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>
