<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon class="timer" fontSet="material-icons-round">timer</mat-icon>
      <span class="kbody-huge">Join Waitlist</span>
    </div>
    <mat-icon class="close" fontSet="material-icons-round" (click)="closeModal()">close</mat-icon>
  </div>
  <div class="content">
    <span class="kbody-large">This course will appear in your PD plan with a status of waiting.</span>
    <span class="kbody-large">If space becomes available for this course, your status for this course will automatically change to registered and you will receive an email notification.</span>
    <span class="kbody-large">If space does not open up, the course will drop from your PD plan after it occurs.</span>
  </div>
  <div class="buttons">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeModal()"
      ></app-button>
    <app-button
      [text]="'Join Waitlist'"
      [icon]="{ materialIcon: 'done' }"
      (click)="registerForCourse()"
      ></app-button>
  </div>
</div>