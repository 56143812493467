<div class="defaults-container">
  <div class="title-row">
    <span class="klabel-huge">Entity Default Settings</span>
    <app-button
      [class]="'full'"
      (click)="saveChanges()"
      [disabled]="!isChanged"
      [text]="'Save Changes'"
      [icon]="{ materialIcon: 'done' }"
    ></app-button>
  </div>
  <div class="tabs">
    <div
      class="tab klabel-medium"
      [ngClass]="{ active: activeTab === 'general' }"
      (click)="activeTab = 'general'"
    >
      General Information
    </div>
    <div
      class="tab klabel-medium"
      [ngClass]="{ active: activeTab === 'approval' }"
      (click)="activeTab = 'approval'"
    >
      Approval Levels
    </div>
    <div
      class="tab klabel-medium"
      [ngClass]="{ active: activeTab === 'registration notice' }"
      (click)="activeTab = 'registration notice'"
    >
      Registration Notice
    </div>
    <div
      class="tab klabel-medium"
      [ngClass]="{ active: activeTab === 'registration message' }"
      (click)="activeTab = 'registration message'"
    >
      Registration Message
    </div>
  </div>
  <div class="small-select">
    <span class="klabel-small">Section</span>
    <select
      class="select"
      #section
      (change)="activeTab = section.value"
      [value]="activeTab"
    >
      <option value="general">General Information</option>
      <option value="approval">Approval Levels</option>
      <option value="registration notice">Registration Notice</option>
      <option value="registration message">Registration Message</option>
    </select>
  </div>
  <div class="content">
    <app-general
      [entity]="entity"
      (setNewDefaults)="setNewDefaults($event)"
      [defaults]="updatedDefaults"
      *ngIf="activeTab === 'general'"
      [error]="error"
    ></app-general>
    <app-approval
      (setNewDefaults)="setNewDefaults($event)"
      [defaults]="updatedDefaults"
      *ngIf="activeTab === 'approval'"
      [error]="error"
    ></app-approval>
    <app-registration-message
      (setNewDefaults)="setNewDefaults($event)"
      [defaults]="updatedDefaults"
      *ngIf="activeTab === 'registration message'"
    ></app-registration-message>
    <app-registration-notice
      (setNewDefaults)="setNewDefaults($event)"
      [defaults]="updatedDefaults"
      *ngIf="activeTab === 'registration notice'"
    ></app-registration-notice>
  </div>
</div>
