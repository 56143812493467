import {
  Component, Inject, OnInit, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-push-modal',
  templateUrl: './push-modal.component.html',
  styleUrls: [ './push-modal.component.scss' ],
})
export class PushModalComponent implements OnInit, OnDestroy {
  selectedPrimaryPushType: any = null;
  completeCourseSessionDetails: any;
  user: any;
  userSubscription: any;
  permissions: any;
  permissionsSubscrioption: any;

  constructor(
    public dialogRef: MatDialogRef<PushModalComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.completeCourseSessionDetails = this.data?.completeCourseSessionDetails;
    this.userSubscription = this.authService.user$.subscribe((user: any) => {
      this.user = user;
    });
    this.permissionsSubscrioption = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
    });
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.permissionsSubscrioption.unsubscribe();
  }

}
