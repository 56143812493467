<div class="form-header">
  <h1>Change Password</h1>

  <div>
    <p>Your password must be changed before you are allowed to log in.</p>
  </div>
</div>

<app-password-change
  (passwordSubmitEvent)="changePassword($event)"
></app-password-change>
