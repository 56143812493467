import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: [ './breadcrumbs.component.scss' ],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  routerSubscription: any;
  breadcrumbs: any;
  breadcrumbObjects: any;

  constructor( private router: Router) { }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.breadcrumbs = this.router.url
      // split url string into an array of capitalized de-hypenated words
        .split('/')
        .map((breadcrumb) => {
          return breadcrumb.replace('-', ' ').replace(/\b\w/g, (l) => l.toUpperCase());
        })
        // remove the first element (empty string)
        .slice(1);
      // this creates an array of objects that have the breadcrumb name and the url
      this.breadcrumbObjects = this.breadcrumbs.map((breadcrumb: any, index: any) => {
        return {
          name: breadcrumb,
          url: this.router.url.split('/').slice(0, index + 2).join('/'),
        };
      });
      // if the name of an object in the breadCrumbObjects is 'Manage Courses' change its url to 'manage-courses/index'
      this.breadcrumbObjects.forEach((breadcrumb: any) => {
        if (breadcrumb.name === 'Manage Courses') {
          breadcrumb.url = 'manage-courses/index';
        }
      });
    })
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

}
