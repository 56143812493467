<div class="container">
  <div class="title-row">
    <span class="klabel-huge">Filter by Tag</span>
    <app-button
      [text]="'De-select all'"
      class="grey"
      (click)="clearTagsFilter()"
    ></app-button>
  </div>
  <div class="content">
    <div class="checkboxes">
      <div *ngIf="tagList.length !== 0" class="checkbox-row">
        <input
          type="checkbox"
          class="kinput"
          [checked]="
            tagList?.length === mainFilter.tags.length && tagList.length !== 0
          "
          (change)="selectAllTagsFilter($event)"
        />
        <span class="kbody-medium">Select all tags</span>
      </div>
      <div class="checkbox-row" *ngFor="let tag of tagList">
        <input
          type="checkbox"
          class="kinput"
          [checked]="checkIncludesRequirement(tag)"
          (change)="updateCheckboxFilter($event, tag)"
        />
        <span class="kbody-medium">{{ tag?.tag }}</span>
      </div>
    </div>
  </div>
</div>
