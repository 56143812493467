<div class="container">
  <div class="old-site">
    <a href="https://www.kalpapdms.com/v3_teachers/home.aspx" target="_blank"
      >Take me to classic Kalpa</a
    >
  </div>
  <div class="forms">
    <app-forgot-password
      *ngIf="modes['forgotPassword']"
      [debugMode]="debugMode"
      (backToSignIn)="toggleMode('basicSignIn')"
      [changePassDetails]="changePassDetails"
    />
    <app-first-time-login
      *ngIf="modes['firstTime']"
      [debugMode]="debugMode"
      (backToSignIn)="toggleMode('basicSignIn')"
    ></app-first-time-login>
    <app-log-in
      *ngIf="modes['basicSignIn']"
      (toggleForgotPasswordEvent)="toggleMode('forgotPassword')"
      (authStatusEvent)="interpretAuthContinuation($event)"
    />
    <app-complete-password-change
      *ngIf="modes['completePassword']"
      [changePassDetails]="changePassDetails"
      (backToSignIn)="toggleMode('basicSignIn')"
    ></app-complete-password-change>
  </div>

  <div id="first-time-container">
    <p
      class="first-time-nav"
      (click)="toggleMode('firstTime')"
      *ngIf="modes['basicSignIn']"
    >
      First time logging in? <strong>Click here</strong>
    </p>
    <p
      class="first-time-nav"
      (click)="toggleMode('basicSignIn')"
      *ngIf="!modes['basicSignIn']"
    >
      Go Back to <strong>sign-in</strong>
    </p>
  </div>

  <ng-container *ngIf="debugMode">
    <div class="form">
      <h2>Debug Area</h2>
      <p>
        Log into AWS and remove yourself from the amplify studio before clicking
        the button or an error will occur. Only main Cynerge emails will work.
      </p>
      <label for="email">Testing AWS Auth</label>
      <input
        name="email"
        type="email"
        [(ngModel)]="testEmail"
        class="input text-input"
        autofill="off"
        placeholder="Put your email to test auth flow"
      />
      <button (click)="sendFakeCreateUserRequestToCognito()">
        Add Cynerge Test Email to AWS
      </button>
    </div>
  </ng-container>
</div>
