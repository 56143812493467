import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';


@Component({
  selector: 'app-status-widget',
  templateUrl: './status-widget.component.html',
  styleUrls: [ './status-widget.component.scss' ],
})
export class StatusWidgetComponent implements OnInit {
  public chart: any;

  constructor() {}

  ngOnInit(): void {
    this.createChart();
  }

  createChart(){
    // https://www.chartjs.org/ for more info on how to use the chart
    this.chart = new Chart('MyChart', {
      type: 'doughnut', //this denotes tha type of chart

      data: {
        labels: [
          'Not Taken', 'Take Survey', 'Completed', 'Needs Validation', 'Denied', 'Cancelled',
        ],
        datasets: [
          {
            label: 'Number of courses ',
            data: [
              300, 240, 100, 432, 253, 34,
            ],
            backgroundColor: [
              '#2E3B63',
              '#0097A3',
              '#8FBF5E',
              '#FDBA51',
              '#AF2F0D',
              '#C2731C',
            ],
            hoverOffset: 40,
          },
        ],
      },
      options: {
        plugins: { legend: { position: 'right', fullSize: true } },
        responsive: true,
        layout: { padding: 10 },
        aspectRatio: 1.5,
      },

    });
  }

}
