import {
  Component, Inject, Input, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses.service';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { EntityService } from 'src/app/services/entity.service';
import { ToastService } from 'src/app/services/toast.service';
import { AuthService } from 'src/app/services/auth.service';
import { RequirementsService } from 'src/app/services/requirements.service';
import Course from './course.class';

@Component({
  selector: 'app-update-course',
  templateUrl: './update-course.component.html',
  styleUrls: [ './update-course.component.scss' ],
})
export class UpdateCourseComponent implements OnInit, OnDestroy {
  @Input() courseToEdit: any;
  courseToEditDetails: any;
  entitySubscription: any;
  requirementSubscription: Subscription;

  steps = [
    { completed: false, text: 'General Info' },
    { completed: false, text: 'Course Details' },
    { completed: false, text: 'Course Sorting' },
    { completed: false, text: 'Additional Details' },
    // { completed: false, text: 'Attachments' },
    { completed: false, text: 'Review & finish' },
  ]
  selectedStep = this.steps[0];
  originalCourse: Course;
  updatedCourse: Course;
  entity: any;
  requirements: any;
  requirementsList: any;
  tags: any;
  courseTypes: any;
  categories: any;
  courseSubscription: Subscription;
  updateCourseSubscription: Subscription;
  isLoading: boolean;
  isEditMode = false;
  error: string;
  permissions: any;
  permissionsSubscription: Subscription;

  constructor(
    private entityService: EntityService,
    private toastService: ToastService,
    private coursesService: CoursesService,
    private authService: AuthService,
    private requirementsService: RequirementsService,

    public dialogRef: MatDialogRef<UpdateCourseComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // there is currently no included association for categories, nor a way to get list of categories, but we would want to do that here as well

    // get the active requirements for the course sorting step
    this.requirementsService.getRequirementsChildrenIncluded()

    this.requirementSubscription = this.requirementsService.requirementsData$.subscribe((requirementData: any) => {
      const activeRequirements = requirementData?.rows?.map((requirement: any) => {
        return {
          name: requirement.requirement,
          requirement: requirement.requirement,
          Id: requirement.requirementId,
          requirementId: requirement.requirementId,
          parentId: requirement.parentRequirementId,
          topMostParentId: requirement.topMostParentId,
          topMostParentName: requirement.topMostParentName,
          nodeType: requirement.nodeType,
          childCount: requirement.childCount,
          children: requirement?.Children?.map((requirement: any) => {
            return {
              name: requirement.requirement,
              requirement: requirement.requirement,
              Id: requirement.requirementId,
              requirementId: requirement.requirementId,
              parentId: requirement.parentRequirementId,
              topMostParentId: requirement.topMostParentId,
              topMostParentName: requirement.topMostParentName,
              nodeType: requirement.nodeType,
              childCount: requirement.childCount,
              children: requirement?.Children?.map((requirement: any) => {
                return {
                  name: requirement.requirement,
                  requirement: requirement.requirement,
                  Id: requirement.requirementId,
                  requirementId: requirement.requirementId,
                  parentId: requirement.parentRequirementId,
                  topMostParentId: requirement.topMostParentId,
                  topMostParentName: requirement.topMostParentName,
                  nodeType: requirement.nodeType,
                  childCount: requirement.childCount,
                  children: null,
                }
              }),
            }
          }),
        }
      })
      this.requirements = activeRequirements
    })


    this.entityService.getEntity('Tags, CourseTypes, Categories')
    this.entitySubscription = this.entityService.entityData$.subscribe((entityData: any) => {
      this.entity = entityData
      // get the active tags for the course sorting step
      const activeTags = entityData?.Tags?.filter((tag: any) => tag.activeFlag)
      this.tags = activeTags
      // get the active course types for the general info step
      const activeCourseTypes = entityData?.CourseTypes?.filter((courseType: any) => courseType.activeFlag)
      this.courseTypes = activeCourseTypes;
      // get the active categories for the course sorting step, currently waiting for endpoint to be updated
      const activeCategories = entityData?.Categories?.filter((category: any) => category.activeFlag)
      this.categories = activeCategories
    })

    // here we get the users permissions
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions
    })

    // if we are editing a course, we want to populate the form with the course data
    if (this.data) {
      setTimeout(() => {
        this.isEditMode = true
        this.isLoading = true;
        // we call for the course session details here
        this.coursesService.clearCourseSessionData();
        this.coursesService.getCourseSession(this.data.courseItemId, { includedAssociations: 'AllCourseDetails' })
        this.courseSubscription = this?.coursesService?.courseSessionData$?.subscribe((courseSessionData: any) => {
          if (!courseSessionData) return;
          this.originalCourse = new Course(courseSessionData)
          this.updatedCourse = new Course(courseSessionData, this.originalCourse)
          this.isLoading = false;
        })
      }, 0)
    } else {
      this.originalCourse = new Course();
      this.updatedCourse = new Course().initializeForCreate();
    }
  }

  closeDialog = () => {
    if (this.updatedCourse.edited) {
      const confirmDialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Are you sure?',
          text: 'Any unsaved changes will be lost',
          confirmButtonTitle: 'Yes, exit',
          cancelButtonTitle: 'No, go back',
        },
      })
      confirmDialogRef.afterClosed().subscribe((result: any) => {
        if (result === 'verified') {
          this.dialog.closeAll()
          this.coursesService.clearCourseSessionData();
        }
      })
    } else {
      this.dialog.closeAll()
      this.coursesService.clearCourseSessionData();
    }
  }

  stepBack = () => {
    const currentIndex = this.steps.findIndex((step: any) => step === this.selectedStep)
    this.selectedStep = this.steps[currentIndex - 1]
  }

  stepForward = (isValid = false) => {
    const currentIndex = this.steps.findIndex((step: any) => step.text === this.selectedStep.text);
    if (!isValid) {
      this.steps[currentIndex].completed = false;
      return;
    }

    this.steps[currentIndex].completed = true;

    let index = currentIndex + 1;
    if (index === this.steps.length) {
      index = currentIndex;
    }

    this.selectedStep = this.steps[index]
  }

  // This is to save changes while in edit mode
  saveChanges = () => {

    if (this.permissions?.COURSE_EDIT === 0) {
      this.isLoading = false
      this.dialog.closeAll()
      this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Oops, something went wrong',
          text: 'You do not have permission to edit courses',
        },
      })
      return
    }
    this.isLoading = true;

    if (this.isEditMode) {
      const updateRequestObject = this.updatedCourse.buildUpdateRequest(this.originalCourse);

      // this is where we make the call to our service to update the course
      this.updateCourseSubscription = this.coursesService.updateCourse(updateRequestObject, this.updatedCourse.courseId).subscribe({
        next: (response: any) => {
          this.toastService.setToast({
            text: 'Course successfully updated', type: 'success', icon: true,
          })
          this.isLoading = false;
          this.dialogRef.close(response)
        },
        error: (error) => {
          console.log('error', error)
          this.isLoading = false;
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert',
              title: 'Oops, something went wrong',
              text: error.error.errMsg || error.error.message,
            },
          })
        },
      })
    }
  }

  ngOnDestroy(): void {
    this.entitySubscription.unsubscribe();
    this?.courseSubscription?.unsubscribe();
    this?.updateCourseSubscription?.unsubscribe();
    this.permissionsSubscription?.unsubscribe();
    this.requirementSubscription?.unsubscribe();
  }

}
