import {
  Component, EventEmitter, Output,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['../../sign-in.component.scss'],
})
export class LogInComponent {
  @Output() toggleForgotPasswordEvent = new EventEmitter<'forgotPasswordMode' | 'confirmationModeIsOn' | 'firstTimeMode' | 'basicSignInMode'>();
  @Output() authStatusEvent = new EventEmitter<{ res: any; rememberMe: boolean; email: string; password: string }>();
  rememberMeChecked = false;
  error$: Observable<any>;
  authSubscription: any;
  windowIsLarge = window.innerWidth > 450;
  rememberedEmail = '';
  private errorSubscription: Subscription;

  constructor(private authService: AuthService,
    private loadingSpinnerService: LoadingSpinnerService) { }

  // TODO: make header nav links work, and update ui in general
  // TODO: make the forgot password link work
  // TODO: implement first time sign in flow
  // TODO: refine token handeling for timeout and refresh of user and permissions

  ngOnInit(): void {
    this.errorSubscription = this.authService.error$.subscribe((error: any) => {
      this.error$ = error
    })

    const loginName = localStorage.getItem('username') || '';

    if (loginName) {
      this.rememberedEmail = loginName;
      this.rememberMeChecked = true;
    }

    // defines google account, google login button, and places button on page
    // TODO: change this google button to use a Kalpa google cloud id instead of josh's. we perhaps also want to hide this as a secret
    google.accounts.id.initialize({
      client_id: '903559517820-bb1bnbkp6mnc8ldkqq2ntu4gni9hm0dk.apps.googleusercontent.com',
      callback: this.handleGoogleLogin.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });



    google.accounts.id.renderButton(document.getElementById('google_btn') as HTMLElement,
      {
        theme: 'outline',
        size: 'large',
        width: this.getGoogleButtonWidth(),
        shape: 'square',
        type: 'standard',
        logo_alignment: 'center',
      });
  }

  getGoogleButtonWidth() {
    const maxSize = 400;
    const forms = document.querySelector('.forms') as HTMLElement;
    const computedStyle = window.getComputedStyle(forms);
    const smallWidth = forms.offsetWidth - parseInt(computedStyle.paddingLeft) - parseInt(computedStyle.paddingRight);

    return (this.windowIsLarge ? maxSize : smallWidth).toString();

  }

  // this is our sign-in handler in the case of email signin.
  submitEmailLogin = async ({ form }: NgForm) => {
    // eject if somehow the form was submitted but is not valid
    if (!form.valid) {
      return;
    }

    this.loadingSpinnerService.setIsLoading(true);

    // consolidate the login data and send it to the auth provider to login
    const loginData = {
      email: form.value.email, password: form.value.password, rememberMe: form.value.rememberMe,
    }

    let res = await this.authService.handleEmailLogin(loginData, this.rememberMeChecked);

    if (res instanceof Error) {
      res = {
        challengeName: res.name,
        original: { ...res },
      }
    }

    if (res) {
      this.authStatusEvent.emit({
        res, rememberMe: this.rememberMeChecked, email: loginData.email, password: loginData.password,
      });
    }

    // form.reset();
  }

  public handleGoogleLogin = (response: any) => {
    this.loadingSpinnerService.setIsLoading(true);
    this.authService.handleGoogleLogin(response, this.rememberMeChecked)
  }

  handleClaimAccount = (email: string, confirmationCode: string) => {
    this.authService.handleClaimAccount(email, confirmationCode)
  }

  toggleForgotPassword = (mode: 'forgotPasswordMode' | 'confirmationModeIsOn' | 'firstTimeMode' | 'basicSignInMode') => {
    this.toggleForgotPasswordEvent.emit(mode)
  }

  submitForgotPasswordRequest = ({ form }: NgForm) => {
    console.log(form)
  }

  ngOnDestroy(): void {
    this?.errorSubscription?.unsubscribe();
  }
}
