<div class="modal">
  <div class="header">
    <div class="container">
      <mat-icon class="icon" fontSet="material-icons-round">checklist</mat-icon>
      <span class="kbody-huge">Course Validation</span>
    </div>
    <mat-icon
      class="close"
      fontSet="material-icons-round"
      (click)="this.dialogRef.close()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <div>
      <div class="title-row">
        <span class="ktitle-tiny">{{ data.courseName }}</span>
        <span class="klabel-huge"
          >COURSE ID:
          <span class="id kbody-large">{{ data.courseItemId }}</span></span
        >
      </div>
      <div class="titles-row">
        <div class="validation-buttons">
          <mat-icon
            class="icon confirm"
            fontSet="material-icons-round"
            (click)="approveAll()"
            [ngClass]="{ selected: styleButton('COMPLETED') }"
            >check</mat-icon
          >
          <mat-icon
            class="icon unmarked"
            fontSet="material-icons-round"
            (click)="unmarkAll()"
            >horizontal_rule</mat-icon
          >
          <mat-icon
            class="icon deny"
            fontSet="material-icons-round"
            (click)="denyAll()"
            [ngClass]="{ selected: styleButton('NOT_TAKEN') }"
            >close</mat-icon
          >
        </div>
        <div
          class="title"
          *ngFor="let title of tableTitles"
          [ngClass]="title?.class"
        >
          <span class="klabel-medium label">{{ title.name }}</span>
        </div>
      </div>
      <div class="items">
        <div class="item" *ngFor="let user of sessionValidationDetails?.rows">
          <div class="validation-buttons">
            <mat-icon
              class="icon confirm"
              fontSet="material-icons-round"
              (click)="updateIndividualValidation(user, 'COMPLETED')"
              [ngClass]="{ selected: isApproved(user) }"
              >check</mat-icon
            >
            <mat-icon
              class="icon unmarked"
              fontSet="material-icons-round"
              (click)="updateIndividualValidation(user, 'REGISTERED')"
              [ngClass]="{ selected: !(isApproved(user) && isDenied(user)) }"
              >horizontal_rule</mat-icon
            >
            <mat-icon
              class="icon deny"
              fontSet="material-icons-round"
              (click)="updateIndividualValidation(user, 'NOT_TAKEN')"
              [ngClass]="{ selected: isDenied(user) }"
              >close</mat-icon
            >
          </div>
          <div class="detail">
            <span class="kbody-medium"
              >{{ user?.firstName }} {{ user?.lastName }}</span
            >
          </div>
          <div class="detail email">
            <span class="kbody-medium">{{ user?.loginName }}</span>
          </div>
          <div class="detail">
            <app-input
              value="{{ user.adjustedValue }}"
              placeholder="1"
              [type]="'number'"
            ></app-input>
          </div>
          <div class="detail status-detail">
            <span
              class="kbody-medium status"
              [ngClass]="user?.currentStatusKid | lowercase"
              >{{ user?.currentStatusText | lowercase }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="this.dialogRef.close()"
      ></app-button>
      <app-button
        [text]="'Save'"
        [icon]="{ materialtype: 'success' }"
        (click)="saveValidationUpdates()"
      ></app-button>
    </div>
  </div>
  <app-loading-spinner
    *ngIf="!sessionValidationDetails"
    class="spinner"
  ></app-loading-spinner>
</div>
