<div class="dialog">
  <div class="title-row">
    <div class="row">
      <mat-icon>category</mat-icon>
      <span class="ktitle-medium">Category details</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>
  <div class="content">
    <div class="detail">
      <span class="klabel-medium label">Name of Category</span>
      <span class="kbody-medium">{{ category.category }}</span>
    </div>
    <div class="detail">
      <span class="klabel-medium label">Status</span>
      <span class="kbody-medium">{{data.activeFlag ? 'active' : 'deactivated'}}</span>
    </div>
  </div>
  <div class="buttons">
    <app-button (click)="closeDialog()" [class]="'white'" [text]="'Done'"></app-button>
  </div>
</div>