import {
  Component, Output, EventEmitter, Input,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';

@Component({
  selector: 'app-course-course-types-filters',
  templateUrl: './course-course-types-filters.component.html',
  styleUrls: [ './course-course-types-filters.component.scss' ],
})
export class CourseCourseTypesFiltersComponent {
  @Input() mainFilter: CourseFilter;
  @Input() courseTypeList: any;
  @Output() updateMainFilter = new EventEmitter<object>();

  constructor() { }

  checkIncludesRequirement(courseType: any) {
    return !!this.mainFilter.courseTypes.find((ct: any) => ct.id === courseType.id);
  }

  updateCheckboxFilter(event: any, courseType: any): void {
    const isChecked = event?.target?.checked;

    if (isChecked) {
      this.mainFilter.courseTypes.push(courseType);
    } else {
      this.mainFilter.courseTypes = this.mainFilter.courseTypes.filter((ct: any) => ct.id !== courseType.id);
    }
  }

  selectAllCourseTypesFilter(event: any): void {
    if (event.target.checked) {
      this.mainFilter.courseTypes = this.courseTypeList;
    } else {
      this.mainFilter.courseTypes = [];
    }
  }

  clearCourseTypesFilter(): void {
    this.mainFilter.courseTypes = [];
  }

}
