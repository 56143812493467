import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-course-request-response',
  templateUrl: './course-request-response.component.html',
  styleUrls: [ './course-request-response.component.scss' ],
})
export class CourseRequestResponseComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CourseRequestResponseComponent>) { }

}
