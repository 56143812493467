import {
  Component, Input, Output, EventEmitter,
  OnInit,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: [ './general.component.scss' ],
})
export class GeneralComponent implements OnInit {
  @Input() defaults: any;
  @Input() error: any;
  @Input() entity: any;
  @Output() setNewDefaults = new EventEmitter<object>();

  $entity: any;

  primaryEmail = '';
  primaryContact = '';
  primaryBuildingName = '';

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.entity$.subscribe((entity) => {
      this.$entity = entity;

      if (this.$entity) {
        this.primaryContact = this.$entity?.primaryContact;
        this.primaryEmail = this.$entity?.primaryEmail;
        this.primaryBuildingName = this.$entity?.Config?.PrimaryBuilding?.buildingName;
      }
    });
  }

  getBuildingId = (buildingName: string) => {
    const foundBuilding = this.entity?.Buildings?.find((b: any) => b.buildingName === buildingName);
    return Number(foundBuilding?.id);
  }

  updateDefaults = (event: any, key: any) => {
    let value = event.target.value;

    if (key === 'primaryBuildingId') {
      value = this.getBuildingId(value);
    }

    if (key === 'primaryContact') {
      this.populateEmail(value);
    }

    const newDefaults = { [key]: value };
    this.setNewDefaults.emit(newDefaults);
  }

  populateEmail = (contactName: string) => {
    if (contactName === '') return;
    const foundUser = this.entity?.Users?.find((u: any) => u.fullName === contactName);

    if (foundUser) {
      const email = foundUser?.contactEmail || foundUser.loginName;
      this.defaults.primaryEmail = email;
    }
  }

}
