<div class="update-component">
  <div class="title-row">
    <div class="container">
      <mat-icon fontSet="material-icons-round">location_city</mat-icon>
      <span class="klabel-huge">{{
        data ? "Edit Building" : "Add New Building"
      }}</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeDialog()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <span class="klabel-huge subtitle">General Information</span>
    <div class="details-row">
      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        class="input detail"
        label="Building Name*"
        value="{{ this.building?.buildingName }}"
        placeholder="Building Name"
        [error]="this.error?.name"
        (updatedValue)="updateBuildingParameter($event, 'buildingName')"
      ></app-input>
      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        class="input detail"
        label="Building Type*"
        value="{{ this.building?.buildingType }}"
        placeholder="Building Type"
        [error]="this.error?.type"
        (updatedValue)="updateBuildingParameter($event, 'buildingType')"
      ></app-input>
    </div>
    <div class="details-row">
      <!-- <app-input [disabled]="building.activeFlag === 0 ? true : false" icon="person" class="input detail" label="Building Supervisor" value="{{this.building?.buildingSupervisor}}" placeholder="Building Supervisor"  (updatedValue)="updateBuildingParameter($event, 'buildingSupervisor')"></app-input> -->

      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        class="input detail"
        label="Building Number"
        value="{{ this.building?.buildingNumber }}"
        placeholder="Building Number"
        (updatedValue)="updateBuildingParameter($event, 'buildingNumber')"
      ></app-input>
    </div>
    <span class="klabel-huge subtitle">Location</span>
    <app-input
      [disabled]="building.activeFlag === 0 ? true : false"
      icon="location_on"
      class="input detail solo"
      label="Street Address"
      value="{{ this.building?.street1 }}"
      placeholder="Street Address"
      (updatedValue)="updateBuildingParameter($event, 'street1')"
    ></app-input>
    <div class="details-row">
      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        class="input detail"
        label="City"
        value="{{ this.building?.city }}"
        placeholder="City"
        (updatedValue)="updateBuildingParameter($event, 'city')"
      ></app-input>
      <app-select
        class="input detail"
        (selectedValue)="updateBuildingParameter($event, 'stateCode')"
        label="State"
        placeholder="State"
        [value]="building?.stateCode"
        [options]="stateCodeList"
        [disabled]="building.activeFlag === 0"
      ></app-select>
      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        class="input detail"
        label="Zip"
        value="{{ this.building?.zipcode }}"
        placeholder="Zip"
        (updatedValue)="updateBuildingParameter($event, 'zipcode')"
      ></app-input>
    </div>
    <span class="klabel-huge subtitle">Contact Information</span>
    <div class="details-row">
      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        icon="phone"
        class="input detail"
        label="Primary Phone Number"
        value="{{ this.building?.telephone }}"
        placeholder="Phone #"
        (updatedValue)="updateBuildingParameter($event, 'telephone')"
      ></app-input>
      <app-input
        [disabled]="building.activeFlag === 0 ? true : false"
        icon="fax"
        class="input detail"
        label="Primary Fax Number"
        value="{{ this.building?.fax }}"
        placeholder="Fax #"
        (updatedValue)="updateBuildingParameter($event, 'fax')"
      ></app-input>
    </div>
  </div>
  <div [ngClass]="{ 'flex-end': !data }" class="button-row">
    <div class="options">
      <app-button
        *ngIf="building?.activeFlag"
        [icon]="{ materialIcon: 'highlight_off' }"
        [class]="'grey'"
        (click)="setActivation(false)"
        [text]="'Deactivate Building'"
      ></app-button>
      <app-button
        *ngIf="data && !building?.activeFlag"
        (click)="setActivation(true)"
        [class]="'grey'"
        [text]="'Activate Building'"
      ></app-button>
    </div>
    <div class="buttons">
      <app-button
        (click)="closeDialog()"
        [class]="'white'"
        [text]="'Cancel'"
      ></app-button>
      <app-button
        [icon]="{ materialIcon: 'done' }"
        (click)="saveBuilding()"
        *ngIf="building.activeFlag !== 0"
        class="button"
        [text]="data ? 'Save Changes' : 'Create Building'"
      ></app-button>
    </div>
  </div>
</div>
