import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApproversService {
  private _primaryApproversData$ = new BehaviorSubject<any>(null);
  public readonly primaryApproversData$ = this._primaryApproversData$.asObservable();

  private _secondaryApproversData$ = new BehaviorSubject<any>(null);
  public readonly secondaryApproversData$ = this._secondaryApproversData$.asObservable();

  user: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this.user = user)
  }

  getPrimaryApprovers = () => {
    return this.http.get(`${environment.expressUrl}/approver/primary/user-list`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this.user.loginName },
      }).subscribe({
      next: (response: any) => {
        this._primaryApproversData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    })
  }

  getSecondaryApprovers = () => {
    return this.http.get(`${environment.expressUrl}/approver/secondary/user-list`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this.user.loginName },
      }).subscribe({
      next: (response: any) => {
        this._secondaryApproversData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    })
  }
}
