import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-update-requirement-modal',
  templateUrl: './update-requirement-modal.component.html',
  styleUrls: [ './update-requirement-modal.component.scss' ],
})
export class UpdateRequirementModalComponent implements OnInit, OnDestroy {
  requirementOptions: any;
  courseRequirements: any;
  userRequirements: any;
  courseDataSubscription: any;
  userSubscription: any;
  userCourseRequirementSubscription: any;
  selectedRequirement: any;
  selectedUser: any;

  constructor(
    private toastService: ToastService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    public dialogRef: MatDialogRef<UpdateRequirementModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // TODO - this should be loading on init, wait for comparison
    this.selectedUser = this.data.user;
    this.selectedRequirement = this.data.selectedRequirementId;
    // we have to call for the course with the requirements included to know join with the user requirements
    const courseId = this.data?.courseId || this.data?.course?.courseId;
    this.coursesService.getCourse(courseId, { includedAssociations: 'Requirements' } )
    this.courseDataSubscription = this?.coursesService?.courseData$?.subscribe((courseData: any) => {
      this.courseRequirements = courseData?.Requirements
      // this is where we save all user requirements that are also course requirements into requirementOptions
      this.requirementOptions = this?.requirementOptions?.filter((requirementOption: any) => {
        return this?.courseRequirements?.find((courseRequirement: any) => {
          return courseRequirement?.id === requirementOption?.value || courseRequirement?.requirementId === requirementOption?.value;
        })
      })
      // this adds the unassigned option to the top of the list
      this?.requirementOptions
        ?.unshift({ value: null, text: 'Unassigned/Portfolio' });
    })
    // this is a list of user requirements we get passed in
    this.requirementOptions = this?.data?.userRequirements?.map((requirement: any) => {
      return { value: requirement.id || requirement.requirementId, text: requirement.requirement };
    });
  }

  setRequirement = (requirement: any) => {
    this.selectedRequirement = requirement;
  }

  updateRequirement = () => {
    const requirementData = {
      registrationId: this.data.course.id,
      currentStatusKid: this.data.course.currentStatusKid,
      loginName: this.data.selectedUser.loginName,
      requirementId: this.selectedRequirement,
    }
    const userCourseRequirementSubscription = this.coursesService.updateUserCourseRequirement(requirementData).subscribe({
      next: (response: any) => {
        this.toastService.setToast({ text: 'Course Requirement Updated', type: 'success' })
        // not wild about this timeout, but we are getting a success response before the db is updated
        this.usersService.getUserById(this.data.selectedUser.id, { 'includedAssociations': 'PrimaryBuilding, PrimaryPosition, Requirements' })
        setTimeout(() => {
          this.coursesService.getCourseRegistrations({ userId: this.data.selectedUser.id, includedAssociations: 'Requirement, Course, CourseItem' });
        }, 1000);
        this.dialog.closeAll();
      },
      error: (error: any) => {
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong updating the course requirement', text: error.error.message,
          },
        })
      },
    })
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.courseDataSubscription?.unsubscribe();
    this.userCourseRequirementSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

}
