<div class="DropDown-container" (mouseleave)="shareCheckedlist(checkedIds)">
  <div class="container" (click)="onClick()">
    <div class="drop-toggle white" [ngClass]="color">
      <span
        *ngIf="!showDropDown || checkedList.length === 0"
        class="kinput klabel-medium label tree-input"
        >{{ label }}</span
      >
      <span *ngIf="checkedList.length > 0 && showDropDown" class="checked-list">
        {{ formatCheckedList() }}
      </span>
      <mat-icon class="icon" fontSet="material-icons-round"
        >expand_more</mat-icon
      >
    </div>
  </div>

  <div class="drop-show kbody-large" *ngIf="showDropDown">
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="treeChecklist"
    >
      <!-- Leaf node -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
      >
        <!--<button mat-icon-button disabled></button>-->
        <mat-checkbox
          [checked]="checklistSelection.isSelected(node)"
          (change)="ItemSelectionToggle(node)"
          >{{ node.name }}</mat-checkbox
        >
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node; when: hasNoContent"
        matTreeNodePadding
      >
        <!--<button mat-icon-button disabled></button>-->
      </mat-tree-node>

      <!-- expandable node -->
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        [matTreeNodePaddingIndent]="48"
      >
        <!--<button mat-icon-button [attr.aria-label]="'toggle ' + node.name" matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>-->
        <mat-checkbox
          font="kbody-large"
          [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="ItemSelectionToggle(node)"
          >{{ node.name }}</mat-checkbox
        >
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
<span *ngIf="error" class="klabel-medium error">{{ error }}</span>
