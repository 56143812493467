<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Buildings & Requirements</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="kbody-large description">Users can be assigned to more than one building and more than one position. The ‘star’ icon indicates the primary selection for that user. To change the primary selection, simply click on a selection item.</span>
    <span class="klabel-huge">Buildings</span>
    <span class="klabel-medium label">Select buildings*</span>
    <div class="input-select-container kinput">
      <input [disabled]="isEditMode && !permissions.USER_EDIT" #buildinginput (change)="updateLocalUserParameter($event, 'buildings')" class="kinput kbody-medium input-select" type="text" placeholder="Type or select a building" list="targets"> 
      <datalist id="targets" class="datalist">
        <option class="kbody-medium" *ngFor="let building of buildings">{{ building.buildingName }}</option>
      </datalist>
    </div>
    <span class="klabel-medium label" *ngIf="localEditedUser.primaryBuilding">Selections</span>
    <div class="chips">
      <app-chip 
        *ngIf="localEditedUser.primaryBuilding"
        (closeChip)="removeBuilding(localEditedUser.primaryBuilding, 'primary')" 
        [closable]="true" 
        [text]="localEditedUser.primaryBuilding?.buildingName" 
        [icon]="{type: 'star'}"
        [disabled]="isEditMode && !permissions.USER_EDIT"
        >
      </app-chip>
      <app-chip 
        *ngFor="let building of localEditedUser.buildings" 
        (closeChip)="removeBuilding(building)" 
        [closable]="true" 
        [text]="building.buildingName" 
        (click)="togglePrimaryBuilding(building)"
        [class]="'outlined'"
        [disabled]="isEditMode && !permissions.USER_EDIT"
        >
      </app-chip>
    </div>

    <span class="klabel-huge">Positions</span>
    <span class="klabel-medium label">Select positions*</span>
    <div class="input-select-container kinput">
      <input [disabled]="isEditMode && !permissions.USER_EDIT" #positioninput (change)="updateLocalUserParameter($event, 'positions')" class="kinput kbody-medium input-select" type="text" placeholder="Type or select a position" list="positiontargets"> 
      <datalist id="positiontargets" class="datalist">
        <option class="kbody-medium" *ngFor="let position of positions">{{ position.positionTitle }}</option>
      </datalist>
    </div>
    <span class="klabel-medium label" *ngIf="localEditedUser.primaryPosition">Selections</span>
    <div class="chips">
      <app-chip 
        *ngIf="localEditedUser.primaryPosition"
        (closeChip)="removePosition(localEditedUser.primaryPosition, 'primary')" 
        [closable]="true" 
        [text]="localEditedUser.primaryPosition?.positionTitle"
        [icon]="{type: 'star'}"
        [disabled]="isEditMode && !permissions.USER_EDIT"
        >
      </app-chip>
      <app-chip 
        *ngFor="let position of localEditedUser.positions" 
        (closeChip)="removePosition(position)" 
        [closable]="true" 
        [text]="position.positionTitle" 
        (click)="togglePrimaryPosition(position)"
        [class]="'outlined'"
        [disabled]="isEditMode && !permissions.USER_EDIT"
        >
      </app-chip>
    </div>
  </div>
  <div class="buttons">
    <app-button (click)="stepBackward()" [text]="'Previous Step'" [icon]="{materialIcon: 'arrow_back'}" [class]="'white'"></app-button>
    <div class="actions">
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeDialog()"></app-button>
      <app-button [disabled]="!this.localEditedUser.primaryBuilding || !this.localEditedUser.primaryPosition" [icon]="{ materialIcon: 'arrow_forward' }" [class]="'icon-right'" [text]="'Next Step'" (click)="updateUser()"></app-button>
    </div>
  </div> 
</div>