<div class="stepper">
  <div
    class="step-container"
    [ngClass]="{
      selected: step === selectedStep,
      completed: step.completed || (isEditMode && step.modified),
      canNav: step.completed || isEditMode
    }"
    *ngFor="let step of steps"
    (click)="navigateToTab(step)"
  >
    <div class="step-topper"></div>
    <div class="step-text-container">
      <mat-icon
        *ngIf="step.completed && step !== selectedStep"
        fontSet="material-icons-round"
        >check_circle</mat-icon
      >
      <mat-icon *ngIf="step === selectedStep" fontSet="material-icons-round"
        >radio_button_checked</mat-icon
      >
      <mat-icon
        *ngIf="!step.completed && step !== selectedStep"
        fontSet="material-icons-round"
        >radio_button_unchecked</mat-icon
      >
      <span class="step klabel-medium">{{ step.text }}</span>
    </div>
  </div>
</div>
