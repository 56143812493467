<div class="container">
  <div class="content" *ngIf="!isLoading">
    <div class="title-row">
      <span class="ktitle-tiny">Review & Finish</span>
    </div>
    <div class="section">
      <span class="klabel-huge">General Information</span>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Course Name</span>
          <span class="kbody-medium">{{ course.courseName }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Hours</span>
          <span class="kbody-medium">{{ course.trackingValue }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Course Type</span>
          <span class="kbody-medium">{{ course.courseType?.courseType }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Status</span>
          <span class="kbody-medium">{{
            course.courseActiveFlag ? "Active" : "Inactive"
          }}</span>
        </div>
      </div>
      <div
        class="details-row"
        *ngIf="course?.instructor || course?.courseCost || course?.hyperlink"
      >
        <div class="detail" *ngIf="course?.instructor">
          <span class="klabel-medium label">Instructor</span>
          <span class="kbody-medium">{{ course.instructor }}</span>
        </div>
        <div class="detail" *ngIf="course.courseCost">
          <span class="klabel-medium label">Course Cost</span>
          <span class="kbody-medium">${{ course.courseCost }}</span>
        </div>
        <!-- TODO: Sort what webUrl is for express -->
        <div class="detail" *ngIf="course?.hyperlink">
          <span class="klabel-medium label">Web URL</span>
          <a
            [href]="'https://' + course.hyperlink || ''"
            class="kbody-medium"
            >{{ course.hyperlink || "-" }}</a
          >
        </div>
      </div>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Course Description</span>
          <span class="kbody-medium">{{ course.courseDescription }}</span>
        </div>
      </div>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Course Availability</span>
          <span class="kbody-medium"
            >{{
              course.courseSize - course.registeredCount > 0
                ? course.courseSize - course.registeredCount
                : 0
            }}
            seats available
            {{
              course.allowWaitListFlag &&
              course.registeredCount >= course.courseSize
                ? "(waitlist enabled)"
                : "(waitlist disabled)"
            }}</span
          >
        </div>
      </div>
    </div>

    <div class="section">
      <span class="klabel-huge">Course Details</span>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Course Offering</span>
          <span class="kbody-medium"
            >{{ course.isAsync ? "Asynchronous " : null
            }}{{ course.virtualFlag ? "Virtual " : null
            }}{{ course.isAnytime ? "Anytime " : null
            }}{{ !course.virtualFlag && !course.isAsync ? "Standard " : null }}
          </span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Catalog Availability</span>
          <span class="kbody-medium"
            >{{
              !!course.regStartDate
                ? course.regStartDate.toLocaleDateString()
                : "-"
            }}
            -
            {{
              course.regEndDate ? course.regEndDate.toLocaleDateString() : "-"
            }}</span
          >
        </div>
        <div class="detail">
          <span class="klabel-medium label">Outside Course</span>
          <span class="kbody-medium">{{
            course.outsideCourseFlag ? "Yes" : "No"
          }}</span>
        </div>
      </div>
      <div class="details-row">
        <div class="detail" *ngIf="course?.location">
          <span class="klabel-medium label">Location</span>
          <span class="kbody-medium">{{ course.location }} </span>
        </div>
        <div class="detail" *ngIf="course?.roomNumber">
          <span class="klabel-medium label">Room Number</span>
          <span class="kbody-medium">{{ course.roomNumber }} </span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Course Date</span>
          <span class="kbody-medium"
            >{{ populateDate(course.dateOffered) }}
          </span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Course Time</span>
          <span class="kbody-medium">{{
            populateTime(course.sessionStartDate) +
              " - " +
              populateTime(course.sessionEndDate)
          }}</span>
        </div>
      </div>
    </div>
    <div class="section">
      <span class="klabel-huge">Course Sorting</span>
      <div class="details-row wide">
        <div class="detail half-max">
          <span class="klabel-medium label">Requirements</span>
          <span
            class="kbody-medium"
            *ngFor="let requirement of course?.requirementsList"
            >{{ requirement?.requirement }}
          </span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Tags</span>
          <div class="chips">
            <app-chip
              [dot]="tag?.color || tag[1]"
              class="outlined tiny"
              *ngFor="let tag of course?.tagsList"
              [text]="tag?.tag || tag[2] || 'no tags'"
            ></app-chip>
          </div>
        </div>
      </div>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Categories</span>
          <div class="chips">
            <app-chip
              class="outlined tiny"
              *ngFor="let category of course?.categoryList"
              [text]="category?.category"
            ></app-chip>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: Sort approval levels, validation type, and state requirement with express -->
    <div class="section">
      <span class="klabel-huge">Additional Details</span>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Approval Levels</span>
          <span class="kbody-medium">{{ course.approval }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Validation Type</span>
          <span class="kbody-medium">{{ course.validation }}</span>
        </div>
        <!-- TODO - add pin back in -->
        <!-- <div class="detail"> -->
        <!-- <span class="klabel-medium label">Registration PIN</span> -->
        <!-- <span class="kbody-medium">{{
            course?.requirePin || sessionData?.pin ? "Yes" : "No"
          }}</span> -->
        <!-- </div> -->
        <!-- <div class="detail">
          <span class="klabel-medium label">State Requirement</span>
          <span class="kbody-medium">{{
            course?.stateReportingRequirement ||
              detailCourse?.stateRequirementId ||
              "None"
          }}</span>
        </div> -->
      </div>
    </div>
    <!-- TODO - add documents below once implemented -->
    <!-- <div class="section">
      <span class="klabel-huge">Attachments</span>
      <div class="details-row">
        <mat-icon fontSet="material-icons-round">picture_as_pdf</mat-icon>
        <span class="kbody-medium">Document_Sample_1234.pdf</span>
      </div>
    </div> -->
  </div>
  <span *ngIf="error" class="klabel-large error">{{ error }}</span>
  <div *ngIf="!isCourseDetails && !isLoading" class="buttons">
    <app-button
      (click)="stepBackward()"
      [text]="'Previous Step'"
      [icon]="{ materialIcon: 'arrow_back' }"
      [class]="'white'"
    ></app-button>
    <div class="actions">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="closeDialog()"
      ></app-button>
      <app-button
        [icon]="{ materialtype: 'success' }"
        [text]="'Finish'"
        (click)="saveCourse()"
      ></app-button>
    </div>
  </div>
  <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
</div>
