<div class="container" *ngIf="!isLoading">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Review & Finish</span>
    </div>
    <div class="section">
      <span class="klabel-huge">General Information</span>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Name</span>
          <span class="kbody-medium">{{
            localEditedUser.firstName + " " + localEditedUser.lastName
          }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">PIC Number</span>
          <span class="kbody-medium">{{
            localEditedUser.stateIssuedId.trim() || "-"
          }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">User Role</span>
          <span class="kbody-medium">{{ getRoleName() }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Status</span>
          <span class="kbody-medium">{{
            localEditedUser.activeUserFlag ? "Active" : "Inactive"
          }}</span>
        </div>
      </div>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">District Email</span>
          <span class="kbody-medium">{{ localEditedUser.email }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Alternate Email</span>
          <span class="kbody-medium">{{
            localEditedUser.altEmail || "-"
          }}</span>
        </div>
      </div>
    </div>

    <div class="section">
      <span class="klabel-huge">Buildings & Positions</span>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Building(s)</span>
          <span
            >{{ localEditedUser?.primaryBuilding?.buildingName }}
            <span class="bold">(primary)</span></span
          >
          <span
            class="kbody-medium"
            *ngFor="let building of localEditedUser.buildings"
            >{{ building.buildingName }}</span
          >
        </div>
        <div class="detail">
          <span class="klabel-medium label">Position(s)</span>
          <span
            >{{ localEditedUser?.primaryPosition?.positionTitle }}
            <span class="bold">(primary)</span></span
          >
          <span
            class="kbody-medium"
            *ngFor="let position of localEditedUser.positions"
            >{{ position.positionTitle }}</span
          >
        </div>
      </div>
    </div>

    <div class="section">
      <span class="klabel-huge">User Assignments & Requirements</span>
      <div class="details-row">
        <div class="detail">
          <span class="klabel-medium label">Primary Approvers</span>
          <span
            class="kbody-medium"
            *ngFor="let approver of localEditedUser.primaryApprovers"
            >{{ approver.fullName }}</span
          >
        </div>
        <div class="detail">
          <span class="klabel-medium label">Secondary Approvers</span>
          <span
            class="kbody-medium"
            *ngFor="let approver of localEditedUser.secondaryApprovers"
            >{{ approver.fullName }}</span
          >
        </div>
        <div class="detail">
          <span class="klabel-medium label">Requirements</span>
          <span
            class="kbody-medium"
            *ngFor="let requirement of localEditedUser.requirements"
            >{{ requirement.requirement }}</span
          >
        </div>
      </div>
    </div>

    <!-- TODO - add functionality for file upload and summary -->
    <!-- <div class="section">
      <span class="klabel-huge">Licenses * Certifications</span>
      <div class="details-row">
        <mat-icon fontSet="material-icons-round">picture_as_pdf</mat-icon>
        <span class="kbody-medium">Document_Sample_1234.pdf</span>
      </div>
    </div> -->
  </div>
  <span *ngIf="error" class="klabel-large error">{{ error }}</span>
  <div class="buttons">
    <app-button
      (click)="stepBackward()"
      [text]="'Previous Step'"
      [icon]="{ materialIcon: 'arrow_back' }"
      [class]="'white'"
    ></app-button>
    <div class="actions">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="closeDialog()"
      ></app-button>
      <app-button
        [icon]="{ materialtype: 'success' }"
        [text]="'Finish'"
        (click)="saveUser()"
      ></app-button>
    </div>
  </div>
</div>
<app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
