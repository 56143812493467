import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CourseTypesService {
  private _courseTypesData$ = new BehaviorSubject<any>(null);
  public readonly courseTypesData$ = this._courseTypesData$.asObservable();

  _user$: any;

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    this.courseTypesData$.subscribe(data =>
      links = data.links)
    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/course-types/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._courseTypesData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getCourseTypes = ({
    courseTypeFilter, activeFlag = 1, sortColumn = 'tag', sortDirection = 'ASC', limit, offset = 0,
  }: {
    courseTypeFilter: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
    offset: number;
    }) => {

    const params: {
        loginName: string;
        activeFlag: 1 | 0;
        courseTypeFilter?: string;
        sortColumn?: string;
        sortDirection?: 'ASC' | 'DESC';
        limit?: number;
        offset?: number;
      } = {
        loginName: this._user$.loginName,
        activeFlag,
      }

    if (courseTypeFilter) {
      params.courseTypeFilter = courseTypeFilter;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection;
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    return this.http.get(`${environment.expressUrl}/maintenance/course-types`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._courseTypesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getCourseTypesFilteredByName = (searchText: string, activeFlag: boolean | 1 | 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/course-types`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': 25,
          'activeFlag': activeFlag,
          'courseTypeFilter': searchText,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._courseTypesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getSortedCourseTypes = (
    sortColumn: string, sortDirection: string, activeFlag: boolean | 1 | 0, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/course-types`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': limit,
          'offset': offset,
          'sortColumn': sortColumn,
          'sortDirection': sortDirection,
          'activeFlag': activeFlag,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._courseTypesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getInactiveCourseTypes = (limit = 25, offset = 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/course-types`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'offset': offset, 'activeFlag': 0,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._courseTypesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  addCourseType = (newCourseType: any) => {
    return this.http.post(
      `${environment.expressUrl}/maintenance/course-types`,
      {
        'loginName': this._user$.loginName,
        'courseType': newCourseType.courseType,
        'showOnSpecialRequestFlag': newCourseType.showOnSpecialRequestFlag ? 1 : 0,
        'noPdCreditFlag': newCourseType.noPdCreditFlag ? 1 : 0,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  toggleCourseTypeActivation = (courseType: any, isActivating: boolean) => {
    const activateEndpoint = isActivating ? 'activate' : 'deactivate';
    return this.http.put(
      `${environment.expressUrl}/maintenance/course-types/${courseType.id}/${activateEndpoint}`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }


  updateCourseType = (newCourseType: any) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/course-types/${newCourseType.id}`,
      {
        'loginName': this._user$.loginName,
        'courseType': newCourseType.courseType,
        'showOnSpecialRequestFlag': newCourseType.showOnSpecialRequestFlag ? 1 : 0,
        'noPdCreditFlag': newCourseType.noPdCreditFlag ? 1 : 0,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
}
