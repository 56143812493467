import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SignInComponent } from './routes/sign-in/sign-in.component';
import { KalpaIconComponent } from './assets/icons/kalpa-icon/kalpa-icon.component';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LineIconComponent } from './assets/icons/line-icon/line-icon.component';
import { AuthService } from './services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ManageCoursesIconComponent } from './assets/icons/manage-courses-icon/manage-courses-icon.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SystemSettingsComponent } from './routes/system-settings/system-settings.component';
import { Amplify } from 'aws-amplify';
import { environment } from '../environments/environment';
import { PositionsComponent } from './routes/system-settings/components/positions/positions.component';
import { BuildingsComponent } from './routes/system-settings/components/buildings/buildings.component';
import { ButtonComponent } from './components/button/button.component';
import { TableComponent } from './components/table/table.component';
import { DetailsDialogComponent } from './routes/system-settings/components/buildings/components/details-dialog/details-dialog.component';
import { PositionDetailsDialogComponent } from './routes/system-settings/components/positions/components/position-details-dialog/position-details-dialog.component';
import { UpdatePositionComponent } from './routes/system-settings/components/positions/components/update-position/update-position.component';
import { UpdateBuildingComponent } from './routes/system-settings/components/buildings/components/update-building/update-building.component';
import { VerificationComponent } from './components/verification/verification.component';
import { RequirementsComponent } from './routes/system-settings/components/requirements/requirements.component';
import { TagsComponent } from './routes/system-settings/components/tags/tags.component';
import { UpdateTagComponent } from './routes/system-settings/components/tags/components/update-tag/update-tag.component';
import { BatchReassignComponent } from './components/batch-reassign/batch-reassign.component';
import { DefaultsComponent } from './routes/system-settings/components/defaults/defaults.component';
import { GeneralComponent } from './routes/system-settings/components/defaults/components/general/general.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ApprovalComponent } from './routes/system-settings/components/defaults/components/approval/approval.component';
import { RegistrationNoticeComponent } from './routes/system-settings/components/defaults/components/registration-notice/registration-notice.component';
import { RegistrationMessageComponent } from './routes/system-settings/components/defaults/components/registration-message/registration-message.component';
import { TagDetailsComponent } from './routes/system-settings/components/tags/components/tag-details/tag-details.component';
import { ChipComponent } from './components/chip/chip.component';
import { CourseTypesComponent } from './routes/system-settings/components/course-types/course-types.component';
import { UpdateCourseTypeComponent } from './routes/system-settings/components/course-types/components/update-course-type/update-course-type.component';
import { CourseTypeDetailsComponent } from './routes/system-settings/components/course-types/components/course-type-details/course-type-details.component';
import { SwapComponent } from './components/swap/swap.component';
import { MatIconModule } from '@angular/material/icon';
import { UserRolesComponent } from './routes/system-settings/components/user-roles/user-roles.component';
import { UpdateRequirementComponent } from './routes/system-settings/components/requirements/components/update-requirement/update-requirement.component';
import { RequirementDetailsComponent } from './routes/system-settings/components/requirements/components/requirement-details/requirement-details.component';
import { ToastComponent } from './components/toast/toast.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { ManageStaffComponent } from './routes/manage-staff/manage-staff.component';
import { StaffListComponent } from './routes/manage-staff/components/staff-list/staff-list.component';
import { UpdateUserComponent } from './routes/manage-staff/components/staff-list/components/update-user/update-user.component';
import { CourseCatalogComponent } from './routes/manage-courses/components/course-catalog/course-catalog.component';
import { UpdateCourseComponent } from './routes/manage-courses/components/course-catalog/components/update-course/update-course.component';
import { ProfileComponent } from './routes/profile/profile.component';
import { MyPdPlanComponent } from './routes/profile/components/my-pd-plan/my-pd-plan.component';
import { MyCoursesComponent } from './routes/profile/components/my-pd-plan/components/my-courses/my-courses.component';
import { UpdateStatusModalComponent } from './routes/profile/components/my-pd-plan/components/my-courses/components/update-status-modal/update-status-modal.component';
import { ValidationComponent } from './routes/manage-courses/components/validation/validation.component';
import { CourseValidationDetailsComponent } from './routes/manage-courses/components/validation/components/course-validation-details/course-validation-details.component';
import { CourseRequestsComponent } from './routes/manage-courses/components/course-requests/course-requests.component';
import { CourseRequestResponseComponent } from './routes/manage-courses/components/course-requests/components/course-request-response/course-request-response.component';
import { MyCourseRequestsComponent } from './routes/profile/components/my-course-requests/my-course-requests.component';
import { UpdateMyCourseRequestComponent } from './routes/profile/components/my-course-requests/components/update-my-course-request/update-my-course-request.component';
import { MyProfileComponent } from './routes/profile/components/my-profile/my-profile.component';
import { ReassignStaffComponent } from './routes/manage-staff/components/reassign-staff/reassign-staff.component';
import { GeneralInfoComponent } from './routes/manage-courses/components/course-catalog/components/update-course/components/general-info/general-info.component';
import { CourseDetailsComponent } from './routes/manage-courses/components/course-catalog/components/update-course/components/course-details/course-details.component';
import { CourseSortingComponent } from './routes/manage-courses/components/course-catalog/components/update-course/components/course-sorting/course-sorting.component';
import { AdditionalDetailsComponent } from './routes/manage-courses/components/course-catalog/components/update-course/components/additional-details/additional-details.component';
import { AttachmentsComponent } from './routes/manage-courses/components/course-catalog/components/update-course/components/attachments/attachments.component';
import { FinishComponent } from './routes/manage-courses/components/course-catalog/components/update-course/components/finish/finish.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { UserGeneralInfoComponent } from './routes/manage-staff/components/staff-list/components/update-user/components/user-general-info/user-general-info.component';
import { BuildingsAndPositionsComponent } from './routes/manage-staff/components/staff-list/components/update-user/components/buildings-and-positions/buildings-and-positions.component';
import { UserAssignmentsComponent } from './routes/manage-staff/components/staff-list/components/update-user/components/user-assignments/user-assignments.component';
import { LicensesAndCertsComponent } from './routes/manage-staff/components/staff-list/components/update-user/components/licenses-and-certs/licenses-and-certs.component';
import { ReviewAndFinishComponent } from './routes/manage-staff/components/staff-list/components/update-user/components/review-and-finish/review-and-finish.component';
import { UserRequirementsComponent } from './routes/manage-staff/components/staff-list/components/update-user/components/user-requirements/user-requirements.component';
import { CourseFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/course-filters.component';
import { GeneralCourseFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/components/general-course-filters/general-course-filters.component';
import { CourseBuildingsFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/components/course-buildings-filters/course-buildings-filters.component';
import { CourseRequirementsFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/components/course-requirements-filters/course-requirements-filters.component';
import { CourseCategoriesFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/components/course-categories-filters/course-categories-filters.component';
import { CourseTagsFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/components/course-tags-filters/course-tags-filters.component';
import { CourseCourseTypesFiltersComponent } from './routes/manage-courses/components/course-catalog/components/course-filters/components/course-course-types-filters/course-course-types-filters.component';
import { CatalogCourseDetailsComponent } from './routes/manage-courses/components/course-catalog/components/catalog-course-details/catalog-course-details.component';
import { RegistrationRequirementModalComponent } from './routes/manage-courses/components/course-catalog/components/registration-requirement-modal/registration-requirement-modal.component';
import { RegistrationSuccessModalComponent } from './routes/manage-courses/components/course-catalog/components/registration-success-modal/registration-success-modal.component';
import { RegistrationConflictModalComponent } from './routes/manage-courses/components/course-catalog/components/registration-conflict-modal/registration-conflict-modal.component';
import { RegistrationWaitlistModalComponent } from './routes/manage-courses/components/course-catalog/components/registration-waitlist-modal/registration-waitlist-modal.component';
import { UpdateRequirementModalComponent } from './routes/profile/components/my-pd-plan/components/my-courses/components/update-requirement-modal/update-requirement-modal.component';
import { PushModalComponent } from './routes/manage-courses/components/course-catalog/components/catalog-course-details/components/push-modal/push-modal.component';
import { PushToGroupComponent } from './routes/manage-courses/components/course-catalog/components/catalog-course-details/components/push-modal/components/push-to-group/push-to-group.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BulletinWidgetComponent } from './routes/dashboard/components/bulletin-widget/bulletin-widget.component';
import { CalendarWidgetComponent } from './routes/dashboard/components/calendar-widget/calendar-widget.component';
import { StatusWidgetComponent } from './routes/dashboard/components/status-widget/status-widget.component';
import { CatalogSuggestionsWidgetComponent } from './routes/dashboard/components/catalog-suggestions-widget/catalog-suggestions-widget.component';
import { CourseRequestsWidgetComponent } from './routes/dashboard/components/course-requests-widget/course-requests-widget.component';
import { ValidationWidgetComponent } from './routes/dashboard/components/validation-widget/validation-widget.component';
import { WaitlistWidgetComponent } from './routes/dashboard/components/waitlist-widget/waitlist-widget.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { KalpaRedComponent } from './assets/logos/kalpa-red/kalpa-red.component';
import { KalpaRedBlackComponent } from './assets/logos/kalpa-red-black/kalpa-red-black.component';
import { ManageCoursesComponent } from './routes/manage-courses/manage-courses.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CategoriesComponent } from './routes/system-settings/components/categories/categories.component';
import { UpdateCategoryComponent } from './routes/system-settings/components/categories/components/update-category/update-category.component';
import { CategoryDetailsComponent } from './routes/system-settings/components/categories/components/category-details/category-details.component';
import { TreeChecklistComponent } from './components/tree-checklist/tree-checklist.component';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { ForgotPasswordComponent } from './routes/sign-in/components/forgot-password/forgot-password.component';
import { LogInComponent } from './routes/sign-in/components/log-in/log-in.component';
import { FirstTimeLoginComponent } from './routes/sign-in/components/first-time-login/first-time-login.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { SendCodeModalComponent } from './routes/sign-in/components/send-code-modal/send-code-modal.component';
import { ConfirmLoginComponent } from './routes/sign-in/components/confirm-login/confirm-login.component';
import { ChangePasswordModalComponent } from './routes/profile/components/change-password-modal/change-password-modal.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { RequirementsListComponent } from './routes/profile/components/my-pd-plan/components/my-courses/components/requirements-list/requirements-list.component';
import { CoursesListComponent } from './routes/profile/components/my-pd-plan/components/my-courses/components/courses-list/courses-list.component';
import { NewTableComponent } from './components/new-table/new-table.component';
import { CompletePasswordChangeComponent } from './routes/sign-in/components/complete-password-change/complete-password-change.component';


Amplify.configure(environment.amplify);

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    KalpaIconComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LineIconComponent,
    SidebarComponent,
    ManageCoursesIconComponent,
    SystemSettingsComponent,
    PositionsComponent,
    BuildingsComponent,
    ButtonComponent,
    TableComponent,
    PositionDetailsDialogComponent,
    DetailsDialogComponent,
    UpdatePositionComponent,
    UpdateBuildingComponent,
    VerificationComponent,
    RequirementsComponent,
    TagsComponent,
    UpdateTagComponent,
    BatchReassignComponent,
    DefaultsComponent,
    GeneralComponent,
    TooltipComponent,
    ApprovalComponent,
    RegistrationNoticeComponent,
    RegistrationMessageComponent,
    TagDetailsComponent,
    ChipComponent,
    CourseTypesComponent,
    UpdateCourseTypeComponent,
    CourseTypeDetailsComponent,
    SwapComponent,
    UserRolesComponent,
    UpdateRequirementComponent,
    RequirementDetailsComponent,
    ToastComponent,
    LoadingSpinnerComponent,
    KalpaRedBlackComponent,
    InputComponent,
    SelectComponent,
    ManageStaffComponent,
    StaffListComponent,
    UpdateUserComponent,
    CourseCatalogComponent,
    UpdateCourseComponent,
    ProfileComponent,
    MyPdPlanComponent,
    MyCoursesComponent,
    UpdateStatusModalComponent,
    ValidationComponent,
    CourseValidationDetailsComponent,
    CourseRequestsComponent,
    CourseRequestResponseComponent,
    MyCourseRequestsComponent,
    UpdateMyCourseRequestComponent,
    MyProfileComponent,
    ReassignStaffComponent,
    GeneralInfoComponent,
    CourseDetailsComponent,
    CourseSortingComponent,
    AdditionalDetailsComponent,
    AttachmentsComponent,
    FinishComponent,
    StepperComponent,
    UserGeneralInfoComponent,
    BuildingsAndPositionsComponent,
    UserAssignmentsComponent,
    LicensesAndCertsComponent,
    ReviewAndFinishComponent,
    UserRequirementsComponent,
    CourseFiltersComponent,
    GeneralCourseFiltersComponent,
    CourseBuildingsFiltersComponent,
    CourseRequirementsFiltersComponent,
    CourseCategoriesFiltersComponent,
    CourseTagsFiltersComponent,
    CourseCourseTypesFiltersComponent,
    CatalogCourseDetailsComponent,
    RegistrationRequirementModalComponent,
    RegistrationSuccessModalComponent,
    RegistrationConflictModalComponent,
    RegistrationWaitlistModalComponent,
    UpdateRequirementModalComponent,
    PushModalComponent,
    PushToGroupComponent,
    BulletinWidgetComponent,
    CalendarWidgetComponent,
    StatusWidgetComponent,
    CatalogSuggestionsWidgetComponent,
    CourseRequestsWidgetComponent,
    ValidationWidgetComponent,
    WaitlistWidgetComponent,
    KalpaRedComponent,
    ManageCoursesComponent,
    BreadcrumbsComponent,
    CategoriesComponent,
    UpdateCategoryComponent,
    CategoryDetailsComponent,
    TreeChecklistComponent,
    ForgotPasswordComponent,
    LogInComponent,
    FirstTimeLoginComponent,
    PasswordChangeComponent,
    SendCodeModalComponent,
    ConfirmLoginComponent,
    ChangePasswordModalComponent,
    AvatarComponent,
    RequirementsListComponent,
    CoursesListComponent,
    NewTableComponent,
    CompletePasswordChangeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatMenuModule,
    MatExpansionModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    FullCalendarModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTreeModule,
  ],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => async () => await authService.autoLogin(),
      deps: [ AuthService ],
      multi: true,
    },
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
