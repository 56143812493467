import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-registration-message',
  templateUrl: './registration-message.component.html',
  styleUrls: [ './registration-message.component.scss' ],
})
export class RegistrationMessageComponent {
  @Input() defaults: any;
  @Output() setNewDefaults = new EventEmitter<object>();

  regMessageChecked = 0;

  ngOnInit() {
    this.regMessageChecked = this.defaults?.Config.regMessageFlag;
  }


  updateRegistrationMessage = (event: any, key: any) => {
    let value = event.target.value;

    if (key === 'regMessageFlag') {
      this.regMessageChecked = this.regMessageChecked == 1 ? 0 : 1;
      value = this.regMessageChecked;
    }

    const newDefaults = { [key]: value };
    this.setNewDefaults.emit(newDefaults);
  }
}
