import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: [ './system-settings.component.scss' ],
})
export class SystemSettingsComponent implements OnInit {
  tabList = [
    { name: 'Defaults', icon: 'settings' },
    { name: 'Buildings', icon: 'location_city' },
    { name: 'Positions', icon: 'work' },
    { name: 'Requirements', icon: 'view_list' },
    { name: 'Course Types', icon: 'summarize' },
    { name: 'Categories', icon: 'category' },
    { name: 'Tags', icon: 'label' },
    { name: 'User Roles', icon: 'key' },
  ]
  selectedTab: any;
  permissions: any;
  permissionsSubscription: any;

  constructor( private authService: AuthService ) {}

  ngOnInit(): void {
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
      if(!this.permissions?.SYS_SETTINGS_DEFAULTS) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Defaults');
      }
      if(!this.permissions?.SYS_SETTINGS_BUILDINGS) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Buildings');
      }
      if(!this.permissions?.SYS_SETTINGS_POSITIONS) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Positions');
      }
      if(!this.permissions?.SYS_SETTINGS_REQUIREMENTS) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Requirements');
      }
      if(!this.permissions?.SYS_SETTINGS_TYPES) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Course Types');
      }
      if(!this.permissions?.SYS_SETTINGS_CATEGORIES) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Categories');
      }
      if(!this.permissions?.SYS_SETTINGS_TAGS) {
        this.tabList = this.tabList.filter((card) => card.name !== 'Tags');
      }
      if(!this.permissions?.SYS_SETTINGS_ROLES) {
        this.tabList = this.tabList.filter((card) => card.name !== 'User Roles');
      }
    });
    this.selectedTab = this.tabList[0].name;
  }

  selectTab = (tab: any) => {
    this.selectedTab = tab;
  }
}
