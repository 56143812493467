import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog,
} from '@angular/material/dialog';
import { UpdateBuildingComponent } from '../update-building/update-building.component';
import { BatchReassignComponent } from 'src/app/components/batch-reassign/batch-reassign.component';
import { BuildingsService } from 'src/app/services/buildings.service';
import { ToastService } from 'src/app/services/toast.service';
import { EntityService } from 'src/app/services/entity.service';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: [ './details-dialog.component.scss' ],
})
export class DetailsDialogComponent implements OnInit, OnDestroy {
  buildingDetails: any;
  buildingsList: any;
  buildingSubscription: Subscription;
  userSubscription: Subscription;
  subscribeDialog: Subscription;
  entitySubscription: Subscription;
  allMoved = false;

  constructor(
    private buildingsService: BuildingsService,
    private userService: UsersService,
    public dialog: MatDialog,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<DetailsDialogComponent>,
    private entityService: EntityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.entityService.getEntity('Buildings')
    this.entitySubscription = this.entityService.entityData$.subscribe((entity: any) => {
      if (!entity) return;
      this.buildingsList = entity.Buildings
    })
  }

  ngOnInit(): void {
    // get building details so that we have the supervisorID and user count as well as data being passed in
    this.buildingSubscription = this.buildingsService.getBuildingDetails(this.data.buildingData.id, 'Users, UserCount').subscribe({
      next: (response: any) => {
        // set local building details to the response data
        this.buildingDetails = response.data;
        // get the supervisor name
        if (this.buildingDetails.supervisorId) {
          this.userService.getUserById(this.buildingDetails.supervisorId)
          this.userSubscription = this.userService.selectedUserData$.subscribe((user: any) => {
            this.buildingDetails.supervisorName = user.fullName;
          })
        }
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  showEditModal = () => {
    const editRef = this.dialog.open(UpdateBuildingComponent, { data: this.data });

    editRef.afterClosed().subscribe(result => {
      this.dialogRef.close(result);
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  setActivation = (setActive: boolean) => {
    // if there are still assigned users, then the user must reassign them before deactivating
    if(this.buildingDetails.Users.length > 0) {
      // get the active users from the user list to display in the modal warning
      const activeUserList = this.buildingDetails.Users.filter((user: any) => user.activeFlag === 1);
      // format for the reassign modal
      const formattedUserList = activeUserList.map((user: any) => {
        return {
          name: user.fullName,
          id: user.id,
        }
      })
      // format reassign list for the reassign modal and exclude the current building
      const filteredPotentialReassignList = this.buildingsList.filter((building: any) => building.id !== this.data.buildingData.id && building.activeFlag == 1);
      const formattedPotentialReassignList = filteredPotentialReassignList.map((building: any) => {
        return {
          name: building.buildingName,
          id: building.id,
        }
      })
      // fire a warning modal
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Reassign staff',
          confirmButtonTitle: 'Reassign Staff',
          cancelButtonTitle: 'Cancel Deactivation',
          text: `There are ${this.buildingDetails.UserCount.activeUsers} staff members assigned to this building. You must assign all active users to a new building before deactivating.`,
        },
      })
      // if the user confirms, then open the reassign modal
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const dialogData = {
            systemSettingName: 'building',
            titleText: 'Reassign staff to new building',
            titleChoose: 'Choose a new building to reassign staff to*',
            needToMoveList: formattedUserList,
            potentialReassignList: formattedPotentialReassignList,
          };
          const batchDialogRef = this.dialog.open(BatchReassignComponent, { data: dialogData });
          // if the batch dialog is closed after all users have been reassigned, we can deactivate the building, so we toggle a local boolean
          batchDialogRef.afterClosed().subscribe(result => {
            if(result === 'success') {
              this.allMoved = true
              this.dialogRef.close('reload')
            }
          })
          // this is the way that we listen to the dialog's emitter for any batch reassignments
          this.subscribeDialog = batchDialogRef.componentInstance.moveObjectEmitter.subscribe((data: any) => {
            const formattedStaffList = data.movingList.map((user: any) => parseFloat(user.id));
            // in the case of a fired batch reassign, we make the call through our service
            this.buildingsService.batchStaffReassign(
              formattedStaffList, this.buildingDetails.id, data.selectedTarget.id,
            ).subscribe({
              next: (response: any) => {
                this.toastService.setToast({ text: 'Staff reassigned', type: 'success' })
                // if all staff have been moved, then we can deactivate the building
                if(this.allMoved === true) {
                  this.buildingsService.toggleBuildingActivation(this.data.buildingData, setActive).subscribe({
                    next: (response: any) => {
                      this.dialogRef.close(response);
                      this.toastService.setToast({ text: 'Building Deactivated', type: 'success' })
                      this.buildingsService.getBuildings();
                    },
                    error: (error: any) => {
                      console.log('error', error)
                    },
                  })
                }
              },
              error: (error) => {
                console.log('error', error);
              },
            })
          })
        }
      })
    } else {
      // if there are no assigned users, then we can just deactivate the building
      this.buildingsService.toggleBuildingActivation(this.data.buildingData, setActive).subscribe({
        next: (response: any) => {
          this.toastService.setToast({ text: `Building ${setActive ? 'Activated' : 'Deactivated'}`, type: 'success' })
          setActive ? this.buildingsService.getInactiveBuildings() : this.buildingsService.getBuildings();

          this.dialogRef.close(response);
        },
        error: (error: any) => {
          console.log('error', error)
        },
      })
    }
  }

  ngOnDestroy(): void {
    this.buildingSubscription.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.subscribeDialog?.unsubscribe();
    this.entitySubscription.unsubscribe();
  }
}
