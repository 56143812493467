import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { SendCodeModalComponent } from '../send-code-modal/send-code-modal.component';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

interface Tabs {
  codeConfirmation: boolean;
  passwordChange: boolean;
}

@Component({
  selector: 'app-first-time-login',
  templateUrl: './first-time-login.component.html',
  styleUrls: [
    '../../sign-in.component.scss', '../forgot-password/forgot-password.component.scss', './first-time-login.component.scss',
  ],
})
export class FirstTimeLoginComponent {
  @Input() debugMode = true;
  confirmationCodeLength = 6;
  confirmationCode = '';
  email = '';

  tabs: Tabs = {
    codeConfirmation: true,
    passwordChange: false,
  }

  @Output() backToSignIn = new EventEmitter<void>();

  constructor(
    private auth: AuthService,
    private toastService: ToastService,
    public dialog: MatDialog,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {
  }

  toggleTabs = (tab: keyof Tabs) => {
    const keys = Object.keys(this.tabs) as Array<keyof Tabs>;
    keys.forEach((key) => {
      this.tabs[key] = false;
    });

    this.tabs[tab] = true;
  }


  // #region CODE CONFIRMATION INPUT
  parseCodeValues = (form: FormGroup) => {
    const allNumsArr = Object.keys(form.controls).filter(name => name.match('number-')).map((key) => {
      return form.controls[key];
    });

    const {
      allTouched, anyInvalid, anyErrors, allFilled,
    } = allNumsArr.reduce((acc, num) => {
      if (!num.touched) {
        acc.allTouched = false;
      }

      if (num.invalid) {
        acc.anyInvalid = true;
      }

      if (num.errors) {
        acc.anyErrors = true;
      }

      if (!num.value) {
        acc.allFilled = false;
      }
      return acc;
    }, {
      allTouched: true,
      anyInvalid: false,
      anyErrors: false,
      allFilled: true,
    });

    return {
      allTouched,
      anyInvalid,
      anyErrors,
      allFilled,
      curValue: allNumsArr.map((num) => num.value).join(''),
    };
  }

  checkCodeForm(form: FormGroup) {
    const {
      allFilled, allTouched, anyErrors, anyInvalid, curValue,
    } = this.parseCodeValues(form);

    this.confirmationCode = curValue;

    if (!allTouched) return false;

    if (anyErrors || anyInvalid) return true;

    return false;
  }

  handleCodeInput = ($event: KeyboardEvent) => {

    const curInput = $event.target as HTMLInputElement;
    const curInputId = curInput.id;
    const index = Number(curInputId.split('-')[1]);

    const prevIndex = index - 1 < 0 ? 0 : index - 1;
    const nextIndex = index + 1 > this.confirmationCodeLength - 1 ? this.confirmationCodeLength - 1 : index + 1;

    if ($event.code === 'Backspace') {
      curInput.value = '';
      //move to previous input
      if (index === prevIndex) {
        return;
      }
      const prevInput = document.querySelector(`#num-${prevIndex}`) as HTMLInputElement;
      prevInput.focus();
      return;
    }

    if (typeof Number($event.key) === 'number' && !isNaN(Number($event.key))) {
      //move to next input
      curInput.value = $event.key;

      if (index === nextIndex) {
        return;
      }
      const nextInput = document.querySelector(`#num-${nextIndex}`) as HTMLInputElement;
      nextInput.focus();
      return;
    }


  }

  onCodeKeyPress({ form }: NgForm, $event: KeyboardEvent) {
    $event.preventDefault();

    this.handleCodeInput($event);
    this.checkCodeForm(form);
  }
  onCodePaste({ form }: NgForm, $event: ClipboardEvent) {
    $event.preventDefault();
    if (!$event.clipboardData) return;

    const clipboardData = $event.clipboardData.getData('text');
    const clipboardArr = clipboardData.split('');

    const allNumsArr = Object.keys(form.controls).filter(name => name.match('number-')).map((key) => {
      return form.controls[key];
    });

    allNumsArr.forEach((num, index) => {
      num.setValue(clipboardArr[index]);
    });

    // forcus on last div
    const lastInput = document.querySelector(`#num-${this.confirmationCodeLength - 1}`) as HTMLInputElement;
    lastInput.focus();

    this.checkCodeForm(form);
  }
  // #endregion


  // #region COGNITO SUBMISSION
  async submitConfirmationCodeRequest({ form }: NgForm) {
    try {
      this.loadingSpinnerService.setIsLoading(true);
      const res = await this.auth.handleClaimAccount(form.value.email, this.confirmationCode)
      this.email = form.value.email;

      if (res) {
        this.toggleTabs('passwordChange');
        this.toastService.setToast({
          text: 'Account Successfully Confirmed', type: 'success', icon: true,
        })
        this.loadingSpinnerService.setIsLoading(false);
      }
      return res;
    } catch (err) {
      this.loadingSpinnerService.setIsLoading(false);
      this.toastService.setToast({
        text: 'Account Confirmation Failed', type: 'error', icon: true,
      })
      throw err;
    }
  }



  async submitPasswordChangeRequest(password: string) {
    this.loadingSpinnerService.setIsLoading(true);
    try {
      const email = this.email;
      const res = await this.auth.handleSetFirstTimePassword(email, password)

      if (res) {
        this.backToSignIn.emit();
      }
    } catch (err) {
      console.log(err);
    }
  }

  openCodeModal({ form }: NgForm) {
    this.dialog.open(SendCodeModalComponent, {});
  }

  // #endregion
}
