import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-courses-icon',
  templateUrl: './manage-courses-icon.component.html',
  styleUrls: [ './manage-courses-icon.component.scss' ],
})
export class ManageCoursesIconComponent {

}
