<!-- TODO: Show applied filters -->
<div class="modal">
  <div class="header">
    <div class="title">
      <mat-icon fontSet="material-icons-round" class="icon">sort</mat-icon>
      <span class="kbody-huge">Apply Filters</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <div class="nav">
      <span
        [ngClass]="{ selected: selectedTab === title }"
        class="klabel-large nav-title"
        *ngFor="let title of sideNavTitles"
        (click)="selectedTab = title"
        >{{ title }}</span
      >
    </div>
    <div class="tab-content">
      <app-general-course-filters
        *ngIf="selectedTab === 'General'"
        [mainFilter]="mainFilter"
        (updateMainFilter)="updateMainFilter($event)"
      ></app-general-course-filters>
      <app-course-buildings-filters
        *ngIf="selectedTab === 'Buildings'"
        [mainFilter]="mainFilter"
        (updateMainFilter)="updateMainFilter($event)"
        [buildingsList]="buildingsList"
      ></app-course-buildings-filters>
      <app-course-requirements-filters
        *ngIf="selectedTab === 'Requirements'"
        [mainFilter]="mainFilter"
        (updateMainFilter)="updateMainFilter($event)"
        [requirementList]="requirementList"
      ></app-course-requirements-filters>
      <app-course-categories-filters
        *ngIf="selectedTab === 'Categories'"
        [mainFilter]="mainFilter"
        (updateMainFilter)="updateMainFilter($event)"
        [categoryList]="categoryList"
      ></app-course-categories-filters>
      <app-course-tags-filters
        *ngIf="selectedTab === 'Tags'"
        [mainFilter]="mainFilter"
        (updateMainFilter)="updateMainFilter($event)"
        [tagList]="tagList"
      ></app-course-tags-filters>
      <app-course-course-types-filters
        *ngIf="selectedTab === 'Course Types'"
        [mainFilter]="mainFilter"
        (updateMainFilter)="updateMainFilter($event)"
        [courseTypeList]="courseTypeList"
      ></app-course-course-types-filters>
    </div>
  </div>
  <div class="buttons">
    <app-button
      [icon]="{ materialIcon: 'restart_alt' }"
      [text]="'Reset All Filters'"
      class="grey"
      (click)="resetMainFilter()"
    ></app-button>
    <app-button
      [icon]="{ materialIcon: 'done' }"
      [text]="'Save Filter Selections'"
      (click)="saveMainFilter()"
    ></app-button>
  </div>
</div>
