<div class="modal">
  <div class="title-row">
    <div class="title-container">
      <mat-icon fontSet="material-icons-round">move_up</mat-icon>
      <span class="kbody-huge">Change Requirement</span>
    </div>
    <mat-icon
      class="close-icon"
      (click)="closeDialog()"
      fontSet="material-icons-round"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <app-select
      [options]="requirementOptions"
      [label]="'Select requirement to apply this course to'"
      [placeholder]="'Select Requirement'"
      [value]="selectedRequirement"
      (selectedValue)="setRequirement($event)"
    ></app-select>
  </div>
  <div class="buttons">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeDialog()"
    ></app-button>
    <app-button
      [text]="'Change Requirement'"
      (click)="updateRequirement()"
    ></app-button>
  </div>
</div>
