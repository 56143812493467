<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Attachments</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="ksubtitle-medium">Max file size 10mb; acceptable file formats include .pdf, .jpg, .png</span>
    <div class="dropbox">
      <mat-icon class="icon" fontSet="material-icons-round">upload</mat-icon>
      <span class="klabel-huge">Drag your files here</span>
      <span class="kbody-medium">or click here to upload from your computer</span>
    </div>
  </div>
  <div class="buttons">
    <app-button (click)="stepBackward()" [text]="'Previous Step'" [icon]="{materialIcon: 'arrow_back'}" [class]="'white'"></app-button>
    <div class="actions">
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeDialog()"></app-button>
      <app-button [icon]="{ materialIcon: 'arrow_forward' }" [class]="'icon-right'" [text]="'Next Step'" (click)="updateUser()"></app-button>
    </div>
  </div> 
</div>