<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">General Information</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <div class="section">
      <span class="klabel-huge">Basic Information</span>
      <div class="row">
        <app-input
          [label]="'First Name*'"
          [value]="localEditedUser?.firstName"
          [disabled]="!isAdminUser"
          [placeholder]="'Enter First Name'"
          (updatedValue)="updateUserParameter($event, 'firstName')"
          [required]="true"
        ></app-input>
        <app-input
          [label]="'Last Name*'"
          value="{{ this?.localEditedUser?.lastName }}"
          [disabled]="!isAdminUser"
          [placeholder]="'Enter Last Name'"
          (updatedValue)="updateUserParameter($event, 'lastName')"
          [required]="true"
        ></app-input>
      </div>
      <div class="row left" *ngIf="isAdminUser">
        <input
          type="checkbox"
          [(ngModel)]="this.localEditedUser.nameChangedFlag"
        />
        <span class="kbody-medium"
          >This person’s name has changed within the last year.</span
        >
        <!-- TODO - implement help tooltip -->
        <!-- <mat-icon fontSet="material-icons-round" class="help">help</mat-icon> -->
      </div>
    </div>
    <div class="section">
      <span class="klabel-huge">District Information</span>
      <div class="row">
        <app-input
          [label]="'District Email*'"
          value="{{ this?.localEditedUser?.email }}"
          [placeholder]="'Enter Distrcit Email'"
          (updatedValue)="updateUserParameter($event, 'email')"
          [required]="true"
          [email]="true"
          [disabled]="isEditMode"
        ></app-input>
        <app-input
          [label]="'Alternate Email'"
          value="{{ this?.localEditedUser?.altEmail }}"
          [placeholder]="'Enter Alternate Email'"
          (updatedValue)="updateUserParameter($event, 'altEmail')"
          [email]="true"
        ></app-input>
      </div>
      <div class="row left">
        <app-input
          class="offset"
          [label]="'PIC Number'"
          value="{{ this?.localEditedUser?.stateIssuedId }}"
          [placeholder]="'Enter PIC Number'"
          (updatedValue)="updateUserParameter($event, 'stateIssuedId')"
          [disabled]="isEditMode && !permissions.USER_EDIT"
        ></app-input>
      </div>
    </div>
    <div class="section" *ngIf="isAdminUser">
      <span class="klabel-huge">Kalpa Access</span>
      <div class="row">
        <app-select
          value="{{ localUserRoleId }}"
          [label]="'User Role*'"
          [options]="formattedRoles"
          (selectedValue)="updateUserParameter($event, 'userRole', 'select')"
          placeholder="Select User Role"
          [disabled]="isEditMode && !permissions.USER_EDIT"
        ></app-select>
        <div class="switch-container">
          <label class="switch">
            <input
              [(ngModel)]="localEditedUser.activeUserFlag"
              type="checkbox"
              [disabled]="isEditMode && !permissions.USER_EDIT"
            />
            <span class="slider round"></span>
          </label>
          <span
            >This user is
            {{ localEditedUser.activeUserFlag ? "active" : "inactive" }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeDialog()"
    ></app-button>
    <app-button
      [disabled]="
        this.localEditedUser.firstName === '' ||
        this.localEditedUser.lastName === '' ||
        this.localEditedUser.email === '' ||
        this.localEditedUser.userRole === ''
      "
      [icon]="{ materialIcon: 'arrow_forward' }"
      [class]="'icon-right'"
      [text]="'Next Step'"
      (click)="updateUser()"
    ></app-button>
  </div>
</div>
