import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-review-and-finish',
  templateUrl: './review-and-finish.component.html',
  styleUrls: [ './review-and-finish.component.scss' ],
})
export class ReviewAndFinishComponent implements OnInit, OnDestroy {
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() editedUser: any;
  @Input() originalUser: any;
  @Input() isEditMode: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }

  localEditedUser: any;
  userSubscription: any;
  error: string;
  isLoading = false;

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<ReviewAndFinishComponent>,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.localEditedUser = this.editedUser;
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  getRoleName() {
    const setRole = this.localEditedUser?.userRole?.roleName;

    if (!!setRole) return setRole;

    const roleId = this.localEditedUser?.userRole;
    const role = this.localEditedUser?.Roles?.find((role: any) => role.id === roleId);

    return role?.roleName || '-';
  }

  public saveUser = async () => {
    // final validation check before sending request
    if (
      this.localEditedUser.firstName === '' ||
      this.localEditedUser.lastName === '' ||
      this.localEditedUser.email === '' ||
      this.localEditedUser.userRole === '' ||
      !this.localEditedUser.primaryBuilding ||
      !this.localEditedUser.primaryPosition
      || this.localEditedUser?.primaryApprovers.length === 0
      || this.localEditedUser?.secondaryApprovers.length === 0
    ) {
      this.error = 'Please go back and fill out all required fields';
      return
    }
    // this.isLoading = true;

    if (this.isEditMode) {
      // find all new primaryApprovers in editedUser.primaryApprovers checking against originalUser.PrimaryApprovers
      const newPrimaryApprovers = this.editedUser.primaryApprovers.filter((approver: any) => !this.originalUser.PrimaryApprovers.some((originalApprover: any) => originalApprover.id === approver.id))
      this.editedUser.newPrimaryApprovers = newPrimaryApprovers.map((approver: any) => parseFloat(approver.userId));
      // find all primaryApprovers to remove in originalUser.PrimaryApprovers checking against editedUser.primaryApprovers
      const primaryApproversToRemove = this.originalUser.PrimaryApprovers.filter((approver: any) => !this.editedUser.primaryApprovers.some((editedApprover: any) => editedApprover.id === approver.id))
      this.editedUser.removePrimaryApprovers = primaryApproversToRemove.map((approver: any) => parseFloat(approver.id));

      // find all new secondaryApprovers in editedUser.secondaryApprovers checking against originalUser.SecondaryApprovers
      const newSecondaryApprovers = this.editedUser.secondaryApprovers.filter((approver: any) => !this.originalUser.SecondaryApprovers.some((originalApprover: any) => originalApprover.id === approver.id))
      this.editedUser.newSecondaryApprovers = newSecondaryApprovers.map((approver: any) => parseFloat(approver.userId));

      // find all secondaryApprovers to remove in originalUser.SecondaryApprovers checking against editedUser.secondaryApprovers
      const secondaryApproversToRemove = this.originalUser.SecondaryApprovers.filter((approver: any) => !this.editedUser.secondaryApprovers.some((editedApprover: any) => editedApprover.id === approver.id))
      this.editedUser.removeSecondaryApprovers = secondaryApproversToRemove.map((approver: any) => parseFloat(approver.id));

      // find all new buildings in editedUser.buildings checking against originalUser.Buildings
      const newBuildings = this.editedUser.buildings.filter((building: any) => !this.originalUser.Buildings.some((originalBuilding: any) => originalBuilding.id === building.id))
      this.editedUser.newBuildingIds = newBuildings.map((building: any) => parseInt(building.id));
      // find all buildings to remove in originalUser.Buildings checking against editedUser.buildings
      const buildingsToRemove = this.originalUser.Buildings.filter((building: any) => !this.editedUser.buildings.some((editedBuilding: any) => editedBuilding.id === building.id))
      this.editedUser.removeBuildingIds = buildingsToRemove.map((building: any) => parseInt(building.id));

      // find all new positions in editedUser.positions checking against originalUser.Positions
      const newPositions = this.editedUser.positions.filter((position: any) => !this.originalUser.Positions.some((originalPosition: any) => originalPosition.id === position.id))
      this.editedUser.newPositionIds = newPositions.map((position: any) => parseInt(position.id));
      // find all positions to remove in originalUser.Positions checking against editedUser.positions
      const positionsToRemove = this.originalUser.Positions.filter((position: any) => !this.editedUser.positions.some((editedPosition: any) => editedPosition.id === position.id))
      this.editedUser.removePositionIds = positionsToRemove.map((position: any) => parseInt(position.id));

      //TODO fix this when Requirements Children can be added/edited separately
      // find all new requirements in editedUser.requirements checking against originalUser.Requirements
      const newRequirements = this.editedUser.requirements.filter((requirement: any) => !this.originalUser.Requirements.some((originalRequirement: any) => originalRequirement.id === requirement.id))
      this.editedUser.addRequirementsArr = newRequirements.filter((requirement: { level: number; }) => requirement.level === 0).map((requirement: any) => {
        return {
          requirementId: parseFloat(requirement.id),
          requiredValue: parseFloat(requirement.trackingValue),
        }
      });

      // find all requirements to remove in originalUser.Requirements checking against editedUser.requirements
      const requirementsToRemove = this.originalUser.Requirements.filter((requirement: any) => !this.editedUser.requirements.some((editedRequirement: any) => editedRequirement.id === requirement.id))
      this.editedUser.removeRequirementsArr = requirementsToRemove.map((requirement: any) => parseFloat(requirement.UserRequirements.id))

      // find all requirements to update in editedUser.requirements checking editedUser.requirements against originalUser.Requirements[requirement]UserRequirement.requirementValue has changed
      const requirementsToUpdate = this.editedUser.requirements.filter((requirement: any) => {
        const originalRequirement = this.originalUser.Requirements.find((originalRequirement: any) => originalRequirement.id === requirement.id);
        return originalRequirement ? originalRequirement.UserRequirements.requiredValue !== requirement.trackingValue : false;
      })

      // map through all the requirementsToUpdate and attach the Requirement.UserRequirements.id from the originalUser.Requirements
      requirementsToUpdate.map((requirement: any) => {
        const originalRequirement = this.originalUser.Requirements.find((originalRequirement: any) => originalRequirement.id === requirement.id);
        requirement.userRequirementsId = originalRequirement.UserRequirements.id;
      })

      // this format is required by the endpoint
      this.editedUser.updateRequirementsArr = requirementsToUpdate.map((requirement: any) => {
        return {
          id: parseFloat(requirement.userRequirementsId),
          requiredValue: parseFloat(requirement.trackingValue),
        }
      });
    } else {
      const cognitoUser = await this.authService.addUserToAWS(this.localEditedUser);
    }

    this.userSubscription = (!this.isEditMode ?
      this.usersService.addUser(this.localEditedUser)
      : this.usersService.updateUser(this.localEditedUser)
    )
      .subscribe({
        next: (response: any) => {
          this.usersService.clearSelectedUser()
          // here we get the updated user if we are in edit mode, mostly for when editing from the profile page
          this.localEditedUser.id ? this.usersService.getUserById(this.localEditedUser.id, { 'includedAssociations': 'PrimaryBuilding, PrimaryPosition, Requirements, Roles' }) : null;
          this.toastService.setToast({ text: 'User successfully added', type: 'success' })
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.isLoading = false;
          let message;
          if (error.error.statusCode === 3002) {
            message = 'Email already exists, please choose another email'
          }
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert', title: 'Oops, something went wrong', text: message || error.error.errMsg || error.error.message,
            },
          })
        },
      })
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

}
