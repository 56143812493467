import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { UpdateCourseTypeComponent } from '../update-course-type/update-course-type.component';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { SwapComponent } from 'src/app/components/swap/swap.component';

@Component({
  selector: 'app-course-type-details',
  templateUrl: './course-type-details.component.html',
  styleUrls: [ './course-type-details.component.scss' ],
})
export class CourseTypeDetailsComponent {

  constructor(
    public dialogRef: MatDialogRef<CourseTypeDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeDialog = () => {
    this.dialogRef.close();
  }

  showEditModal = () => {
    const updateRef = this.dialog.open(UpdateCourseTypeComponent, { data: this.data })

    updateRef.afterClosed().subscribe(result => {
      this.dialogRef.close(result)
    })
  }

  toggleActivation = () => {
    // this should actually only pop when we get an error that there are staff. this works as a demo until we hook up with the backend
    const dialogRef = this.dialog.open(VerificationComponent, {
      data: {
        type: 'alert', title: 'Replace Course Type', confirmButtonTitle: 'Replace Course Type', cancelButtonTitle: 'Cancel', text: 'This course type is assigned to courses in the current year catelog. You must choose a new course type to replace it.',
      },
    })
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        // this will be some real data in the future
        const swapRef = this.dialog.open(SwapComponent, {
          data: {
            title: 'Replace Course Type',
            label: 'Select a new course type',
            optionPlaceHolder: 'Select course type',
            options: [
              'option1', 'option2', 'option3',
            ],
          },
        })

        swapRef.afterClosed().subscribe(result => {
          if(result) {
            this.dialogRef.close(result)
          }
        })
      }
    })
  }

}
