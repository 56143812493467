import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-complete-password-change',
  templateUrl: './complete-password-change.component.html',
  styleUrls: [
    '../../sign-in.component.scss',
    '../forgot-password/forgot-password.component.scss',
    './complete-password-change.component.scss',
  ],
})
export class CompletePasswordChangeComponent {
  @Input() changePassDetails: { email: string; password: string; rememberMe: boolean } | null;
  @Output() backToSignIn = new EventEmitter<object>();

  constructor(private authService: AuthService,
    private toast: ToastService) {}


  async changePassword(newPassword: string) {
    if (!this.changePassDetails) return;

    const { email, password: oldPassword } = this.changePassDetails;

    const res = await this.authService.handleCompletePassword(
      email, oldPassword, newPassword,
    )

    if (res) {
      this.toast.setToast({
        text: 'Password changed successfully',
        type: 'success',
        icon: true,
      });

      this.backToSignIn.emit();
      return;
    }

    this.toast.setToast({
      text: 'Error changing password',
      type: 'error',
      icon: true,
    });
  }
}
