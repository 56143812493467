<div class="container">
  <div class="title-row">
    <span class="klabel-huge">Filter by Category</span>
    <app-button
      [text]="'De-select all'"
      class="grey"
      (click)="clearCategoriesFilter()"
    ></app-button>
  </div>
  <div class="content">
    <div class="checkboxes">
      <div *ngIf="categoryList.length !== 0" class="checkbox-row">
        <input
          type="checkbox"
          class="kinput"
          [checked]="
            categoryList?.length === mainFilter.categories.length &&
            categoryList.length !== 0
          "
          (change)="selectAllCategoriesFilter($event)"
        />
        <span class="kbody-medium">Select all categories</span>
      </div>
      <div class="checkbox-row" *ngFor="let category of categoryList">
        <input
          type="checkbox"
          class="kinput"
          [checked]="checkIncludesCategory(category)"
          (change)="updateCheckboxFilter($event, category)"
        />
        <span class="kbody-medium">{{ category?.category }}</span>
      </div>
    </div>
  </div>
</div>
