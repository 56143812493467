<div class="validation-widget">
  <div class="row title-row">
    <span class="klabel-medium title">Course Name</span>
    <div class="right">
      <span class="klabel-medium title">Date</span>
      <span class="klabel-medium title">Enrolled</span>
    </div>
  </div>
  <div class="courses">
    <div class="row course" *ngFor="let course of courses">
      <span class="detail klabel-huge">{{course.name}}</span>
      <div class="right">
        <span class="detail kbody-large">{{course.date}}</span>
        <span class="detail kbody-large">{{course.enrolled}}</span>
      </div>
    </div>
  </div>
  <app-button class="btn" [text]="'Go to Validation'" [class]="'grey'"></app-button>
</div>