import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [ './profile.component.scss' ],
})
export class ProfileComponent implements OnInit, OnDestroy {
  loggedInUser: any;
  user: any;
  authSubscription: Subscription;
  defaultSections = [
    { text: 'Overview', icon: 'account_circle' },
    { text: 'PD Plan', icon: 'list' },
    // { text: 'Course Requests', icon: 'post_add' },
    // TODO: cleanup - add this back in when the goals feature is implemented
    // { text: 'Goals', icon: 'track_changes' },
  ]
  selectedSection = this.defaultSections[0]
  userId: number;
  userSubscription: Subscription;
  isLoading = true;
  viewingOthersProfile: any;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // this is for the user to view their own profile
    if(!this?.route?.snapshot?.params['id']) {
      this.authSubscription = this.authService.user$.subscribe((user: any) => {
        this.loggedInUser = user
      })
    }
    this?.route?.snapshot?.params['id'] ? this.viewingOthersProfile = true : this.viewingOthersProfile = false
    this.isLoading = true
    // this changes id based on whether the user is viewing their own profile or someone else's
    this.userId = this?.route?.snapshot?.params['id'] ? this?.route?.snapshot?.params['id'] : this.loggedInUser.id
    this.usersService.clearSelectedUser()
    this.usersService.getUserById(this.userId, { 'includedAssociations': 'PrimaryBuilding, PrimaryPosition, Requirements, Roles, PrimaryApprovers, SecondaryApprovers' })
    this.userSubscription = this.usersService.selectedUserData$.subscribe((user: any) => {
      this.user = user
      user ? this.isLoading = false : this.isLoading = true
    })
  }

  toggleSelectedSection = (section: any) => {
    this.selectedSection = section
  }

  ngOnDestroy(): void {
    this?.authSubscription?.unsubscribe()
    this?.userSubscription?.unsubscribe()
  }
}
