import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PositionsService {
  private _positionsData$ = new BehaviorSubject<any>(null);
  public readonly positionsData$ = this._positionsData$.asObservable();

  _user$: any;


  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)

  }

  getPagination = (pageDirection: any) => {
    let links = '';
    this.positionsData$.subscribe(data =>
      links = data.links)
    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/positions/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._positionsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getPositions = ({
    positionTitleFilter, activeFlag = 1, sortColumn = 'positionTitle', sortDirection = 'ASC', limit, offset = 0,
  }: {
    positionTitleFilter: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
    offset: number;
    }) => {

    const params: {
        loginName: string;
        activeFlag: 1 | 0;
        positionTitleFilter?: string;
        sortColumn?: string;
        sortDirection?: 'ASC' | 'DESC';
        limit?: number;
        offset?: number;
      } = {
        loginName: this._user$.loginName,
        activeFlag,
      }

    if (positionTitleFilter) {
      params.positionTitleFilter = positionTitleFilter;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    return this.http.get(`${environment.expressUrl}/maintenance/positions`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({

        next: (response: any) => {
          this._positionsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getPositionDetails = (positionId: number, includedAssociations: string) => {
    return this.http.get(`${environment.expressUrl}/maintenance/positions/${positionId}`,

      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this._user$.loginName, 'includedAssociations': includedAssociations },
      })
  }

  getSortedPosition = (
    title: string, sortDirection: string, activeFlag: boolean, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/positions`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': limit,
          'offset': offset,
          'sortDirection': sortDirection,
          'activeFlag': activeFlag ? 1 : 0,
        },
      })
      .subscribe({
        next: (response: any) => {

          this._positionsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getPositionsFilteredByName = (positionTitleFilter: string, activeFlag: number) => {
    return this.http.get(`${environment.expressUrl}/maintenance/positions`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': 25, 'positionTitleFilter': positionTitleFilter, 'activeFlag': activeFlag,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._positionsData$.next(response.data || {});
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getInactivePositions = (limit = 25, offset = 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/positions`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'offset': offset, 'activeFlag': 0,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._positionsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  addPosition = (position: any) => {
    console.log('🚀 - position:', position);
    return this.http.post(
      `${environment.expressUrl}/maintenance/positions`,
      {
        'loginName': this._user$.loginName,
        'entityId': parseFloat(this._user$.entityId), //22
        'positionTitle': position?.positionTitle,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  updatePosition = (position: any) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/positions/${position.id}`,
      {
        'loginName': this._user$.loginName,
        'entityId': parseFloat(this._user$.entityId),
        'positionTitle': position?.positionTitle,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  togglePositionActivation = (position: any, isActivating: boolean) => {
    const activeEndpoint = isActivating ? 'activate' : 'deactivate';
    return this.http.put(
      `${environment.expressUrl}/maintenance/positions/${position.id}/${activeEndpoint}`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  batchStaffReassign = (
    staffList: any, curPositionId: any, newPositionId: any,
  ) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/positions/reassign-users`,
      {
        'loginName': this._user$.loginName,
        'userIds': staffList,
        'curPositionId': parseFloat(curPositionId),
        'newPositionId': parseFloat(newPositionId),
        'maintainPositionAssignment': false,
        'overwritePrimaryPosition': true,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

}
