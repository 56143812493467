import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';
import { ChangeEvent } from 'preact/compat';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-general-course-filters',
  templateUrl: './general-course-filters.component.html',
  styleUrls: [ './general-course-filters.component.scss' ],
})
export class GeneralCourseFiltersComponent {
  @Input() mainFilter: CourseFilter;
  @Output() updateMainFilter = new EventEmitter<object>();
  permissions: any;
  hasPermission = false;

  privilegedFilters: { text: string, parameter: keyof CourseFilter }[] = [ { text: 'Ignore the registration window', parameter: 'ignoreRegistrationWindowFlag' } ]

  basicList: {text: string, parameter: keyof CourseFilter}[] = [
    { text: 'Show cancelled courses', parameter: 'showCancelledCoursesFlag' },
    // { text: 'Show previous hours', parameter: 'showPreviousHoursFlag' },
    { text: 'Show async', parameter: 'showAsyncFlag' },
    { text: 'Show anytime courses', parameter: 'showAnytimeCoursesFlag' },
    { text: 'Show virtual', parameter: 'showVirtualFlag' },
    { text: 'Show outside courses', parameter: 'showOutsideCoursesFlag' },
    { text: 'Show waitlisted courses', parameter: 'showWaitlistedCoursesFlag' },
  ]

  checkboxList: { text: string, parameter: keyof CourseFilter }[] = [];



  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.permissions$.subscribe((permissions) => {
      if (!permissions) return;
      this.permissions = permissions;

      const allowsPrivledge = [
        'COURSE_ADD', 'COURSE_EDIT', 'COURSE_PUSH',
      ]

      const hasPrivledge = allowsPrivledge.some((privledge) => permissions[privledge] === 1);

      if (hasPrivledge) {
        this.hasPermission = true;
        this.checkboxList = [ ...this.basicList, ...this.privilegedFilters ]
      } else {
        this.hasPermission = false;
        this.checkboxList = [ ...this.basicList ]
      }
    })
  }

  updateCheckboxFilter(parameter: keyof CourseFilter, event: any): void {
    (this.mainFilter[parameter] as boolean) = event.target.checked;
  }

  updateFilter(parameter: keyof CourseFilter, event: any): void {
    (this.mainFilter[parameter] as string)= event.target.value as string;
  }

  resetGeneralFilters(): void {
    this.mainFilter.resetGeneral();
    console.log(this.mainFilter)
    const otherFilters = [
      'startDate', 'endDate', 'hours',
    ];
    otherFilters.forEach((filter) => {
      this.updateMainFilter.emit({ parameter: filter, value: '' });
    });
  }
}
