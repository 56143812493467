import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseRequestResponseComponent } from './components/course-request-response/course-request-response.component';

@Component({
  selector: 'app-course-requests',
  templateUrl: './course-requests.component.html',
  styleUrls: [ './course-requests.component.scss' ],
})
export class CourseRequestsComponent {
  selectedTab = 'Pending';

  tableTitles = [
    { name: 'Course Name' },
    { name: 'Hours' },
    { name: 'Date & Time' },
    { name: 'Submitted on' },
    { name: 'Submitted by' },
  ];

  courseList = [
    {
      courseName: 'Math Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Science Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'English Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Math Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'Manny Ham',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Science Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'English Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Math Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Science Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'English Course',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      hours: '10',
      submittedOn: '01/01/2020',
      submittedBy: 'John Doe',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
  ];

  constructor( public dialog: MatDialog) { }

  setSelectedTab(tab: any) {
    this.selectedTab = tab;
  }

  openCourseRequestResponse(course: any) {
    this.dialog.open(CourseRequestResponseComponent, { data: course });
  }

}
