<div class="container">
  <div class="title-row">
    <span class="klabel-huge">General</span>
    <app-button
      [text]="'Reset General Filters'"
      class="grey"
      (click)="resetGeneralFilters()"
    ></app-button>
  </div>
  <div class="content">
    <div class="inputs">
      <span class="klabel-medium label">Date Range</span>
      <mat-form-field class="kinput">
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Start date"
            [value]="mainFilter.startDate"
            (dateChange)="updateFilter('startDate', $event)"
          />
          <input
            matEndDate
            placeholder="End date"
            [value]="mainFilter.endDate"
            (dateChange)="updateFilter('endDate', $event)"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <span class="klabel-medium label">Hours</span>
      <input
        type="number"
        class="kinput"
        placeholder="0.00"
        min="0"
        step="0.25"
        [value]="mainFilter.hours"
        (change)="updateFilter('hours', $event)"
      />
    </div>
    <div class="checkboxes">
      <div class="checkbox-row" *ngFor="let filter of checkboxList">
        <input
          [checked]="mainFilter[filter.parameter]"
          type="checkbox"
          (change)="updateCheckboxFilter(filter.parameter, $event)"
          class="kinput"
        />
        <span class="kbody-medium">{{ filter.text }}</span>
      </div>
    </div>
  </div>
</div>
