import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

// This is the basic auth guard for logged in status
export const AuthGuard = () => {
  const authService = inject(AuthService)
  const router = inject(Router)
  let loggedIn

  const authSubscription = authService.loggedIn$
    .subscribe((loggedInStatus: any) => {
      loggedIn = loggedInStatus
    })

  if(loggedIn) {
    return true;
  }

  authSubscription.unsubscribe();
  router.navigate([ '/', 'signin' ])
  return false

}
