<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon fontSet="material-icons-round" class="icon">key</mat-icon>
      <span class="kbody-huge">Change Password</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>
  <div class="container">
    <app-password-change
      [isPasswordChange]="true"
      (passwordChangeSubmitEvent)="changePassword($event)"
    ></app-password-change>
  </div>
</div>
