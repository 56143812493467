<div class="positions-component">
  <app-table
    (addNewItem)="addNewPosition()"
    (sortColumn)="sortColumn($event)"
    (filterColumn)="getPositionsFilteredByName($event)"
    (toggleActiveList)="toggleActiveList($event)"
    (showItemDetails)="showItemDetails($event)"
    (showEditModal)="showEditModal($event)"
    (itemsPerPageChange)="onItemsPerPageChange($event)"
    (pageChanged)="onPageChanged($event)"
    [tableData]="tableData"
    [isLoading]="loading"
  >
  </app-table>
  <app-loading-spinner class="spinner" *ngIf="loading"></app-loading-spinner>
</div>
