import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses.service';
import { RegistrationSuccessModalComponent } from '../registration-success-modal/registration-success-modal.component';
import { VerificationComponent } from 'src/app/components/verification/verification.component';

@Component({
  selector: 'app-registration-requirement-modal',
  templateUrl: './registration-requirement-modal.component.html',
  styleUrls: [ './registration-requirement-modal.component.scss' ],
})
export class RegistrationRequirementModalComponent implements OnInit, OnDestroy {
  registrationSubscription: Subscription;
  userRequirements: any;
  courseRequirements: any;
  requirementOptions: any;
  formattedRequirementOptions: any;
  selectedRequirement: any;

  constructor(
    public dialogRef: MatDialogRef<RegistrationRequirementModalComponent>,
    public dialog: MatDialog,
    private coursesService: CoursesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  // TODO: once requirements has it's group update, the requirement dropdown will break and need to be updated
  // TODO: handle registerring for a class that has a pin

  ngOnInit(): void {
    this.userRequirements = this.data.userRequirements;
    this.courseRequirements = this.data.selectedCourse;

    // this is where we filter the user requirements to only the ones that match the course requirements
    this.requirementOptions = this.userRequirements.filter((userRequirement: any) => {
      return this.courseRequirements.reqAgg.find((courseRequirement: any) => {
        return userRequirement.id === courseRequirement;
      })
    })
    // this is where we format the requirements for our select dropdown
    this.formattedRequirementOptions = this.requirementOptions.map((requirementOption: any) => {
      return {
        text: requirementOption.requirement,
        value: requirementOption.id,
      }
    })
    // this adds the unassigned option to the top of the list
    this.formattedRequirementOptions.unshift({ value: null, text: 'Unassigned/Portfolio' });
  }

  setSelectedRequirement = (event: any) => {
    this.selectedRequirement = event || null;
  }

  registerForCourse = () => {
    const registrationData = {
      courseId: this.data.selectedCourse.courseId,
      courseItemId: this.data.selectedCourse.courseItemId,
      requirementId: this.selectedRequirement,
    }
    this.registrationSubscription = this.coursesService.registerForCourse(registrationData).subscribe({
      next: (response: any) => {
        // this.coursesService.getCourseSessions({ includedAssociations:'Tags, Items' });
        this.dialog.open(RegistrationSuccessModalComponent, { data: this.data.selectedCourse })
        this.dialogRef.close();
      },
      error: (error) => {
        console.log('error', error);
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong registering', text: error.error.statusCode === 3102 ? 'You are already registered for this course.' : error.error.message,
          },
        })
      },
    })

  }

  closeModal = () => {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.registrationSubscription?.unsubscribe();
  }

}
