import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  private _validationData$ = new BehaviorSubject<any>(null);
  public readonly validationData$ = this._validationData$.asObservable();

  private _validationTypes$ = new BehaviorSubject<any>(null);
  public readonly validationTypes$ = this._validationTypes$.asObservable();

  private _sessionValidationDetails$ = new BehaviorSubject<any>(null);
  public readonly validationSessionData$ = this._sessionValidationDetails$.asObservable();

  user: any;

  constructor(private http: HttpClient, private authService: AuthService) {    this.authService.user$.subscribe((user: any) => this.user = user) }

  getValidationSessions = ( isNeedsValidation: any, additionalParams?: any) => {
    return this.http.get(`${environment.expressUrl}/validation/${isNeedsValidation ? 'needs-validation' : 'past-validation'}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this.user.loginName,
          'limit': 25,
          ...additionalParams,
        },
      }).subscribe({
      next: (response: any) => {
        this._validationData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
        this._validationData$.next(error);
      },
    })
  }

  getSessionValidationDetails = ( validationData: any ) => {
    return this.http.get(`${environment.expressUrl}/validation/course-details`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this.user.loginName,
          'courseItemId': validationData.courseItemId,
          'courseId': validationData.courseId,
        },
      }).subscribe({
      next: (response: any) => {
        this._sessionValidationDetails$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
        this._validationData$.next(error);
      },
    })
  }

  clearSessionValidationDetails = () => {
    this._sessionValidationDetails$.next(null);
  }

  bulkValidate = (validationData: any) => {
    return this.http.put(
      `${environment.expressUrl}/courses/registration`,
      {
        'loginName': this.user.loginName,
        'courseId': parseFloat(validationData.courseId),
        'courseItemId': parseFloat(validationData.courseItemId),
        'updateArr': validationData.updateArr,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  getValidationTypes = () => {
    this.http.get(`${environment.expressUrl}/global/validation-types`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this.user.loginName },
      }).subscribe({
      next: (response: any) => {
        this._validationTypes$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }
}
