<div class="approval-container">
  <span class="kstrong-large">Approval Level</span>
  <app-select
    (selectedValue)="updateDefaults($event, 'defaultSpereqApprovalKid')"
    label="How many approval levels do pending course requests require*"
    placeholder="Select Approval Levels"
    [value]="defaults?.Config?.defaultSpereqApprovalKid"
    [options]="approvalOptions"
    [error]="error?.approvalLevel"
  >
  </app-select>
</div>
