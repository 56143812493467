
class CourseFilter {
  courseNameFilter: string;
  startDate: Date | '';
  endDate: Date | '';
  hours = '';
  showAsyncFlag = true;
  showAnytimeCoursesFlag = true;
  showCancelledCoursesFlag = false;
  showOutsideCoursesFlag = true;
  // showPreviousHoursFlag = false;
  showVirtualFlag = true;
  showWaitlistedCoursesFlag = true;
  ignoreRegistrationWindowFlag = false;
  buildings: string[] = [];
  requirements: string[] = [];
  categories: string[] = [];
  tags: string[] = [];
  courseTypes: string[] = [];
  specialRequestFlag = false;

  constructor(valuesObj?: CourseFilter) {
    if (!valuesObj) {
      return this;
    }
    const copy = JSON.parse(JSON.stringify(valuesObj));

    this.startDate = copy.startDate || '';
    this.endDate = copy.endDate || '';
    this.hours = copy.hours || '';
    this.showAsyncFlag = copy.showAsyncFlag !== undefined ? copy.showAsyncFlag : true;
    this.showAnytimeCoursesFlag = copy.showAnytimeCoursesFlag !==  undefined ? copy.showAnytimeCoursesFlag : true;
    this.showCancelledCoursesFlag = copy.showCancelledCoursesFlag !== undefined ? copy.showCancelledCoursesFlag : false;
    this.showOutsideCoursesFlag = copy.showOutsideCoursesFlag !== undefined ? copy.showOutsideCoursesFlag : true;
    // this.showPreviousHoursFlag = copy.showPreviousHoursFlag || false;
    this.showVirtualFlag = copy.showVirtualFlag !== undefined ? copy.showVirtualFlag : true;
    this.showWaitlistedCoursesFlag = copy.showWaitlistedCoursesFlag !== undefined ? copy.showWaitlistedCoursesFlag : true;
    this.ignoreRegistrationWindowFlag = copy.ignoreRegistrationWindowFlag !== undefined ? copy.ignoreRegistrationWindowFlag : false;
    this.buildings = copy.buildings || [];
    this.requirements = copy.requirements || [];
    this.categories = copy.categories || [];
    this.tags = copy.tags || [];
    this.courseTypes = copy.courseTypes || [];
    this.specialRequestFlag = false;

    return this;
  }

  clear() {
    this.startDate = '';
    this.endDate = '';
    this.hours = '';
    this.showAsyncFlag = false;
    this.showAnytimeCoursesFlag = false;
    this.showCancelledCoursesFlag = false;
    this.showOutsideCoursesFlag = false;
    // this.showPreviousHoursFlag = false;
    this.showVirtualFlag = false;
    this.showWaitlistedCoursesFlag = false;
    this.ignoreRegistrationWindowFlag = false;
    this.specialRequestFlag = false;

    this.buildings = [];
    this.requirements = [];
    this.categories = [];
    this.tags = [];
    this.courseTypes = [];
    return this;
  }

  copyValues() {
    return new CourseFilter(this);
  }

  setValues(valuesObj: CourseFilter) {
    this.startDate = valuesObj.startDate || this.startDate;
    this.endDate = valuesObj.endDate || this.endDate;
    this.hours = valuesObj.hours || this.hours;

    this.showAsyncFlag = valuesObj.showAsyncFlag !== undefined ? valuesObj.showAsyncFlag : this.showAsyncFlag;
    this.showAnytimeCoursesFlag = valuesObj.showAnytimeCoursesFlag !== undefined ? valuesObj.showAnytimeCoursesFlag : this.showAnytimeCoursesFlag;
    this.showCancelledCoursesFlag = valuesObj.showCancelledCoursesFlag !== undefined ? valuesObj.showCancelledCoursesFlag : this.showCancelledCoursesFlag;
    this.showOutsideCoursesFlag = valuesObj.showOutsideCoursesFlag || this.showOutsideCoursesFlag;
    // this.showPreviousHoursFlag = valuesObj.showPreviousHoursFlag || this.showPreviousHoursFlag;
    this.showVirtualFlag = valuesObj.showVirtualFlag !== undefined ? valuesObj.showVirtualFlag : this.showVirtualFlag;
    this.showWaitlistedCoursesFlag = valuesObj.showWaitlistedCoursesFlag !== undefined ? valuesObj.showWaitlistedCoursesFlag : this.showWaitlistedCoursesFlag;
    this.ignoreRegistrationWindowFlag = valuesObj.ignoreRegistrationWindowFlag !== undefined ? valuesObj.ignoreRegistrationWindowFlag : this.ignoreRegistrationWindowFlag;
    this.ignoreRegistrationWindowFlag = valuesObj.ignoreRegistrationWindowFlag !== undefined ? valuesObj.ignoreRegistrationWindowFlag : this.ignoreRegistrationWindowFlag;

    this.buildings = valuesObj.buildings || this.buildings;
    this.requirements = valuesObj.requirements || this.requirements;
    this.categories = valuesObj.categories || this.categories;
    this.tags = valuesObj.tags || this.tags;
    this.courseTypes = valuesObj.courseTypes || this.courseTypes;
    this.specialRequestFlag = false;

    return this;
  }

  resetAll() {
    this.startDate = '';
    this.endDate = '';
    this.hours = '';
    this.showAsyncFlag = true;
    this.showAnytimeCoursesFlag = true;
    this.showCancelledCoursesFlag = false;
    this.showOutsideCoursesFlag = true;
    // this.showPreviousHoursFlag = false;
    this.showVirtualFlag = true;
    this.showWaitlistedCoursesFlag = true;
    this.ignoreRegistrationWindowFlag = false;
    this.buildings = [];
    this.requirements = [];
    this.categories = [];
    this.tags = [];
    this.courseTypes = [];
    this.specialRequestFlag = false;
    this.ignoreRegistrationWindowFlag = false;

    return this;
  }

  resetGeneral() {
    this.startDate = '';
    this.endDate = '';
    this.hours = '';
    this.showAsyncFlag = true;
    this.showAnytimeCoursesFlag = true;
    this.showCancelledCoursesFlag = false;
    this.showOutsideCoursesFlag = true;
    // this.showPreviousHoursFlag = false;
    this.showVirtualFlag = true;
    this.showWaitlistedCoursesFlag = true;
    this.specialRequestFlag = false;
    this.ignoreRegistrationWindowFlag = false;
  }
}

export  { CourseFilter };
