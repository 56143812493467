<div class="sidebar" [ngClass]="{ open: sidebarIsOpen }">
  <div class="burger-row">
    <mat-icon
      fontSet="material-icons-round"
      class="burger"
      (click)="toggleSidebar()"
      >{{ sidebarIsOpen ? "menu_open" : "menu" }}</mat-icon
    >
    <app-kalpa-red *ngIf="sidebarIsOpen" class="logo"></app-kalpa-red>
  </div>
  <div class="nav-list">
    <div
      *ngFor="let item of menuList"
      class="item-container"
      [ngClass]="{ closed: !sidebarIsOpen }"
    >
      <a routerLink="/{{ item.link }}" (click)="selectedMenuItem = item">
        <div
          class="item-button"
          [ngClass]="{
            closed: !sidebarIsOpen,
            'quick-action': item.class === 'quick action',
            selected: item === selectedMenuItem
          }"
        >
          <mat-icon fontSet="material-icons-round" class="icon">{{
            item.icon
          }}</mat-icon>
          <span
            *ngIf="!item.subMenu"
            [ngClass]="{ open: sidebarIsOpen }"
            class="ksubtitle-medium label"
            >{{ item.label }}</span
          >
          <span
            *ngIf="item.label === 'Manage Courses'"
            [ngClass]="{ open: sidebarIsOpen }"
            class="ksubtitle-medium label"
            >{{ item.label }}
            <span *ngIf="item.subMenu" (click)="toggleDropdown('courses')">
              <mat-icon fontSet="material-icons-round">{{
                coursesDropdownIsOpen ? "arrow_drop_up" : "arrow_drop_down"
              }}</mat-icon>
            </span>
          </span>
        </div>
      </a>
      <div
        class="sub-menu"
        *ngIf="
          item.subMenu &&
          sidebarIsOpen &&
          item.label === 'Manage Courses' &&
          coursesDropdownIsOpen
        "
      >
        <a
          [ngClass]="{ selected: subItem === selectedMenuItem }"
          *ngFor="let subItem of item.subMenu"
          routerLink="{{ subItem.link }}"
          (click)="selectedMenuItem = subItem"
        >
          <span class="sub-item kbody-large">{{ subItem.label }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
