import { Component } from '@angular/core';

@Component({
  selector: 'app-waitlist-widget',
  templateUrl: './waitlist-widget.component.html',
  styleUrls: [ './waitlist-widget.component.scss' ],
})
export class WaitlistWidgetComponent {
  courses = [
    {
      name: 'Angular', date: '10/24/23', waitlistedCount: 8, courseRegistrationCount: 40,
    },
    {
      name: 'React', date: '10/24/23', waitlistedCount: 10, courseRegistrationCount: 55,
    },
    {
      name: 'Vue', date: '10/24/23', waitlistedCount: 5, courseRegistrationCount: 30,
    },
    {
      name: 'Ember', date: '10/24/23', waitlistedCount: 15, courseRegistrationCount: 60,
    },
    {
      name: 'Backbone', date: '10/24/23', waitlistedCount: 3, courseRegistrationCount: 25,
    },
    {
      name: 'Node', date: '10/24/23', waitlistedCount: 7, courseRegistrationCount: 45,
    },
    {
      name: 'Express', date: '10/24/23', waitlistedCount: 12, courseRegistrationCount: 70,
    },
    {
      name: 'MongoDB', date: '10/24/23', waitlistedCount: 20, courseRegistrationCount: 80,
    },
    {
      name: 'SQL', date: '10/24/23', waitlistedCount: 2, courseRegistrationCount: 15,
    },
    {
      name: 'Python', date: '10/24/23', waitlistedCount: 6, courseRegistrationCount: 35,
    },
    {
      name: 'Django', date: '10/24/23', waitlistedCount: 18, courseRegistrationCount: 65,
    },
    {
      name: 'Flask', date: '10/24/23', waitlistedCount: 4, courseRegistrationCount: 20,
    },
  ]
}
