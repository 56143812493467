import { Component } from '@angular/core';

@Component({
  selector: 'app-validation-widget',
  templateUrl: './validation-widget.component.html',
  styleUrls: [ './validation-widget.component.scss' ],
})
export class ValidationWidgetComponent {
  courses = [
    {
      name: 'Angular', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'React', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Vue', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Ember', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Backbone', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Node', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Express', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'MongoDB', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'SQL', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Python', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Django', date: '10/24/23', enrolled: 23,
    },
    {
      name: 'Flask', date: '10/24/23', enrolled: 23,
    },
  ]
}
