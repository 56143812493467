import {
  Component, Inject, OnInit, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TagsService } from 'src/app/services/tags.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-update-tag',
  templateUrl: './update-tag.component.html',
  styleUrls: [ './update-tag.component.scss' ],
})
export class UpdateTagComponent implements OnInit, OnDestroy {
  error: string;
  tagsSubscription: Subscription;
  newTag = {
    name: '', color: '#BE00DF', conversion: '', isVisibleFlag: false, track: '', description: '', id: null,
  }
  trackList = [
    'hours', 'credits', 'days', 'units', 'points',
  ];


  constructor(
    public dialogRef: MatDialogRef<UpdateTagComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public tagsService: TagsService,
    public toast: ToastService,
  ) { }

  ngOnInit(): void {
    if(this.data) {
      this.newTag = {
        name: this.data.tag,
        color: this.data.color,
        conversion: this.data.conversion,
        isVisibleFlag: this.data.isVisibleFlag,
        track: this.data.track,
        description: this.data.description,
        id: this.data.id,
      }
    }
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  saveChanges = () => {
    if(this.newTag.name === '') {
      this.error = 'Tag name is required';
      return;
    }
    this.tagsSubscription = (this.data ? this.tagsService.updateTag(this.newTag) : this.tagsService.addTag(this.newTag))
      .subscribe({
        next: (response: any) => {
          this.dialogRef.close(response);
          const message = `Tag: '${this.data ? this.data.tag : this.newTag.name}' Was Successfully ${this.data ? 'Updated' : 'Added'}`

          this.toast.setToast({
            text: message,
            type: 'success',
          })
        },
        error: (error: any) => {
          console.log('error from addTag: ', error);
          this.toast.setToast({
            text: `There was an error updating the tag: ${error?.error?.message || error?.message}`,
            type: 'error',
          })
        },
      })
  }

  setActivation = (setActive: boolean) => {
    this.tagsService.toggletagActivation(this.data, setActive).subscribe({
      next: (response: any) => {
        this.dialogRef.close(response);
        this.toast.setToast({
          text: `Tag: '${this.data.tag}' Was Successfully ${setActive ? 'Activated' : 'Deactivated'}`,
          type: 'success',
        })
      },
      error: (error: any) => {
        console.log('error', error)
      },
    })
  }

  ngOnDestroy(): void {
    if(this.tagsSubscription) {
      this.tagsSubscription.unsubscribe();
    }
  }

}
