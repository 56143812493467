<!-- #region Submit Forgot Password -->
<div class="form-header">
  <h1 *ngIf="(showEmailForm || showConfirmationCodeForm) && !changeMode">
    Forgot Password?
  </h1>
  <h1 *ngIf="(showEmailForm || showConfirmationCodeForm) && changeMode">
    Change Password
  </h1>
  <h1 *ngIf="showResetPasswordForm">New Password</h1>
  <p *ngIf="showEmailForm">Enter your email below to receive a code.</p>

  <div *ngIf="showConfirmationCodeForm">
    <p *ngIf="changeMode">
      Your password has expired and must be changed before you are able to log
      in.
    </p>
    <p>
      Enter the code you received at
      <strong> {{ hiddenEmail }}. </strong>
    </p>
  </div>
  <p *ngIf="showResetPasswordForm">
    Enter a new password. Your password must be at least 8 characters long and
    include upper and lowercase letters, numbers, and at least 1 special
    character.
  </p>
</div>

<form
  class="form"
  #authForm="ngForm"
  (ngSubmit)="submitForgotPasswordRequest(authForm)"
  *ngIf="showEmailForm"
>
  <label for="email">District Email*</label>
  <input
    id="email"
    type="email"
    placeholder="Enter your district email"
    class="text-input"
    name="email"
    autocomplete="email"
    autofocus
    required
    #email="ngModel"
    email
    ngModel
    [ngClass]="{
      inputError: email.invalid && email.errors && email.touched
    }"
  />

  <span
    class="klabel-small error"
    *ngIf="email.invalid && email.touched && email.errors"
    >Please check your entries</span
  >
  <span class="klabel-small error" *ngIf="!!error$">{{ error$ }}</span>
  <div class="btn-container">
    <button
      type="submit"
      class="submit-btn"
      [ngClass]="{ disabled: !authForm.valid }"
      [disabled]="!authForm.valid"
    >
      Submit
    </button>
  </div>
</form>
<!-- #endregion -->

<!-- #region confirmation code -->
<form
  *ngIf="showConfirmationCodeForm"
  class="form"
  #codeForm="ngForm"
  (ngSubmit)="submitConfirmationCodeRequest(codeForm)"
>
  <div class="code-container">
    <input
      *ngFor="let item of [].constructor(confirmationCodeLength); let i = index"
      [id]="'num-' + i"
      type="text"
      class="num-input"
      pattern="[0-9]*"
      maxlength="1"
      placeholder="0"
      class="code-input"
      [name]="'number-' + i"
      #num="ngModel"
      ngModel
      required
      (keyup)="onCodeKeyPress(codeForm, $event)"
      (paste)="onCodePaste(codeForm, $event)"
      [ngClass]="{
        inputError: num.invalid && num.errors && num.touched
      }"
    />
  </div>
  <div class="btn-container code">
    <button
      type="submit"
      class="submit-btn"
      [ngClass]="{ disabled: !codeForm.valid }"
      [disabled]="!codeForm.valid"
    >
      Submit Code
    </button>
  </div>
</form>
<!-- #endregion -->

<!-- #region Reset Password Submit -->
<app-password-change
  *ngIf="showResetPasswordForm"
  (passwordSubmitEvent)="setPassword($event)"
></app-password-change>
<!-- #endregion -->

<ng-container *ngIf="debugMode">
  <button (click)="toggleShow('showEmailForm')">Show First</button>
  <button (click)="toggleShow('showConfirmationCodeForm')">Show Code</button>
  <button (click)="toggleShow('showResetPasswordForm')">Show Submit</button>
</ng-container>
