<div class="calendar-widget">
  <full-calendar 
    #calendar 
    class="calendar" 
    [options]="calendarOptions" 
    [events]="calendarEvents"
    (dateClick)="handleDateClick($event)"
    ></full-calendar>

</div>
