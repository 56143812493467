import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component, Injectable, Input, Output, EventEmitter, OnInit, ViewEncapsulation,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';


export class ItemFullNode {
  name: string;
  Id: number;
  parentId: any;
  children: ItemFullNode[];
  isChecked: boolean;
  topMostParentName: string;
  nodeType: 'SINGLE' | 'GROUP_NODE' | 'GROUP_LEAF' | 'GROUP_PARENT';
}

export class ItemFlatNode {
  expandable: boolean;
  name: string;
  Id: number;
  parentId: any;
  level: number;
  isChecked: boolean;
  topMostParentName: string;
  nodeType: 'SINGLE' | 'GROUP_NODE' | 'GROUP_LEAF' | 'GROUP_PARENT';
}

@Component({
  selector: 'app-tree-checklist',
  templateUrl: 'tree-checklist.component.html',
  styleUrls: [ 'tree-checklist.component.scss' ],
  // encapsulation: ViewEncapsulation.None,
})

export class TreeChecklistComponent implements OnInit {
  @Input() label: string;
  @Input() error: string;
  @Input() color: string;
  @Input() dataset: any[];
  @Input() existingData: any[];
  @Input() showItems: boolean;
  @Output() shareCheckedList: EventEmitter<string[]> = new EventEmitter<string[]>();

  requirementsToList: any;
  showDropDown: boolean;
  checkedList: any[];
  checkedIds: any[];
  matches: any[];

  //Map from flat node to nested node. This helps us finding the nested node to be modified
  FlatNodeMap = new Map<ItemFlatNode, ItemFullNode>();

  //Map from nested node to flattened node. This helps us to keep the same object for selection
  nestedNodeMap = new Map<ItemFullNode, ItemFlatNode>();

  //A selected parent node to be inserted
  selectedParent: ItemFlatNode | null = null;

  //The selection for checklist
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemFullNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemFullNode, ItemFlatNode>;

  constructor() {
    this.checkedList = [];
    this.checkedIds = [];

    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);

    this.treeFlattener = new MatTreeFlattener(
      this._transformer, this.getLevel, this.isExpandable, this.getChildren,
    );

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  }

  ngOnInit() {
    this.dataSource.data = this?.dataset?.sort((a, b) => a.name.localeCompare(b.name));
    this.matches = [];
    this.treeControl.expandAll();
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemFullNode): ItemFullNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.name === '';

  // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
  private _transformer = (node: ItemFullNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.Id === node.Id
      ? existingNode
      : new ItemFlatNode();
    flatNode.Id = node.Id;
    flatNode.level = level;
    flatNode.name = node.name;
    flatNode.parentId = node.parentId;
    flatNode.topMostParentName = node.topMostParentName;
    flatNode.nodeType = node.nodeType;
    flatNode.isChecked = this.checkedChecker(node);
    flatNode.expandable = !!node.children && node.children.length > 0;
    this.FlatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }
  //Whether all the descendants of the node are selected
  descendantsAllSelected(node: ItemFlatNode): boolean {

    const descendants = this.treeControl.getDescendants(node);

    if (descendants.length === 0) {
      return this.checklistSelection.isSelected(node);
    }
    const allselected = descendants.every(child => this.checklistSelection.isSelected(child));
    if (allselected) {
      this.checklistSelection.select(node);
    } else {
      this.checklistSelection.deselect(node);
    }

    return allselected;

  }

  //Whether part of the descendants are selected
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  //Toggle the to-do item selection. Select/deselect all the descendants node
  ItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    const selectedValues = this.checklistSelection.selected

    const filteredSelectedValues = selectedValues.filter((ItemFlatNode) => {
      const isSingle = ItemFlatNode.nodeType === 'SINGLE';
      const isLeaf = ItemFlatNode.nodeType === 'GROUP_LEAF';
      if (isSingle || isLeaf) {
        return true;
      }

      const hasSelectedChildren = selectedValues.some((selectedItem) => selectedItem.parentId === ItemFlatNode.Id);

      return hasSelectedChildren;
    });



    //reset the list
    this.checkedList = [];
    this.checkedIds = [];

    //crete new list
    const resultName = new Set(filteredSelectedValues.flatMap((ItemFlatNode) => ItemFlatNode.topMostParentName));
    const resultId = selectedValues.flatMap((ItemFlatNode) => ItemFlatNode.Id);
    if (this.showItems) {
      this.checkedList.push(...resultName);
    }
    this.checkedIds.push(resultId);
  }

  //Clear all checkboxes
  uncheckAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
        this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
      }
    }
  }

  formatCheckedList() {
    return this.checkedList.sort().join(', ');
  }


  //Set checkboxes to match existing selections
  preSetCheckboxes() {
    this.matches = [];
    //clear the checks first
    this.uncheckAll();

    //Build array to match to
    for (let i = 0; i < this?.dataset.length; i++) {
      if (this?.dataset[i].children.length > 0) {
        const childData = this?.dataset[i].children;
        for (let t = 0; t < childData.length; t++) {
          if (childData[t].childCount > 0) {
            const subchildData = childData[t].children;
            for (let x = 0; x < subchildData.length; x++) {
              if (this.checkedChecker(subchildData[x])) {
                this.matches.push(subchildData[x]);
              }
            }
          }
          else {
            if (this.checkedChecker(childData[t])) {
              this.matches.push(childData[t]);
            }
          }
        }
      }
      else {
        if (this.checkedChecker(this.dataSource.data[i])) {
          this.matches.push(this.dataSource.data[i]);
        }
      }
    }

    //Find matches and set the checkbox
    for (let r = 0; r < this.matches.length; r++) {
      const rID = this.matches[r].Id;
      for (let t = 0; t < this.treeControl.dataNodes.length; t++) {
        if (this.treeControl.dataNodes[t].Id === rID) {
          this.ItemSelectionToggle(this.treeControl.dataNodes[t]);
        }
      }
    }

  }

  //Share the data and clear the selected lists
  shareCheckedlist(list: string[]) {
    if (list.length > 0) {
      this.shareCheckedList.emit(list);
      this.checkedIds = [];
      this.uncheckAll();
      this.showDropDown = false;
    }

  }

  onClick() {
    if (this.showDropDown) {
      this.showDropDown = false;
    }
    else {
      this.preSetCheckboxes();
      this.showDropDown = true;
    }
  }

  //TODO need to resolve incoming requirementId to make more generalized
  //Check the checkbox if previously selected
  checkedChecker = (item: any) => {
    return this?.existingData.filter((existingItem: any) => existingItem.requirementId === item.Id).length > 0;
  }
}
