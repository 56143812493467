<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon fontSet="material-icons-round">playlist_add_check</mat-icon>
      <span class="kbody-huge">Registration Successful</span>
    </div>
    <mat-icon class="close" fontSet="material-icons-round" (click)="closeModal()">close</mat-icon>
  </div>
  <div class="content">
    <span class="message">This course has been applied to your PD Plan.</span>
    <span class="detail">{{ data?.courseName}}</span>
    <span class="detail">{{data?.sessionStartDate ? data?.sessionStartDate : '' }}</span>
    <span class="detail">{{ data?.location }}</span>
  </div>
  <div class="buttons">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeModal()"
      ></app-button>
    <app-button
      [text]="'Ok'"
      [icon]="{ materialIcon: 'done' }"
      (click)="closeModal()"
      ></app-button>
  </div>
</div>