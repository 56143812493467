<div class="dialog">
  <div class="title-row">
    <div class="row">
      <mat-icon>category</mat-icon>
      <span class="ktitle-medium">Course type details</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>

  <div class="content">
    <div class="detail-row">
      <div class="detail">
        <span class="klabel-medium label">Name of Course Type</span>
        <span class="kbody-medium">{{data.itemData[0].content}}</span>
      </div>
      <div class="detail">
        <span class="klabel-medium label">Status</span>
        <span class="kbody-medium">Active</span>
      </div>
    </div>
    <div class="detail">
      <span class="klabel-medium label">Additional Info</span>
      <div class="row">
        <app-chip *ngIf="data.itemData[1].checked" [text]="'show on special requests'"></app-chip>
        <app-chip *ngIf="data.itemData[2].checked" [text]="'personal - NO PD credit'"></app-chip>
      </div>
    </div>
  </div>

  <div class="buttons">
    <app-button (click)="closeDialog()" [class]="'white'" [text]="'Cancel'"></app-button>
    <app-button (click)="showEditModal()" [icon]="{type: 'edit'}" [text]="'Edit Course Type'"></app-button>
  </div>

</div>