import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-staff',
  templateUrl: './manage-staff.component.html',
  styleUrls: [ './manage-staff.component.scss' ],
})
export class ManageStaffComponent {
  tabList = [ { name: 'Staff List' }, { name: 'Reassign Staff' } ]
  selectedTab = this.tabList[0].name;

  constructor() { }

  selectTab = (tab: any) => {
    this.selectedTab = tab;
  }
}
