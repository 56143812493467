import {
  Component, OnInit, Inject, Input, EventEmitter,
} from '@angular/core';
import {
  MatDialogRef, MAT_DIALOG_DATA, MatDialog,
} from '@angular/material/dialog';
import { VerificationComponent } from '../verification/verification.component';


@Component({
  selector: 'app-batch-reassign',
  templateUrl: './batch-reassign.component.html',
  styleUrls: [ './batch-reassign.component.scss' ],
})
export class BatchReassignComponent implements OnInit {
  moveObjectEmitter = new EventEmitter();

  // the two main screen lists
  needToMoveList: { name: string;  id: number }[];
  movingList: { name: string;  id: number }[] = [];

  // lists of selected checkboxes
  selectedNeedToMoveList: { name: string;  id: number }[] = [];
  selectedMovingList: { name: string;  id: number }[] = [];

  // potential reassign list to hydrate multi input
  potentialReassignList: {name: string, id: number}[] = [];
  selectedReassignTarget: { name: string;  id: number };

  // ui strings
  titleText: any;
  titleChoose: any;
  systemSettingName: any;

  constructor(
    public dialogRef: MatDialogRef<BatchReassignComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    const dialogData = this.data;
    if (dialogData) {
      this.systemSettingName = dialogData.systemSettingName;
      this.titleText = dialogData.titleText;
      this.titleChoose = dialogData.titleChoose;
      this.needToMoveList = dialogData.needToMoveList.sort((a: any, b: any) => a.name.localeCompare(b.name));
      this.potentialReassignList = dialogData.potentialReassignList;

      this.potentialReassignList = this.potentialReassignList.sort((a, b) => a.name.localeCompare(b.name));
    }

    // // this is some fakester data for some of the components still waiting for endpoints
    // if(this.needToMoveList === undefined) {
    //   this.needToMoveList = [
    //     { name: 'Angelo Martinez', id: 0 }, { name: 'Gabriel Evangelo', id: 0 }, { name: 'Shannon O’Bannon', id: 0 }, { name: 'Scott Thompson', id: 0 }, { name: 'Jane Lester', id: 0 },
    //   ]
    // }
  }

  handleNeedToMoveCheckboxChange = (event: any, item: any) => {
    if (event.target.checked) {
      this.selectedNeedToMoveList.push(item);
    } else {
      this.selectedNeedToMoveList = this.selectedNeedToMoveList.filter((selectedItem: any) => selectedItem.name !== item.name);
    }
  }

  handleMovingCheckboxChange = (event: any, item: any) => {
    console.log('here')
    if (event.target.checked) {
      this.selectedMovingList.push(item);
    } else {
      this.selectedMovingList = this.selectedMovingList.filter((selectedItem: any) => selectedItem.name !== item.name);
    }
  }

  needIsChecked = (item: any) => {
    return this.selectedNeedToMoveList.filter(element => element.name === item.name).length > 0 ? true : false;
  }

  movingIsChecked = (item: any) => {
    return this.selectedMovingList.filter(element => element === item).length > 0 ? true : false;
  }

  transferSelectedToMovingList = () => {
    this.movingList = [ ...this.movingList, ...this.selectedNeedToMoveList ];
    for (let i = 0; i < this.selectedNeedToMoveList.length; i++) {
      this.needToMoveList = this.needToMoveList.filter((element: any) => element.name !== this.selectedNeedToMoveList[i].name);
    }
    this.selectedNeedToMoveList = [];
    this.sortStaffLists();
  }

  transferSelectedToNeedToMoveList = () => {
    this.needToMoveList = [ ...this.needToMoveList, ...this.selectedMovingList ];

    const selectedIds = this.selectedMovingList.map((element: { name: string; id: number }) => element.id);

    this.movingList = this.movingList.filter((element: { name: string; id: number }) => {
      return selectedIds.indexOf(element.id) === -1;
    });

    this.selectedMovingList = [];
    this.sortStaffLists();
  }

  selectAllNeedToMove = (event: any) => {
    if (event.target.checked) {
      this.selectedNeedToMoveList = [ ...this.needToMoveList ];
    } else {
      this.selectedNeedToMoveList = [];
    }
  }

  selectAllMoving = (event: any) => {
    if (event.target.checked) {
      this.selectedMovingList = [ ...this.movingList ];
    } else {
      this.selectedMovingList = [];
    }
  }

  sortStaffLists() {
    this.needToMoveList = this.needToMoveList.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.movingList = this.movingList.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  submitReassign = () => {
    const targetData = this.potentialReassignList.find((element: any) => element.name === this.selectedReassignTarget);
    const moveObject = {
      selectedTarget: targetData,
      movingList: this.movingList,
    }
    this.moveObjectEmitter.emit(moveObject);
    this.movingList = [];
    if(this.needToMoveList.length === 0) {
      this.dialogRef.close('success');
    }
  }
}
