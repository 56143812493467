import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { PositionsService } from 'src/app/services/positions.service';
import { EntityService } from 'src/app/services/entity.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { BatchReassignComponent } from 'src/app/components/batch-reassign/batch-reassign.component';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-update-position',
  templateUrl: './update-position.component.html',
  styleUrls: [ './update-position.component.scss' ],
})
export class UpdatePositionComponent implements OnInit, OnDestroy {
  position: any = {}
  positionList: any;
  error = '';
  user: any;
  addPositionSubscription: Subscription;
  authSubscription: Subscription;
  positionSubscription: Subscription;
  entitySubscription: Subscription;
  subscribeDialog: Subscription;
  allMoved = false;

  constructor(
    private authService: AuthService,
    private positionsService: PositionsService,
    private entityService: EntityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdatePositionComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.authSubscription = this.authService.user$.subscribe((user: any) => this.user = user)

    // if we are deactivating a position, we need to get the list of positions to reassign to if there are assigned users
    if(this.data) {
      this.entityService.getEntity('Positions')
      this.entitySubscription = this.entityService.entityData$.subscribe((entity: any) => {
        if (!entity) return;
        this.positionList = entity.Positions
      })
    }
    data ? this.position = data : {}
  }

  ngOnInit(): void {
    // if we are updating a position, we reach out for it's details
    if(this.data) {
      this.positionSubscription = this.positionsService.getPositionDetails(this.position.id, 'Users, UserCount').subscribe({
        next: (response: any) => {
          // set local position details to know the user count for deactivation
          this.position = response.data;
        },
        error: (error) => {
          console.log('error', error);
        },
      })
    }
  }

  savePosition = () => {
    if (!this.position.positionTitle) {
      this.error = 'Please enter a position name';
    }
    else {
      this.addPositionSubscription = (this.data ? this.positionsService.updatePosition(this.position) : this.positionsService.addPosition(this.position))
        .subscribe({
          next: (response: any) => {
            this.dialogRef.close(response);
            this.toastService.setToast({ text: `Position ${this.data ? 'Updated' : 'Created'}`, type: 'success' })
          },
          error: (error: any) => {
            console.log('error', error)
            this.dialog.open(VerificationComponent, {
              data: {
                type: 'alert', title: `Oops, something went wrong creating ${this.position.positionTitle}`, text: error.message,
              },
            })
          },
        })
    }
  }

  setActivation = (setActive: boolean) => {
    // if there are still assigned users, then the user must reassign them before deactivating
    if(this.position.Users.length > 0) {
      // get the active users from the user list to display in the modal warning
      const activeUserList = this.position.Users.filter((user: any) => user.activeFlag === 1);
      // format for the reassign modal
      const formattedUserList = activeUserList.map((user: any) => {
        return {
          name: user.fullName,
          id: user.id,
        }
      })
      // format reassign list for the reassign modal and exclude the current position
      const filteredPotentialReassignList = this.positionList.filter((position: any) => position.id !== this.data.id && position.activeFlag == 1);
      const formattedPotentialReassignList = filteredPotentialReassignList.map((position: any) => {
        return {
          name: position.positionTitle,
          id: position.id,
        }
      })
      // fire a warning modal
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Reassign staff',
          confirmButtonTitle: 'Reassign Staff',
          cancelButtonTitle: 'Cancel Deactivation',
          text: `There are ${this.position.UserCount.activeUsers} staff members assigned to this position. You must assign all active users to a new position before deactivating.`,
        },
      })
      // if the user confirms, then open the reassign modal
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          const dialogData = {
            systemSettingName: 'position',
            titleText: 'Reassign staff to new position',
            titleChoose: 'Choose a new position to reassign staff to*',
            needToMoveList: formattedUserList,
            potentialReassignList: formattedPotentialReassignList,
          };
          const batchDialogRef = this.dialog.open(BatchReassignComponent, { data: dialogData });
          // if the batch dialog is closed after all users have been reassigned, we can deactivate the position, so we toggle a local boolean
          batchDialogRef.afterClosed().subscribe(result => {
            if(result === 'success') {
              this.allMoved = true
            }
          })
          // this is the way that we listen to the dialog's emitter for any batch reassignments
          this.subscribeDialog = batchDialogRef.componentInstance.moveObjectEmitter.subscribe((data: any) => {
            const formattedStaffList = data.movingList.map((user: any) => parseFloat(user.id));
            // in the case of a fired batch reassign, we make the call through our service
            this.positionsService.batchStaffReassign(
              formattedStaffList, this.position.id, data.selectedTarget.id,
            ).subscribe({
              next: (response: any) => {
                this.toastService.setToast({ text: 'Staff reassigned', type: 'success' })
                // if all staff have been moved, then we can deactivate the position
                if (this.allMoved === true) {
                  this.positionsService.togglePositionActivation(this.position, setActive).subscribe({
                    next: (response: any) => {
                      this.dialogRef.close(response);
                      this.toastService.setToast({ text: 'Position Deactivated', type: 'success' })
                    },
                    error: (error: any) => {
                      console.log('error', error)
                    },
                  })
                }
              },
              error: (error) => {
                console.log('error', error);
              },
            })
          })
        }
      })
    } else {
      // if there are no assigned users, then we can just deactivate the position
      this.dialogRef.close('reload')
      this.positionsService.togglePositionActivation(this.position, setActive).subscribe({
        next: (response: any) => {
          this.dialogRef.close(response);
          this.toastService.setToast({ text: `Position ${setActive ? 'Activated' : 'Deactivated'}`, type: 'success' })
        },
        error: (error: any) => {
          console.log('error', error)
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert', title: `Oops, something went wrong creating ${this.position.positionTitle}`, text: error.message,
            },
          })
        },
      })
    }
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  updatePositionParameters = (event: any, parameter: string) => {
    this.position[parameter] = event.target.value;
  }

  openBatchReassignModal(): void {
    const dialogData = {
      systemSettingName: 'position',
      titleText: 'Reassign staff to new position',
      titleChoose: 'Choose a new position to reassign staff to*',
      data: this.data,     // Pass necessary data here if needed
    };
    this.dialog.open(BatchReassignComponent, { data: dialogData });
  }

  ngOnDestroy() {
    this.addPositionSubscription?.unsubscribe();
    this.positionSubscription?.unsubscribe();
    this.authSubscription?.unsubscribe();
  }

}
