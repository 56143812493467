import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reassign-staff',
  templateUrl: './reassign-staff.component.html',
  styleUrls: [ './reassign-staff.component.scss' ],
})
export class ReassignStaffComponent implements OnInit {
  reassignTypeOptions = [
    { text: 'Move users to a new building' }, { text: 'Assign a new administrator to users' }, { text: 'Assign a new supervisor to users' }, { text: 'Assign new supervisor to users from a particular building' }, { text: 'Assign new requirement to users' }, { text: 'Replace a requirement with a new requirement for selected users' }, { text: 'Assign new requirement to users from a particular school' }, { text: 'Assign new requirement to users from a particular position' },
  ]

  // the two main screen lists
  needToMoveList: any[];
  movingList: any[] = [];

  // lists of selected checkboxes
  selectedNeedToMoveList: any[] = [];
  selectedMovingList: any[] = [];

  // potential reassign list to hydrate multi input
  potentialReassignList: any[] = [];
  selectedReassignTarget: any;

  ngOnInit(): void {
    if(this.needToMoveList === undefined) {
      this.needToMoveList = [
        { name: 'Angelo Martinez' }, { name: 'Gabriel Evangelo' }, { name: 'Shannon O’Bannon' }, { name: 'Scott Thompson' }, { name: 'Jane Lester' },
      ]
    }
  }

  handleNeedToMoveCheckboxChange = (event: any, item: any) => {
    if (event.target.checked) {
      this.selectedNeedToMoveList.push(item);
    } else {
      this.selectedNeedToMoveList = this.selectedNeedToMoveList.filter((selectedItem: any) => selectedItem.name !== item.name);
    }
  }

  handleMovingCheckboxChange = (event: any, item: any) => {
    if (event.target.checked) {
      this.selectedMovingList.push(item);
    } else {
      this.selectedMovingList = this.selectedMovingList.filter((selectedItem: any) => selectedItem.name !== item.name);
    }
  }

  needIsChecked = (item: any) => {
    return this.selectedNeedToMoveList.filter(element => element.name === item.name).length > 0 ? true : false;
  }

  movingIsChecked = (item: any) => {
    return this.selectedMovingList.filter(element => element === item).length > 0 ? true : false;
  }

  transferSelectedToMovingList = () => {
    this.movingList = [ ...this.movingList, ...this.selectedNeedToMoveList ];
    for (let i = 0; i < this.selectedNeedToMoveList.length; i++) {
      this.needToMoveList = this.needToMoveList.filter((element: any) => element.name !== this.selectedNeedToMoveList[i].name);
    }
    this.selectedNeedToMoveList = [];
  }

  transferSelectedToNeedToMoveList = () => {
    this.needToMoveList = [ ...this.needToMoveList, ...this.selectedMovingList ];
    for (let i = 0; i < this.selectedMovingList.length; i++) {
      this.movingList = this.movingList.filter((element: any) => element.name !== this.selectedMovingList[i].name);
    }
    this.selectedMovingList = [];
  }

  selectAllNeedToMove = (event: any) => {
    if (event.target.checked) {
      this.selectedNeedToMoveList = [ ...this.needToMoveList ];
    } else {
      this.selectedNeedToMoveList = [];
    }
  }

  selectAllMoving = (event: any) => {
    if (event.target.checked) {
      this.selectedMovingList = [ ...this.movingList ];
    } else {
      this.selectedMovingList = [];
    }
  }

  submitReassign = () => {
    const targetData = this.potentialReassignList.find((element: any) => element.name === this.selectedReassignTarget);
    const moveObject = {
      selectedTarget: targetData,
      movingList: this.movingList,
    }
    console.log('move me', moveObject);
  }

}
