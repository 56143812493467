import { Component } from '@angular/core';

@Component({
  selector: 'app-bulletin-widget',
  templateUrl: './bulletin-widget.component.html',
  styleUrls: [ './bulletin-widget.component.scss' ],
})
export class BulletinWidgetComponent {
  bulletin = {
    datePosted: '8/30/23',
    author: 'Nancy Martin',
    title: 'New Course: Introduction to Python',
    content: 'This is the most recent bulletin post. The bulletin board is going to behave basically like a blog, and whenever there is a new post, the new post will exist here as well as on the bulletin page. The full content of the post will show when a user clicks on the ‘read post’ button. Until that happens, they will only see this excerpt, which is just the post content with a limited character count and overflowing text...',
  };

}
