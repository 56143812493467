interface FormattedCourse {
  isPdPlanRequest: true;
  name: string;
  id: number;
  courseId: number;
  courseItemId: number;
  selectedRequirementId: number;
  userId: number;
  time: string;
  sessionStartDate: Date;
  sessionEndDate: Date;

  dateOffered: string;
  location: string;
  room: string;
  parentRequirement: string;
  requirement: string;
  hours: string;
  statusDisplay: string;
  statusKid: string;
  validationKid: string;
  validationDisplay: string;
}

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString();
}

const formatTime = (time: string) => {
  return new Date(time).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).toLowerCase();
};


const formatCourse = (course: any) => {
  const copy = { ...course };

  const courseDisplay: FormattedCourse = {
    isPdPlanRequest: true,
    id: copy?.id,
    name: copy?.courseName,
    courseId: copy?.courseId,
    courseItemId: copy?.courseItemId,
    userId: copy?.userId,

    // style: copy?.CourseItem.preRecordedOption,

    dateOffered: formatDate(copy?.dateOffered),
    time: `${formatTime(copy?.sessionStartDate)} - ${formatTime(copy?.sessionEndDate)}`,
    sessionStartDate: copy?.sessionStartDate,
    sessionEndDate: copy?.sessionEndDate,

    location: copy?.location,
    room: copy?.roomNumber || '-',

    selectedRequirementId: Number(copy?.requirementId),
    parentRequirement: copy.topMostParentRequirementName,
    requirement: copy?.requirement,

    hours: copy?.adjustedValue || copy?.Course?.trackingValue,

    statusKid: copy?.currentStatusKid,
    statusDisplay: copy.userCourseStatus,
    validationKid: copy?.validationKid,
    validationDisplay: copy?.validation,
  }

  const type = copy?.preRecordedOption;
  const isAsync = type === 'ASYNC';
  const isAnytime = type === 'ANYTIME';
  const isVirtual = copy?.virtualFlag == 1;


  if (isAsync) {
    courseDisplay.time = 'ASYNC';
    courseDisplay.dateOffered = 'ASYNC';
  }
  if (isAnytime) {
    courseDisplay.time = 'ANYTIME';
  }

  if (isVirtual) {
    courseDisplay.location = 'Virtual';
  }

  if (!courseDisplay.requirement) {
    courseDisplay.requirement = 'Portfolio/Unassigned';
  }

  return courseDisplay;
}


export { formatCourse, FormattedCourse }
