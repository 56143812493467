import {
  Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, HostListener,
} from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ],
})
export class TableComponent implements OnInit, OnChanges {
  @Output() addNewItem = new EventEmitter();
  @Output() sortColumn = new EventEmitter<object>();
  @Output() filterColumn = new EventEmitter<object>();
  @Output() toggleActiveList = new EventEmitter<boolean>();
  @Output() showItemDetails = new EventEmitter<object>();
  @Output() showEditModal = new EventEmitter<object>();
  @Output() showStaffEditModal = new EventEmitter<object>();
  @Output() paginationEvent = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<object>();
  @Output() approveItem = new EventEmitter<object>();
  @Output() denyItem = new EventEmitter<object>();
  @Output() itemsPerPageChange = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() rowClicked = new EventEmitter<any>();
  @Input() isLoading = false;
  @Input() tableData: any;
  @Input() hideCreateNewButton: any = false;
  @Input() hideEditButton: any = false;
  @Input() hideViewInactiveButton: any = false;
  @Input() hideSearchBar: any = false;
  @Input() hideShowDetailsButton: any = false;
  @Input() showRemoveItemButton: any = false;
  @Input() hideFilter = false;
  @Input() hideTitle = false;
  @Input() hideIcon = false;
  @Input() searchText = '';
  @Input() showApprovalButton = false;
  @Input() showDenialButton = false;

  pager: any = {};
  selectedItemsPerPage = 25;
  isDropdownOpen = false;

  meatballMenu: any = '';
  prevLink = true;
  nextLink = true;
  links: any;
  selected: string;
  showActiveItems = true;
  sortOrder = {
    title: '', direction: 'DESC', showActiveItems: true,
  };

  constructor() { }

  // on clicking anywhere on the page, set meatballMenu to ''
  @HostListener('document:click', [ '$event' ])
  clickout(event: any) {
    if (event.target.id !== 'meatball-container1' && event.target.id !== 'meatball1' && event.target.id !== 'meatball-container2' && event.target.id !== 'meatball2') {
      this.meatballMenu = '';
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectItemsPerPage(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.isDropdownOpen = false;
    this.pager.pageSize = itemsPerPage;
    this.setPage(this.pager.currentPage);
    this.itemsPerPageChange.emit(itemsPerPage);
  }

  tab(page: number) {
    this.pageChanged.emit(page);
    this.setPage(page);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData'] && this.tableData) {
      this.setPage(this.pager.currentPage);
    }
  }

  ngOnInit() {
    if (this.tableData) {
      this.setPage(this.pager.currentPage);
    }
  }


  setPage(page: number, command?: string) {
    const targetPage = page;
    const totalItems = (this.showActiveItems ? this.tableData.activeTotal : this.tableData.inactiveTotal) || 0;

    this.pager = this.getPager(
      totalItems, targetPage, this.selectedItemsPerPage,
    );

    if (command && [
      'first', 'prev', 'next', 'last',
    ].includes(command)) {
      this.pageChanged.emit(this.pager.currentPage);

    } else if (command) {
      console.error('Invalid page command:', command);
      return;
    } else {
      const newPage = this.getPager(totalItems, targetPage).currentPage;
      if (newPage !== this.pager.currentPage) {
        this.pager = this.getPager(totalItems, targetPage);
        // this.pageChanged.emit(page);
      }
    }
  }


  getPager(
    totalItems: number, currentPage = this.tableData.curPage, pageSize = this.selectedItemsPerPage,
  ): any {
    const curPage = this.tableData.curPage || 1;
    const totalPages = this.tableData.totalPages || 1;
    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (curPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (curPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = curPage - 5;
        endPage = curPage + 4;
      }
    }

    const length = (endPage + 1) - startPage;
    const pages = length > 0 ? Array.from(Array(length).keys()).map(i => startPage + i) : [];

    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      pages,
    };
  }

  addNew = () => {
    this.addNewItem.emit();
  }

  sortColumnEvent = ({ title, sortBy }: { title: string, sortBy?: string }) => {
    this.sortColumn.emit({
      title: title, sortBy, direction: this.sortOrder.direction, showActiveItems: this.showActiveItems,
    });
    this.sortOrder = {
      title,
      direction: this.sortOrder.direction === 'ASC' ? 'DESC' : 'ASC',
      showActiveItems: this.showActiveItems,
    };
  }

  searchColumn = (event: any) => {
    this.searchText = event.target.value;
    const searchItem = { 'searchText': this.searchText, 'showActiveItems': this.showActiveItems };
    this.filterColumn.emit(searchItem);
  }

  toggleActive = () => {
    this.toggleActiveList.emit(this.showActiveItems);
    this.showActiveItems = !this.showActiveItems;
  }

  showDetails = (item: any, event: Event) => {
    event.stopPropagation();
    this.meatballMenu = '';
    this.showItemDetails.emit(item.itemId);
  }

  toggleEditModal = (item: any, event: Event) => {
    event.stopPropagation();

    this.meatballMenu = '';
    this.showEditModal.emit(item.itemId);
  }

  toggleStaffEditModal = (item: any, event: Event) => {
    event.stopPropagation();
    this.showStaffEditModal.emit(item.itemId);
  }

  toggleRemoveItem = (item: any, event: Event) => {
    event.stopPropagation();
    this.meatballMenu = '';
    this.removeItem.emit(item);
  }

  toggleApproveItem = (item: any) => {
    this.meatballMenu = '';
    this.approveItem.emit(item);
  }

  toggleDenyItem = (item: any) => {
    this.meatballMenu = '';
    this.denyItem.emit(item);
  }

  paginationClick = (pageDirection: string) => {
    this.paginationEvent.emit(pageDirection);
    this.links = this?.tableData?.links;
  }

  setMeatball = (meatballMenu: any, event: Event) => {
    event.stopPropagation();
    if (this.meatballMenu === meatballMenu) {
      this.meatballMenu = '';
      return;
    }
    this.meatballMenu = meatballMenu;
  }

  checkIfShowEdit(item: any): boolean {
    const showEdit = item.showEdit;

    if (showEdit === false) {
      return false;
    }

    return true;
  }

  handleRowClick = (item: any) => {
    this.rowClicked.emit(item);
    this.showItemDetails.emit(item.itemId);
  }

}

