import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SwapComponent } from 'src/app/components/swap/swap.component';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { RequirementsService } from 'src/app/services/requirements.service';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-update-requirement',
  templateUrl: './update-requirement.component.html',
  styleUrls: [ './update-requirement.component.scss' ],
})
export class UpdateRequirementComponent implements OnInit, OnDestroy {
  isLoading = false;
  createrequirement: any = {};
  newRequirement: any = {
    nodeType: '',
    requirement: '',
    type: '',
    requirementId: '',
    activeFlag: true,
    Requirement: [
      {
        id: null,
        level: 0,
        description: '',
        requirement: '',
        trackingValue: 0,
        trackingUom: 'Hours',
        repFlag: false,
        counselorFlag: false, //requirementType
        allUsersFlag: false,
        rollingFlag: false,
        rollingYears: 1,
        requirementType: false,
        reportToStateFlag: false,
        parentRequirementName: '',
      },
    ],
  }

  counts: { curYrUsers: number; curYrCourses: number; };


  error: any;
  viewSettings = true;
  addRequirementSubscription: Subscription;
  requirementSubscription: Subscription;
  activationSubscription: Subscription;

  meatballMenu: any;
  meatballMenuChild: any;
  meatballMenuLeaf: any;
  ChildData: any;
  ChildArray: any = [];
  newChildArray: any = [];
  SubChildArray: any = [];

  constructor(
    private requirementsService: RequirementsService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<UpdateRequirementComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  ngOnInit(): void {
    if (this.data) {
      this.isLoading = true;
      // in edit mode set local requirement details to know the user count for deactivation
      const requirementId = this.data.itemToEdit.requirementId || this.data.itemToEdit.itemId;
      this.requirementSubscription = this.requirementsService.getRequirementDetails(requirementId, 'AllChildrenDetails, Counts').subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.newRequirement = response.data;
          this.newRequirement.Requirement.id = Number(this.newRequirement.requirementId);
          this.counts = response.data.Counts;



          if (this.newRequirement?.Children) {
            for (const child of this.newRequirement.Children) {
              this.ChildArray.push(child);
              this.ChildData = this.ChildArray;
            }
          }
        },
        error: (error) => {
          console.log('error', error);
        },
      })
    }
    else {
      this.ChildArray = [
        {
          'requirement': '', 'nodeType': '', 'Requirement': [ { 'trackingValue': 0 } ], 'Children': [],
        },
      ];
      this.ChildData = this.ChildArray;
    }

  }

  toggleViewSettings = () => {
    this.viewSettings = !this.viewSettings;
  }

  toggleActivation = () => {
    const setActive = !this.newRequirement.activeFlag;

    // if you have staff or courses for the year, you need to reassign
    if ((this.newRequirement.Counts.curYrCourses) > 0 || this.newRequirement.Counts.curYrStaff > 0) {
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert', title: 'Replace requirement', confirmButtonTitle: 'Replace Requirement', cancelButtonTitle: 'Cancel', text: 'This requirement is assigned to courses in the current year catelog. You must choose a new requirement to replace it.',
        },
      })
      const formattedOptions = this.data.entityWithRequirements?.Requirements?.map((requirement: any) => {
        return { value: requirement.id, label: requirement.requirement }
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this will be some real data in the future
          this.dialog.open(SwapComponent, {
            data: {
              title: 'Replace requirement',
              label: 'Select a new requirement',
              optionPlaceHolder: 'Select a new requirement',
              options: formattedOptions,
            },
          })
        }
      })
    }
    else {
      this.activationSubscription = this.requirementsService.toggleRequirementActivation(this.newRequirement, setActive).subscribe({
        next: (response: any) => {
          this.toastService.setToast({ text: `Requirement ${setActive ? 'Activated' : 'Deactivated'}`, type: 'success' })
          setActive ? this.requirementsService.getInactiveRequirements() : this.requirementsService.getRequirements();
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          console.log('error', error)
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert', title: `Oops, something went wrong ${setActive ? 'activating' : 'deactivating'} ${this.newRequirement.requirement}`, text: error.error.message,
            },
          })
        },
      })
    }

  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  saveChanges = () => {
    if (this.newRequirement.Requirement.requirement === '') {
      this.error = 'Requirement Name is required';
      return;
    }
    if (this.newRequirement.nodeType === '') {
      this.error = 'Type is required';
      return;
    }
    if (this.newRequirement.Requirement.trackingValue === '' || !this.newRequirement.Requirement.trackingValue) {
      this.error = 'Hours Needed are required';
      return;
    }
    if (this.newRequirement.nodeType === 'GROUP_PARENT') {
      this.ChildArray = this.ChildData;
      if (this.ChildArray) {
        for (const child of this.ChildArray) {
          if (child.Requirement.requirement === '') {
            this.error = 'Sub-requirement name is required';
            return;
          }
          if (child.Requirement.trackingValue === '' || !child.Requirement.trackingValue) {
            this.error = 'Minimum Hours are required';
            return;
          }

          let tot = this.newRequirement.Requirement.trackingValue;
          if (!tot) { tot = 0; }
          let sum = 0;
          for (let i = 0; i < this.ChildArray.length; i++) {
            const num = Number(this.ChildArray[i].Requirement.trackingValue);
            sum += num;
          }
          if (sum > tot) {
            this.error = 'Hours of all the Sub elements cannot exceed the Hours for the Requirement';
            return;
          }

          this.SubChildArray = child.Children;
          if (this.SubChildArray) {
            for (const sub of this.SubChildArray) {
              if (sub.Requirement.requirement === '') {
                this.error = 'Child-requirement name is required';
                return;
              }
              if (sub.Requirement.trackingValue === '' || !sub.Requirement.trackingValue) {
                this.error = 'Child Minimum Hours are required';
                return;
              }

            }

            let tot = child.Requirement.trackingValue;
            if (!tot) { tot = 0; }
            let sum = 0;
            for (let i = 0; i < this.SubChildArray.length; i++) {
              const num = Number(this.SubChildArray[i].Requirement.trackingValue);
              sum += num;
            }

            if (sum > tot) {
              this.error = 'Hours of all the Child elements cannot exceed the Hours for the Sub Requirement';
              return;
            }

          }

        }

      }
    }
    const saveReq = () => {
      console.log('counselor flag', this.newRequirement.Requirement.requirementType)
      if (this.newRequirement.nodeType === 'SINGLE') {
        const requirementToAdd = {
          'type': 'SINGLE',
          'requirements': [
            {
              'id': this.newRequirement.Requirement.id,
              'level': 0,
              'header': 'Header test',
              'requirement': this.newRequirement.Requirement.requirement,
              'trackingUom': 'Hours',
              'trackingValue': this.newRequirement.Requirement.trackingValue,
              'requirementType': this.newRequirement.Requirement.requirementType ? 'Counselor' : null,
              'rollingFlag': this.newRequirement.Requirement.rollingFlag,
              'rollingYears': this.newRequirement.Requirement.rollingYears,
              'reportToStateFlag': this.newRequirement.Requirement.reportToStateFlag,
              'allUsersFlag': this.newRequirement.Requirement.allUsersFlag,
              'allCoursesFlag': this.newRequirement.Requirement.allCoursesFlag,
              'description': this.newRequirement.Requirement.description,
            },
          ],
        }
        this.createrequirement = requirementToAdd;
        this.addRequirementSubscription = (this.data ? this.requirementsService.updateRequirement(this.createrequirement) : this.requirementsService.addRequirement(this.createrequirement))
          .subscribe({
            next: (response: any) => {
              this.toastService.setToast({ text: this.data ? 'Changes Saved' : 'Requirement successfully added', type: 'success' })
              this.dialogRef.close(response);
            },
            error: (error: any) => {
              console.log('error', error)
              this.dialog.open(VerificationComponent, {
                data: {
                  type: 'alert', title: `Oops, something went wrong creating ${this.newRequirement.Requirement.requirement}`, text: error.error.message,
                },
              })
            },
          })
      }
      else {
        let requirementsArray = [];
        requirementsArray = [
          {
            'id': this.newRequirement.Requirement.id,
            'level': 0,
            'header': 'Header test',
            'requirement': this.newRequirement.Requirement.requirement,
            'trackingUom': 'Hours',
            'trackingValue': this.newRequirement.Requirement.trackingValue,
            'requirementType': this.newRequirement.Requirement.requirementType ? 'Counselor' : null,
            'rollingFlag': this.newRequirement.Requirement.rollingFlag,
            'rollingYears': this.newRequirement.Requirement.rollingYears,
            'reportToStateFlag': this.newRequirement.Requirement.reportToStateFlag,
            'allUsersFlag': this.newRequirement.Requirement.allUsersFlag,
            'allCoursesFlag': this.newRequirement.Requirement.allCoursesFlag,
            'description': this.newRequirement.Requirement.description,
          },
        ];
        this.newChildArray = this.ChildData;
        for (const child of this.newChildArray) {
          const subrequirement = child.Requirement.requirement;
          const parentRequirement = this.newRequirement.Requirement.requirement;
          const subtrackingValue = Number(child.Requirement.trackingValue);
          this.SubChildArray = child.Children;
          const newsubRow = {
            'id': child.id, 'level': 1, 'header': 'Header test', 'requirement': subrequirement, 'trackingUom': 'Hours', 'trackingValue': subtrackingValue, 'requirementType': null, 'rollingFlag': this.newRequirement.rollingFlag, 'rollingYears': this.newRequirement.rollingYears, 'reportToStateFlag': this.newRequirement.reportToStateFlag, 'allUsersFlag': this.newRequirement.allUsersFlag, 'allCoursesFlag': this.newRequirement.allCoursesFlag, 'parentRequirementName': parentRequirement,
          };
          requirementsArray = [ ...requirementsArray, newsubRow ];
          if (this.SubChildArray) {
            for (const sub of this.SubChildArray) {
              const leafrequirement = sub.Requirement.requirement;
              const parentRequirement = this.newRequirement.Requirement.requirement;
              const leaftrackingValue = Number(sub.Requirement.trackingValue);
              const newleafRow = {
                'id': sub.id, 'level': 2, 'header': 'Header test', 'requirement': leafrequirement, 'trackingUom': 'Hours', 'trackingValue': leaftrackingValue, 'requirementType': null, 'rollingFlag': this.newRequirement.rollingFlag, 'rollingYears': this.newRequirement.rollingYears, 'reportToStateFlag': this.newRequirement.reportToStateFlag, 'allUsersFlag': this.newRequirement.allUsersFlag, 'allCoursesFlag': this.newRequirement.allCoursesFlag, 'parentRequirementName': subrequirement,
              };
              requirementsArray = [ ...requirementsArray, newleafRow ];
            }
          }
        }
        const groupToAdd = { 'type': 'GROUP', 'requirements': requirementsArray }
        this.createrequirement = groupToAdd;

        this.addRequirementSubscription = (this.data ? this.requirementsService.updateRequirement(this.createrequirement) : this.requirementsService.addRequirement(this.createrequirement))
          .subscribe({
            next: (response: any) => {
              this.toastService.setToast({ text: this.data ? 'Changes Saved' : 'Requirement successfully added', type: 'success' })
              this.dialogRef.close(response);
            },
            error: (error: any) => {
              console.log('error', error)
              this.dialog.open(VerificationComponent, {
                data: {
                  type: 'alert', title: `Oops, something went wrong creating ${this.newRequirement.requirement}`, text: error.error.message,
                },
              })
            },
          })
      }

    }
    if (!this.data) {
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Are you sure?',
          text: 'The following details cannot be changed when this requirement is saved.',
          details: [ { title: 'Name of Requirement:', value: this.newRequirement.Requirement.requirement }, { title: ' Node Type: ', value: this.newRequirement.nodeType } ],
          boldText: 'Is the above information accurate?',
          confirmButtonTitle: 'Yes, I\'m Sure',
          cancelButtonTitle: 'Go Back',
        },
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          saveReq();
        }
      })
    }
    else {
      saveReq()
    }
  }

  ngOnDestroy(): void {
    this.addRequirementSubscription?.unsubscribe();
    this.requirementSubscription?.unsubscribe();
    this.activationSubscription?.unsubscribe();
  }

  MSelected(value: any) {
    this.newRequirement.nodeType = value;
  }

  setMeatball = (meatballMenu: any) => {
    if (this.meatballMenu === meatballMenu) {
      this.meatballMenu = '';
      return;
    }
    this.meatballMenu = meatballMenu;
  }

  //Insert a New Child record
  AddChildSelected() {
    this.meatballMenu = '';
    this.ChildArray = this.ChildData;
    const row = this.ChildArray.length;
    const newRow = {
      'requirement': '', 'nodeType': '', 'Requirement': [ { 'trackingValue': 0 } ], 'Children': [],
    };
    this.ChildArray = [ ...this.ChildArray, newRow ];
    this.ChildData = this.ChildArray;
  }

  //Remove Child Record
  RemoveChildSelected(rowid: any) {
    this.meatballMenu = '';
    this.ChildArray = this.ChildData;
    this.ChildArray.splice(rowid, 1);
    this.ChildData = this.ChildArray;
  }

  ctrackValueOnChange() {
    this.ChildArray = this.ChildData;
    let tot = this.newRequirement.Requirement.trackingValue;
    if (!tot) { tot = 0; }
    let sum = 0;
    for (let i = 0; i < this.ChildArray.length; i++) {
      const num = Number(this.ChildArray[i].Requirement.trackingValue);
      sum += num;
    }
    if (sum > tot) {
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Totals',
          text: 'The total hours of all the Sub elements cannot exceed the Hours Needed for the Requirement',
          cancelButtonTitle: 'Go Back',
        },
      })
    }
  }

  //Insert a New Leaf record
  AddLeafSelected(rowid: any) {
    this.meatballMenu = '';
    this.newChildArray = [
      {
        requirement: '', nodeType: '', Requirement: [ { trackingValue: '' } ],
      },
    ];
    this.ChildArray = this.ChildData;
    if (this.ChildArray[rowid].Children.length === 0) {
      this.ChildArray[rowid].Children = this.newChildArray;
      this.ChildData = this.ChildArray;
    }
    else {
      const newRow = {
        'requirement': '', 'nodeType': '', 'Requirement': [ { 'trackingValue': 0 } ],
      };
      this.ChildArray[rowid].Children = [ ...this.ChildArray[rowid].Children, newRow ];
      this.ChildData = this.ChildArray;
    }
  }

  //Remove Leaf Record
  RemoveLeafSelected(rowid: any, childid: any) {
    this.meatballMenu = '';
    this.ChildArray = this.ChildData;
    this.SubChildArray = this.ChildArray[rowid].Children;
    this.SubChildArray.splice(childid, 1);
    this.ChildArray[rowid].Children = this.SubChildArray;
    this.ChildData = this.ChildArray;
  }

  ltrackValueOnChange(rowid: any) {
    this.ChildArray = this.ChildData[rowid].Children;
    let tot = this.ChildData[rowid].Requirement.trackingValue;
    if (!tot) { tot = 0; }
    let sum = 0;
    for (let i = 0; i < this.ChildArray.length; i++) {
      const num = Number(this.ChildArray[i].Requirement.trackingValue);
      sum += num;
    }
    if (sum > tot) {
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Totals',
          text: 'The total hours of all the Child elements cannot exceed the Hours Needed for the Sub Requirement',
          cancelButtonTitle: 'Go Back',
        },
      })
    }
  }
}
