import {
  Component, Output, EventEmitter, Input,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';

@Component({
  selector: 'app-course-categories-filters',
  templateUrl: './course-categories-filters.component.html',
  styleUrls: [ './course-categories-filters.component.scss' ],
})
export class CourseCategoriesFiltersComponent {
  @Input() mainFilter: CourseFilter;
  @Input() categoryList: any;
  @Output() updateMainFilter = new EventEmitter<object>();

  constructor() { }

  checkIncludesCategory(cat: any) {
    return !!this.mainFilter.categories.find((categoryItem: any) => cat.id === categoryItem.id);
  }

  updateCheckboxFilter(event: any, category: any): void {
    const isChecked = event?.target?.checked;

    if (isChecked) {
      this.mainFilter.categories.push(category);
    } else {
      this.mainFilter.categories = this.mainFilter.categories.filter((cat: any) => cat.id !== category.id);
    }
  }

  selectAllCategoriesFilter(event: any): void {
    if (event.target.checked) {
      this.mainFilter.categories = this.categoryList;
    } else {
      this.mainFilter.categories = [];
    }
  }

  clearCategoriesFilter(): void {
    this.mainFilter.categories = [];
  }

}
