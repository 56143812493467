import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseValidationDetailsComponent } from './components/course-validation-details/course-validation-details.component';
import { CatalogCourseDetailsComponent } from '../course-catalog/components/catalog-course-details/catalog-course-details.component';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: [ './validation.component.scss' ],
})
export class ValidationComponent implements OnInit, OnDestroy {
  selectedTab = 'Need Validation';

  tableTitles = [
    { name: 'Course Name', sortName: 'courseName'  },
    { name: 'Members', sortName: 'gcRegisteredCount' },
    { name: 'Location', sortName: 'location' },
    { name: 'Date', sortName: 'dateOffered' },
    { name: 'Time', sortName: 'sessionStartDate' },
    {
      name: 'Course ID', class: 'right', sortName: 'courseItemId',
    },
  ];
  courseList: any;
  isLoading = true;
  validationSubscription: any;
  sortDirection = 'ASC';
  stringFilter: any;
  error: any;
  isNeedsValidationTab = true;

  constructor( public dialog: MatDialog, private validationService: ValidationService ) { }

  // TODO: make sure that the backend is returning a list relevant to the users accessLevel in our get call
  // TODO: bug - validation currently broken for some reason
  ngOnInit(): void {
    this.isLoading = true;
    this.validationService.getValidationSessions(this.isNeedsValidationTab);
    this.validationSubscription = this.validationService.validationData$.subscribe((response: any) => {
      this.error = null;
      this.courseList = response?.rows;
      // here we map through the courseList and add a formatted date property and a formatted time property
      this.courseList?.map((course: any) => {
        course.formattedDate = new Date(course.dateOffered).toLocaleDateString();
        course.sessionStartTime = new Date(course?.sessionStartDate)?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
        course.sessionEndTime = new Date(course?.sessionEndDate)?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
      })
      this.isLoading = this.courseList ? false : true;
      if(response?.error) {
        this.error = response.error
        this.isLoading = false;
      }
    })
  }

  sortColumn = (column: any) => {
    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    this.courseList = [];
    this.isLoading = true;
    this.validationService.getValidationSessions(this.isNeedsValidationTab, { sortColumn: column.sortName, sortDirection: this.sortDirection } );
  }

  search = (event: any) => {
    this.courseList = [];
    this.isLoading = true;
    this.stringFilter = event.target.value;
    this.validationService.getValidationSessions(this.isNeedsValidationTab, { filterString: this.stringFilter } );
  }

  setSelectedTab = (tab: any) => {
    this.courseList = null;
    this.isLoading = true;
    this.selectedTab = tab;
    tab === 'Need Validation' ? (this.isNeedsValidationTab = true) : (this.isNeedsValidationTab = false);
    this.validationService.getValidationSessions(this.isNeedsValidationTab);
  }

  openCourseValidationDetails = (course: any) => {
    const courseDialogRef = this.dialog.open(CourseValidationDetailsComponent, { data: course });
    courseDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.isLoading = true;
        this.courseList = [];
        this.validationService.getValidationSessions(this.isNeedsValidationTab)
      }
    })
  }

  openCourseDetails = (course: any) => {
    const dialogRef = this.dialog.open(CatalogCourseDetailsComponent, { data: { selectedCourse: course, noRegistrationButtons: true } })
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.validationService.getValidationSessions(this.isNeedsValidationTab)
      }
    })
  }

  ngOnDestroy(): void {
    this.validationSubscription.unsubscribe();
  }
}
