<div class="modal">
  <div class="title-row">
    <div class="title-container">
      <mat-icon fontSet="material-icons-round" *ngIf="data.type === 'alert'" class="icon alert">warning</mat-icon>
      <mat-icon fontSet="material-icons-round" *ngIf="data.type === 'success'" class="icon success">success</mat-icon>
      <span class="klabel-huge">{{ data.title }}</span>
    </div>
    <mat-icon fontSet="material-icons-round" class="close-icon" (click)="closeDialog()">close</mat-icon>
  </div>
  <hr>
  <div class="content" [ngClass]="{ 'lefty': data.details }">
    <span class="kbody-medium">{{ data.text }}</span>
    <div class="detail" *ngFor="let detail of data.details">
      <span class="klabel-medium label">{{ detail.title }}</span>
      <span class="kbody-large">{{ detail.value }}</span>
    </div>
    <span class="bold">{{ data.boldText }}</span>
    <div class="buttons">
      <app-button *ngIf="data.cancelButtonTitle" (click)="closeDialog()" [class]="'white'" class="button" [text]="data.cancelButtonTitle"></app-button>
      <app-button *ngIf="data.confirmButtonTitle" (click)="sendVerification()" class="button" [text]="data.confirmButtonTitle"></app-button> 
    </div>
  </div>
</div>