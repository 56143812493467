import { Component } from '@angular/core';

@Component({
  selector: 'app-kalpa-red-black',
  templateUrl: './kalpa-red-black.component.html',
  styleUrls: [ './kalpa-red-black.component.scss' ],
})
export class KalpaRedBlackComponent {

}
