import {
  Component, EventEmitter, Input, Output, OnInit, OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ],
})
export class InputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() value: any;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() error: string;
  @Input() icon: string;
  @Input() iconSide: string;
  @Input() color: string;
  @Input() required: boolean;
  @Input() email: boolean;
  @Input() debounceTime = 0;
  @Output() updatedValue = new EventEmitter<any>();

  private inputSubject = new Subject<any>();
  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.inputSubject.pipe(debounceTime(this.debounceTime),
      takeUntil(this.destroy$)).subscribe(value => {
      this.updatedValue.emit(value);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateInput(event: any) {
    this.inputSubject.next(event);
  }
}
