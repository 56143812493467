import {
  Component, Output, EventEmitter, Input,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';

@Component({
  selector: 'app-course-tags-filters',
  templateUrl: './course-tags-filters.component.html',
  styleUrls: [ './course-tags-filters.component.scss' ],
})
export class CourseTagsFiltersComponent {
  @Input() mainFilter: CourseFilter;
  @Input() tagList: any;
  @Output() updateMainFilter = new EventEmitter<object>();

  constructor() { }

  checkIncludesRequirement(tag: any) {
    return !!this.mainFilter.tags.find((tagItem: any) => tag.id === tagItem.id);
  }

  updateCheckboxFilter(event: any, tag: any): void {
    const isChecked = event?.target?.checked;

    if (isChecked) {
      this.mainFilter.tags.push(tag);
    } else {
      this.mainFilter.tags = this.mainFilter.tags.filter((tagItem: any) => tagItem.id !== tag.id);
    }
  }

  selectAllTagsFilter(event: any): void {
    if (event.target.checked) {
      this.mainFilter.tags = this.tagList;
    } else {
      this.mainFilter.tags = [];
    }
  }

  clearTagsFilter(): void {
    this.mainFilter.tags = [];
  }

}
