import {
  Component, Inject, OnInit, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog,
} from '@angular/material/dialog';
import { UpdatePositionComponent } from '../update-position/update-position.component';
import { Subscription } from 'rxjs';
import { PositionsService } from 'src/app/services/positions.service';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './position-details-dialog.component.html',
  styleUrls: [ './position-details-dialog.component.scss' ],
})
export class PositionDetailsDialogComponent implements OnInit, OnDestroy {
  position: any = {}
  positionSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PositionDetailsDialogComponent>,
    public dialog: MatDialog,
    private positionsService: PositionsService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.positionSubscription = this.positionsService.getPositionDetails(this.data.id, 'Users, UserCount').subscribe({
      next: (response: any) => {
        // set local position details to know the user count for deactivation
        this.position = response.data;
      },
      error: (error) => {
        console.log('error', error);
      },
    })
  }

  showEditModal = () => {
    const editRef = this.dialog.open(UpdatePositionComponent, { data: this.data });

    editRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(result);
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.positionSubscription.unsubscribe();
  }
}
