<svg width="104" height="90" viewBox="0 0 300 90" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1560_845)">
    <path d="M129.412 54.5248L122.119 61.2873V70.9007H115.755V21.7067H122.119V53.2651L141.28 35.7621H148.971L134.186 50.2816L150.429 70.9007H142.606L129.412 54.5248Z" fill="white"/>
    <path d="M188.286 35.7621V70.9006H182.186V65.3315C180.771 67.2775 178.981 68.7576 176.816 69.7736C174.65 70.7913 172.263 71.2984 169.656 71.2984C166.251 71.2984 163.202 70.5476 160.507 69.0443C157.81 67.5427 155.699 65.4301 154.176 62.7136C152.651 59.9953 151.888 56.8685 151.888 53.3332C151.888 49.7978 152.649 46.6818 154.176 43.985C155.701 41.29 157.812 39.19 160.507 37.6866C163.202 36.185 166.251 35.4324 169.656 35.4324C172.175 35.4324 174.496 35.9072 176.617 36.8569C178.739 37.8084 180.506 39.2115 181.921 41.0678V35.7639H188.286V35.7621ZM176.22 64.1722C178.031 63.1347 179.445 61.6761 180.463 59.7964C181.479 57.9186 181.988 55.7629 181.988 53.3332C181.988 50.9034 181.479 48.7478 180.463 46.8681C179.445 44.9902 178.031 43.5424 176.22 42.5264C174.406 41.5104 172.396 41.0015 170.186 41.0015C167.977 41.0015 165.909 41.5104 164.121 42.5264C162.331 43.5442 160.915 44.9902 159.878 46.8681C158.838 48.7478 158.321 50.9016 158.321 53.3332C158.321 55.7647 158.84 57.9186 159.878 59.7964C160.915 61.6761 162.331 63.1347 164.121 64.1722C165.911 65.2115 167.932 65.7293 170.186 65.7293C172.44 65.7293 174.406 65.2097 176.22 64.1722Z" fill="white"/>
    <path d="M203.137 68.4798C201.281 66.602 200.352 63.9608 200.352 60.558V21.7067H206.717V60.0276C206.717 61.9288 207.17 63.3874 208.075 64.4033C208.98 65.4211 210.34 65.9282 212.152 65.9282C213.256 65.9282 214.295 65.7078 215.268 65.2652L215.599 70.5029C214.141 71.0333 212.616 71.2985 211.025 71.2985C207.62 71.2985 204.992 70.3595 203.135 68.4798H203.137Z" fill="white"/>
    <path d="M249.414 37.6848C252.109 39.1882 254.22 41.2882 255.744 43.9832C257.269 46.68 258.031 49.796 258.031 53.3314C258.031 56.8667 257.269 59.9953 255.744 62.7118C254.22 65.4301 252.109 67.5409 249.414 69.0425C246.717 70.5459 243.667 71.2967 240.264 71.2967C237.745 71.2967 235.435 70.8111 233.335 69.8381C231.235 68.8669 229.456 67.4513 227.997 65.5949V83.7609H221.633V35.7621H227.732V41.3312C229.146 39.3871 230.936 37.9177 233.102 36.9232C235.267 35.9287 237.654 35.4324 240.263 35.4324C243.665 35.4324 246.715 36.185 249.412 37.6866L249.414 37.6848ZM245.8 64.1722C247.59 63.1347 249.003 61.6761 250.043 59.7964C251.08 57.9186 251.602 55.7629 251.602 53.3332C251.602 50.9034 251.082 48.7478 250.043 46.8681C249.003 44.9902 247.59 43.5424 245.8 42.5264C244.009 41.5104 241.986 41.0015 239.734 41.0015C237.482 41.0015 235.523 41.5212 233.733 42.5605C231.943 43.5997 230.527 45.0476 229.49 46.9022C228.451 48.7585 227.931 50.9034 227.931 53.3332C227.931 55.7629 228.438 57.9186 229.456 59.7964C230.472 61.6761 231.886 63.1347 233.699 64.1722C235.511 65.2115 237.521 65.7293 239.732 65.7293C241.943 65.7293 244.008 65.2097 245.798 64.1722H245.8Z" fill="white"/>
    <path d="M300 35.7621V70.9006H293.9V65.3315C292.485 67.2775 290.695 68.7576 288.53 69.7736C286.364 70.7913 283.977 71.2984 281.37 71.2984C277.965 71.2984 274.916 70.5476 272.221 69.0443C269.524 67.5427 267.413 65.4301 265.89 62.7136C264.365 59.9953 263.602 56.8685 263.602 53.3332C263.602 49.7978 264.363 46.6818 265.89 43.985C267.415 41.29 269.526 39.19 272.221 37.6866C274.916 36.185 277.965 35.4324 281.37 35.4324C283.889 35.4324 286.21 35.9072 288.331 36.8569C290.453 37.8084 292.22 39.2115 293.635 41.0678V35.7639H300V35.7621ZM287.934 64.1722C289.745 63.1347 291.159 61.6761 292.177 59.7964C293.193 57.9186 293.702 55.7629 293.702 53.3332C293.702 50.9034 293.193 48.7478 292.177 46.8681C291.159 44.9902 289.745 43.5424 287.934 42.5264C286.12 41.5104 284.11 41.0015 281.9 41.0015C279.691 41.0015 277.623 41.5104 275.835 42.5264C274.045 43.5442 272.629 44.9902 271.592 46.8681C270.552 48.7478 270.035 50.9016 270.035 53.3332C270.035 55.7647 270.554 57.9186 271.592 59.7964C272.629 61.6761 274.045 63.1347 275.835 64.1722C277.625 65.2115 279.646 65.7293 281.9 65.7293C284.154 65.7293 286.12 65.2097 287.934 64.1722Z" fill="white"/>
    <path d="M24.0845 71.9812C25.656 65.4892 27.8116 59.2015 30.7019 53.1755C34.291 45.6927 38.8692 38.8836 44.5817 32.8485C50.3264 26.7795 56.8954 21.8017 64.2976 17.9312C68.1663 15.9082 72.1639 14.2077 76.3551 13.009C68.2594 4.96885 57.1086 0 44.7967 0C20.0564 0 0 20.0564 0 44.7967C0 61.1905 8.80883 75.5255 21.9504 83.3344C22.4772 79.5231 23.176 75.7369 24.0863 71.9812H24.0845ZM15.3653 27.8743C15.3563 27.7973 15.3151 27.722 15.2882 27.6467V25.3836C15.3706 24.9321 15.4477 24.4805 15.5355 24.0308C16.4368 19.4597 20.1334 15.7917 24.5737 15.0589C29.6626 14.2203 34.5813 16.8722 36.5917 21.6942C38.4625 26.1774 37.9052 30.4421 34.7766 34.1781C29.8687 40.0411 20.8054 39.1738 16.9296 32.5618C16.0766 31.1068 15.557 29.5443 15.3635 27.8707L15.3653 27.8743Z" fill="#E25B34"/>
    <path d="M66.3493 68.625C63.1365 64.3693 59.2356 60.9558 54.2721 58.8736C50.407 57.252 46.3646 56.641 42.1913 56.8811C40.9531 56.9528 39.7203 57.123 38.4839 57.2377C38.2116 57.2627 38.079 57.381 37.9912 57.6462C36.5075 62.1044 35.3786 66.6504 34.5669 71.2787C33.7229 76.0863 33.2176 80.9315 33.0976 85.8072C33.0797 86.5508 33.0779 87.2963 33.085 88.0417C36.8193 89.0505 40.7435 89.5916 44.7967 89.5916C55.2935 89.5916 64.9445 85.981 72.5814 79.9335C70.9741 75.9125 68.9744 72.0994 66.3493 68.6232V68.625Z" fill="#E25B34"/>
    <path d="M77.8011 14.5088C75.8247 15.3993 73.8232 16.2433 71.8897 17.2198C65.3691 20.5169 59.5473 24.7887 54.4495 30.0299C49.8802 34.7282 46.1119 40.007 43.0604 45.8019C42.5784 46.7158 42.1286 47.6458 41.6663 48.5686C41.6125 48.6779 41.5695 48.7926 41.5086 48.9341C41.6448 48.9574 41.7505 48.9879 41.858 48.9933C46.3072 49.2334 50.4733 50.4949 54.4172 52.5232C59.6584 55.22 63.907 59.0761 67.3957 63.7941C70.6354 68.1734 73.0114 72.9703 74.832 78.0305C83.897 69.8327 89.5934 57.9795 89.5934 44.7949C89.5934 33.1155 85.1227 22.4808 77.8011 14.507V14.5088Z" fill="#E25B34"/>
  </g>
  <defs>
    <clipPath id="clip0_1560_845">
      <rect width="300" height="89.5934" fill="white"/>
    </clipPath>
  </defs>
</svg>
  