<div class="user-roles">
  <div class="title-row">
    <div class="title-content">
      <span class="klabel-huge">User Roles</span>
      <app-button
        (click)="openNewMode()"
        [text]="'New Custom Role'"
        [icon]="{ materialIcon: 'add' }"
      ></app-button>
    </div>
  </div>

  <div class="sub-nav">
    <div class="sub-tabs">
      <span
        (click)="toggleSelectedRole(role)"
        *ngFor="let role of roles"
        class="sub-tab klabel-large"
        [ngClass]="{ selected: role.id === selectedRole.id }"
        >{{ role?.name }}</span
      >
      <span (click)="openNewMode()" class="sub-tab klabel-large"
        >+ Add New</span
      >
    </div>
    <app-loading-spinner
      *ngIf="isLoading"
      class="spinner"
    ></app-loading-spinner>
    <div class="content" *ngIf="!isLoading">
      <div class="title-container">
        <input
          class="title-input ktitle-small primary-title"
          [(ngModel)]="selectedRole.name"
          (ngModelChange)="setCanSave()"
        />
        <div class="actions">
          <app-button
            (click)="deactivateRole()"
            [text]="'Remove Role'"
            [class]="'grey'"
            *ngIf="
              selectedRole.roleType !== 'DEFAULT' &&
              selectedRole.roleType !== 'NEW'
            "
          ></app-button>
          <app-button
            (click)="saveChanges()"
            [disabled]="!canSave"
            [text]="'Save Changes'"
            [icon]="{ materialIcon: 'done' }"
          ></app-button>
        </div>
      </div>

      <div class="section">
        <span class="roles-title">General Permissions</span>
        <div class="permissions">
          <div
            class="permission-row"
            *ngFor="
              let permission of filterDisplayPermissions('General Permissions')
            "
            [ngClass]="{
              authorized:
                selectedRole.formattedPermissions[permission.permission]
            }"
          >
            <label class="switch">
              <input
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (ngModelChange)="setCanSave()"
                type="checkbox"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <span
                class="slider round"
                [ngClass]="{ disabled: selectedRole.roleType === 'DEFAULT' }"
              ></span>
            </label>
            <div class="permission-details">
              <span class="roles-title">{{ permission.title }}</span>
              <span class="roles-content">{{ permission.description }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <span class="roles-title">Catalog Permissions</span>
        <div class="permissions">
          <div
            class="permission-row"
            *ngFor="
              let permission of filterDisplayPermissions('Catalog Permissions')
            "
            [ngClass]="{
              authorized:
                selectedRole.formattedPermissions[permission.permission],
              'checkbox-container': permission.type === 'checkbox',
              hidden:
                permission.type === 'checkbox' &&
                !selectedRole.formattedPermissions.ManageCourseCatalog
            }"
          >
            <label class="switch" *ngIf="permission.type === 'switch'">
              <input
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (change)="setCanSave()"
                type="checkbox"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <span
                class="slider round"
                [ngClass]="{ disabled: selectedRole.roleType === 'DEFAULT' }"
              ></span>
            </label>
            <input
              type="checkbox"
              *ngIf="
                permission.type === 'checkbox' &&
                selectedRole.formattedPermissions.ManageCourseCatalog
              "
              (ngModelChange)="setCanSave()"
              [(ngModel)]="
                selectedRole.formattedPermissions[permission.permission]
              "
              [disabled]="selectedRole.roleType === 'DEFAULT'"
            />
            <div
              class="permission-details"
              *ngIf="
                permission.type === 'switch' ||
                selectedRole.formattedPermissions.ManageCourseCatalog
              "
            >
              <span class="roles-title">{{ permission.title }}</span>
              <span class="roles-content">{{ permission.description }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <span class="roles-title">System Settings Permissions</span>
        <div class="permissions">
          <div
            class="permission-row"
            *ngFor="
              let permission of filterDisplayPermissions(
                'System Settings Permissions'
              )
            "
            [ngClass]="{
              authorized:
                selectedRole.formattedPermissions[permission.permission],
              'checkbox-container': permission.type === 'checkbox',
              hidden:
                permission.type === 'checkbox' &&
                !selectedRole.formattedPermissions.SYS_SETTINGS_ACCESS
            }"
          >
            <label class="switch" *ngIf="permission.type === 'switch'">
              <input
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (ngModelChange)="setCanSave()"
                type="checkbox"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <span
                class="slider round"
                [ngClass]="{ disabled: selectedRole.roleType === 'DEFAULT' }"
              ></span>
            </label>
            <input
              type="checkbox"
              *ngIf="
                permission.type === 'checkbox' &&
                selectedRole.formattedPermissions.SYS_SETTINGS_ACCESS
              "
              [(ngModel)]="
                selectedRole.formattedPermissions[permission.permission]
              "
              (ngModelChange)="setCanSave()"
              [disabled]="selectedRole.roleType === 'DEFAULT'"
            />
            <div
              class="permission-details"
              *ngIf="
                permission.type === 'switch' ||
                selectedRole.formattedPermissions.SYS_SETTINGS_ACCESS
              "
            >
              <span class="roles-title">{{ permission.title }}</span>
              <span class="roles-content">{{ permission.description }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <span class="roles-title">Kalpa Accounts Management</span>
        <div class="permissions">
          <div
            class="permission-row"
            *ngFor="
              let permission of filterDisplayPermissions(
                'Kalpa Accounts Management'
              )
            "
            [ngClass]="{
              authorized:
                selectedRole.formattedPermissions[permission.permission],
              'checkbox-container': permission.type === 'checkbox',
              hidden:
                permission.type === 'checkbox' &&
                !selectedRole.formattedPermissions.USER_ACCOUNTS
            }"
          >
            <label class="switch" *ngIf="permission.type === 'switch'">
              <input
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (ngModelChange)="setCanSave()"
                type="checkbox"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <span
                class="slider round"
                [ngClass]="{ disabled: selectedRole.roleType === 'DEFAULT' }"
              ></span>
            </label>
            <input
              type="checkbox"
              *ngIf="
                permission.type === 'checkbox' &&
                selectedRole.formattedPermissions.USER_ACCOUNTS
              "
              [(ngModel)]="
                selectedRole.formattedPermissions[permission.permission]
              "
              (ngModelChange)="setCanSave()"
              [disabled]="selectedRole.roleType === 'DEFAULT'"
            />
            <div
              class="permission-details"
              *ngIf="
                permission.type === 'switch' ||
                selectedRole.formattedPermissions.USER_ACCOUNTS
              "
            >
              <span class="roles-title">{{ permission.title }}</span>
              <span class="roles-content">{{ permission.description }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <span class="roles-title">User Activity Management Permissions</span>
        <div class="permissions">
          <div
            class="permission-row"
            *ngFor="
              let permission of filterDisplayPermissions(
                'User Activity Management'
              )
            "
            [ngClass]="{
              authorized:
                selectedRole.formattedPermissions[permission.permission],
              'checkbox-container': permission.type === 'checkbox',
              hidden:
                permission.type === 'checkbox' &&
                !selectedRole.formattedPermissions.USER_ACCOUNTS
            }"
          >
            <label class="switch" *ngIf="permission.type === 'switch'">
              <input
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (ngModelChange)="setCanSave()"
                type="checkbox"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <span
                class="slider round"
                [ngClass]="{ disabled: selectedRole.roleType === 'DEFAULT' }"
              ></span>
            </label>
            <input
              type="checkbox"
              *ngIf="
                permission.type === 'checkbox' &&
                selectedRole.formattedPermissions.USER_ACCOUNTS
              "
              [(ngModel)]="
                selectedRole.formattedPermissions[permission.permission]
              "
              (ngModelChange)="setCanSave()"
              [disabled]="selectedRole.roleType === 'DEFAULT'"
            />
            <div
              class="permission-details"
              *ngIf="
                permission.type === 'switch' ||
                selectedRole.formattedPermissions.USER_ACCOUNTS
              "
            >
              <span class="roles-title">{{ permission.title }}</span>
              <span class="roles-content">{{ permission.description }}</span>
            </div>
          </div>
          <div
            class="sub-section"
            *ngIf="selectedRole?.formattedPermissions?.ManageUserActivity"
          >
            <span class="subtitle"
              >Select user management parameters (required)</span
            >
            <div
              class="permission-row"
              *ngFor="
                let permission of filterDisplayPermissions(
                  'User Management Buttons'
                )
              "
            >
              <input
                type="radio"
                name="userGroup"
                [value]="permission.value"
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (ngModelChange)="setCanSave()"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <div class="permission-details">
                <span class="roles-title">{{ permission.title }}</span>
                <span class="roles-content">{{ permission.description }}</span>
              </div>
            </div>

            <div
              *ngIf="
                selectedRole.formattedPermissions.UserManagementGroup ===
                'AssignedUsers'
              "
            >
              <div
                class="permission-row indent"
                *ngFor="
                  let permission of filterDisplayPermissions(
                    'Assigned User Level'
                  )
                "
              >
                <input
                  type="radio"
                  name="approverlevel"
                  [value]="permission.value"
                  [(ngModel)]="
                    selectedRole.formattedPermissions[permission.permission]
                  "
                  (ngModelChange)="setCanSave()"
                  [disabled]="selectedRole.roleType === 'DEFAULT'"
                />
                <div class="permission-details">
                  <span class="roles-title">{{ permission.title }}</span>
                  <span class="roles-content">{{
                    permission.description
                  }}</span>
                </div>
              </div>
            </div>

            <span class="subtitle">Additional User Management Permissions</span>
            <div
              class="permission-row"
              *ngFor="
                let permission of filterDisplayPermissions(
                  'Additional Permissions'
                )
              "
            >
              <input
                type="checkbox"
                *ngIf="permission.type === 'checkbox'"
                [(ngModel)]="
                  selectedRole.formattedPermissions[permission.permission]
                "
                (ngModelChange)="setCanSave()"
                [disabled]="selectedRole.roleType === 'DEFAULT'"
              />
              <div class="permission-details">
                <span class="roles-title">{{ permission.title }}</span>
                <span class="roles-content">{{ permission.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
