import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { UpdateRequirementComponent } from '../update-requirement/update-requirement.component';
import { RequirementsService } from 'src/app/services/requirements.service';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { Subscription } from 'rxjs';
import { SwapComponent } from 'src/app/components/swap/swap.component';
import { ToastService } from 'src/app/services/toast.service';
//import { NgIf } from '@angular/common';

@Component({
  selector: 'app-requirement-details',
  templateUrl: './requirement-details.component.html',
  styleUrls: [ './requirement-details.component.scss' ],
})
export class RequirementDetailsComponent implements OnInit, OnDestroy {
  requirement: any = {}
  requirementTot: any = {}
  requirementSubscription: Subscription;
  activationSubscription: Subscription;
  formattedNodeType: any;
  isLoading = true;

  constructor(
    private requirementsService: RequirementsService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<RequirementDetailsComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // set initial name and description while waiting for api call
    this.requirement = { requirement: this.data.itemToEdit.itemData[0].content };
    this.requirementSubscription = this.requirementsService.getRequirementDetails(this.data.itemToEdit.itemId, 'AllChildrenDetails, Counts').subscribe({
      next: (response: any) => {
        // Format the Type
        if (!response.data) return;
        this.requirement = response.data;
        const formattedType = formatType(this?.requirement?.nodeType);
        this.requirement.formattedNodeType = formattedType;
        this.requirementTot = response.data;
        this.isLoading = false;

      },
      error: (error) => {
        console.log('error', error);
      },
    })

    // this.requirementTot = { requirementTot: this.data.itemToEdit.itemData[0].content };
    // this.requirementSubscription = this.requirementsService.getRequirementDetails(this.data.itemToEdit.itemId, 'Counts').subscribe({
    //   next: (response: any) => {
    //     // set local requirementTot details to know the user count for deactivation
    //     this.requirementTot = response.data;
    //   },
    //   error: (error) => {
    //     console.log('error', error);
    //   },
    // })

    //Format the Node Type
    const formatType = (string: any) => {
      const newType = new String(string);
      let nNode = newType;
      const position = newType.search('_');
      if (position > 0)
        nNode = 'Group'
      else
      if (newType.length > 0)
        nNode = 'Single'
      else
        nNode = newType;
      return [ nNode ];
    }


  }


  closeDialog = () => {
    this.dialogRef.close();
  }

  toggleEditModal = () => {
    const editRef = this.dialog.open(UpdateRequirementComponent, { data: { itemToEdit: this.requirement, entityWithRequirements: this.data.entityWithRequirements } });

    editRef.afterClosed().subscribe((result) => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.dialogRef.close(result);
      }
    });
  }

  ngOnDestroy(): void {
    this.requirementSubscription.unsubscribe();
  }

  toggleActivation = (isDisabled: boolean) => {
    if (isDisabled) return;
    const setActive = !this.requirement.activeFlag;

    // if you have staff or courses for the year, you need to reassign
    if ((this.requirementTot.Counts.curYrCourses) > 0 || this.requirementTot.Counts.curYrStaff > 0) {
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert', title: 'Replace requirement', confirmButtonTitle: 'Replace Requirement', cancelButtonTitle: 'Cancel', text: 'This requirement is assigned to courses in the current year catalog. You must choose a new requirement to replace it.',
        },
      })
      const formattedOptions = this.data.entityWithRequirements?.Requirements?.map((requirement: any) => {
        return { value: requirement.Requirement.id, label: requirement.requirement }
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this will be some real data in the future
          this.dialog.open(SwapComponent, {
            data: {
              title: 'Replace requirement',
              label: 'Select a new requirement',
              optionPlaceHolder: 'Select a new requirement',
              options: formattedOptions,
            },
          })
        }
      })
    }
    else {
      this.activationSubscription = this.requirementsService.toggleRequirementActivation(this.requirement, setActive).subscribe({
        next: (response: any) => {
          this.toastService.setToast({ text: `Requirement ${setActive ? 'Activated' : 'Deactivated'}`, type: 'success' })
          setActive ? this.requirementsService.getInactiveRequirements() : this.requirementsService.getRequirements();
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          console.log('error', error)
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert', title: `Oops, something went wrong ${setActive ? 'activating' : 'deactivating'} ${this.requirement.requirement}`, text: error.error.message,
            },
          })
        },
      })
    }

  }

  checkIfAdditionalInfo = (requirement: any) => {
    const reportToStateFlag = requirement.Requirement.reportToStateFlag === 1;
    const allUsersFlag = requirement.Requirement.allUsersFlag === 1;
    const allCoursesFlag = requirement.Requirement.allCoursesFlag === 1;
    const rollingFlag = requirement.Requirement.rollingFlag === 1;
    const counselorFlag = requirement.Requirement.requirementType === 'Counselor';

    return reportToStateFlag || allUsersFlag || allCoursesFlag || rollingFlag || counselorFlag;
  }
}
