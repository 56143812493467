import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/services/entity.service';


@Component({
  selector: 'app-defaults',
  templateUrl: './defaults.component.html',
  styleUrls: [ './defaults.component.scss' ],
})
export class DefaultsComponent implements OnInit, OnDestroy {
  activeTab = 'general';
  entitySubscription: Subscription;
  entity: any;
  updatedDefaults: any;
  error: any;
  isChanged = false;


  constructor( private entityService: EntityService) {}

  // TODO: this page needs major ui and functionality updates

  ngOnInit(): void {
    this.entityService.getEntity('Users, Buildings, Config');
    this.entitySubscription = this.entityService.entityData$.subscribe((entity: any) => {
      const entityProps = entity?.current || entity;

      if (entityProps) {
        this.entity = entityProps
        this.updatedDefaults = JSON.parse(JSON.stringify(entityProps));
      }
    })
  }

  filterUpdates = (updateBody: any, isConfig = false): object => {
    const comparison = isConfig ? this.entity?.Config : this.entity;
    return Object.entries(updateBody).reduce((acc, [ key, value ]) => {
      if (value === undefined) return acc;
      if (value == comparison[key]) return acc;

      if (key === 'Config') {
        value = this.filterUpdates(value, true);
      }

      acc[key] = value;

      return acc;
    }, {} as any)
  }

  parseUpdates = () => {
    const updateBody = {
      entityName: this.updatedDefaults?.entityName,
      primaryContact: this.updatedDefaults?.primaryContact,
      primaryEmail: this.updatedDefaults?.primaryEmail,
    }

    const updateConfig = {
      primaryBuildingId: this.updatedDefaults?.primaryBuildingId,
      defaultSpereqApprovalKid: this.updatedDefaults?.defaultSpereqApprovalKid,
      regMessageFlag: this.updatedDefaults?.regMessageFlag,
      registrationNotice: this.updatedDefaults?.registrationNotice,
      registrationMessage: this.updatedDefaults?.registrationMessage,
    }

    const filteredBody = this.filterUpdates(updateBody);
    const filteredConfig = this.filterUpdates(updateConfig, true);

    const filteredUpdateBody:{ [key: string]: unknown; Config?: object } = { ...filteredBody }

    if (Object.keys(filteredConfig).length) {
      filteredUpdateBody.Config = filteredConfig;
    }

    return filteredUpdateBody;
  }

  saveChanges = () => {
    if (!this.updatedDefaults || !this.isChanged) return;

    if(this?.updatedDefaults?.entityName === null || this?.updatedDefaults?.entityName === '') {
      this.error = { 'entityName': 'Name is required' }
      return
    }
    if (!this.entity.Config.defaultSpereqApprovalKid && !this.updatedDefaults.defaultSpereqApprovalKid) {
      this.error = { approvalLevel: 'Approval level is required' }
      return
    }

    const filteredUpdateBody: { [key: string]: unknown; Config?: object } = this.parseUpdates();

    if (!Object.keys(filteredUpdateBody).length) {
      this.isChanged = false;
      return;
    }

    this.entityService.updateEntity(filteredUpdateBody);
  }

  setNewDefaults = (newDefaults: any) => {
    this.updatedDefaults = {
      ...this.updatedDefaults,
      ...newDefaults,
    };

    const parsedUpdated = this.parseUpdates();

    this.isChanged = Object.keys(parsedUpdated).length > 0;
  }

  ngOnDestroy(): void {
    this.entitySubscription.unsubscribe();
  }

}
