<div class="form-header">
  <h1>Welcome to Kalpa v4.0</h1>
</div>
<form class="form" #authForm="ngForm" (ngSubmit)="submitEmailLogin(authForm)">
  <label for="email">District Email</label>
  <input
    id="email"
    type="email"
    placeholder="Enter your district email"
    class="text-input"
    name="email"
    autocomplete="email"
    required
    #email="ngModel"
    [ngModel]="rememberedEmail"
    [ngClass]="{
      inputError: email.invalid && email.errors && email.touched
    }"
  />
  <label for="password">Password</label>
  <input
    id="password"
    type="password"
    placeholder="Enter your password"
    class="text-input"
    name="password"
    autocomplete="current-password"
    required
    minlength="6"
    #password="ngModel"
    ngModel
    [ngClass]="{
      inputError: password.invalid && password.errors && password.touched
    }"
  />
  <div id="form-help-btns">
    <div class="checkbox-row">
      <input
        id="remember-me"
        type="checkbox"
        name="rememberMe"
        #rememberme
        [(ngModel)]="rememberMeChecked"
      />
      <label for="remember-me">Remember Me</label>
    </div>
    <button
      type="button"
      class="forgot-btn"
      (click)="toggleForgotPassword('forgotPasswordMode')"
    >
      Forgot Password?
    </button>
  </div>

  <span
    class="klabel-small error"
    *ngIf="
      (email.invalid && email.touched && email.errors) ||
      (password.invalid && password.touched && password.errors)
    "
    >Please check your entries</span
  >
  <span class="klabel-small error" *ngIf="!!error$">{{ error$ }}</span>
  <button
    type="submit"
    class="submit-btn"
    [ngClass]="{ disabled: !authForm.valid }"
    [disabled]="!authForm.valid"
  >
    Sign in with my district email'
  </button>
  <div class="google-container">
    <div id="google_btn" class="google_btn"></div>
  </div>
</form>
