import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateRequirementComponent } from './components/update-requirement/update-requirement.component';
import { RequirementDetailsComponent } from './components/requirement-details/requirement-details.component';
import { RequirementsService } from 'src/app/services/requirements.service';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: [ './requirements.component.scss' ],
})
export class RequirementsComponent implements OnInit, OnDestroy {
  tableData: any;
  loading = false;
  selectedItemsPerPage = 25;
  isActive = false;
  requirementsData: any;
  requirementSubscription: Subscription;
  entityWithRequirementsSubscription: Subscription;
  entityWithRequirements: any;

  searchText = '';
  sortColumnName = 'requirement';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;


  constructor(
    private requirementsService: RequirementsService,
    public dialog: MatDialog,
    private entityService: EntityService,
  ) {
  }

  ngOnInit(): void {
    this.tableData = {
      tableTitle: 'Requirements',
      columnTitles: [
        {
          title: 'Requirement Name', class: 'first-title left-align wide', sortBy: 'requirement',
        },
        {
          title: 'Type', sortBy: 'nodeType', class: 'left-align skinny',
        },
        {
          title: 'Status', notClickable: true, class: 'left-align skinny',
        },
        {
          title: 'Minimum Value', sortBy: 'Requirement.tracking_value', class: 'left-align',
        },
      ],
    }

    this.searchForRequirements();
    this.requirementSubscription = this.requirementsService.requirementsData$.subscribe((data: any) => {
      if (!data) return;
      this.requirementsData = data;
      if (data?.error) {
        this.loading = false;
      }
      const formatedTableData = data?.rows?.map((requirement: any) => {
        return {
          itemId: requirement.requirementId,
          showEdit: requirement?.nodeType === 'SINGLE' && requirement?.activeFlag === 1,
          itemData: [
            {
              type: 'maincolumn', maincontent: requirement?.requirement + '  ' + this.checkCount(requirement?.childCount), class: 'first kbody-medium left-align wide',
            },
            {
              type: 'grouping', single: requirement?.nodeType, class: 'left-align skinny',
            },
            {
              type: 'status', active: requirement?.activeFlag, class: 'left-align skinny',
            },
            { content: requirement?.Requirement?.trackingValue, class: 'kbody-medium left-align' },
          ],
        }
      })
      this.tableData = {
        tableTitle: 'Requirements',
        error: data?.error || null,
        columnTitles: this.tableData.columnTitles,
        //Todo automate the list of school years
        // allSchoolYears: [
        //   '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020', '2018-2019',
        // ],
        activeTotal: data?.counts.active,
        inactiveTotal: data?.counts.inactive,
        curPage: data?.pagination.curPage,
        totalItems: data?.pagination.totalItems,
        totalPages: data?.pagination.totalPages,
        data: formatedTableData,
      }

      this.loading = data ? false : true;
    })
    this.entityService.getEntity('Requirements')
    this.entityWithRequirementsSubscription = this.entityService.entityData$.subscribe((entity: any) => {
      this.entityWithRequirements = entity
    })
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForRequirements();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForRequirements();
  }

  searchForRequirements() {
    this.loading = true;
    this.tableData.data = []
    this.requirementsService.getRequirements(
      this.searchText,
      'SINGLE,GROUP_PARENT',
      this.isActive ? 0 : 1,
      this.sortColumnName,
      this.sortDirection,
      this.selectedItemsPerPage,
      this.offset,
    )
  }

  checkCount(str: string) {
    if (str !== null) {
      //return " +" + str;
      return '<span class=' + '"klabel-super"' + '>' + '+' + str + '</span> ';
    }

    return ''

  }


  paginationEvent = (pageDirection: string) => {
    this.loading = true;
    this.tableData.data = [];
    this.requirementsService.getPagination(pageDirection);
  }

  getRequirementsFilteredByName = (searchItem: any) => {
    this.searchText = searchItem?.searchText;
    this.offset = 0;
    this.searchForRequirements();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;
    this.offset = 0;

    this.searchForRequirements();
  }

  hideEditButton = (itemId: any) => {
    const itemToEdit = this.tableData.data.find((tableItem: any) => tableItem.itemId === itemId)
    if (itemToEdit.nodeType === 'GROUP_PARENT') {
      return true;
    }
    return false;
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.offset = 0;

    this.searchForRequirements();
  }

  addNewRequirement(): void {
    const dialog = this.dialog.open(UpdateRequirementComponent);

    dialog.afterClosed().subscribe((result) => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForRequirements();
      }
    });
  }

  showEditModal = (itemId: any) => {
    const itemToShow = this.tableData.data.find((tableItem: any) => tableItem.itemId === itemId)
    if (itemToShow.itemData[1].single === 'SINGLE') {
      const dialogRef = this.dialog.open(UpdateRequirementComponent, { data: { itemToEdit: itemToShow, entityWithRequirements: this.entityWithRequirements } });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'reload' || result?.statusCode === 1000) {
          this.searchForRequirements();
        }
      });
    }
  }

  showItemDetails = (itemId: any) => {
    const itemToShow = this.tableData.data.find((tableItem: any) => tableItem.itemId === itemId)

    const dialogRef = this.dialog.open(RequirementDetailsComponent, { data: { itemToEdit: itemToShow, entityWithRequirements: this.entityWithRequirements } });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForRequirements();
      }
    });
  }

  ngOnDestroy(): void {
    this.requirementSubscription.unsubscribe();
    this.entityWithRequirementsSubscription.unsubscribe();
  }

}
