<div class="modal">
  <div class="title-row">
    <div>
      <mat-icon fontSet="material-icons-round">view_list</mat-icon>
      <span class="ktitle-medium"
        >{{ data ? "Edit" : "Add New" }} Requirement</span
      >
    </div>
    <mat-icon
      class="close-icon"
      (click)="closeDialog()"
      fontSet="material-icons-round"
      >close</mat-icon
    >
  </div>

  <div class="content" *ngIf="isLoading">
    <app-loading-spinner id="spinner"></app-loading-spinner>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="content">
      <span class="klabel-large">General Information</span>
      <div class="row" #main>
        <div class="detail rname">
          <span class="klabel-medium label">Requirement Name*</span>
          <input
            [(ngModel)]="newRequirement.Requirement.requirement"
            type="text"
            class="kinput"
            placeholder="Enter a name"
            [disabled]="data"
            [ngClass]="{ disabled: data }"
          />
        </div>
        <div class="detail ntype">
          <span class="klabel-medium label">Type*</span>
          <select
            #mselect
            class="kinput kbody-medium"
            required
            (change)="MSelected(mselect.value)"
            [disabled]="data"
            [ngClass]="{ disabled: data }"
            [(ngModel)]="newRequirement.nodeType"
          >
            <option value="SINGLE">Single</option>
            <option value="GROUP_PARENT">Group</option>
          </select>
        </div>
        <div class="detail units">
          <span class="klabel-medium label">Hours Needed*</span>
          <input
            type="number"
            class="kinput"
            placeholder="0.00"
            min="0.00"
            [(ngModel)]="newRequirement.Requirement.trackingValue"
          />
        </div>
        <div
          class="actions"
          *ngIf="
            newRequirement.nodeType === 'GROUP_PARENT' ||
            mselect.value === 'GROUP_PARENT'
          "
        >
          <mat-icon
            class="icon"
            fontSet="material-icons-round"
            (click)="setMeatball(main)"
            [ngClass]="{ open: meatballMenu === main }"
            >more_horiz</mat-icon
          >
          <div class="pop-up" [ngClass]="{ open: meatballMenu === main }">
            <div class="btn kbody-large" (click)="AddChildSelected()">
              <span>Add sub-requirement</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="subitems"
        *ngIf="
          newRequirement?.nodeType === 'GROUP_PARENT' ||
          mselect.value === 'GROUP_PARENT'
        "
      >
        <div class="row" *ngFor="let child of ChildData; let ic = index">
          <div class="wrapper">
            <div class="wrowchild">
              <mat-icon class="material-symbols-outlined">
                subdirectory_arrow_right
              </mat-icon>
              <div class="subitems iname">
                <span class="klabel-medium label">Sub-requirement Name*</span>
                <input
                  #crequirement
                  [(ngModel)]="child.Requirement.requirement"
                  type="text"
                  class="kinput"
                  placeholder="Enter a name"
                />
              </div>
              <div class="subitems iunits">
                <span class="klabel-medium label">Minimum Hours*</span>
                <input
                  #ctrackingValue
                  [(ngModel)]="child?.Requirement.trackingValue"
                  type="number"
                  class="kinput"
                  placeholder="0.00"
                  min="0.00"
                  (focusout)="ctrackValueOnChange()"
                />
              </div>
              <div class="actions">
                <mat-icon
                  class="icon"
                  fontSet="material-icons-round"
                  (click)="setMeatball(ic)"
                  [ngClass]="{ open: meatballMenu === ic }"
                  >more_horiz</mat-icon
                >
                <div class="pop-up" [ngClass]="{ open: meatballMenu === ic }">
                  <div class="btn kbody-large" (click)="AddLeafSelected(ic)">
                    <span>Add Child</span>
                  </div>
                  <div
                    class="btn kbody-large"
                    (click)="RemoveChildSelected(ic)"
                  >
                    <span>Remove sub-requirement </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="wrowleaf"
              *ngFor="let leaf of child?.Children; let il = index"
            >
              <div class="break"></div>
              <mat-icon class="material-symbols-outlined">
                subdirectory_arrow_right
              </mat-icon>
              <div class="subitems iname">
                <span class="klabel-medium label">Child-requirement Name*</span>
                <input
                  [(ngModel)]="leaf.Requirement.requirement"
                  type="text"
                  class="kinput"
                  placeholder="Enter a name"
                />
              </div>
              <div class="subitems iunits">
                <span class="klabel-medium label">Minimum Hours*</span>
                <input
                  #ltrackValue
                  [(ngModel)]="leaf?.Requirement.trackingValue"
                  type="number"
                  class="kinput"
                  placeholder="0.00"
                  min="0.00"
                  (focusout)="ltrackValueOnChange(ic)"
                />
              </div>
              <div class="actions">
                <mat-icon
                  class="icon"
                  fontSet="material-icons-round"
                  (click)="setMeatball(ic + '.' + il)"
                  [ngClass]="{ open: meatballMenu === ic + '.' + il }"
                  >more_horiz</mat-icon
                >
                <div
                  class="pop-up"
                  [ngClass]="{ open: meatballMenu === ic + '.' + il }"
                >
                  <div
                    class="btn kbody-large"
                    (click)="RemoveLeafSelected(ic, il)"
                  >
                    <span>Remove child-requirement </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail">
        <span class="klabel-medium label">Requirement Description</span>
        <textarea
          placeholder="Enter a description for this requirement."
          name=""
          id=""
          class="kinput"
          rows="4"
          [(ngModel)]="newRequirement.Requirement.description"
        ></textarea>
      </div>

      <div class="additional-settings">
        <div class="row">
          <span>Additional Settings</span>
          <mat-icon
            class="toggle-settings"
            (click)="toggleViewSettings()"
            [ngClass]="{ hidden: !viewSettings }"
            fontSet="material-icons-round"
            >expand_less</mat-icon
          >
        </div>
        <div *ngIf="viewSettings">
          <div class="row">
            <input
              type="checkbox"
              [(ngModel)]="newRequirement.Requirement.reportToStateFlag"
            />
            <span>REP Reporting</span>
            <!-- TODO - implement help tooltip -->
            <!-- <mat-icon fontSet="material-icons-round">help</mat-icon> -->
          </div>
          <div class="row">
            <input
              [(ngModel)]="newRequirement.Requirement.requirementType"
              type="checkbox"
            />
            <span>This is a counselor requirement</span>
            <!-- TODO - implement help tooltip -->
            <!-- <mat-icon fontSet="material-icons-round">help</mat-icon> -->
          </div>
          <div class="row">
            <input
              type="checkbox"
              [disabled]="data"
              [(ngModel)]="newRequirement.Requirement.allUsersFlag"
            />
            <span>Make this the default requirement for all users</span>
            <!-- TODO - implement help tooltip -->
            <!-- <mat-icon fontSet="material-icons-round">help</mat-icon> -->
          </div>
          <div class="row">
            <input
              [disabled]="data"
              type="checkbox"
              [(ngModel)]="newRequirement.Requirement.rollingFlag"
            />
            <span>This is a rolling requirement</span>
            <!-- TODO - implement help tooltip -->
            <!-- <mat-icon fontSet="material-icons-round">help</mat-icon> -->
          </div>
          <div
            *ngIf="newRequirement.rollingFlag"
            class="row rollover-container"
          >
            <span class="rollover kbody-medium"
              >Number of years to rollover:</span
            >
            <input
              [disabled]="data"
              [(ngModel)]="newRequirement.Requirement.rollingYears"
              class="kinput rollover-input"
              type="number"
              step="1"
              min="0"
              placeholder="1"
            />
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="error" class="error">{{ error }}</span>
    <div class="buttons" [ngClass]="{ 'new-mode': !data }">
      <div class="actions">
        <app-button
          *ngIf="data"
          [disabled]="counts.curYrCourses > 0 && counts.curYrUsers > 0"
          class="button right"
          [text]="newRequirement.activeFlag ? 'Deactivate' : 'Activate'"
          [class]="'grey'"
          (click)="toggleActivation()"
          [icon]="{ materialIcon: 'highlight_off' }"
        ></app-button>
        <div class="right-actions">
          <app-button
            class="button left"
            [text]="'Cancel'"
            [class]="'white'"
            (click)="closeDialog()"
          ></app-button>
          <app-button
            (click)="saveChanges()"
            class="button left"
            [text]="data ? 'Save Changes' : 'Create Requirement'"
          ></app-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
