import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: [ './verification.component.scss' ],
})
export class VerificationComponent {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeDialog = () => {
    this.dialogRef.close();
  }

  sendVerification = () => {
    this.dialogRef.close('verified');
  }

}
