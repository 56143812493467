<div
  class="master-row"
  *ngIf="
    (!hideTitle || !hideCreateNewButton || !hideEditButton || !hideFilter) &&
    tableData
  "
>
  <div class="titles" *ngIf="!hideTitle">
    <span *ngIf="showActiveItems" class="klabel-huge">{{
      tableData.tableTitle
    }}</span>
    <span *ngIf="!showActiveItems" class="klabel-huge"
      >Inactive {{ tableData.tableTitle }}</span
    >
  </div>

  <div
    class="filters"
    *ngIf="!hideCreateNewButton || !hideEditButton || !hideFilter"
  >
    <app-button
      *ngIf="!hideCreateNewButton"
      (click)="addNew()"
      class="button"
      [text]="
        '+ Add New ' +
        (tableData.buttonLabel
          ? tableData.buttonLabel
          : tableData.noTitleSlice
          ? tableData.tableTitle
          : tableData.tableTitle?.slice(0, -1))
      "
    ></app-button>

    <app-button
      (click)="toggleActive()"
      [icon]="{ materialIcon: 'visibility' }"
      *ngIf="!hideViewInactiveButton"
      text="{{ !showActiveItems ? 'Hide Inactive' : 'View Inactive' }}"
      [class]="'grey'"
    ></app-button>

    <div *ngIf="tableData?.allSchoolYears">
      <select
        [(ngModel)]="selected || tableData.allSchoolYears[0]"
        class="kbody-medium school-year"
      >
        <option
          *ngFor="let year of tableData.allSchoolYears"
          value="{{ year }}"
        >
          {{ year }}
        </option>
      </select>
    </div>
    <app-input
      *ngIf="!hideSearchBar"
      class="search"
      (updatedValue)="searchColumn($event)"
      [value]="searchText"
      [debounceTime]="300"
      placeholder="Search"
    ></app-input>
  </div>
</div>

<div class="table" *ngIf="tableData">
  <div class="title-row">
    <div
      class="titles"
      [ngClass]="{ 'space-between': tableData?.noLineButtons }"
    >
      <div
        (click)="sortColumnEvent(titleObject)"
        class="title-container"
        [ngClass]="
          (titleObject.class ? titleObject.class : '') +
          (tableData?.noLineButtons ? ' space-between' : '')
        "
        *ngFor="let titleObject of tableData?.columnTitles"
      >
        <mat-icon *ngIf="titleObject.icon" fontSet="material-icons-round">{{
          titleObject.icon
        }}</mat-icon>
        <!-- <mat-icon *ngIf="!titleObject.noCircle" class="circle">fiber_manual_record</mat-icon> -->
        <span class="title klabel-large">{{ titleObject.title }}</span>
        <mat-icon
          class="arrow"
          [ngClass]="{
            ascending:
              titleObject.title === sortOrder.title &&
              sortOrder.direction === 'ASC'
          }"
          *ngIf="!titleObject.notClickable"
          >arrow_upward</mat-icon
        >
      </div>
      <div style="width: 40px"></div>
    </div>
  </div>
  <div class="items tall" [ngClass]="{ tall: tableData.isStaffList }">
    <span
      class="error"
      *ngIf="!isLoading && (tableData?.error || tableData?.data?.length === 0)"
      >Sorry, no items found.</span
    >
    <div
      class="item"
      *ngFor="let item of tableData?.data"
      (click)="handleRowClick(item)"
    >
      <div
        class="details"
        [ngClass]="{ 'space-between': tableData.noLineButtons }"
      >
        <span
          *ngFor="let detail of item.itemData"
          [ngClass]="detail.class"
          [id]="detail.type"
        >
          <div
            *ngIf="detail.type === 'maincolumn'"
            [innerHtml]="detail.maincontent"
          ></div>
          <label *ngIf="detail.type === 'checkbox'" class="container">
            <input type="checkbox" disabled [checked]="detail.checked" />
            <span class="checkmark"></span>
          </label>
          <label *ngIf="detail.type === 'grouping'" class="chipcontainer">
            <ng-template
              [ngIf]="detail.single === 'GROUP_PARENT'"
              [ngIfElse]="single"
            >
              <app-chip
                class="group klabel-medium label"
                [text]="'GROUP'"
              ></app-chip>
            </ng-template>
            <ng-template #single>
              <app-chip
                class="single klabel-medium label"
                [text]="'SINGLE'"
              ></app-chip>
            </ng-template>
          </label>
          <label *ngIf="detail.type === 'status'" class="chipcontainer">
            <ng-template [ngIf]="detail.active === 1" [ngIfElse]="inactive">
              <app-chip
                class="active klabel-medium label"
                [text]="'ACTIVE'"
              ></app-chip>
            </ng-template>
            <ng-template #inactive>
              <app-chip
                class="inactive klabel-medium label"
                [text]="'INACTIVE'"
              ></app-chip>
            </ng-template>
          </label>
          <svg
            class="dot"
            *ngIf="detail.type === 'color'"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            [style]="{ fill: detail?.color }"
          >
            <circle cx="12px" cy="12px" r="12px" />
          </svg>
          {{ detail.content }}
        </span>
        <div
          *ngIf="tableData?.tableTitle !== 'Staff'"
          class="actions"
          id="meatball-container1"
        >
          <mat-icon
            class="icon"
            fontSet="material-icons-round"
            (click)="setMeatball(item, $event)"
            id="meatball1"
            [ngClass]="{ open: meatballMenu === item }"
            >more_horiz</mat-icon
          >
          <div class="pop-up" [ngClass]="{ open: meatballMenu === item }">
            <div
              class="btn kbody-large"
              (click)="showDetails(item, $event)"
              *ngIf="!hideShowDetailsButton"
            >
              <span>View {{ tableData?.tableTitle }} Details</span>
            </div>
            <div
              (click)="toggleEditModal(item, $event)"
              class="btn kbody-large"
              *ngIf="!hideEditButton && checkIfShowEdit(item)"
            >
              <span>Edit {{ tableData.tableTitle }}</span>
            </div>
            <div
              (click)="toggleRemoveItem(item, $event)"
              class="btn kbody-large"
              *ngIf="showRemoveItemButton"
            >
              <span>Remove {{ tableData?.tableTitle }}</span>
            </div>
            <div
              (click)="toggleApproveItem(item)"
              class="btn kbody-large"
              *ngIf="showApprovalButton"
            >
              <span>Approve {{ tableData?.tableTitle }}</span>
            </div>
            <div
              (click)="toggleDenyItem(item)"
              class="btn kbody-large"
              *ngIf="showDenialButton"
            >
              <span>Deny {{ tableData?.tableTitle }}</span>
            </div>
          </div>
        </div>
        <div
          *ngIf="tableData?.tableTitle === 'Staff'"
          class="actions"
          id="meatball-container2"
        >
          <mat-icon
            id="meatball2"
            class="icon"
            fontSet="material-icons-round"
            (click)="setMeatball(item, $event)"
            [ngClass]="{ open: meatballMenu === item }"
            >more_horiz</mat-icon
          >
          <div class="pop-up" [ngClass]="{ open: meatballMenu === item }">
            <div
              class="btn kbody-large"
              routerLink="/profile/{{ item.itemId }}"
            >
              <span>View {{ tableData.tableTitle }} Details</span>
            </div>
            <div
              (click)="toggleStaffEditModal(item, $event)"
              class="btn kbody-large"
              *ngIf="!hideEditButton"
            >
              <span>Edit {{ tableData.tableTitle }}</span>
            </div>
          </div>
        </div>

        <span *ngIf="!tableData" class="kbody-medium no-items"
          >Sorry, no items found</span
        >
      </div>
    </div>

    <span *ngIf="!tableData" class="kbody-medium no-items"
      >Sorry, no items found</span
    >
  </div>

  <div class="pagination">
    <div class="tabs" *ngIf="pager.pages && pager.pages.length">
      <div class="tabs numbers-arrows">
        <div class="tabs arrows">
          <app-button
            *ngIf="pager.totalPages > 1"
            (click)="setPage(1, 'first')"
            [icon]="{ materialIcon: 'first_page' }"
            [class]="'paginationButtonArrows'"
            [disabled]="pager.currentPage === 1"
            [disableBorder]="false"
          >
            ></app-button
          >
          <app-button
            *ngIf="pager.totalPages > 1"
            (click)="setPage(pager.currentPage - 1, 'prev')"
            [icon]="{ materialIcon: 'chevron_left' }"
            [class]="'paginationButtonArrows'"
            [disabled]="pager.currentPage === 1"
            [disableBorder]="false"
          ></app-button>
        </div>
        <div class="tabs numbers" *ngIf="pager.totalPages > 1">
          <app-button
            *ngFor="let page of pager.pages"
            [disableBorder]="false"
            [text]="'' + page"
            (click)="tab(page)"
            [class]="
              page === tableData?.curPage
                ? 'paginationButtonNumbersActive'
                : 'paginationButtonNumbers'
            "
          >
            {{ page }}
          </app-button>
        </div>
        <div class="tabs arrows">
          <app-button
            *ngIf="pager.totalPages > 1"
            [disabled]="pager.currentPage === pager.totalPages"
            (click)="setPage(pager.currentPage + 1, 'next')"
            [icon]="{ materialIcon: 'chevron_right' }"
            [class]="'paginationButtonArrows'"
            [disableBorder]="false"
          ></app-button>
          <app-button
            *ngIf="pager.totalPages > 1"
            [disabled]="pager.currentPage === pager.totalPages"
            (click)="setPage(pager.totalPages, 'last')"
            [icon]="{ materialIcon: 'last_page' }"
            [class]="'paginationButtonArrows'"
            [disableBorder]="false"
          ></app-button>
        </div>
      </div>
      <div class="tabs pager">
        <div class="dropdown">
          <app-button
            [text]="selectedItemsPerPage + ' Items per page'"
            (click)="toggleDropdown()"
            [icon]="{ materialIcon: 'expand_more' }"
            [class]="'paginationReverse'"
          ></app-button>
          <div class="dropdown-content" [class.show]="isDropdownOpen">
            <a (click)="selectItemsPerPage(25)">25</a>
            <a (click)="selectItemsPerPage(50)">50</a>
            <a (click)="selectItemsPerPage(100)">100</a>
            <a (click)="selectItemsPerPage(500)">500</a>
          </div>
        </div>

        <div class="tabs counter">
          <div class="itemcount">Total: {{ tableData?.totalItems }} items</div>
        </div>
      </div>
    </div>
  </div>
</div>
