<div class="course-requests">
  <div class="container">
    <div class="tab-row">
      <span class="tab" [ngClass]="{'selected': selectedTab === 'Pending'}" (click)="setSelectedTab('Pending')" >Pending</span>
      <span class="tab" [ngClass]="{'selected': selectedTab === 'Approved'}" (click)="setSelectedTab('Approved')" >Approved</span>
      <span class="tab" [ngClass]="{'selected': selectedTab === 'Denied'}" (click)="setSelectedTab('Denied')" >Denied</span>
    </div>
    <div class="title-row">
      <span class="ktitle-large">{{selectedTab}} Course Requests</span>
      <app-input value="" placeholder="Search" [icon]="'search'" [type]="'text'"></app-input>
    </div>
    <div class="table">
      <div class="titles-row">
        <span class="klabel-medium label" *ngFor="let title of tableTitles">{{title.name}}</span>
      </div>
      <div class="items">
        <div class="item" *ngFor="let course of courseList">
          <span (click)="openCourseRequestResponse(course)" class="kbody-medium detail name">{{course.courseName}}</span>
          <span class="kbody-medium detail">{{course.hours}}</span>
          <span class="kbody-medium detail">{{course.date}}, {{course.time}}</span>
          <span class="kbody-medium detail">{{course.submittedOn}}</span>
          <span class="kbody-medium detail">{{course.submittedBy}}</span>
        </div>
      </div>
    </div> 
  </div>
</div>