export const CHALLENGE_TYPES = {
  DEFAULT: 'NONE',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  PasswordResetRequiredException: 'PasswordResetRequiredException',
  NotAuthorizedException: 'NotAuthorizedException',
  UserNotFoundException: 'UserNotFoundException',
}

export function getChallengeType(authObj: any) {
  const challengeName = authObj?.challengeName as keyof typeof CHALLENGE_TYPES;
  const challengeType = CHALLENGE_TYPES?.[challengeName];

  if (challengeType) return challengeType;

  if (!challengeName) {
    return CHALLENGE_TYPES.DEFAULT;
  } else if (challengeName && !challengeType) {
    throw new Error(`Challenge type ${challengeName} not supported - please update the authHandler function`);
  }

  throw new Error('No challenge type found');
}
