import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-licenses-and-certs',
  templateUrl: './licenses-and-certs.component.html',
  styleUrls: [ './licenses-and-certs.component.scss' ],
})
export class LicensesAndCertsComponent implements OnInit {
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() editedUser: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }
  @Input() isAdminUser: boolean;

  localEditedUser: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.localEditedUser = this.editedUser;
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  updateUser = () => {
    this.updateUserObject.emit(this.localEditedUser);
    this.step.modified = true;
  }

  saveUser = () => {
    console.log('save user', this.localEditedUser);
  }

}
