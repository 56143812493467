<div class="course-types-component">
  <app-table
    (sortColumn)="sortColumn($event)"
    (filterColumn)="getCourseTypesFilteredByName($event)"
    (toggleActiveList)="toggleActiveList($event)"
    (showItemDetails)="showItemDetails($event)"
    (showEditModal)="showEditModal($event)"
    (itemsPerPageChange)="onItemsPerPageChange($event)"
    (pageChanged)="onPageChanged($event)"
    (addNewItem)="addNew()"
    [tableData]="tableData"
    [isLoading]="loading"
  >
  </app-table>
  <app-loading-spinner class="spinner" *ngIf="loading" />
</div>
