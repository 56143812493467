import { Component } from '@angular/core';
import { UpdateMyCourseRequestComponent } from './components/update-my-course-request/update-my-course-request.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-my-course-requests',
  templateUrl: './my-course-requests.component.html',
  styleUrls: [ './my-course-requests.component.scss' ],
})
export class MyCourseRequestsComponent {
  tableTitles = [
    { name: 'Course Name' },
    { name: 'Date & Time' },
    { name: 'Submitted' },
    { name: 'Request Status' },
  ];

  courseListEntries = [
    {
      courseName: 'Math Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Science Course',
      requestStatus: 'accepted',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'English Course',
      requestStatus: 'denied',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Math Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Science Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'English Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Math Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'Science Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
    {
      courseName: 'English Course',
      requestStatus: 'pending',
      submissionDate: '01/01/2020',
      id: '123456',
      location: 'Grosse Pointe',
      date: '01/01/2020',
      time: '3:00-4:00',
      members: '10',
      users: [
        {
          fullName: 'John Doe', hours: '4', status: 'registered', email: 'jdoe@email.com',
        },
        {
          fullName: 'Sandy Man', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Gary Fella', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Halla Beck', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Isa May', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Haya Doin', hours: '3', status: 'registered', email: 'sman@email.com',
        },
        {
          fullName: 'Yanna Hendricks', hours: '3', status: 'registered', email: 'sman@email.com',
        },
      ],
    },
  ];

  courseList = [ ...this.courseListEntries, ...this.courseListEntries ]


  constructor(private dialog: MatDialog) { }

  toggleUpdateModal = () => {
    this.dialog.open(UpdateMyCourseRequestComponent);
  }

}
