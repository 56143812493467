<div class="update-component">
  <div class="title-row">
    <div class="row">
      <mat-icon>category</mat-icon>
      <span class="ktitle-medium">{{
        data ? "Edit Course Type" : "Add New Course Type"
      }}</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeDialog()"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <div class="detail">
      <span class="klabel-medium label">Name of Course Type*</span>
      <input
        [disabled]="!this.newCourseType.activeFlag && data"
        *ngIf="!data"
        class="kinput input"
        type="text"
        placeholder="Enter name of course type"
        [(ngModel)]="this.newCourseType.courseType"
      />
      <span *ngIf="data">{{ newCourseType.courseType }}</span>
    </div>
    <div class="detail">
      <span class="klabel-medium label">Additional Details</span>
      <div class="check-row">
        <input
          [disabled]="!this.newCourseType.activeFlag && data"
          type="checkbox"
          [(ngModel)]="this.newCourseType.showOnSpecialRequestFlag"
        />
        <span>Show on special requests</span>
        <!-- TODO - implement help tooltip -->
        <!-- <mat-icon>help</mat-icon> -->
      </div>
      <div class="check-row">
        <input
          [disabled]="!this.newCourseType.activeFlag && data"
          type="checkbox"
          [(ngModel)]="this.newCourseType.noPdCreditFlag"
        />
        <span>Personal - NO PD Credit</span>
        <!-- TODO - implement help tooltip -->
        <!-- <mat-icon>help</mat-icon> -->
      </div>
    </div>
  </div>
  <div class="buttons" [ngClass]="{ end: !data }">
    <app-button
      (click)="toggleActivation()"
      *ngIf="data"
      [icon]="{ type: 'people' }"
      [class]="'grey'"
      [text]="this.newCourseType.activeFlag === 1 ? 'Deactivate' : 'Activate'"
    ></app-button>
    <div class="actions">
      <app-button text="Cancel" [class]="'white'" (click)="closeDialog()"
        >Cancel</app-button
      >
      <app-button
        (click)="updateCourseType()"
        text="{{ data ? 'Save Changes' : 'Add Course Type' }}"
        [icon]="{ type: 'success' }"
      ></app-button>
    </div>
  </div>
  <span class="error" *ngIf="!data && error">{{ error }}</span>
</div>
