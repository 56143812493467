import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { PasswordChangeComponent } from 'src/app/components/password-change/password-change.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: [ './change-password-modal.component.scss' ],
})
export class ChangePasswordModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PasswordChangeComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  isLoading = true;

  async changePassword(form: FormGroup) {
    this.isLoading = true;

    try {
      const userInfo = await this.authService.getCurrentUser();

      const user = userInfo.username;
      const oldPassword = form.value['old-password'];
      const newPassword = form.value['password'];

      console.log({
        user, oldPassword, newPassword,
      });

      const res = await this.authService.handleChangePassword(
        user, oldPassword, newPassword,
      );

      if (res) {
        this.dialogRef.close();
      }
    } catch (err) {
      console.error(err);
    }
  }


  closeModal = () => {
    this.dialogRef.close();
  }
}
