<div class="catalog-container">
  <span class="ktitle-huge title">Course Catalog</span>
  <div class="options-row">
    <div class="options">
      <app-select
        [options]="formattedYears"
        [placeholder]="'Select Year'"
        color="white"
        (selectedValue)="updateYearFilter($event)"
      ></app-select>
      <app-input
        placeholder="Search courses"
        name="courseNameFilter"
        icon="search"
        color="white"
        (updatedValue)="searchCourses($event)"
      ></app-input>
      <app-button
        [icon]="{ materialIcon: 'filter_list' }"
        [text]="'Advanced Filters'"
        class="grey"
        (click)="openFiltersModal()"
      ></app-button>
    </div>
    <app-button
      *ngIf="permissions.COURSE_ADD"
      [icon]="{ materialIcon: 'add' }"
      [text]="'Add New Course'"
      (click)="popAddNewModal()"
    ></app-button>
  </div>
  <span class="klabel-tiny count"
    >Showing {{ totalCount > 25 ? "25 of " : null
    }}{{ totalCount }} Courses</span
  >
  <div class="table">
    <div class="row column-titles">
      <span
        (click)="item.noSortFlag ? null : sortTable(item)"
        *ngFor="let item of table?.columnTitles"
        [ngClass]="item.class"
        class="klabel-medium label"
        >{{ item.title }}
        <mat-icon
          *ngIf="!item.noSortFlag"
          class="sort-icon"
          fontSet="material-icons-round"
          >unfold_more</mat-icon
        ></span
      >
    </div>
    <div class="items">
      <ng-container *ngIf="this?.error !== null || courseList?.length === 0">
        <span
          class="error"
          *ngIf="!error?.message?.includes('Course item(s) not found')"
        >
          {{ error?.message }}
        </span>

        <span
          class="error"
          *ngIf="
            error?.message?.includes('Course item(s) not found') ||
            courseList?.length === 0 ||
            totalCount === 0
          "
        >
          No Courses Found with the current filters
        </span>
      </ng-container>
      <div
        *ngFor="let item of table?.data"
        (click)="openCourseDetailsModal($event, item)"
        [ngClass]="{
          row: true,
          item: true,
          userRegistered: checkIfRegistered(item.courseItemId)
        }"
      >
        <span class="detail wide kbody-small">{{ item.courseName }}</span>
        <span class="detail skinny kbody-small">{{ item.courseItemId }}</span>
        <span class="detail kbody-small">{{
          item.location === "undefined" ? "-" : item?.location
        }}</span>
        <span class="detail skinny kbody-small">{{ item.date }}</span>
        <span class="detail kbody-small"
          >{{ item.sessionStartTime | lowercase }} -
          {{ item.sessionEndTime | lowercase }}</span
        >
        <span class="detail skinny kbody-small">{{ item.hours }}</span>
        <div class="dot-container" *ngIf="item?.tagAgg?.length > 0">
          <svg
            *ngFor="let tag of item.tags.slice(0, 3)"
            class="dot"
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
            [style]="{ fill: tag[1] }"
          >
            <circle cx="5px" cy="5px" r="5px" />
          </svg>
          <p class="kbody-small sum" *ngIf="item.tags.length > 3">
            +{{ item.tags.length - 3 }}
          </p>
        </div>
        <span
          class="kbody-small"
          *ngIf="item?.tagAgg?.length === 0 || !item?.tagAgg?.length"
          >no tags</span
        >
        <span class="kbody-small skinny">{{ item.enrolement }}</span>
        <div class="actions">
          <mat-icon
            class="icon burger"
            fontSet="material-icons-round"
            (click)="setMeatball($event, item)"
            [ngClass]="{ open: meatballMenu === item }"
            >more_horiz</mat-icon
          >
          <div class="pop-up" [ngClass]="{ open: meatballMenu === item }">
            <div
              *ngIf="item.canRegister"
              class="btn"
              (click)="openRegistrationModal($event, item)"
            >
              <span>Register</span>
            </div>
            <div
              *ngIf="item.canWaitList"
              class="btn"
              (click)="openWaitlistModal($event, item)"
            >
              <span>Join Waitlist</span>
            </div>
            <div class="btn" (click)="openCourseDetailsModal($event, item)">
              <span>View Details</span>
            </div>
            <div
              *ngIf="permissions.COURSE_EDIT"
              (click)="openUpdateModal($event, item)"
              class="btn"
            >
              <span>Edit Course</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-loading-spinner
      *ngIf="isLoading"
      class="spinner"
    ></app-loading-spinner>
  </div>
</div>
