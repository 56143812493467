import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-manage-courses',
  templateUrl: './manage-courses.component.html',
  styleUrls: [ './manage-courses.component.scss' ],
})
export class ManageCoursesComponent implements OnInit, OnDestroy {
  cards = [
    {
      title: 'Course Catalog', icon: 'list', link: '/manage-courses/course-catalog',
    },
    {
      title: 'Validation', icon: 'checklist', link: '/manage-courses/validation',
    },
    // {
    //   title: 'Course Calendar', icon: 'calendar_month', link: '',
    // },
    // {
    //   title: 'Course Requests', icon: 'post_add', link: '/manage-courses/course-requests',
    // },
    // {
    //   title: 'Catalog Suggestions', icon: 'playlist_add_check', link: '',
    // },
    // {
    //   title: 'State Documenation Export', icon: 'ios_share', link: '',
    // },
  ]
  permissions: any;
  permissionsSubscription: any;

  constructor( private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
      if(!this.permissions?.COURSE_SUGGESTIONS) {
        this.cards = this.cards.filter((card) => card.title !== 'Catalog Suggestions');
      }
      // if the permission for validating courses is set to false, remove the validation card
      if(this.permissions?.VALIDATE_ATTENDANCE === 0) {
        this.cards = this.cards.filter((card) => card.title !== 'Validation')
      }
      if(!this.permissions?.COURSE_REQUESTS) {
        this.cards = this.cards.filter((card) => card.title !== 'Course Requests');
      }
    });

    if (this.cards.length === 1) {
      this.router.navigate([ this.cards[0].link ]);
    }
  }

  ngOnDestroy(): void {
    this.permissionsSubscription.unsubscribe();
  }

}
