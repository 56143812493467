<div class="modal" *ngIf="selectedStep">
  <div class="title-row">
    <div>
      <mat-icon fontSet="material-icons-round">view_list</mat-icon>
      <span class="ktitle-medium">{{ data ? "Edit" : "Add New" }} Staff</span>
    </div>
    <mat-icon
      class="close-icon"
      (click)="closeDialog()"
      fontSet="material-icons-round"
      >close</mat-icon
    >
  </div>
  <div *ngIf="!isLoading && !!selectedStep">
    <app-stepper
      [steps]="steps"
      [selectedStep]="selectedStep"
      (selectedStepChange)="changeStep($event)"
      [isEditMode]="isEditMode"
    ></app-stepper>
    <app-user-general-info
      [roles]="roles"
      (updateUserObject)="updateUser($event)"
      [isEditMode]="isEditMode"
      [permissions]="permissions"
      [editedUser]="editedUser"
      [step]="generalStep"
      [isAdminUser]="isAdminUser"
      *ngIf="selectedStep.text === 'General Info'"
    ></app-user-general-info>
    <app-buildings-and-positions
      [isEditMode]="isEditMode"
      [permissions]="permissions"
      [step]="buildingsAndPositionsStep"
      [buildings]="buildings"
      [positions]="positions"
      [editedUser]="editedUser"
      (updateUserObject)="updateUser($event)"
      (stepBack)="stepBack()"
      *ngIf="selectedStep.text === 'Buildings & positions'"
    ></app-buildings-and-positions>
    <app-user-assignments
      [step]="userAssignmentsStep"
      [isEditMode]="isEditMode"
      [secondaryApprovers]="secondaryApprovers"
      [primaryApprovers]="primaryApprovers"
      [editedUser]="editedUser"
      (updateUserObject)="updateUser($event)"
      (stepBack)="stepBack()"
      *ngIf="selectedStep.text === 'User assignments'"
    ></app-user-assignments>
    <app-user-requirements
      [step]="requirementsStep"
      [isEditMode]="isEditMode"
      [permissions]="permissions"
      [requirements]="requirements"
      [editedUser]="editedUser"
      (updateUserObject)="updateUser($event)"
      (stepBack)="stepBack()"
      *ngIf="selectedStep.text === 'Requirements'"
    ></app-user-requirements>
    <app-licenses-and-certs
      [step]="licensesAndCertsStep"
      [editedUser]="editedUser"
      (updateUserObject)="updateUser($event)"
      (stepBack)="stepBack()"
      [isAdminUser]="isAdminUser"
      *ngIf="selectedStep.text === 'Licenses & certs'"
    ></app-licenses-and-certs>
    <app-review-and-finish
      [originalUser]="originalUser"
      [isEditMode]="isEditMode"
      [editedUser]="editedUser"
      (updateUserObject)="updateUser($event)"
      (stepBack)="stepBack()"
      *ngIf="selectedStep.text === 'Review & finish'"
    ></app-review-and-finish>
  </div>
  <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
</div>
