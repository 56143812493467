<div class="course-requests-widget">
  <div class="row title-row">
    <span class="klabel-medium shorty title">Course Name</span>
    <div class="right">
      <span class="klabel-medium title">Submitted on</span>
      <span class="klabel-medium title">Submitted by</span>
    </div>
  </div>
  <div class="courses">
    <div class="row course" *ngFor="let course of courses">
      <span class="detail shorty ksubtitle-tiny">{{course.name}}</span>
      <div class="right">
        <span class="detail ksubtitle-tiny">{{course.date}}</span>
        <span class="detail ksubtitle-tiny">{{course.author}}</span>
      </div>
    </div>
  </div>
  <app-button class="btn" [text]="'Go to Validation'" [class]="'grey'"></app-button>
</div>