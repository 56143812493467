import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-assignments',
  templateUrl: './user-assignments.component.html',
  styleUrls: [ './user-assignments.component.scss' ],
})
export class UserAssignmentsComponent implements OnInit {
  @ViewChild('administratorinput') administratorinput: any;
  @ViewChild('supervisorinput') supervisorinput: any;
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() isEditMode: boolean;
  @Input() editedUser: any;
  @Input() secondaryApprovers: any;
  @Input() primaryApprovers: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }

  localEditedUser: any;
  administrators: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.localEditedUser = this.editedUser;
  }

  updateLocalUserParameter = (event: any, parameter: string) => {
    let selectedValue;
    if(parameter === 'primaryApprovers') {
      selectedValue = this.primaryApprovers.find((item: any) => item.userId === event.target.value);
    }
    if(parameter === 'secondaryApprovers') {
      selectedValue = this.secondaryApprovers.find((item: any) => item.userId === event.target.value);
    }
    if(this.localEditedUser[parameter]?.includes(selectedValue)) { return }
    if(!selectedValue) { return }
    this.localEditedUser[parameter] = [ ...this.localEditedUser[parameter], selectedValue ];
    //this resets the input value to blank
    this.administratorinput.nativeElement.value = '';
    this.supervisorinput.nativeElement.value = '';

    this.step.modified = true;
  }

  removePrimaryApprover = (primaryApprover: any) => {
    this.localEditedUser.primaryApprovers = this.localEditedUser.primaryApprovers.filter((item: any) => item !== primaryApprover);
  }

  removeSecondaryApprover = (secondaryApprover: any) => {
    this.localEditedUser.secondaryApprovers = this.localEditedUser.secondaryApprovers.filter((approver: any) => approver !== secondaryApprover);
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  updateUser = () => {
    if (this.localEditedUser?.primaryApprovers.length === 0 || this.localEditedUser?.secondaryApprovers.length === 0) {
      return
    }
    this.updateUserObject.emit(this.localEditedUser);
  }

}
