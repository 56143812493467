<div class="registration-container">
  <span class="klabel-huge">Registration Message</span>
  <div class="row">
    <input
      type="checkbox"
      [checked]="regMessageChecked === 1"
      (change)="updateRegistrationMessage($event, 'regMessageFlag')"
    />
    <span class="kbody-medium">Enable registration message</span>
  </div>
  <div class="section">
    <span class="klabel-medium label"
      >Message displayed after every successful registration:</span
    >
    <textarea
      class="kinput input"
      type="text"
      rows="5"
      placeholder="Enter your message here"
      [value]="
        defaults.registrationMessage || defaults.Config?.registrationMessage
      "
      (keyup)="updateRegistrationMessage($event, 'registrationMessage')"
    ></textarea>
  </div>
</div>
