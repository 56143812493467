<form
  #resetPasswordForm="ngForm"
  class="form form-group"
  id="resetPasswordForm"
  (ngSubmit)="submitChangePassword(resetPasswordForm)"
>
  <!-- NOTE: this line is needed for accessibility reasons -->
  <input type="text" id="email" name="email" hidden autocomplete="username" />

  <div *ngIf="isPasswordChange" class="input-container current">
    <label for="old-password">Current Password*</label>
    <input
      id="old-password"
      name="old-password"
      type="password"
      class="text-input password-input"
      placeholder="Enter your current password"
      #oldPassword="ngModel"
      autocomplete="current-password"
      ngModel
      required
      (keyup)="checkPasswordStrength(resetPasswordForm)"
      (change)="checkPasswordStrength(resetPasswordForm)"
      [ngClass]="{
        inputError:
          oldPassword.invalid && oldPassword.errors && oldPassword.touched
      }"
    />
  </div>

  <div class="input-container">
    <label for="password">Create new password*</label>
    <input
      id="password"
      name="password"
      type="password"
      class="text-input password-input"
      minlength="8"
      placeholder="Set New Password"
      #password="ngModel"
      autocomplete="new-password"
      ngModel
      required
      (keyup)="checkPasswordStrength(resetPasswordForm)"
      (change)="checkPasswordStrength(resetPasswordForm)"
      [ngClass]="{
        inputError: password.invalid && password.errors && password.touched
      }"
    />
    <div
      class="password-symbols"
      *ngIf="password.value?.length > 0 && !passVal.topTrue"
    >
      <div
        class="passCheck"
        [ngClass]="{
          success: passVal.isAtLeast8Chars
        }"
      >
        {{ passVal.isAtLeast8Chars ? checkMark : xMark }} Is at least 8
        characters
      </div>
      <div
        class="passCheck"
        [ngClass]="{
          success: passVal.hasUpperCase
        }"
      >
        {{ passVal.hasUpperCase ? checkMark : xMark }} Includes uppercase letter
      </div>
      <div
        class="passCheck"
        [ngClass]="{
          success: passVal.hasNumber
        }"
      >
        {{ passVal.hasNumber ? checkMark : xMark }} Includes number
      </div>
      <div
        class="passCheck"
        [ngClass]="{
          success: passVal.hasSpecialChar
        }"
      >
        {{ passVal.hasSpecialChar ? checkMark : xMark }} Includes special
        character character
      </div>
    </div>
  </div>

  <div class="input-container bottom">
    <label for="password-confirm" class="password-confirm-input"
      >Confirm new password*</label
    >
    <input
      name="password-confirm"
      type="password"
      id="password-confirm"
      class="text-input"
      minlength="8"
      placeholder="Confirm New Password"
      #passwordConfirmation="ngModel"
      autocomplete="new-password"
      ngModel
      required
      (change)="checkPasswordStrength(resetPasswordForm)"
      (keyup)="checkPasswordStrength(resetPasswordForm)"
      [ngClass]="{
        inputError:
          passwordConfirmation.invalid &&
          passwordConfirmation.errors &&
          passwordConfirmation.touched
      }"
    />
    <div
      class="password-symbols"
      *ngIf="passwordConfirmation.value?.length > 0 && !passVal.allTrue"
    >
      <div
        class="passCheck"
        [ngClass]="{
          success: passVal.passMatch
        }"
      >
        {{ passVal.passMatch ? checkMark : xMark }}
        {{ passVal.passMatch ? "Passwords match" : "Passwords do not match" }}
      </div>
    </div>
  </div>
  <span class="klabel-small error" *ngIf="!!error$">{{ error$ }}</span>

  <div class="btn-container">
    <button
      type="submit"
      class="submit-btn"
      [ngClass]="{ disabled: !passVal.allTrue }"
      [disabled]="!resetPasswordForm.valid"
    >
      Save Password and Continue to Log in
    </button>
  </div>
</form>
