import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code-modal.component.html',
  styleUrls: [ '../../sign-in.component.scss', './send-code-modal.component.scss' ],
})
export class SendCodeModalComponent {
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<SendCodeModalComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
    private toast: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeModal = () => {
    this.dialogRef.close();
  }

  sendNewCodeRequest = async ($event: Event, { form }: NgForm) => {
    $event.preventDefault();

    const email = form.value.email;
    this.isLoading = true;

    try {
      const res = await this.authService.sendNewCodeToUser(email);

      if (res) {
        this.closeModal();
      }
    } catch (err) {
      console.log('🚀 - err:', err);

      form.reset();
    }

    this.isLoading = false;
  }
}
