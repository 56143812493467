<div class="validation">
  <span class="ktitle-medium v-title">Validation</span>
  <div class="container">
    <div class="tab-row">
      <div
        class="tab"
        [ngClass]="{ selected: selectedTab === 'Need Validation' }"
        (click)="setSelectedTab('Need Validation')"
      >
        <mat-icon class="icon" fontSet="material-icons-round">warning</mat-icon>
        <span class="klabel-large">Need Validation</span>
      </div>
      <div
        class="tab"
        [ngClass]="{ selected: selectedTab === 'Previously Validated' }"
        (click)="setSelectedTab('Previously Validated')"
      >
        <mat-icon class="icon" fontSet="material-icons-round"
          >file_download_done</mat-icon
        >
        <span class="klabel-large">Previously Validated</span>
      </div>
    </div>
    <div class="title-row">
      <span class="klabel-huge">{{
        selectedTab === "Need Validation"
          ? "Courses that need validation"
          : "Previously validated courses"
      }}</span>
      <div class="actions">
        <!-- TODO - cleanup - put back in when this functionality exists -->
        <!-- <div class="download">
          <mat-icon class="icon" fontSet="material-icons-round">download</mat-icon>
          <span class="klabel-large">Download Sign-in Sheet</span>
        </div> -->
        <app-input
          (updatedValue)="search($event)"
          value="{{ this.stringFilter }}"
          placeholder="Search"
          [icon]="'search'"
          [type]="'text'"
        ></app-input>
      </div>
    </div>
    <div class="table">
      <div class="titles-row">
        <div
          (click)="sortColumn(title)"
          *ngFor="let title of tableTitles"
          class="title-container"
          [ngClass]="title.class"
        >
          <span class="klabel-medium">{{ title.name }}</span>
          <mat-icon class="icon" fontSet="material-icons-round"
            >unfold_more</mat-icon
          >
        </div>
      </div>
      <div class="items">
        <div class="item" *ngFor="let course of courseList">
          <div (click)="openCourseDetails(course)" class="detail">
            <span class="kbody-medium name">{{ course?.courseName }}</span>
          </div>
          <div (click)="openCourseValidationDetails(course)" class="detail">
            <span class="kbody-medium">{{ course?.gcRegisteredCount }}</span>
          </div>
          <div (click)="openCourseValidationDetails(course)" class="detail">
            <span class="kbody-medium">{{ course?.location }}</span>
          </div>
          <div (click)="openCourseValidationDetails(course)" class="detail">
            <span class="kbody-medium">{{ course?.formattedDate }}</span>
          </div>
          <div (click)="openCourseValidationDetails(course)" class="detail">
            <span class="kbody-medium"
              >{{ course?.sessionStartTime | lowercase }} -
              {{ course?.sessionEndTime | lowercase }}</span
            >
          </div>
          <div
            (click)="openCourseValidationDetails(course)"
            class="detail right"
          >
            <span class="kbody-medium">{{ course?.courseItemId }}</span>
          </div>
        </div>
      </div>
    </div>
    <app-loading-spinner
      class="spinner"
      *ngIf="isLoading"
    ></app-loading-spinner>
    <span class="error" *ngIf="error">{{ error?.message }}</span>
  </div>
</div>
