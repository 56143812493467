import {
  Component, OnInit, OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { UpdateCategoryComponent } from './components/update-category/update-category.component';
import { CategoryDetailsComponent } from './components/category-details/category-details.component';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: [ './categories.component.scss' ],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  categoriesDataSubscription = new Subscription();
  columnTitles = [ 'Category Name' ];
  _categoriesData: any;
  categoriesData$: Observable<any>;
  tableData: any;
  loading = false;
  selectedItemsPerPage = 25;
  isActive = false;

  searchText = '';
  sortColumnName = 'category';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;

  // TODO: update this to the categories service once available
  constructor(private categoriesService: CategoriesService, public dialog: MatDialog) {  }

  ngOnInit(): void {
    this.loading = true;
    this.tableData = {
      tableTitle: 'Categories',
      buttonLabel: 'Category',
      columnTitles: [ { title:'Category Name', sortBy: 'category' } ],
      data: [],
    }
    this.searchForCategories();
    this.categoriesDataSubscription = this.categoriesService.categoriesData$.subscribe(data => {
      this._categoriesData = data;
      const formattedTableData = data?.rows?.map((category: any) => {
        return {
          itemId: category.categoryId,
          itemData: [ { content: category.category, class: 'klabel-large' } ],
        }
      })
      this.tableData = {
        tableTitle: 'Categories',
        buttonLabel: 'Category',
        columnTitles: this.tableData.columnTitles,
        activeTotal: data?.pagination?.totalItems,
        inactiveTotal: data?.counts?.inactive,
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination?.curPage,
        totalPages: data?.pagination?.totalPages,
        data: formattedTableData,
        error: data?.error || null,
      }
      this.loading = data ? false : true;
    })

  }

  searchForCategories() {
    this.loading = true;
    this.tableData.data = []
    const params = {
      filterString: this.searchText,
      activeFlag: this.isActive ? 0 : 1 as 0 | 1,
      sortColumn: this.sortColumnName,
      sortDirection: this.sortDirection,
      limit: this.selectedItemsPerPage,
      offset: this.offset,
    }

    this.categoriesService.getCategories(params);
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForCategories();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForCategories();
  }

  paginationEvent = (pageDirection: string) => {
    this.loading = true;
    this.categoriesService.getPagination(pageDirection);
    this.loading = false;
  }

  getCategoriesFilteredByName = (searchItem: any) => {
    this.searchText = searchItem?.searchText;
    this.offset = 0;
    this.searchForCategories();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;

    const currentPage = this._categoriesData?.pagination?.curPage || 1;
    const offset = this.calculateOffset(currentPage);
    this.offset = offset;

    this.searchForCategories();
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.offset = 0;
    this.searchForCategories();
  }

  addNewCategory = () => {
    const categoryDialogRef = this.dialog.open(UpdateCategoryComponent);

    categoryDialogRef.afterClosed().subscribe(result => {
      if (result?.statusCode === 1000 || result === 'reload') {
        this.loading = true;
        this.categoriesService.addNewCategory(result);
      }
    });
  }

  showItemDetails = (itemId: any) => {
    const categoryData = this._categoriesData?.rows?.find((category: any) => {
      return category?.categoryId === itemId;
    });
    const dialogRef = this.dialog.open(CategoryDetailsComponent, { data: categoryData })

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000 || result === 'reload') {
        this.searchForCategories();
      }
    });
  }

  showEditModal = (itemId: any) => {
    const categoryData = this._categoriesData?.rows?.find((category: any) => {
      return category?.categoryId === itemId;
    });
    const dialogRef = this.dialog.open(UpdateCategoryComponent, { data: categoryData });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000 || result === 'reload') {
        this.searchForCategories();
      }
    });
  }

  ngOnDestroy(): void {
    this.categoriesDataSubscription.unsubscribe();
  }
}
