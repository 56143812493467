<div class="container">
  <app-table
    (addNewItem)="addNewStaff()"
    (sortColumn)="sortColumn($event)"
    (filterColumn)="getStaffFilteredByName($event)"
    (toggleActiveList)="toggleActiveList($event)"
    (showStaffEditModal)="showStaffEditModal($event)"
    [hideCreateNewButton]="!permissions.USER_ADD ? true : false"
    [hideEditButton]="!permissions.USER_EDIT ? true : false"
    (paginationEvent)="paginationEvent($event)"
    (itemsPerPageChange)="onItemsPerPageChange($event)"
    (pageChanged)="onPageChanged($event)"
    (rowClicked)="onRowClicked($event)"
    [tableData]="tableData"
    [isLoading]="loading"
  >
  </app-table>
  <app-loading-spinner class="spinner" *ngIf="loading"></app-loading-spinner>
</div>
