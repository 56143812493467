import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-my-pd-plan',
  templateUrl: './my-pd-plan.component.html',
  styleUrls: [ './my-pd-plan.component.scss' ],
})
export class MyPdPlanComponent {
  @Input() user: any;
  navTitles = [
    { text: 'My Courses', icon: 'list' },
    // { text: 'Tag Details', icon: 'label' },
    // { text: 'Requirement Summary', icon: 'summarize' },
    // { text: 'Survey Responses', icon: 'poll' },
  ]

  myCoursesTabs = [
    { text: 'Course List View', value: 'courses' },
    { text: 'Requirements View', value: 'requirements' },
  ]

  selectedTab = this.navTitles[0];
  selectedCoursesTab = this.myCoursesTabs[0].value;
  courses: any;

  constructor() { }

  changeTab = (tab: any) => {
    this.selectedTab = tab;
  }

  changeCoursesTab = (event: Event) => {
    const tab = event.target as HTMLSelectElement;
    this.selectedCoursesTab = tab.value;
  }
}
