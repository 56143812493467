import {
  Component, Inject, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-registration-waitlist-modal',
  templateUrl: './registration-waitlist-modal.component.html',
  styleUrls: [ './registration-waitlist-modal.component.scss' ],
})
export class RegistrationWaitlistModalComponent implements OnDestroy {
  registrationSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RegistrationWaitlistModalComponent>,
    public dialog: MatDialog,
    private coursesService: CoursesService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  registerForCourse = () => {
    const registrationData = {
      courseId: this.data.selectedCourse.courseId,
      courseItemId: this.data.selectedCourse.courseItemId,
    }
    this.registrationSubscription = this.coursesService.registerForCourse(registrationData).subscribe({
      next: (response: any) => {
        this.toastService.setToast({
          text: 'Added to waitlist', type: 'success', icon: true,
        })
        this.dialogRef.close('reload');
      },
      error: (error) => {
        console.log('error', error);
        const errorMessage = error.error.statusCode === 3102 ? 'You are already registered for this course' : error.error.message;
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong registering for the waitlist', text: errorMessage,
          },
        })
        this.toastService.setToast({
          text: 'Error adding to waitlist', type: 'error', icon: true,
        })
      },
    })

  }

  closeModal = () => {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.registrationSubscription?.unsubscribe();
  }

}

