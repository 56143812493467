import {
  Component, EventEmitter, Input, Output,
  SimpleChange,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [ '../../sign-in.component.scss', './forgot-password.component.scss' ],
})
export class ForgotPasswordComponent {
  @Output() backToSignIn = new EventEmitter<boolean>();
  @Input() debugMode: boolean;
  @Input() changePassDetails: { email: string; password: string; rememberMe: boolean; } | null;

  changeMode = false;
  rememberMeChecked = false;
  error$: Observable<any>;
  authSubscription: any;
  private errorSubscription: Subscription;

  showEmailForm = true;
  showConfirmationCodeForm = false;
  showResetPasswordForm = false;

  email = '';
  hiddenEmail = '';
  confirmationCodeLength = 6;
  confirmationCode = '';
  errorWithCodeInput = false;

  checkMark = '√';
  xMark = 'X';

  password = '';
  confirmPassword = '';
  passVal = {
    hasUpperCase: false,
    hasSpecialChar: false,
    isAtLeast8Chars: false,
    hasNumber: false,
    passMatch: false,
    topTrue: false,

    allTrue: false,
  }

  constructor(
    private authService: AuthService,
    private loadingSpinnerService: LoadingSpinnerService,
    private toast: ToastService,
  ) { }


  ngOnInit(): void {
    this.errorSubscription = this.authService.error$.subscribe((error: any) => {
      this.error$ = error
    })

    if (this.changePassDetails) {
      this.changeMode = true;
      const { email } = this.changePassDetails;

      this.submitConfirmationCodeRequest({ form: { value: { email } } } as NgForm);

    }
  }

  // #region COGNITO REQUESTS
  submitForgotPasswordRequest = async ({ form }: NgForm) => {
    try {
      this.loadingSpinnerService.setIsLoading(true);
      this.email = form.value.email;
      const res = await this.authService.handleForgotPasswordGetCode(this.email);

      this.hiddenEmail = res.CodeDeliveryDetails.Destination;

      if (res) {
        this.toggleShow('showConfirmationCodeForm');
        this.toast.setToast({
          text: 'Confirmation code sent to your email.',
          type: 'success',
          dismissible: true,
          icon: true,
        })
        this.loadingSpinnerService.setIsLoading(false);
      }
    } catch (error) {
      this.loadingSpinnerService.setIsLoading(false);
      this.toast.setToast({
        text: 'Error sending forgot password request.',
        type: 'error',
        dismissible: true,
        icon: 'error',
      });
      throw error;
    }
  }

  submitConfirmationCodeRequest = ({ form }: NgForm) => {
    if (form.valid) {
      this.toggleShow('showResetPasswordForm');
    }
  }

  changePassword() {
    if (!this.changePassDetails) throw new Error('No changePassDetails found.');

    const oldPassword = this.changePassDetails.password;
    const email = this.changePassDetails.email;
    const rememberMe = this.changePassDetails.rememberMe;
    const newPassword = this.password;

    console.log({
      oldPassword,
      newPassword,
      email,
      rememberMe,
    });
  }

  async setPassword(password: string) {
    if (this.changePassDetails) {
      return this.changePassword();
    }
    try {
      const code = this.confirmationCode;
      const email = this.email;

      const res = await this.authService.handleForgotPasswordCodeSubmit(
        email, password, code,
      );


      if (res) {
        this.toast.setToast({
          text: 'Password successfully set.',
          type: 'success',
          dismissible: true,
          icon: true,
        });
        this.loadingSpinnerService.setIsLoading(false);
        return this.backToSignIn.emit();
      }
      throw new Error('No response from setting password.')
    } catch (err) {
      this.toast.setToast({
        text: 'Error setting password.',
        type: 'error',
        dismissible: true,
        icon: 'error',
      });
      throw err;
    }
  }
  // #endregion

  // #region CODE CONFIRMATION INPUT
  parseCodeValues = (form: FormGroup) => {
    const allNumsArr = Object.keys(form.controls).filter(name => name.match('number-')).map((key) => {
      return form.controls[key];
    });

    const {
      allTouched, anyInvalid, anyErrors, allFilled,
    } = allNumsArr.reduce((acc, num) => {
      if (!num.touched) {
        acc.allTouched = false;
      }

      if (num.invalid) {
        acc.anyInvalid = true;
      }

      if (num.errors) {
        acc.anyErrors = true;
      }

      if (!num.value) {
        acc.allFilled = false;
      }
      return acc;
    }, {
      allTouched: true,
      anyInvalid: false,
      anyErrors: false,
      allFilled: true,
    });

    return {
      allTouched,
      anyInvalid,
      anyErrors,
      allFilled,
      curValue: allNumsArr.map((num) => num.value).join(''),
    };
  }

  checkCodeForm(form: FormGroup) {
    const {
      allFilled, allTouched, anyErrors, anyInvalid, curValue,
    } = this.parseCodeValues(form);

    this.confirmationCode = curValue;

    if (!allTouched) return false;

    if (anyErrors || anyInvalid) return true;

    return false;
  }

  handleCodeInput = ($event: KeyboardEvent) => {

    const curInput = $event.target as HTMLInputElement;
    const curInputId = curInput.id;
    const index = Number(curInputId.split('-')[1]);

    const prevIndex = index - 1 < 0 ? 0 : index - 1;
    const nextIndex = index + 1 > this.confirmationCodeLength - 1 ? this.confirmationCodeLength - 1 : index + 1;

    if ($event.code === 'Backspace') {
      curInput.value = '';
      //move to previous input
      if (index === prevIndex) {
        return;
      }
      const prevInput = document.querySelector(`#num-${prevIndex}`) as HTMLInputElement;
      prevInput.focus();
      return;
    }

    if (typeof Number($event.key) === 'number' && !isNaN(Number($event.key))) {
      //move to next input
      curInput.value = $event.key;

      if (index === nextIndex) {
        return;
      }
      const nextInput = document.querySelector(`#num-${nextIndex}`) as HTMLInputElement;
      nextInput.focus();
      return;
    }


  }

  onCodeKeyPress({ form }: NgForm, $event: KeyboardEvent) {
    $event.preventDefault();

    this.handleCodeInput($event);
    this.checkCodeForm(form);
  }
  onCodePaste({ form }: NgForm, $event: ClipboardEvent) {
    $event.preventDefault();
    if (!$event.clipboardData) return;

    const clipboardData = $event.clipboardData.getData('text');
    const clipboardArr = clipboardData.split('');

    const allNumsArr = Object.keys(form.controls).filter(name => name.match('number-')).map((key) => {
      return form.controls[key];
    });

    allNumsArr.forEach((num, index) => {
      num.setValue(clipboardArr[index]);
    });

    // forcus on last div
    const lastInput = document.querySelector(`#num-${this.confirmationCodeLength - 1}`) as HTMLInputElement;
    lastInput.focus();

    this.checkCodeForm(form);
  }
  // #endregion

  // #region TOGGLE SHOW
  toggleShow = (formToShow: 'showConfirmationCodeForm' | 'showResetPasswordForm' | 'showEmailForm') => {
    this.showEmailForm = false;
    this.showConfirmationCodeForm = false;
    this.showResetPasswordForm = false;
    this[formToShow] = true;
  }

  resetToBeginning = () => {
    this.toggleShow('showEmailForm');
  }


  // #endregion

  ngOnDestroy(): void {
    this?.errorSubscription?.unsubscribe();
  }
}
