<table class="table">
  <thead>
    <tr>
      <th
        *ngFor="let column of tableData.columnTitles"
        [ngClass]="{
          sortable: column.sortable,
          'sort-asc': column.sortable && column.sortDirection === 'ASC',
          'sort-desc': column.sortable && column.sortDirection === 'DESC'
        }"
        [ngStyle]="{
          width: column.width !== null ? column.width : '100%'
        }"
        (click)="sortClicked(column)"
      >
        <span class="header-container">
          {{ column.name }}
          <mat-icon
            class="arrow"
            *ngIf="column.sortable"
            [ngClass]="{
              active: column.sortDirection !== null,
              asc: column.sortDirection === 'ASC',
              desc: column.sortDirection === 'DESC'
            }"
            >arrow_upward</mat-icon
          >
        </span>
      </th>
      <th class="burger-col" *ngIf="tableData.hasBurgerMenu"></th>
    </tr>
  </thead>
  <tbody
    *ngIf="tableData.data && tableData.data.length > 0"
    [ngClass]="{ loading: isLoading }"
    [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
  >
    <tr *ngFor="let row of tableData.data" (click)="rowClicked(row.itemId)">
      <td
        *ngFor="let cell of row.columnData; let i = index"
        [ngStyle]="{
          width:
            tableData.columnTitles[i].width !== null
              ? tableData.columnTitles[i].width
              : 'auto'
        }"
      >
        <div *ngIf="cellIsUserInfo(cell)" class="user-info">
          <p>{{ cell.fullName }} - {{ i }}</p>
          not implemented
        </div>

        <div *ngIf="cellIsGeneralInfo(cell)" class="general">
          <p
            [ngClass]="{
              'main-text': true,
              'only-main': !cell.subText
            }"
          >
            {{ cell.mainText }}
          </p>
          <p class="sub-text" *ngIf="cell.subText">{{ cell.subText }}</p>
        </div>

        <div
          *ngIf="cellIsStatusInfo(cell)"
          [ngClass]="['status', cell.statusType, cell.statusKid]"
        >
          <p>
            {{ cell.statusDisplay.toLocaleUpperCase() }}
          </p>
        </div>

        <div *ngIf="cellIsSpecialInfo(cell)" class="special">
          <p>
            {{ cell.specialType }}
          </p>
          not implemented
        </div>
        <ng-template #unknown>Unknown data type</ng-template>
      </td>
      <td
        *ngIf="tableData.hasBurgerMenu && tableData.burgerContent"
        class="burger-cell"
        [ngStyle]="{
          minWidth: tableData.hamburgerMenuWidth || 'auto'
        }"
      >
        <div>
          <div class="burger-trigger" (click)="$event.stopPropagation()">
            <mat-icon mat-button [matMenuTriggerFor]="menu">
              more_horizontal
            </mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <div class="links-container">
              <div
                class="link-container"
                *ngFor="let item of tableData.burgerContent"
                (click)="burgerMenuClicked(item.eventName, row.itemId)"
              >
                <p class="burger-link">
                  {{ item.content }}
                </p>
              </div>
            </div>
          </mat-menu>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div *ngIf="tableData.data && tableData.data.length === 0 && !isLoading">
  <div class="no-data">
    <h2>{{ tableData.noDataFoundMessage || "No data available" }}</h2>
  </div>
</div>
<div class="loading-spinner" *ngIf="isLoading">
  <app-loading-spinner class="spinner" />
</div>
