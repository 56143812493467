import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { SwapComponent } from 'src/app/components/swap/swap.component';
import { ToastService } from 'src/app/services/toast.service';
import { Subscription } from 'rxjs';
import { CourseTypesService } from 'src/app/services/course-types.service';

@Component({
  selector: 'app-update-course-type',
  templateUrl: './update-course-type.component.html',
  styleUrls: [ './update-course-type.component.scss' ],
})
export class UpdateCourseTypeComponent implements OnInit, OnDestroy {
  newCourseType: any = {
    courseType: '', showOnSpecialRequestFlag: false, noPdCreditFlag: false, id: null,
  }
  error: string;
  addCourseTypeSubscription: Subscription;
  activationSubscription: Subscription;

  constructor(
    private courseTypesService: CourseTypesService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<UpdateCourseTypeComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if(this.data) {
      this.newCourseType = this.data?.orig || this.data;
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  toggleActivation = () => {
    const setActive = !this.newCourseType.activeFlag;

    this.activationSubscription = this.courseTypesService.toggleCourseTypeActivation(this.newCourseType, setActive).subscribe({
      next: (response: any) => {
        this.dialogRef.close(response);
        this.toastService.setToast({ text: setActive ? 'Course Type Activated' : 'Course Type Deactivated', type: 'success' })
      },
      error: (error) => {
        console.log('error', error);
      },
    })
    // this was built to pop when we get an error that there are staff assigned to a course type that is being deactivated. it is undecided whether or not that is actually an issue and the backend currently allows it
    // const dialogRef = this.dialog.open(VerificationComponent, {
    //   data: {
    //     type: 'alert', title: 'Replace Course Type', confirmButtonTitle: 'Replace Course Type', cancelButtonTitle: 'Cancel', text: 'This course type is assigned to courses in the current year catelog. You must choose a new course type to replace it.',
    //   },
    // })
    // dialogRef.afterClosed().subscribe(result => {
    //   if(result) {
    //     // this will be some real data in the future
    //     this.dialog.open(SwapComponent, {
    //       data: {
    //         title: 'Replace Course Type',
    //         label: 'Select a new course type',
    //         optionPlaceHolder: 'Select course type',
    //         options: [
    //           'option1', 'option2', 'option3',
    //         ],
    //       },
    //     })
    //   }
    // })
  }

  updateCourseType(): void {
    if(this.newCourseType.courseType === '') {
      this.error = 'Course type name is required';
      return;
    }
    const saveCourseType = () => {
      this.addCourseTypeSubscription = (this.data ? this.courseTypesService.updateCourseType(this.newCourseType) : this.courseTypesService.addCourseType(this.newCourseType)).subscribe({
        next: (response: any) => {
          this.dialogRef.close(response);

          this.toastService.setToast({ text: this.data ? 'Changes Saved' : 'Course Type successfully added', type: 'success' })
        },
        error: (error: any) => {
          console.log('error', error)
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert', title: `Oops, something went wrong creating ${this.newCourseType.requirement}`, text: error.error.message,
            },
          })
        },
      })
    }
    if(!this.data) {
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Verify Course Type',
          text: 'The following details cannot be changed when this course type is saved.',
          details: [ { title: 'Name of course type', value: this.newCourseType.courseType } ],
          boldText: 'Is this information accurate?',
          confirmButtonTitle: 'Yes, I\'m sure',
          cancelButtonTitle: 'Go Back',
        },
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'verified') {
          saveCourseType();
        }
      })
    }
    else {
      saveCourseType();
    }
  }

  ngOnDestroy(): void {
    this.addCourseTypeSubscription?.unsubscribe();
    this.activationSubscription?.unsubscribe();
  }

}
