import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: [ './password-change.component.scss' ],
})
export class PasswordChangeComponent {
  @Output() passwordSubmitEvent = new EventEmitter<string>();
  @Output() passwordChangeSubmitEvent = new EventEmitter<FormGroup>();
  @Input() isPasswordChange = false;
  error$: Observable<any>;
  private errorSubscription: Subscription;

  previousPassword = '';
  password = '';
  confirmPassword = '';
  passVal = {
    hasUpperCase: false,
    hasSpecialChar: false,
    isAtLeast8Chars: false,
    hasNumber: false,
    passMatch: false,
    topTrue: false,

    allTrue: false,
  }

  checkMark = '√';
  xMark = 'X';

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.errorSubscription = this.authService.error$.subscribe((error: any) => {
      this.error$ = error
    })
  }

  // #region PASSWORD RESET VALIDATION
  submitChangePassword({ form }: NgForm) {
    if (this.isPasswordChange) {
      return this.passwordChangeSubmitEvent.emit(form);
    }
    return this.passwordSubmitEvent.emit(form.value.password);
  }

  checkPasswordStrength({ form }: NgForm) {
    const password = form.value.password;
    const confirmPassword = form.value?.['password-confirm'];

    this.passVal.hasUpperCase = /[A-Z]/.test(password);
    this.passVal.hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
    this.passVal.isAtLeast8Chars = password?.length >= 8;
    this.passVal.hasNumber = /[0-9]/.test(password);

    this.passVal.passMatch = password === confirmPassword;

    this.passVal.topTrue = this.passVal.hasUpperCase && this.passVal.hasSpecialChar && this.passVal.isAtLeast8Chars && this.passVal.hasNumber;

    this.passVal.allTrue = this.passVal.topTrue && this.passVal.passMatch;
  }
  // #endregion


}
