import {
  Component, OnInit, OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BuildingsService } from 'src/app/services/buildings.service';
import { DetailsDialogComponent } from './components/details-dialog/details-dialog.component';
import { UpdateBuildingComponent } from './components/update-building/update-building.component';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: [ './buildings.component.scss' ],
})
export class BuildingsComponent implements OnInit, OnDestroy {
  buildingDataSubscription = new Subscription();
  columnTitles = [ 'Building Name', 'Type' ];
  _buildingsData: any;
  buildingsData$: Observable<any>;
  tableData: any = { data: [] };
  loading = false;
  filterText = '';
  showActiveItems = true;
  sortColumnName = 'buildingName';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;

  selectedItemsPerPage = 25;
  isActive = false;
  constructor( private buildingsService: BuildingsService, public dialog: MatDialog ) {  }

  ngOnInit(): void {
    this.tableData = {
      tableTitle: 'Buildings',
      columnTitles: [
        { title: 'Building Name', sortBy: 'buildingName' },
        {
          title: 'Type', class: 'left-align', sortBy: 'buildingType',
        },
      ],
      // error: data?.error || null,
      // activeTotal: data?.counts.activeCount,
      // inactiveTotal: data?.counts.inactiveCount,
      // curPage: data?.pagination.curPage,
      // totalPages: data?.pagination,
      data: [],
    }
    this.searchForBuildings();
    this.buildingsData$ = this.buildingsService.buildingsData$
    this.buildingDataSubscription = this.buildingsData$.subscribe(data => {
      if (!data) return;
      this._buildingsData = data;
      const formatedTableData = data?.rows?.map((building: any) => {
        return {
          itemId: building.id,
          itemData: [
            { content: building?.buildingName, class: 'kbody-medium' },
            { content: building?.buildingType, class: 'kbody-medium, left-align' },
          ],
        }

      })
      this.tableData = {
        tableTitle: 'Buildings',
        error: data?.error || null,
        columnTitles: [
          { title: 'Building Name', sortBy: 'buildingName' },
          {
            title: 'Type', class: 'left-align', sortBy: 'buildingType',
          },
        ],
        activeTotal: data?.counts.activeCount,
        inactiveTotal: data?.counts.inactiveCount,
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination.curPage,
        totalPages: data?.pagination.totalPages,
        data: formatedTableData,
      }
      this.loading = data ? false : true;
    })
  }


  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForBuildings();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForBuildings();
  }

  paginationEvent = (pageDirection: string) => {
    this.loading = true;
    this.tableData.data = [];
    this.buildingsService.getPagination(pageDirection);
  }

  searchForBuildings() {
    this.loading = true;
    this.tableData.data = []
    this.buildingsService.getBuildingsFilteredByName(
      this.filterText, this.showActiveItems ? 1 : 0, this.sortColumnName, this.sortDirection, this.selectedItemsPerPage, this.offset,
    );
  }

  getBuildingsFilteredByName = (searchItem: any) => {
    this.filterText = searchItem?.searchText;
    this.showActiveItems = searchItem?.showActiveItems;

    this.searchForBuildings();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;
    this.offset = 0;

    this.searchForBuildings();
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.loading = true;
    this.tableData.data = [];
    const limit = this.selectedItemsPerPage;
    const offset = 0;

    if (showActiveItems) {
      this.buildingsService.getInactiveBuildings(limit, offset);
    } else {
      this.buildingsService.getBuildings(limit, offset);
    }
  }


  showItemDetails = (itemId: any) => {
    const buildingData = this._buildingsData?.rows?.find((building: any) => {
      return building?.id === itemId;
    });
    const dialogData = {
      buildingData: buildingData,
      buildingsList: this._buildingsData,
    }
    const dialogRef = this.dialog.open(DetailsDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForBuildings();
      }
    });
  }

  addNewBuilding = () => {
    const buildingDialogRef = this.dialog.open(UpdateBuildingComponent);

    buildingDialogRef.afterClosed().subscribe(result => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForBuildings();
      }
    });
  }

  showEditModal = (itemId: any) => {
    const buildingData = this._buildingsData?.rows?.find((building: any) => {
      return building?.id === itemId;
    });
    const dialogData = {
      buildingData: buildingData,
      buildingsList: this._buildingsData,
    }
    const dialogRef = this.dialog.open(UpdateBuildingComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForBuildings();
      }
    });
  }

  ngOnDestroy(): void {
    this.buildingDataSubscription.unsubscribe();
  }
}
