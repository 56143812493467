<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon fontSet="material-icons-round">playlist_add</mat-icon>
      <span class="kbody-huge">Choose Requirements</span>
    </div>
    <mat-icon class="close" fontSet="material-icons-round" (click)="closeModal()">close</mat-icon>
  </div>
  <div class="content">
    <app-select
      [placeholder]="'Select a requirement'"
      [label]="'Select which requirement to apply this course to'"
      [options]="formattedRequirementOptions"
      (selectedValue)="setSelectedRequirement($event)"
      ></app-select>
  </div>
  <div class="buttons">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeModal()"
      ></app-button>
    <app-button
      [text]="'Continue'"
      [icon]="{ materialIcon: 'done' }"
      (click)="registerForCourse()"
      ></app-button>
  </div>
</div>