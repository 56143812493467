<div class="logged-out-header" *ngIf="!loggedIn$">
  <div class="title-container">
    <app-kalpa-red-black></app-kalpa-red-black>
  </div>
  <div class="links">
    <a
      href="https://www.kalpasolutions.com"
      target="_blank"
      class="link green-link"
      >Visit Website</a
    >
    <!-- <a href="#" class="link green-link">Get Help</a> -->
  </div>
  <mat-icon (click)="toggleMobileNav()" class="burger">menu</mat-icon>
  <div class="mobilenav" [ngClass]="{ open: mobileNavIsOpen === true }">
    <a href="#" class="navlink klink-medium">Visit Website</a>
    <!-- <a href="#" class="navlink klink-medium">Get Help</a> -->
    <span class="close" (click)="toggleMobileNav()">X</span>
  </div>
</div>

<div class="logged-in-header" *ngIf="loggedIn$">
  <div class="school-info">
    <!-- TODO - add school logo here -->
    <div class="column">
      <span class="klabel-medium">{{ (entity$ | async)?.entityName }}</span>
    </div>
  </div>
  <span class="title">KALPA</span>
  <div class="buttons">
    <div class="extended-profile">
      <span class="kbody-medium hi"
        >Hi,
        <span class="kbody-medium name">{{
          (user$ | async)?.firstName || "user"
        }}</span></span
      >
      <div class="user-container">
        <app-avatar size="md" routerLink="profile" />
        <mat-icon class="caret" [matMenuTriggerFor]="menu"
          >chevron_left</mat-icon
        >
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()">Log Out</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
