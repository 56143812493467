import { Component, ViewChild } from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: [ './calendar-widget.component.scss' ],
})
export class CalendarWidgetComponent {
  // @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [ dayGridPlugin, interactionPlugin ],
    // eventClick: this.handleDateClick.bind(this),
    dateClick: this.handleDateClick.bind(this),
    select: this.handleDateClick.bind(this),
    selectable: true,
    headerToolbar: {
      left: 'title',
      right: 'prev,next today',
      // center: 'dayGridMonth,dayGridWeek,dayGridDay', // not in design, but we may want these later
    },
  };

  calendarEvents: EventInput[] = [

    { title: 'Teaching for teachers', start: new Date() },
    { title: 'Typeology', start: new Date('2023-12-15') },
    { title: 'Eating for success', start: new Date('2023-12-18') },
    { title: 'Finding Yes', start: new Date('2023-12-20') },
    { title: 'School Training', start: new Date('2023-12-23') },
    { title: 'General Webinar', start: new Date('2023-12-25') },
    { title: 'Teaching Seminar', start: new Date('2023-12-28') },
    { title: 'Megs Presentation', start: new Date('2023-12-30') },
    { title: 'Fun and Learning', start: new Date('2023-12-31') },
    { title: 'Typing', start: new Date('2024-01-01') },
    { title: 'Gym', start: new Date('2024-01-02') },
    { title: 'Teaching Seminar', start: new Date('2024-01-03') },
    { title: 'Blinking', start: new Date('2024-01-04') },
    { title: 'Fundamentals of math', start: new Date('2024-01-05') },
    { title: 'Number Theory', start: new Date('2024-01-56') },
    { title: 'Teaching for teachers', start: new Date('2024-01-17') },
    { title: 'General Education', start: new Date('2024-01-18') },
    { title: 'Team Seminar', start: new Date('2024-01-05') },
    { title: 'Building Training', start: new Date('2024-01-11') },
    { title: 'Safety training', start: new Date('2024-01-11') },
    { title: 'Entity Wide', start: new Date('2024-01-12') },
    { title: 'Webinar', start: new Date('2024-01-23') },
    { title: 'In Person', start: new Date('2024-01-24') },
    { title: 'Regs', start: new Date('2024-01-25') },
  ];


  handleDateClick(event: any) {
    // console.log('date click! ', event)
  }

}
