import {
  Component, OnInit, SimpleChange,
  ViewChild,
} from '@angular/core';
import gsap from 'gsap';
import { SetToastObject, ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: [ './toast.component.scss' ],
})
export class ToastComponent implements OnInit {
  toasts: SetToastObject[];
  autoRemoveRunning = false;

  left: 24;
  bottom: 24;
  gap: 10;
  duration: 1;
  delay: 2;
  easeType: 'power3.inOut';

  removalRunning = false;

  constructor( private toastService: ToastService ) {}

  ngOnInit(): void {
    this.toastService.newToast
      .subscribe((toast: SetToastObject) => {
        if (toast === null) return

        const id = Math.random().toString(36).substr(2, 9);
        toast._ = {};
        toast._.id = id;
        toast._.displayText = toast.text.length > 30 ? `${toast.text.slice(0, 30)}...` : toast.text;

        this.toasts = this.toasts ? [ ...this.toasts, toast ] : [ toast ];
      })

    // NOTE - uncomment this to test toasts
    // this.testToast();
  }

  animateToastArr(
    forceAnimation = false, setDurToZero = false, addedElement?: Element,
  ) {
    if ((this.removalRunning) && !forceAnimation) return;
    // set all active tweens to completed state
    // gsap.killTweensOf('.toast');

    let toastEls = Array.from(document.querySelectorAll('.toast'));

    toastEls = toastEls.filter((el) => {
      const id = el.getAttribute('id');
      const inArray = this.toasts.some((toast) => toast?._?.id === id);
      const wasRemoved = el.classList.contains('removing');
      return inArray && !wasRemoved;
    });

    if (toastEls.length === 0) return;

    const newEl = toastEls[0] as HTMLDivElement;
    const hasAppeared = newEl.classList.contains('has-appeared');

    if (!hasAppeared) {
      const otherEls = toastEls.slice(1);
      const sum = otherEls.reduce((acc, el) => acc + el.clientHeight + 10, 0) * -1;

      gsap.set(newEl, {
        x: -newEl.clientWidth - 24,
        y: sum,
      })
      newEl.classList.remove('has-appeared');
    }

    let curHeightDelta = 0;

    toastEls.reverse().forEach((toastEl, i) => {
      let duration = 0.5;
      if (toastEl === newEl && !hasAppeared) {
        duration = 1;
      }
      gsap.to(toastEl, {
        x: 0,
        y: -curHeightDelta,
        duration: setDurToZero ? 0 : duration,
        delay: setDurToZero ? 0 : 0.05 * i,
        ease: 'power1.inOut',
      })

      curHeightDelta += toastEl.clientHeight + 10;
    });
    newEl.classList.add('has-appeared')

    this.scanForAutoDismiss();
  }

  dismissToast(toast: Element, bottomToast = false) {
    toast.classList.add('removing');

    return new Promise((resolve) => {
      this.removalRunning = true;

      if (bottomToast) {
        gsap.to(toast, {
          x: 0,
          y: toast.clientHeight + 100,
          duration: 0.6,
          ease: 'power1.inOut',
        });
      } else {
        gsap.to(toast, {
          x: (toast.clientWidth + 24) * -1,
          duration: 0.6,
          ease: 'power1.inOut',
        });
      }

      this.animateToastArr();
      setTimeout(() => {
        this.autoRemoveRunning = false;
        resolve(true);
      }, 550)
    });
  }

  async removeToast($event: Event, toast: SetToastObject) {
    $event.stopPropagation();

    const toastEls = Array.from(document.querySelectorAll('.toast'));
    const target = toastEls.find((el) => el.getAttribute('id') === toast?._?.id);

    if (!target) return;

    let lastToast = false;

    const targetY = gsap.getProperty(target, 'y');
    if (toastEls.length === 1 || targetY === 0) {
      lastToast = true;
    }

    await this.dismissToast(target, lastToast);

    const filteredToasts = this.toasts.filter((t) => t?._?.id !== toast?._?.id);
    this.toasts = filteredToasts;
    this.removalRunning = false;
  }

  scanForAutoDismiss() {
    const toastEls = Array.from(document.querySelectorAll('.toast'));
    const toasts = this.toasts;

    if (toasts.length === 0) return;

    const lastAutoRemovableToast = toasts.find((toast) => toast.dismissible === false);

    if (lastAutoRemovableToast && !this.autoRemoveRunning) {
      this.autoRemoveRunning = true;
      setTimeout(() => {
        this.removeToast(new Event('click'), lastAutoRemovableToast);
      }, 1000 * 3);
    }
  }

  testToast() {

    this.toastService.setToast({
      text: 'Loading ... 1', type: 'success', icon: true, dismissible: false,
    })

    setTimeout(() => {
      this.toastService.setToast({
        text: 'Loading ... 2', type: 'info', icon: true, dismissible: true,
      })
    }, 1000 * 1)
    setTimeout(() => {
      this.toastService.setToast({
        text: 'Loading ... 3', type: 'warn', icon: true, dismissible: false,
      })
    }, 1000 * 1.8)
    setTimeout(() => {
      this.toastService.setToast({
        text: 'Loading... 4', type: 'error', icon: true, dismissible: false,
      })
    }, 1000 * 4)
  }

  @ViewChild('elementToCheck') set elementToCheck(elementToCheck: any) {
    this.animateToastArr(
      false, false, elementToCheck,
    );
  }
}
