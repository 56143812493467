import {
  Component, Inject, OnInit, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-registration-success-modal',
  templateUrl: './registration-success-modal.component.html',
  styleUrls: [ './registration-success-modal.component.scss' ],
})
export class RegistrationSuccessModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RegistrationSuccessModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit(): void {
    this.data.sessionStartDate = new Date(this.data.sessionStartDate).toLocaleDateString();
  }

  closeModal = () => {
    this.dialogRef.close();
  }
}
