import {
  Component, Output, EventEmitter, Input,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';

@Component({
  selector: 'app-course-buildings-filters',
  templateUrl: './course-buildings-filters.component.html',
  styleUrls: [ './course-buildings-filters.component.scss' ],
})
export class CourseBuildingsFiltersComponent {
  @Input() mainFilter: CourseFilter;
  @Input() buildingsList: any;
  @Output() updateMainFilter = new EventEmitter<object>();

  constructor() { }

  checkIncludesBuilding(building: any) {
    return !!this.mainFilter.buildings.find((buildingItem: any) => building.id === buildingItem.id);
  }

  updateCheckboxFilter(event: any, building: any): void {
    const isChecked = event?.target?.checked;

    if (isChecked) {
      this.mainFilter.buildings.push(building);
    } else {
      this.mainFilter.buildings = this.mainFilter.buildings.filter((buildingItem: any) => buildingItem.id !== building.id);
    }
  }

  selectAllBuildingsFilter(event: any): void {
    if (event.target.checked) {
      this.mainFilter.buildings = this.buildingsList;
    } else {
      this.mainFilter.buildings = [];
    }
  }

  clearBuildingsFilter(): void {
    this.mainFilter.buildings = [];
  }

}
