<div class="waitlist-widget">
  <div class="row title-row">
    <span class="klabel-medium title">Course Name</span>
    <div class="right">
      <span class="klabel-medium title">Waiting/Cap</span>
      <span class="klabel-medium title">Date</span>
    </div>
  </div>
  <div class="courses">
    <div class="row course" *ngFor="let course of courses">
      <span class="detail ksubtitle-tiny">{{course.name}}</span>
      <div class="right">
        <span class="detail ksubtitle-tiny"><span class="count ksubtitle-tiny">{{course.waitlistedCount}}</span>/ {{course.courseRegistrationCount}}</span>
        <span class="detail ksubtitle-tiny">{{course.date}}</span>
      </div>
    </div>
  </div>
  <app-button class="btn" [text]="'View Course Catalog'" [class]="'grey'"></app-button>
</div>