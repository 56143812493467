import { Component } from '@angular/core';

@Component({
  selector: 'app-requirements-list',
  templateUrl: './requirements-list.component.html',
  styleUrls: [ './requirements-list.component.scss' ],
})
export class RequirementsListComponent {

}
