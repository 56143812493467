import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.scss' ],
})
export class StepperComponent {
  @Input() steps: { text: string; completed: boolean; modified?: boolean; }[] = []; // expects [{text: string, completed: boolean}, ...]
  @Input() selectedStep: { text: string; completed: boolean; modified?: boolean; } = this.steps[0] // expects {text: string, completed: boolean}
  @Input() isEditMode = false;
  @Output() selectedStepChange = new EventEmitter<any>();

  constructor() { }

  navigateToTab = (step: any) => {
    const isEditMode = this.isEditMode;
    const canNav = isEditMode || step.completed;

    if (canNav) {
      this.selectedStepChange.emit(step);
    }
  }

  selectStep = (step: any) => {
    this.selectedStep = step;
    this.selectedStepChange.emit(step);
  }

}
