<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Course Sorting</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="klabel-huge">Requirements</span>
    <span class="kbody-large"
      >The requirement selections you make here determine which requirements
      this course can be applied to. If a user registers for this course but has
      not been assigned to any of the selected requirements made here, the user
      will only be able to apply this course to their Other/Personal Portfolio
      bucket.</span
    >
    <span class="klabel-medium label">Select requirements*</span>

    <!--start-->
    <div class="kinput input-select-container kbody-large tree-container">
      <div class="kinput input-select-container kbody-large">
        <app-tree-checklist
          #requirementsinput
          class="tree"
          [label]="'Select requirements'"
          [dataset]="this.requirements"
          [existingData]="this.course.requirementsList"
          [showItems]="true"
          (shareCheckedList)="
            updateLocalCourseParameter($event, 'requirements')
          "
        >
        </app-tree-checklist>
      </div>

      <span
        class="klabel-medium label"
        *ngIf="course.requirementsList.length > 0"
        >Selections</span
      >
      <table class="table klabel-medium label">
        <thead>
          <tr>
            <th class="thl">Parent Requirement</th>
            <th class="thr">Sub-Requirement</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr" *ngFor="let entry of RequirementList">
            <td class="tdl">
              <div class="chips parent-requirement">
                <app-chip
                  class="outlined requirement parent-requirement"
                  (closeChip)="removeAllFromArray(entry[0], 'requirements')"
                  [closable]="true"
                  [text]="entry[0].topMostParentName || ''"
                ></app-chip>
              </div>
            </td>

            <td class="tdr">
              <div class="chips child-requirements">
                <ng-container *ngFor="let requirement of entry">
                  <ng-container *ngIf="requirement.nodeType === 'GROUP_LEAF'">
                    <app-chip
                      class="outlined requirement child-requirement"
                      (closeChip)="
                        removeAssetFromArray(requirement, 'requirements')
                      "
                      [closable]="true"
                      [text]="requirement.requirement || ''"
                    ></app-chip>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <span class="klabel-huge">Tags</span>
      <span class="klabel-medium label"
        >Select tags to attach to this course</span
      >
      <div class="input-select-container kinput">
        <input
          #tagsinput
          (change)="updateLocalCourseParameter($event, 'tags')"
          class="kinput kbody-medium input-select"
          type="text"
          placeholder="Type or select a tag"
          list="tagtargets"
        />
        <datalist id="tagtargets" class="datalist">
          <option class="kbody-medium" *ngFor="let tag of tags">
            {{ tag.tag }}
          </option>
        </datalist>
      </div>
      <span class="klabel-medium label" *ngIf="course.tagsList.length > 0"
        >Selections</span
      >
      <div class="chips">
        <app-chip
          [dot]="tag?.color"
          class="outlined"
          (closeChip)="removeAssetFromArray(tag, 'tags')"
          [closable]="true"
          *ngFor="let tag of course.tagsList"
          [text]="tag.tag"
        ></app-chip>
      </div>

      <span class="klabel-huge">Categories</span>
      <span class="klabel-medium label"
        >Select categories to attach to this course</span
      >
      <div class="input-select-container kinput">
        <input
          #categoriesinput
          (change)="updateLocalCourseParameter($event, 'categories')"
          class="kinput kbody-medium input-select"
          type="text"
          placeholder="Type or select a category"
          list="categorytargets"
        />
        <datalist id="categorytargets" class="datalist">
          <option class="kbody-medium" *ngFor="let category of categories">
            {{ category.category }}
          </option>
        </datalist>
      </div>
      <span class="klabel-medium label" *ngIf="course.categoryList.length > 0"
        >Selections</span
      >
      <div class="chips">
        <app-chip
          class="outlined"
          (closeChip)="removeAssetFromArray(category, 'categories')"
          [closable]="true"
          *ngFor="let category of course.categoryList"
          [text]="category.category"
        ></app-chip>
      </div>
    </div>

    <div class="buttons" *ngIf="!isEditMode">
      <app-button
        (click)="stepBackward()"
        [text]="'Previous Step'"
        [icon]="{ materialIcon: 'arrow_back' }"
        [class]="'white'"
      ></app-button>
      <div class="actions">
        <app-button
          [text]="'Cancel'"
          [class]="'white'"
          (click)="closeDialog()"
        ></app-button>
        <app-button
          [disabled]="course.requirementsList.length === 0"
          [icon]="{ materialIcon: 'arrow_forward' }"
          [class]="'icon-right'"
          [text]="'Next Step'"
          (click)="updateCourse()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
