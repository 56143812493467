<div class="manage-staff">
  <span class="ktitle-huge">Manage Staff</span>
  <!-- <div class="tabs">
    <span
      *ngFor="let tab of tabList"
      (click)="selectTab(tab.name)"
      class="tab klabel-medium"
      [ngClass]="{ 'selected': tab.name === selectedTab }"
      >
        {{ tab.name }}
    </span>
  </div> -->
  <div class="small-select">
    <span class="klabel-small">Select Setting</span>
    <select
      class="select"
      #settings
      (change)="selectTab(settings.value)"
      [value]="selectedTab"
    >
      <option *ngFor="let tab of tabList" [value]="tab.name">
        {{ tab.name }}
      </option>
    </select>
  </div>
  <div class="tab-content">
    <app-staff-list *ngIf="selectedTab === 'Staff List'"></app-staff-list>
    <app-reassign-staff
      *ngIf="selectedTab === 'Reassign Staff'"
    ></app-reassign-staff>
  </div>
</div>
