import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UpdateUserComponent } from 'src/app/routes/manage-staff/components/staff-list/components/update-user/update-user.component';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';

// TODO: this is a poorly named component. It should be renamed to something like ProfileOverviewComponent
// TODO: this component will need a ui update and may break once the requirement groups are implemented in the backend
// TODO: update user image and implement conditional allowance of editing the image based on permissions
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: [ './my-profile.component.scss' ],
})
export class MyProfileComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() viewingOthersProfile: any;
  @Output() refreshUser = new EventEmitter<any>();

  certificationList: { name: string; type: string; expirationDate: string }[] = [];
  authSubscription: Subscription;
  permissions: any;

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
    });
  }

  openChangePasswordModal = () => {
    const dialogRef = this.dialog.open(ChangePasswordModalComponent)

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshUser.emit(result);
      }
    });
  }

  openUpdateModal = () => {
    this.usersService.clearSelectedUser();
    const dialogRef = this.dialog.open(UpdateUserComponent, { data: this.user })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshUser.emit(result);
        this.usersService.getUserById(this.user.id, { 'includedAssociations': 'PrimaryBuilding, PrimaryPosition, Requirements, Roles, PrimaryApprovers, SecondaryApprovers' })
      }
    });
  }


  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

}
