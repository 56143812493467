<div class="update-component">
  <div class="title-row">
    <div class="row">
      <mat-icon fontSet="material-icons-round">category</mat-icon>
      <span class="ktitle-medium">{{ data ? 'Edit category' : 'Add new category' }}</span>
    </div>
    <mat-icon fontSet="material-icons-round" class="close-icon" (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="content">
    <app-input
      class="" 
      label="Category Name*" 
      placeholder="Category Name" 
      [value]="category.category"
      (updatedValue)="updateParameter($event, 'category')"
      [disabled]="data && !data?.activeFlag ? true : false"
      ></app-input>
  </div>
  <div class="buttons" [ngClass]="{'end': !data}">
    <app-button *ngIf="data" (click)="toggleActivation()" [icon]="{type: 'people'}"  [class]="'grey'" text="{{(data && data?.activeFlag) ? 'Deactivate' : 'Activate'}}"></app-button>
    <div class="actions">
      <app-button text="Cancel" [class]="'white'" (click)="closeDialog()">Cancel</app-button>
      <app-button (click)="saveCategory()" text="{{ data ? 'Save Changes' : 'Add Category'}}" [icon]="{type: 'success'}"></app-button>
    </div>
  </div>
  <span class="error" *ngIf="!data && error">{{ error }}</span>
</div>