import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { UpdateTagComponent } from '../update-tag/update-tag.component';
import { TagsService } from 'src/app/services/tags.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-tag-details',
  templateUrl: './tag-details.component.html',
  styleUrls: [ './tag-details.component.scss' ],
})
export class TagDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<TagDetailsComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tagsService: TagsService,
    public toastService: ToastService,
  ) { }

  showEditModal = () => {
    const updateRef = this.dialog.open(UpdateTagComponent, { data: this.data });

    updateRef.afterClosed().subscribe(result => {
      this.dialogRef.close(result);
    });
  }

  setActivation = (setActive: boolean) => {
    this.tagsService.toggletagActivation(this.data, setActive).subscribe({
      next: (response: any) => {
        this.dialogRef.close(response);

        this.toastService.setToast({
          text: `Tag: '${this.data.tag}' Was Successfully ${setActive ? 'Activated' : 'Deactivated'}`,
          type: 'success',
        })
      },
      error: (error: any) => {
        console.log('error', error)
        const activeType = setActive ? 'activating' : 'deactivating';
        this.toastService.setToast({ text: `There was an error ${activeType} tag: '${this.data.tag}'`, type: 'error' })
        // this is in the case that there is a non reassign error. this is still being worked on in express, so we will conditionally use this in the future
        // const dialogRef = this.dialog.open(VerificationComponent, {
        //   data: {
        //     type: 'alert', title: 'Reassign staff', confirmButtonTitle: 'Reassign Staff', cancelButtonTitle: 'Cancel Deactivation', text: 'There are (x) staff members assigned to this tag. You must assign all active users to a new building before deactivating.',
        //   },
        // })
        // dialogRef.afterClosed().subscribe(result => {
        //   if(result) {
        //     this.dialog.open(BatchReassignComponent)
        //   }
        // })
      },
    })
  }

  closeDialog = () => {
    this.dialogRef.close();
  }
}
