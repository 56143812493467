import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: [ './swap.component.scss' ],
})
export class SwapComponent {

  constructor(
    public dialogRef: MatDialogRef<SwapComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeDialog = () => {
    this.dialogRef.close();
  }

}
