import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ElementRef, ViewChild } from '@angular/core';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';


import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
})
export class DashboardComponent implements OnInit, OnDestroy  {
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;
  user$: Observable<any>;

  widgets: Array<any> = [
    {
      title: 'Course Status Summary', showContent: true, component: 'status',
    },
    {
      title: 'Calendar', showContent: true, component: 'calendar',
    },
    {
      title: 'Latest Bulletin', showContent: true, component: 'bulletin',
    },
    {
      title: 'Validation', showContent: true, component: 'validation',
    },
    {
      title: 'Catalog Suggestions', showContent: true, component: 'suggestions',
    },
    {
      title: 'Course Requests', showContent: true, component: 'requests',
    },
    {
      title: 'Waiting List', showContent: true, component: 'waitlist',
    },
  ];
  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };
  permissions: any;
  permissionsSubscription: any;

  constructor(private authService: AuthService) { }

  // TODO: hook up all widgets, which are currently using fake data
  ngOnInit(): void {
    this.user$ = this.authService.user$
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
      if(this.permissions.COURSE_SUGGESTIONS === 0) {
        this.widgets = this.widgets.filter((widget) => widget.component !== 'suggestions');
      }
      if(this.permissions.COURSE_REQUESTS === 0) {
        this.widgets = this.widgets.filter((widget) => widget.component !== 'requests');
      }
      if(this.permissions.VALIDATE_ATTENDANCE === 0) {
        this.widgets = this.widgets.filter((widget) => widget.component !== 'validation');
      }

    });
  }

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(
        this.widgets, dragIndex, dropIndex,
      );
    }
  }

  dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;

    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector('.cdk-drag-placeholder');

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }

    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  ngOnDestroy(): void {
    this.permissionsSubscription.unsubscribe();
  }

}
