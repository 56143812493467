import {
  Component, OnInit, OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { PositionsService } from 'src/app/services/positions.service';
import { PositionDetailsDialogComponent } from './components/position-details-dialog/position-details-dialog.component';
import { UpdatePositionComponent } from './components/update-position/update-position.component';


@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: [ './positions.component.scss' ],
})
export class PositionsComponent implements OnInit, OnDestroy {
  positionDataSubscription = new Subscription();
  columnTitles = [ 'Position Name' ];
  _positionsData: any;
  positionsData$: Observable<any>;
  tableData: any;
  loading = false;
  selectedItemsPerPage = 25;
  isActive = false;

  searchText = '';
  sortColumnName = 'positionTitle';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;

  constructor( private positionsService: PositionsService, public dialog: MatDialog ) {  }

  ngOnInit(): void {
    this.tableData = {
      tableTitle: 'Positions',
      columnTitles: [ { title: 'Position Name', sortBy: 'positionTitle' } ],
      data: [],
    }

    this.searchForPositions();
    this.positionsData$ = this.positionsService.positionsData$
    this.positionDataSubscription = this.positionsData$.subscribe(data => {
      if (!data) return;
      this._positionsData = data;
      const formatedTableData = data?.rows?.map((position: any) => {
        return {
          itemId: position.id,
          itemData: [ { content: position?.positionTitle, class: 'klabel-large' } ],
        }

      })
      this.tableData = {
        tableTitle: 'Positions',
        columnTitles: [ { title: 'Position Name', sortBy: 'positionTitle' } ],
        activeTotal: data?.counts.active,
        inactiveTotal: data?.counts.inactive,
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination.curPage,
        totalPages: data?.pagination.totalPages,
        data: formatedTableData,
      }
      this.loading = data ? false : true;
    })

  }

  searchForPositions() {
    this.loading = true;
    this.tableData.data = [];

    const params = {
      positionTitleFilter: this.searchText?.toLowerCase() || '',
      activeFlag: (this.isActive ? 0 : 1) as 0 | 1,
      sortColumn: this.sortColumnName,
      sortDirection: this.sortDirection,
      limit: this.selectedItemsPerPage,
      offset: this.offset,
    };

    this.positionsService.getPositions(params)
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForPositions();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForPositions();
  }

  getPositionsFilteredByName = (searchItem: any) => {
    this.searchText = searchItem?.searchText;
    this.offset = 0;
    this.searchForPositions();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;

    const currentPage = this._positionsData?.pagination?.curPage || 1;
    const offset = this.calculateOffset(currentPage);
    this.offset = offset;

    this.searchForPositions();
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.offset = 0;
    this.searchForPositions();
  }

  addNewPosition = () => {
    const positionDialogRef = this.dialog.open(UpdatePositionComponent);

    positionDialogRef.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000 || result === 'reload') {
        this.searchForPositions();
      }
    });
  }

  showItemDetails = (itemId: any) => {
    const positionData = this._positionsData?.rows?.find((position: any) => {
      return position?.id === itemId;
    });
    const dialogRef = this.dialog.open(PositionDetailsDialogComponent, { data: positionData });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForPositions();
      }
    });
  }

  showEditModal = (itemId: any) => {
    const positionData = this._positionsData?.rows?.find((position: any) => {
      return position?.id === itemId;
    });
    const dialogRef = this.dialog.open(UpdatePositionComponent, { data: positionData });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.searchForPositions();
      }
    });
  }

  ngOnDestroy(): void {
    this.positionDataSubscription.unsubscribe();
  }

}
