import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { CategoriesService } from 'src/app/services/categories.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-update-category',
  templateUrl: './update-category.component.html',
  styleUrls: [ './update-category.component.scss' ],
})
export class UpdateCategoryComponent implements OnInit, OnDestroy {
  error: any;
  category: any = {};
  categorySubscription: any;

  constructor(
    private categoriesService: CategoriesService,
    public dialog: MatDialog,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateCategoryComponent>,
  ) { }

  ngOnInit(): void {
    if(this.data) {
      this.category = this.data
    }
  }

  updateParameter = (event: any, parameter: string) => {
    this.category[parameter] = event.target.value;
  }

  saveCategory = () => {
    if (!this.category.category) {
      this.error = 'Please enter a category name';
    }
    else {
      this.categorySubscription = (this.data ? this.categoriesService.updateCategory(this.category) : this.categoriesService.addNewCategory(this.category))
        .subscribe({
          next: (response: any) => {
            this.toastService.setToast({ text: `Category ${this.data ? 'Updated' : 'Created'}`, type: 'success' })
            this.dialogRef.close(response);
          },
          error: (error: any) => {
            console.log('error', error)
            this.dialog.open(VerificationComponent, {
              data: {
                type: 'alert', title: `Oops, something went wrong ${this.data ? 'updating' : 'creating'} ${this.category.category}`, text: error.error.message || error.message,
              },
            })
          },
        })
    }
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  toggleActivation = () => {
    const setToActive = !this.category.activeFlag
    this.categoriesService.toggleCategoryActivation(this.category, setToActive).subscribe({
      next: (response: any) => {
        this.toastService.setToast({ text: `Position ${setToActive ? 'Activated' : 'Deactivated'}`, type: 'success' })

        this.dialogRef.close(response);
      },
      error: (error: any) => {
        this.toastService.setToast( {
          type: 'error',
          text: `Something went wrong creating ${this.category.category}: ${error.error.message || error.message}`,
        })

        this.dialogRef.close();
      },
    })
  }

  ngOnDestroy(): void {
    this.categorySubscription?.unsubscribe();
  }

}
