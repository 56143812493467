import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Course from '../../course.class';
import { ApprovalService } from 'src/app/services/approval.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: [ './additional-details.component.scss' ],
})
export class AdditionalDetailsComponent implements OnInit {
  @Output() stepForward = new EventEmitter<boolean>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Input() course: Course;
  @Input() isEditMode: any;
  approvalLevels: { value: number, kid: string, text: string}[] = []
  // TODO: get validation types from Express
  validationTypes: { value: number, kid: string, text: string }[] = []

  approvalDefaultKid = 'L0_NONE';
  validationDefaultKid = 'HONOR';
  approvalDefaultId: number;
  validationDefaultId: number;
  setApprovalId: number | undefined;
  setValidationId: number | undefined;

  // TODO: get state reporting requirements from Express
  stateReportingRequirements = [
    { text: 'Classroom Management', value: 'classroomManagement' },
    { text: 'Instructional Delivery', value: 'instructionalDelivery' },
  ]
  showStateReportingRequirements = false;

  constructor(
public dialog: MatDialog, private approvalService: ApprovalService, private validationService: ValidationService,
  ) { }

  ngOnInit(): void {
    const stateGroup = this?.course?.requirementsList?.filter((requirement: any) => requirement?.reportToStateFlag === 1);
    if (stateGroup?.length > 0) {
      this.showStateReportingRequirements = true;
    }

    this.approvalService.approvalData$.subscribe((data: any) => {
      if (!data) return;

      this.approvalLevels = data.map((approval: any) => ({
        text: approval?.approvalName, value: approval.id, kid: approval?.kalpaIdentifier,
      }))

      const defaultApproval = this.approvalLevels.find((approval: any) => approval.kid === this.approvalDefaultKid);
      this.approvalDefaultId = defaultApproval?.value as number;

      this.setApprovalId = this.course.approvalId || this.approvalDefaultId;
    })
    this.approvalService.getApprovalLevels();

    this.validationService.validationTypes$.subscribe((data: any) => {
      if (!data) return;

      this.validationTypes = data.map((validation: any) => ({
        text: validation?.validation, value: validation.id, kid: validation.kalpaIdentifier,
      }))
        .filter((vt: any) => vt.kid !== 'SURVEY');

      const defaultValidation = this.validationTypes.find((validation: any) => validation.kid === this.validationDefaultKid);
      this.validationDefaultId = defaultValidation?.value as number;
      this.setValidationId = this.course.validationId || this.validationDefaultId;

      // TODO - remove filter once survey's are implemented
    });
    this.validationService.getValidationTypes();
  }
  ngOnChanges(changes: any): void {
    if (changes.course?.currentValue) {
      this.course = changes.course.currentValue;

      this.setApprovalId = this.course.approvalId || this.approvalDefaultId;
      this.setValidationId = this.course.validationId || this.validationDefaultId;

      this.course.setProperty('approvalId', this.setApprovalId);
      this.course.setProperty('validationId', this.setValidationId);
    }
  }

  updateLocalCourseParameter = (event: any, parameter: keyof Course) => {
    const value = event?.target?.value ? event.target.value : event;

    if (parameter === 'approvalId') {
      const approval = this.approvalLevels.find((approval: any) => {
        return approval.value === value;
      });

      if (approval) {
        this.setApprovalId = approval.value;
        this.course.setProperty('approval', approval.text);
        this.course.setProperty('approvalId', approval.value);
      }
    }

    if (parameter === 'validationId') {
      const validation = this.validationTypes.find((validation: any) => {
        return validation.value === value;
      });

      if (validation) {
        this.setValidationId = validation.value;
        this.course.setProperty('validation', validation.text);
        this.course.setProperty('validationId', validation.value);
      }
    }

    this.course.setProperty(parameter, value);
    // this.course[parameter] = event?.target?.value ? event.target.value : event;
  }

  updateCourse = () => {
    // if (this.course.stateReportingRequirements === '' || null) {
    //   return
    // }

    const approval = this.approvalLevels.find((approval: any) => approval.value === this.setApprovalId);
    this.course.setProperty('approval', approval?.text);
    this.course.setProperty('approvalId', this.setApprovalId);

    const validation = this.validationTypes.find((validation: any) => validation.value === this.setValidationId);
    this.course.setProperty('validation', validation?.text);
    this.course.setProperty('validationId', this.setValidationId);

    this.stepForward.emit(true);
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.closeDialogEmitter.emit();
  }

}
