import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import {
  Observable, Subscription, tap,
} from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

// TODO: we need to redesign the header
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedInSubscription: Subscription;
  mobileNavIsOpen = false;
  loggedIn$: any = false;
  user$: Observable<any>;
  entity$: Observable<any>;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.loggedInSubscription = this.authService.loggedIn$
      .subscribe((loggedInStatus: any) => {
        this.loggedIn$ = loggedInStatus
      })
    this.user$ = this.authService.user$
    this.entity$ = this.authService.entity$

  }

  toggleMobileNav = () => {
    this.mobileNavIsOpen = !this.mobileNavIsOpen
  }

  logout = () => {
    this.authService.logout()
  }

  ngOnDestroy(): void {
    this.loggedInSubscription.unsubscribe()
  }

}
