<div class="dialog">
  <div class="title-row">
    <div class="row">
      <mat-icon>category</mat-icon>
      <span class="ktitle-medium">Tag Details</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>

  <div class="content">
    <div class="detail-row">
      <div class="detail">
        <span class="klabel-medium label">Tag Name</span>
        <div class="color-container">
          <svg
            class="dot"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            [style]="{ fill: data.color }"
          >
            <circle cx="12px" cy="12px" r="12px" />
          </svg>
          <span class="kbody-medium">{{ data.tag }}</span>
        </div>
      </div>
      <div class="container">
        <div class="detail">
          <span class="klabel-medium label">Tracking Type</span>
          <span class="kbody-medium">{{ data.track }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Conversion</span>
          <span class="kbody-medium">{{ data.conversion }}</span>
        </div>
      </div>
    </div>
    <div class="detail">
      <span class="klabel-medium label">Tag Description</span>
      <span class="kbody-medium description">{{
        data.description || "This could be a description of the tag."
      }}</span>
    </div>
    <div *ngIf="data.isVisibleFlag" class="detail">
      <span class="klabel-medium label">Additional Details</span>
      <app-chip
        [text]="'visible to teacher'"
        [icon]="{ type: 'checkmark' }"
      ></app-chip>
    </div>
  </div>
  <div class="buttons">
    <app-button
      *ngIf="data?.activeFlag"
      [class]="'grey'"
      (click)="setActivation(false)"
      [text]="'Deactivate Tag'"
    ></app-button>
    <app-button
      *ngIf="data && !data?.activeFlag"
      (click)="setActivation(true)"
      [class]="'grey'"
      [text]="'Activate Tag'"
    ></app-button>
    <div class="actions">
      <app-button
        (click)="closeDialog()"
        [class]="'white'"
        [text]="'Cancel'"
      ></app-button>
      <app-button
        (click)="showEditModal()"
        [icon]="{ type: 'edit' }"
        [text]="'Edit Tag'"
      ></app-button>
    </div>
  </div>
</div>
