import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UpdateCourseTypeComponent } from './components/update-course-type/update-course-type.component';
import { CourseTypeDetailsComponent } from './components/course-type-details/course-type-details.component';
import { CourseTypesService } from 'src/app/services/course-types.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-course-types',
  templateUrl: './course-types.component.html',
  styleUrls: [ './course-types.component.scss' ],
})
export class CourseTypesComponent implements OnInit, OnDestroy {
  tableData: any;
  loading = false;
  selectedItemsPerPage = 25;
  isActive = false;
  courseTypesData: any;
  courseTypesSubscription: Subscription;

  searchText = '';
  sortColumnName = 'courseType';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;

  constructor(public dialog: MatDialog, private courseTypesService: CourseTypesService) { }

  ngOnInit(): void {
    this.tableData = {
      tableTitle: 'Course Types',
      columnTitles: [
        { title: 'Course Type Name', sortBy: 'courseType' },
        { title: 'Show on special requests', sortBy: 'showOnSpecialRequestFlag' },
        { title: 'Personal - No PD Credit', sortBy: 'noPdCreditFlag' },
      ],
      data: [],
      // allSchoolYears: [
      //   '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020', '2018-2019',
      // ],
    }

    this.searchForCourseTypes();
    this.courseTypesSubscription = this.courseTypesService.courseTypesData$.subscribe((data: any) => {
      this.courseTypesData = data;
      const formattedTableData = data?.rows?.map((courseType: any) => {
        return {
          itemId: courseType.id,
          orig: courseType,
          itemData: [
            { content: courseType?.courseType, class: 'kbody-medium' },
            {
              type: 'checkbox', checked: courseType?.showOnSpecialRequestFlag, disabled: true,
            },
            {
              type: 'checkbox', checked: courseType?.noPdCreditFlag, disabled: true,
            },
          ],
        }
      })
      this.tableData = {
        tableTitle: this.tableData.tableTitle,
        columnTitles: this.tableData.columnTitles,
        // allSchoolYears: [
        //   '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020', '2018-2019',
        // ],
        activeTotal: data?.counts.active,
        inactiveTotal: data?.counts.inactive,
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination.curPage,
        totalPages: data?.pagination.totalPages,
        data: formattedTableData,
      }
      this.loading = data ? false : true;
    })
  }

  searchForCourseTypes() {
    this.loading = true;
    this.tableData.data = [];
    const params = {
      courseTypeFilter: this.searchText?.toLowerCase() || '',
      activeFlag: this.isActive ? 0 : 1 as 0 | 1,
      sortColumn: this.sortColumnName,
      sortDirection: this.sortDirection,
      limit: this.selectedItemsPerPage,
      offset: this.offset,
    }

    this.courseTypesService.getCourseTypes(params);
  }


  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForCourseTypes();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForCourseTypes();
  }

  getCourseTypesFilteredByName = (searchItem: any) => {
    this.searchText = searchItem?.searchText;
    this.offset = 0;
    this.searchForCourseTypes();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;

    const currentPage = this.courseTypesData?.pagination?.curPage || 1;
    const offset = this.calculateOffset(currentPage);
    this.offset = offset;

    this.searchForCourseTypes();
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.offset = 0;
    this.searchForCourseTypes();
  }

  addNew(): void {
    const dialogRef = this.dialog.open(UpdateCourseTypeComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000) {
        this.searchForCourseTypes();
      }
    });
  }

  showItemDetails = (itemId: any) => {
    const courseTypeData = this.tableData?.data?.find((courseType: any) => {
      return courseType?.itemId === itemId;
    });
    const dialogRef = this.dialog.open(CourseTypeDetailsComponent, { data: courseTypeData });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000) {
        this.searchForCourseTypes();
      }
    });
  }

  showEditModal = (item: any) => {
    const courseTypeData = this.courseTypesData.rows.find((type: any) => {
      return type?.id === item;
    });
    const dialogRef = this.dialog.open(UpdateCourseTypeComponent, { data: courseTypeData });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000) {
        this.searchForCourseTypes();
      }
    });
  }

  ngOnDestroy(): void {
    this.courseTypesSubscription.unsubscribe();
  }
}
