import {
  Component, Inject, OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: [ './category-details.component.scss' ],
})
export class CategoryDetailsComponent implements OnInit{
  category: any = {};

  constructor(public dialogRef: MatDialogRef<CategoryDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void {
    this.category = this.data;
  }

  closeDialog = () => {
    this.dialogRef.close();
  }
}
