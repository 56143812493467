<div class="pd-plan">
  <div>
    <!-- <div class="nav">
      <div
        class="tab"
        [ngClass]="{ selected: tab === selectedTab }"
        (click)="changeTab(tab)"
        *ngFor="let tab of navTitles"
      >
        <mat-icon class="icon material-icons-outlined">{{ tab.icon }}</mat-icon>
        <span class="nav-text klabel-medium">{{ tab.text }}</span>
      </div>
    </div> -->
    <div class="title-row">
      <div class="left-side">
        <h2>PD Plan</h2>
        <!-- <div>
          <app-select
            [options]="myCoursesTabs"
            [value]="myCoursesTabs[0].value"
            (change)="changeCoursesTab($event)"
            styleType="outline"
            color="green"
          />
        </div> -->
      </div>
      <div class="options">
        <!-- <app-button
          [icon]="{ materialIcon: 'print' }"
          [text]="'Print'"
          class="white"
        ></app-button> -->
        <!-- <app-button
          [icon]="{ materialIcon: 'filter_list' }"
          [text]="'Apply Filters'"
          class="grey"
        ></app-button> -->
        <!-- <app-button text="Submit Course Request"></app-button> -->
      </div>
    </div>
  </div>
  <div class="content">
    <app-my-courses
      [user]="user"
      [activeTab]="selectedCoursesTab"
      *ngIf="selectedTab.text === 'My Courses'"
    ></app-my-courses>
  </div>
</div>
