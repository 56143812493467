import {
  Component, OnDestroy, OnInit,
  isDevMode,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ToastService } from 'src/app/services/toast.service';
import { CHALLENGE_TYPES, getChallengeType } from './authHandler.utils';

interface Modes {
  confirmation: boolean;
  forgotPassword: boolean;
  firstTime: boolean;
  basicSignIn: boolean;
  completePassword?: boolean;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: [ './sign-in.component.scss' ],
})
export class SignInComponent implements OnInit, OnDestroy {
  debugMode = false;
  error$: Observable<any>;
  currentUser$: Observable<any>;
  changePassDetails: { email: string; password: string; rememberMe: boolean; } | null
  testEmail = ''


  modes: Modes = {
    confirmation: false,
    forgotPassword: false,
    firstTime: false,
    basicSignIn: true,
  }

  private errorSubscription: Subscription;
  constructor(
private authService: AuthService,
    private loadingSpinnerService: LoadingSpinnerService,
    private toast: ToastService,
  ) {
  }

  // TODO: make header nav links work, and update ui in general
  // TODO: refine token handeling for timeout and refresh of user and permissions

  toggleMode = (mode: keyof Modes) => {
    const keys = Object.keys(this.modes) as Array<keyof Modes>;
    this.authService.clearError();
    keys.forEach((key) => {
      this.modes[key] = false
    })

    this.modes[mode] = true;
  }

  ngOnInit(): void {
    this.authService.clearError();
    this.errorSubscription = this.authService.error$.subscribe((error: any) => {
      this.error$ = error
    })

    if (!isDevMode()) {
      this.debugMode = false;
    }
  }

  toggleForgotPasswordMode = () => {
    if (this.modes['forgotPassword']) {
      this.toggleMode('basicSignIn');
    } else {
      this.toggleMode('forgotPassword');
    }
  }

  sendFakeCreateUserRequestToCognito = async () => {
    if (!isDevMode() || !this.debugMode) {
      return;
    }
    try {
      const email = this.testEmail;

      const cynergeHandle = '@cynerge.com';
      const kalpaUser = new RegExp(/kalpauser\+*\w*@gmail.com/);
      const isKalpaUser = kalpaUser.test(email);


      if (!email.includes(cynergeHandle) && !isKalpaUser) {
        throw new Error('Email is not accepted');
      }

      const res = await this.authService.addUserToAWS(email);

      if (res) {
        this.toast.setToast({
          text: 'User created successfully',
          type: 'success',
          icon: true,
        })
      }

    } catch (error) {
      this.toast.setToast({
        text: 'Error creating user',
        type: 'error',
        icon: true,
      });
      throw error;
    }
  }

  // #region HANDLE AUTH

  interpretAuthContinuation = ({
    res, rememberMe, email, password,
  }: { res: any; rememberMe: boolean; email: string; password: string; }) => {
    const challengeType = getChallengeType(res);


    if (challengeType === CHALLENGE_TYPES.NotAuthorizedException || challengeType === CHALLENGE_TYPES.UserNotFoundException) {
      this.loadingSpinnerService.setIsLoading(false);
      return;
    }

    if (challengeType === CHALLENGE_TYPES.DEFAULT) {
      return this.authService.getUser({
        loginData: { email }, rememberMe, cognitoUserObject: res, fromSignIn: true,
      });
    }

    if (challengeType === CHALLENGE_TYPES.NEW_PASSWORD_REQUIRED) {
      this.changePassDetails = {
        rememberMe,
        email,
        password,
      };
      this.toggleMode('completePassword');
      this.toast.setToast({
        text: 'Password must be changed before continuing',
        type: 'info',
        dismissible: true,
        icon: true,
      });

      this.loadingSpinnerService.setIsLoading(false);
      return;
    }

    if (challengeType === CHALLENGE_TYPES.PasswordResetRequiredException) {
      this.toast.setToast({
        text: 'Password must be reset before continuing. Follow forgot password flow.',
        type: 'info',
        dismissible: true,
        icon: true,
      });
      this.toggleMode('forgotPassword');
      this.loadingSpinnerService.setIsLoading(false);
      return;
    }

    return;
  }

  // #endregion

  ngOnDestroy(): void {
    this?.errorSubscription?.unsubscribe();
    this.changePassDetails = null;
    this.authService.clearError();
  }
}
