import {
  Component, Input, Output, EventEmitter,
} from '@angular/core';

const APPROVAL_LEVEL_KIDS = {
  L0_NONE: 'L0_NONE',
  L1_SU_ONLY: 'L1_SU_ONLY',
  L1_AD_ONLY: 'L1_AD_ONLY',
  L2_AD_AND_SU: 'L2_SU_AND_AD',
};

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: [ './approval.component.scss' ],
})
export class ApprovalComponent {
  @Input() defaults: any;
  @Input() error: any;
  @Output() setNewDefaults = new EventEmitter<object>();

  approvalOptions = [
    // { text: 'No Approval Required', value: APPROVAL_LEVEL_KIDS.L0_NONE },
    { text: '1 level approval (just a secondary manager)', value: APPROVAL_LEVEL_KIDS.L1_SU_ONLY },
    { text: '1 level approval (just a primary manager)', value: APPROVAL_LEVEL_KIDS.L1_AD_ONLY },
    { text: '2 level approval (secondary and primary manager)', value: APPROVAL_LEVEL_KIDS.L2_AD_AND_SU },
  ];

  updateDefaults = (event: any, key: any) => {
    const newDefaults = { [key]: event };
    this.setNewDefaults.emit(newDefaults);
  }

}
