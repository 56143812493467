import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { BatchReassignComponent } from 'src/app/components/batch-reassign/batch-reassign.component';
import { RolesService } from 'src/app/services/roles.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: [ './user-roles.component.scss' ],
})
export class UserRolesComponent implements OnInit, OnDestroy {
  permissions: any;
  defaultRoles: any;
  customRoles: any;
  originalRole: any;
  selectedRole: any;
  roles: any;
  rolesSubscription: any;
  roleUpdateSubscription: any;
  isLoading: boolean;
  roleDeactivateSubscription: any;
  canSave = false;

  constructor(
    public dialog: MatDialog,
    private rolesService: RolesService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.permissions =
      [
        {
          title: 'Manage Surveys', description: 'Grants access to survey management, allowing users to create, edit, or delete surveys.', permission: 'MANAGE_SURVEYS', group: 'General Permissions',
        },
        {
          title: 'Run Data Reports', description: 'Grants access to report management, enabling users to generate reports.', permission: 'RUN_DATA_REPORTS', group: 'General Permissions',
        },
        {
          title: 'Edit Bulletin Board', description: 'Allows users to add, modify, and remove bulletin posts.', permission: 'EDIT_BULLETIN_BOARD', group: 'General Permissions',
        },
        {
          title: 'Manage Course Catalog', description: 'Empowers the user to adjust the curriculum of your organization. When this permission is activated, at least one of the additional abilities must also be enabled.', permission: 'ManageCourseCatalog', group: 'Catalog Permissions', type: 'switch',
        },
        {
          title: 'Add/Cancel Course', description: 'The user can add new courses to the catalog and cancel existing ones.', permission: 'COURSE_ADD', group: 'Catalog Permissions', type: 'checkbox',
        },
        {
          title: 'Edit Courses', description: 'The user has the ability to edit details for all courses listed in the catalog.', permission: 'COURSE_EDIT', group: 'Catalog Permissions', type: 'checkbox',
        },
        {
          title: 'Manage Catalog Suggestions', description: 'The user can approve or deny all catalog suggestions submitted by others.', permission: 'COURSE_SUGGESTIONS', group: 'Catalog Permissions', type: 'checkbox',
        },
        {
          title: 'Adjust System Settings', description: 'This permission grants access to the system settings. When enabled, all system settings tabs are accessible and editable for this role by default. If any tabs are unchecked, they become read-only for this role, meaning they cannot be edited.', permission: 'SYS_SETTINGS_ACCESS', group: 'System Settings Permissions', type: 'switch',
        },
        {
          title: 'Defaults', description: 'Grant users permission to modify data within the \'defaults\' tab of system settings.', permission: 'SYS_SETTINGS_DEFAULTS', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Buildings', description: 'Grant users permission to modify data within the \'buildings\' tab of system settings.', permission: 'SYS_SETTINGS_BUILDINGS', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Positions', description: 'Grant users permission to modify data within the \'positions\' tab of system settings.', permission: 'SYS_SETTINGS_POSITIONS', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Requirements', description: 'Grant users permission to modify data within the \'requirments\' tab of system settings.', permission: 'SYS_SETTINGS_REQUIREMENTS', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Course Types', description: 'Grant users permission to modify data within the \'course types\' tab of system settings.', permission: 'SYS_SETTINGS_TYPES', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Categories', description: 'Grant users permission to modify data within the \'categories\' tab of system settings.', permission: 'SYS_SETTINGS_CATEGORIES', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Tags', description: 'Grant users permission to modify data within the \'tags\' tab of system settings.', permission: 'SYS_SETTINGS_TAGS', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'User Roles', description: 'Grant users permission to modify data within the \'user roles\' tab of system settings.', permission: 'SYS_SETTINGS_ROLES', group: 'System Settings Permissions', type: 'checkbox',
        },
        {
          title: 'Manage Kalpa Accounts', description: 'Enables access to the Manage Staff page and read-only visibility of all users within your organization\'s Kalpa system. Additionally, this permission allows you to optionally grant the role the ability to add new users and edit existing ones, although it is not mandatory.', permission: 'USER_ACCOUNTS', group: 'Kalpa Accounts Management', type: 'switch',
        },
        {
          title: 'Add Users', description: 'Gives this role the capability to add new users to your organization\'s Kalpa system.', permission: 'USER_ADD', group: 'Kalpa Accounts Management', type: 'checkbox',
        },
        {
          title: 'Edit Users', description: 'Gives this role the capability to edit users to your organization\'s Kalpa system.', permission: 'USER_EDIT', group: 'Kalpa Accounts Management', type: 'checkbox',
        },
        {
          title: 'Manage User Activity', description: 'Provides this role with the capability to oversee other users’ PD data, including course attendance, course requests, state documentation, and course schedules.', permission: 'ManageUserActivity', group: 'User Activity Management', type: 'switch',
        },
        {
          title: 'All Users', description: 'The user has the capability to oversee all individuals within the Kalpa system of your organization.', permission: 'UserManagementGroup', group: 'User Management Buttons', type: 'radio', value: 'AllUsers',
        },
        {
          title: 'Assigned Users', description: 'The user has the capability to oversee exclusively those individuals within the Kalpa system to which they have been assigned.', permission: 'UserManagementGroup', group: 'User Management Buttons', type: 'radio', value: 'AssignedUsers',
        },
        {
          title: 'Primary', description: 'The user operates as the superior decision-maker above the secondary assignment for the individuals they have been designated to oversee. Primaries have authority over Secondaries.', permission: 'AssignedUsersPermission', group: 'Assigned User Level', type: 'radio', value: 'Primary',
        },
        {
          title: 'Secondary', description: 'The user operates as the lower-level decision-maker below the primary assignment for the individuals they have been designated to oversee.', permission: 'AssignedUsersPermission', group: 'Assigned User Level', type: 'radio', value: 'Secondary',
        },
        {
          title: 'Validate Attendance', description: 'The user can validate attendance for individuals to which they have been assigned.', permission: 'VALIDATE_ATTENDANCE', group: 'Additional Permissions', type: 'checkbox',
        },
        {
          title: 'Manage Course Requests', description: 'The user can approve or deny course requests submitted by users to which they have been assigned.', permission: 'COURSE_REQUESTS', group: 'Additional Permissions', type: 'checkbox',
        },
        {
          title: 'Export State Documentation', description: 'The user can access the export state documentation and is able to export PD data for users to which they have been assigned.', permission: 'EXPORT_STATE_DOC', group: 'Additional Permissions', type: 'checkbox',
        },
        {
          title: 'Push Courses', description: 'The user is able to push courses to the PD plans of users to which they have been assigned.', permission: 'COURSE_PUSH', group: 'Additional Permissions', type: 'checkbox',
        },

      ]

    this.isLoading = true;
    this.rolesService.getRoles();
    this.rolesSubscription = this.rolesService.rolesData$.subscribe((rolesData: any) => {
      this.roles = rolesData?.rows.map((role: any) => {
        // here we format our permissions for our ui
        const formattedPermissions = {
          'MANAGE_SURVEYS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'MANAGE_SURVEYS') ? true : false,
          'RUN_DATA_REPORTS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'RUN_DATA_REPORTS') ? true : false,
          'EDIT_BULLETIN_BOARD': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'EDIT_BULLETIN_BOARD') ? true : false,
          'ManageCourseCatalog': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'COURSE_ADD' || permission.kalpaIdentifier === 'COURSE_EDIT' || permission.kalpaIdentifier === 'COURSE_SUGGESTIONS') ? true : false,
          'COURSE_ADD': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'COURSE_ADD') ? true : false,
          'COURSE_EDIT': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'COURSE_EDIT') ? true : false,
          'COURSE_SUGGESTIONS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'COURSE_SUGGESTIONS') ? true : false,
          'SYS_SETTINGS_ACCESS':role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_ACCESS') ? true : false,
          'SYS_SETTINGS_DEFAULTS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_DEFAULTS') ? true : false,
          'SYS_SETTINGS_BUILDINGS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_BUILDINGS') ? true : false,
          'SYS_SETTINGS_POSITIONS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_POSITIONS') ? true : false,
          'SYS_SETTINGS_REQUIREMENTS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_REQUIREMENTS') ? true : false,
          'SYS_SETTINGS_TYPES': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_TYPES') ? true : false,
          'SYS_SETTINGS_CATEGORIES': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_CATEGORIES') ? true : false,
          'SYS_SETTINGS_TAGS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_TAGS') ? true : false,
          'SYS_SETTINGS_ROLES': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'SYS_SETTINGS_ROLES') ? true : false,
          'USER_ACCOUNTS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'USER_ACCOUNTS') ? true : false,
          'USER_ADD': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'USER_ADD') ? true : false,
          'USER_EDIT': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'USER_EDIT') ? true : false,
          'ManageUserActivity': (role.accessLevel && role.accessLevel !== 'BASIC') || role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'VALIDATE_ATTENDANCE' || permission.kalpaIdentifier === 'COURSE_REQUESTS' || permission.kalpaIdentifier === 'EXPORT_STATE_DOC' || permission.kalpaIdentifier === 'COURSE_PUSH') ? true : false,
          'UserManagementGroup': (role.accessLevel && role.accessLevel !== 'BASIC') ? role.accessLevel === 'MANAGE_ALL_AUTHORITY' ? 'AllUsers' : 'AssignedUsers' : false,
          'AssignedUsersPermission': role.accessLevel === 'MANAGE_SECONDARY_AUTHORITY' ? 'Secondary' : role.accessLevel === 'MANAGE_PRIMARY_AUTHORITY' ? 'Primary' : false,
          'VALIDATE_ATTENDANCE': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'VALIDATE_ATTENDANCE') ? true : false,
          'COURSE_REQUESTS': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'COURSE_REQUESTS') ? true : false,
          'EXPORT_STATE_DOC': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'EXPORT_STATE_DOC') ? true : false,
          'COURSE_PUSH': role.Permissions.find((permission: any) => permission.kalpaIdentifier === 'COURSE_PUSH') ? true : false,
        }
        return {
          ...role,
          name: role.roleName,
          description: role.roleDetails,
          formattedPermissions,
          originalRoleInfo: { originalPermissions: role.Permissions, name: role.roleName },
        }

      })
      if(this.roles?.length > 0) {
        // order the roles that have a type DEFAULT first
        this.defaultRoles = this.roles.filter((role: any) => role.roleType === 'DEFAULT');
        this.customRoles = this.roles.filter((role: any) => role.roleType === 'CUSTOM');
        this.roles = [ ...this.defaultRoles, ...this.customRoles ];
        this.selectedRole = this.selectedRole || this.roles[0];
        this.originalRole = JSON.parse(JSON.stringify(this.selectedRole));
        this.isLoading = false;
      }
    })
  }

  openNewMode = () => {
    this.canSave = false;
    this.selectedRole = {
      name: 'new role',
      formattedPermissions: {
        'MANAGE_SURVEYS': false,
        'RUN_DATA_REPORTS': false,
        'EDIT_BULLETIN_BOARD': false,
        'ManageCourseCatalog': false,
        'COURSE_ADD': false,
        'COURSE_EDIT': false,
        'COURSE_SUGGESTIONS': false,
        'SYS_SETTINGS_ACCESS':false,
        'SYS_SETTINGS_DEFAULTS': false,
        'SYS_SETTINGS_BUILDINGS': false,
        'SYS_SETTINGS_POSITIONS': false,
        'SYS_SETTINGS_REQUIREMENTS': false,
        'SYS_SETTINGS_TYPES': false,
        'SYS_SETTINGS_CATEGORIES': false,
        'SYS_SETTINGS_TAGS': false,
        'SYS_SETTINGS_ROLES': false,
        'USER_ACCOUNTS': false,
        'USER_ADD': false,
        'USER_EDIT': false,
        'ManageUserActivity': false,
        'UserManagementGroup': false,
        'AssignedUsersPermission': false,
        'VALIDATE_ATTENDANCE': false,
        'COURSE_REQUESTS': false,
        'EXPORT_STATE_DOC': false,
        'COURSE_PUSH': false,
      },
      roleType: 'NEW',
      originalRoleInfo: { originalPermissions: [], name: '' },
    }
    this.originalRole = JSON.parse(JSON.stringify(this.selectedRole));
  }

  saveChanges = () => {
    if (!this.canSave) return;

    this.isLoading = true;
    this.canSave = false;
    this.roleUpdateSubscription = this.rolesService.updateRole(this.selectedRole).subscribe({
      next: (response: any) => {
        this.toastService.setToast({ text: 'Role successfully updated', type: 'success' })
        this.rolesService.getRoles();
      },
      error: (error) => {
        console.log('error', error);
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong', text: error.error.errMsg || error.error.message,
          },
        })
      },
    })
  }

  setCanSave() {
    const name = this.selectedRole.name.length > 0;
    this.canSave = !!name;
  }


  filterDisplayPermissions = (group: any) => {
    return this.permissions.filter((permission: any) => {
      return permission.group === group;
    });
  }

  toggleSelectedRole = (role: any) => {
    this.canSave = false;
    this.selectedRole = role;
    this.originalRole = JSON.parse(JSON.stringify(role));
  }

  deactivateRole = () => {
    const basicUserRole = this.defaultRoles.find((role: any) => role.kalpaIdentifier === 'DEFAULT_USER');
    const dialogRef = this.dialog.open(VerificationComponent, {
      data: {
        type: 'alert',
        title: 'Are you sure?',
        confirmButtonTitle: 'Yes, Delete Role',
        cancelButtonTitle: 'Cancel',
        text: `This action permanently removes this role from your Kalpa environment and resets assigned users to the '${basicUserRole.roleName}' Role. Are you sure you\`d like to proceed?`,
      },
    })
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.isLoading = true;
        this.roleDeactivateSubscription = this.rolesService.deactivateRole(this.selectedRole).subscribe({
          next: (response: any) => {
            this.toastService.setToast({ text: 'Role successfully deactivated', type: 'success' })
            this.rolesService.getRoles();
          },
          error: (error) => {
            console.log('error', error);
            this.isLoading = false;
            this.dialog.open(VerificationComponent, {
              data: {
                type: 'alert', title: 'Oops, something went wrong', text: error.error.errMsg || error.error.message,
              },
            })
          },
        })
      }
    })
  }

  ngOnDestroy() {
    this.rolesSubscription?.unsubscribe();
    this.roleUpdateSubscription?.unsubscribe();
    this.roleDeactivateSubscription?.unsubscribe();
  }

}
