<div class="reassign-staff">
  <div class="content">
    <div class="row">
      <app-select 
      label="Select Reassignment Type" 
      placeholder="select reassignment type" 
      [options]="reassignTypeOptions"
      ></app-select>
      <app-select
      label="Current Assignment"
      placeholder="select current assignment"
      ></app-select>
    </div>
    <div class="lists">
      <div class="list-container">
        <div class="row">
          <app-select
          label="Filter Staff"
          placeholder="by position"
          ></app-select>
          <app-select
          label="Select Position"
          placeholder="select position"
          ></app-select>
        </div>
        <div class="list">
          <div class="top-row">
            <input type="checkbox" (change)="selectAllNeedToMove($event)"
              [checked]="needToMoveList.length === selectedNeedToMoveList.length && selectedNeedToMoveList.length !== 0" />
            <span class="select-all kbody-large">Select All</span>
          </div>
          <div class="row" *ngFor="let item of needToMoveList">
            <input type="checkbox" (change)="handleNeedToMoveCheckboxChange($event, item)" [checked]="needIsChecked(item)" />
            <span class="name kbody-medium">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="chevrons">
          <mat-icon class="mat-icon" (click)="transferSelectedToMovingList()" fontSet="material-icons-round">chevron_right</mat-icon>
          <mat-icon class="mat-icon" (click)="transferSelectedToNeedToMoveList()" fontSet="material-icons-round">chevron_left</mat-icon>
        </div>
      </div>
      <div class="list-container">
        <app-select
        label="New Assignment"
        placeholder="select new assignment"
        ></app-select>
        <div class="list">
          <div class="top-row">
            <input type="checkbox" (change)="selectAllMoving($event)" [checked]="movingList.length === potentialReassignList.length && movingList.length !== 0" />
            <span class="select-all kbody-large">Select All</span>
          </div>
          <div class="row" *ngFor="let item of movingList">
            <input type="checkbox" (change)="handleMovingCheckboxChange($event, item)" [checked]="movingIsChecked(item)" />
            <span class="name kbody-medium">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="options">
    <div class="check-row">
      <input type="radio" name="replace" checked>
      <span>Replace current assignment with new assignment</span>
    </div>
    <div class="check-row">
      <input type="radio" name="replace">
      <span>Maintain current assignment and also add new assignment</span>
    </div>
    <app-button text="Submit Reassign" (click)="submitReassign()"></app-button>
  </div>
</div>