<div class="buildings-component">
  <app-table
    (addNewItem)="addNewBuilding()"
    (sortColumn)="sortColumn($event)"
    (filterColumn)="getBuildingsFilteredByName($event)"
    (toggleActiveList)="toggleActiveList($event)"
    (showItemDetails)="showItemDetails($event)"
    (showEditModal)="showEditModal($event)"
    (paginationEvent)="paginationEvent($event)"
    [tableData]="tableData"
    [searchText]="filterText"
    [isLoading]="loading"
    (itemsPerPageChange)="onItemsPerPageChange($event)"
    (pageChanged)="onPageChanged($event)"
  >
  </app-table>
  <app-loading-spinner class="spinner" *ngIf="loading"></app-loading-spinner>
</div>
