<div class="dialog">
  <div class="title-row">
    <div class="row">
      <mat-icon fontSet="material-icons-round">view_list</mat-icon>
      <span class="ktitle-medium">Requirement Details</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>
  <div class="content" *ngIf="!isLoading">
    <div class="detail-row">
      <div class="container">
        <div class="detail">
          <span class="klabel-medium label">Requirement Name</span>
          <span class="kbody-medium">{{
            requirement?.requirement || "Needs a name"
          }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label">Type</span>
          <span class="kbody-medium">{{
            requirement?.formattedNodeType || "Needs a Type"
          }}</span>
        </div>
        <div class="detail">
          <span class="klabel-medium label"
            >{{ requirement?.Requirement?.trackingUom }} Needed</span
          >
          <span class="kbody-medium">{{
            requirement?.Requirement?.trackingValue || "No Units Found"
          }}</span>
        </div>
      </div>
      <div class="active-container">
        <div class="option" *ngIf="requirementTot.Counts?.curYrUsers">
          <mat-icon class="mat-icon" fontSet="material-icons-round"
            >check</mat-icon
          >
          <span>Active Users</span>
        </div>
        <div class="option" *ngIf="requirementTot.Counts?.curYrCourses">
          <mat-icon class="mat-icon" fontSet="material-icons-round"
            >check</mat-icon
          >
          <span>Active Courses</span>
        </div>
      </div>
    </div>

    <div class="detail" *ngIf="requirement?.nodeType === 'GROUP_PARENT'">
      <span class="klabel-medium label">Sub-Requirements</span>
      <ol class="li-group">
        <li
          class="li-item klist-item"
          *ngFor="let requirement of requirement?.Children"
        >
          {{ requirement.requirement + ", "
          }}{{ requirement.Requirement.trackingValue + " "
          }}{{ requirement.Requirement.trackingUom + " needed" }}
          <ul>
            <li
              class="li-subitem klist-subitem"
              *ngFor="let requirement of requirement?.Children"
            >
              {{ requirement.requirement + " ("
              }}{{ requirement.Requirement.trackingValue + " minimum "
              }}{{ requirement.Requirement.trackingUom + ")" }}
            </li>
          </ul>
        </li>
      </ol>
    </div>

    <div class="detail">
      <span class="klabel-medium label">Requirement Description</span>
      <span class="kbody-medium">{{
        requirement?.Requirement?.description || "-"
      }}</span>
    </div>
    <div class="detail">
      <span class="klabel-medium label">Additional Information</span>
      <div class="chip-row" *ngIf="checkIfAdditionalInfo(requirement)">
        <app-chip
          *ngIf="requirement?.Requirement?.reportToStateFlag"
          [text]="'Report to state'"
          [class]="'grey'"
        ></app-chip>
        <app-chip
          *ngIf="requirement?.Requirement?.requirementType === 'Counselor'"
          [text]="'Counselor requirement'"
          [class]="'grey'"
        ></app-chip>
        <app-chip
          *ngIf="requirement?.Requirement?.allUsersFlag"
          [text]="'Default requirement'"
          [class]="'grey'"
        ></app-chip>
        <app-chip
          *ngIf="requirement?.Requirement?.rollingFlag"
          [text]="'Rolling'"
          [class]="'grey'"
        ></app-chip>
      </div>
      <div *ngIf="!checkIfAdditionalInfo(requirement)">-</div>
    </div>
  </div>

  <div class="buttons" *ngIf="!isLoading">
    <app-button
      *ngIf="requirement?.activeFlag"
      [class]="'grey'"
      (click)="
        toggleActivation(
          requirementTot.Counts?.curYrUsers ||
            requirementTot.Counts?.curYrCourses
        )
      "
      [disabled]="
        requirementTot.Counts?.curYrUsers || requirementTot.Counts?.curYrCourses
      "
      [text]="'Deactivate'"
    ></app-button>
    <app-button
      *ngIf="requirement && !requirement?.activeFlag"
      [class]="'grey'"
      [text]="'Activate'"
    ></app-button>
    <div class="actions">
      <app-button
        (click)="closeDialog()"
        [class]="'white'"
        [text]="'Cancel'"
      ></app-button>
      <app-button
        *ngIf="
          requirement.nodeType !== 'GROUP_PARENT' &&
          requirement?.activeFlag === 1
        "
        [icon]="{ type: 'edit' }"
        [text]="'Edit Requirement'"
        (click)="toggleEditModal()"
      ></app-button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="isLoading">
    <app-loading-spinner class="spinner"></app-loading-spinner>
  </div>
</div>
