import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
  private _categoriesData$ = new BehaviorSubject<any>(null);
  public readonly categoriesData$ = this._categoriesData$.asObservable();

  _user$: any;

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    this.categoriesData$.subscribe(data =>
      links = data.links)
    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/categories/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._categoriesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getCategories = ({
    filterString, activeFlag = 1, sortColumn = 'tag', sortDirection = 'ASC', limit, offset = 0,
  }: {
    filterString: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
    offset: number;
    }) => {
    const params: any = {
      loginName: this._user$.loginName,
      activeFlag,
    }

    if (filterString) {
      params.filterString = filterString;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection;
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    return this.http.get(`${environment.expressUrl}/maintenance/categories`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._categoriesData$.next(response.data);
        },
        error: (error) => {
          this._categoriesData$.next(error);
          console.log('error', error);
        },
      })
  }

  getCategoryDetails = (categoryId: number, includedAssociations: string) => {
    return this.http.get(`${environment.expressUrl}/maintenance/categories/${categoryId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this._user$.loginName, 'includedAssociations': includedAssociations },
      })
      .subscribe({
        next: (response: any) => {
          this._categoriesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getSortedCategories = (
    title: string, sortDirection: string, activeFlag: boolean, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/categories`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': limit,
          'offset': offset,
          'sortDirection': sortDirection,
          'sortColumn': title,
          'activeFlag': activeFlag ? 1 : 0,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._categoriesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getCategoriesFilteredByName = (searchText: string, activeFlag: boolean) => {
    return this.http.get(`${environment.expressUrl}/maintenance/categories`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': 25,
          'activeFlag': activeFlag ? 1 : 0,
          'filterString': searchText,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._categoriesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getInactiveCategories = (limit = 25, offset = 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/categories`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'offset': offset, 'activeFlag': 0,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._categoriesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
          this._categoriesData$.next(error);
        },
      })
  }

  addNewCategory = (categoryData: any) => {
    return this.http.post(
      `${environment.expressUrl}/maintenance/categories`,
      {
        'loginName': this._user$.loginName,
        'category': categoryData.category,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  updateCategory = (categoryData: any) => {
    return this.http.put(
      `${environment.expressUrl}/maintenance/categories/` + categoryData.categoryId,
      {
        'loginName': this._user$.loginName,
        'category': categoryData.category,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  toggleCategoryActivation = (category: any, setToActive: boolean) => {
    const activeEndpoint = setToActive ? 'activate' : 'deactivate';
    return this.http.put(
      `${environment.expressUrl}/maintenance/categories/${category.categoryId}/${activeEndpoint}`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
}
