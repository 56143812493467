<div class="swap-container">
  <div class="title-row">
    <div class="title-container">
      <mat-icon fontSet="material-icons-round">swap_calls</mat-icon>
      <span class="ktitle-medium">{{ data.title }}</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()">cancel</mat-icon>
  </div>
  <div class="content">
    <div class="detail">
      <span class="klabel-medium label">{{ data.label }}</span>
      <select class="kinput">
        <option disabled selected>{{ data.optionPlaceHolder }}</option>
        <option *ngFor="let option of data.options" [value]="option">{{ option.label }}</option>
      </select>
    </div>
  </div>
  <div class="buttons">
    <app-button (click)="closeDialog()" [class]="'white'" class="button" [text]="'Cancel'"></app-button>
    <app-button class="button" [text]="'Save Changes'" [icon]="{type: 'success'}"></app-button> 
  </div>
</div>