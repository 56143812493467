<div
  *ngFor="let toast of toasts"
  #elementToCheck
  [id]="toast?._?.id"
  [ngClass]="[
    'toast',
    toast.type,
    toast.style,
    toast.outline ? 'outline' : '',
    toast.dark ? 'dark' : '',
    toast?.dismissible ? 'dismissible' : ''
  ]"
  (click)="removeToast($event, toast)"
>
  <mat-icon fontSet="material-icons-round" *ngIf="toast.icon">{{
    toast.icon
  }}</mat-icon>
  <span class="klabel-huge">{{ toast.text }}</span>
  <span class="klabel-huge full-text">{{ toast.text }}</span>
  <mat-icon
    fontSet="material-icons-round"
    *ngIf="toast.dismissible"
    class="close"
    (onload)="animateToastArr()"
    >close</mat-icon
  >
</div>
