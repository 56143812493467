<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">User Assignments</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="kbody-large description">Users need to have at least one of each assignment.</span>
    <span class="klabel-huge">Primary</span>
    <span class="klabel-medium label">Select primary*</span>
    <div class="input-select-container kinput">
      <input #administratorinput (change)="updateLocalUserParameter($event, 'primaryApprovers')" class="kinput kbody-medium input-select" type="text" placeholder="Type or select an approver" list="targets"> 
      <datalist id="targets" class="datalist">
        <option [value]="approver.userId" class="kbody-medium" *ngFor="let approver of primaryApprovers">{{ approver.fullName }}</option>
      </datalist>
    </div>
    <span class="klabel-medium label" *ngIf="localEditedUser?.primaryApprovers?.length > 0">Selections</span>
    <div class="chips">
      <app-chip class="outlined" (closeChip)="removePrimaryApprover(approver)" [closable]="true" *ngFor="let approver of localEditedUser.primaryApprovers" [text]="approver.fullName" ></app-chip>
    </div>

    <span class="klabel-huge">Secondary</span>
    <span class="klabel-medium label">Select secondary*</span>
    <div class="input-select-container kinput">
      <input #supervisorinput (change)="updateLocalUserParameter($event, 'secondaryApprovers')" class="kinput kbody-medium input-select" type="text" placeholder="Type or select an approver" list="supervisortargets"> 
      <datalist id="supervisortargets" class="datalist">
        <option [value]="secondaryApprover.userId" class="kbody-medium" *ngFor="let secondaryApprover of secondaryApprovers">{{ secondaryApprover.fullName }}</option>
      </datalist>
    </div>
    <span class="klabel-medium label" *ngIf="localEditedUser?.secondaryApprovers?.length > 0">Selections</span>
    <div class="chips">
      <app-chip class="outlined" (closeChip)="removeSecondaryApprover(approver)" [closable]="true" *ngFor="let approver of localEditedUser.secondaryApprovers" [text]="approver.fullName" ></app-chip>
    </div>
  </div>
  <div class="buttons">
    <app-button (click)="stepBackward()" [text]="'Previous Step'" [icon]="{materialIcon: 'arrow_back'}" [class]="'white'"></app-button>
    <div class="actions">
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeDialog()"></app-button>
      <app-button [disabled]="this.localEditedUser?.primaryApprovers?.length === 0 || this.localEditedUser?.secondaryApprovers?.length === 0" [icon]="{ materialIcon: 'arrow_forward' }" [class]="'icon-right'" [text]="'Next Step'" (click)="updateUser()"></app-button>
    </div>
  </div> 
</div>