<div class="container" *ngIf="course">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">General Information</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="klabel-huge">Basic Information</span>
    <div class="row">
      <app-input
        [label]="'Course Name*'"
        [value]="this?.course?.courseName"
        [placeholder]="'Enter Course Name'"
        (updatedValue)="updateCourseParameter($event, 'courseName')"
        [required]="true"
      ></app-input>
      <div class="number-container">
        <span class="klabel-medium label">Hours*</span>
        <input
          type="number"
          class="kinput"
          placeholder="0.00"
          min="0"
          step="0.25"
          (change)="updateCourseParameter($event, 'trackingValue')"
          (focusout)="makePositive()"
          [required]="true"
          [ngModel]="this?.course?.trackingValue"
          #creditHours="ngModel"
        />
        <span
          class="klabel-medium error"
          *ngIf="
            creditHours.invalid && (creditHours.dirty || creditHours.touched)
          "
          >Credit hours are required</span
        >
      </div>
      <div class="detail">
        <span class="klabel-medium label">Course Type*</span>
        <div class="input-select-container">
          <input
            (change)="updateCourseParameter($event, 'courseType')"
            class="kinput kbody-medium input-select"
            type="text"
            placeholder="Type or select a course type"
            list="targets"
            [ngModel]="course.courseType?.courseType"
            required
            #courseType="ngModel"
          />
          <datalist id="targets" class="datalist">
            <option class="kbody-medium" *ngFor="let courseType of courseTypes">
              {{ courseType.courseType }}
            </option>
          </datalist>
          <span
            class="klabel-medium error"
            *ngIf="
              courseType.invalid && (courseType.dirty || courseType.touched)
            "
            >Course type is required</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <app-input
        [label]="'Instructor'"
        [value]="this?.course?.instructor"
        [placeholder]="'Enter Instructor Name'"
        (updatedValue)="updateCourseParameter($event, 'instructor')"
      ></app-input>
      <div class="number-container">
        <span class="klabel-medium label">Course Cost</span>
        <input
          type="number"
          class="kinput"
          placeholder="0.00"
          min="0.01"
          step="0.01"
          [value]="this?.course?.courseCost || null"
          (change)="updateCourseParameter($event, 'courseCost')"
        />
      </div>
      <app-input
        [label]="'Web URL'"
        [value]="this?.course?.hyperlink"
        [placeholder]="'Enter URL for course information'"
        (updatedValue)="updateCourseParameter($event, 'hyperlink')"
      ></app-input>
    </div>
    <div class="description-container">
      <span class="klabel-medium label">Course Description*</span>
      <textarea
        placeholder="This is where you can write a description of your course."
        class="kinput"
        rows="4"
        (keyup)="updateCourseParameter($event, 'courseDescription')"
        [ngModel]="this?.course?.courseDescription"
        required
        #description="ngModel"
      ></textarea>
      <span
        class="klabel-medium error"
        *ngIf="description.touched && description.errors"
        >Description is required</span
      >
    </div>
    <span class="klabel-huge">Course Availability</span>
    <div class="spread-row">
      <div class="section">
        <div class="number-container">
          <span class="klabel-medium label">Course Size*</span>
          <input
            type="number"
            class="kinput"
            placeholder="0"
            min="0"
            step="1"
            [(ngModel)]="course.courseSize"
            (change)="updateCourseParameter($event, 'courseSize')"
            required
            #size="ngModel"
          />
          <span class="klabel-medium error" *ngIf="size.errors && size.touched"
            >Course size is required</span
          >
        </div>
        <div class="detail-column">
          <span class="klabel-medium label">Waitlist</span>
          <div class="detail-row">
            <input type="checkbox" [(ngModel)]="course.allowWaitListFlag" />
            <span>Allow waiting list</span>
          </div>
        </div>
      </div>
      <div class="detail-column">
        <span class="klabel-medium label">Course Status</span>
        <div class="detail-row">
          <label class="switch">
            <input [(ngModel)]="course.courseActiveFlag" type="checkbox" />
            <span class="slider round"></span>
          </label>
          <span
            >This course is
            {{ course.courseActiveFlag ? "active" : "inactive" }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="buttons" *ngIf="!isEditMode">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeDialog()"
    ></app-button>
    <app-button
      [disabled]="
        !course.courseName ||
        !course.trackingValue ||
        !course.courseType ||
        !course.courseSize ||
        !course.courseDescription
      "
      [icon]="{ materialIcon: 'arrow_forward' }"
      [class]="'icon-right'"
      [text]="'Next Step'"
      (click)="updateCourse()"
    ></app-button>
  </div>
</div>
