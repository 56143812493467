<div class="general-container">
  <div class="section">
    <span class="klabel-huge">General Information</span>
    <div class="input-row">
      <app-input
        class="half"
        label="Entity Name*"
        placeholder="Enter name of your entity"
        [error]="error?.entityName"
        value="{{ this.defaults?.entityName }}"
        (updatedValue)="updateDefaults($event, 'entityName')"
      ></app-input>
      <div class="input-container half">
        <span class="klabel-medium label">Primary Building</span>
        <input
          type="text"
          name="target"
          list="targets"
          placeholder="Select primary building"
          class="kinput with-icon kplaceholder"
          [value]="primaryBuildingName"
          (keyup)="updateDefaults($event, 'primaryBuildingId')"
        />
        <datalist id="targets" class="datalist">
          <option *ngFor="let building of entity?.Buildings">
            {{ building.buildingName }}
          </option>
        </datalist>
        <mat-icon class="icon" fontSet="material-icons-round"
          >location_city</mat-icon
        >
      </div>
    </div>
  </div>
  <div class="section">
    <span class="klabel-large">Kalpa Support Contact Information</span>
    <span class="kbody-small"
      >The information below will populate on the help/FAQ page for all members
      of your Kalpa entity. Please select/enter information that will help your
      Kalpa members understand who to reach out to for support within your
      entity.</span
    >
    <div class="input-row">
      <div class="input-container half">
        <span class="klabel-medium label">Primary Support Contact</span>
        <input
          type="text"
          name="primaryContact"
          list="users"
          placeholder="Select a person"
          class="kinput with-icon kplaceholder"
          [value]="this?.defaults?.primaryContact"
          (keyup)="updateDefaults($event, 'primaryContact')"
        />
        <datalist id="users" class="datalist">
          <option *ngFor="let user of entity?.Users">
            {{ user.fullName }}
          </option>
        </datalist>
        <mat-icon class="icon" fontSet="material-icons-round">person</mat-icon>
      </div>
      <app-input
        class="half"
        nam="primaryEmail"
        label="Primary Contact Email"
        placeholder="Enter Primary Contact Email"
        [value]="this?.defaults?.primaryEmail"
        icon="email"
        (updatedValue)="updateDefaults($event, 'primaryEmail')"
      ></app-input>
    </div>
  </div>
</div>
