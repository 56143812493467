<!-- NOTE: This page was created with many items that could be abstracted into their own components. This was done deliberately to make the code more readable and easier to follow. -->
<div class="push-to-group">
  <div class="top">
    <app-stepper *ngIf="currentScreen !== 'index'" [steps]="steps" [selectedStep]="selectedStep" (selectedStepChange)="this.selectedStep = $event"></app-stepper>
    <div class="index" *ngIf="currentScreen === 'index'">
      <span class="ktitle-tiny">Group Push</span> 
      <app-button (click)="advanceScreen()" [text]="'Add New Group Push'"></app-button>
      <div class="table">
        <span class="klabel-huge">Previous Group Pushes</span>
        <div class="title-row">
          <span class="klabel-medium label cell">Push Type</span>
          <span class="klabel-medium label cell">Requirement</span>
          <span class="klabel-medium label cell">Building</span>
          <span class="klabel-medium label cell">Position</span>
          <span class="klabel-medium label cell">Notes</span>
        </div>
        <div class="item" *ngFor="let push of coursePushes">
          <span class="cell kbody-medium">{{ push?.Push?.name }}</span>
          <span class="cell kbody-medium">{{ push?.Requirement?.requirement }}</span>
          <span class="cell kbody-medium">{{ push?.Building?.buildingName }}</span>
          <span class="cell kbody-medium">{{ push?.Position?.positionTitle }}</span>
          <span class="cell kbody-medium">{{ push?.messageSubject }}</span>
        </div>
      </div>
    </div>
  
    <div class="select-group" *ngIf="currentScreen === 'select-group'">
      <span class="ktitle-tiny push-title">Group Push</span> 
      <span class="klabel-huge">Select push parameter</span>
      <div class="radio-row" *ngFor="let group of pushGroups">
        <input 
          type="radio" 
          name="group" 
          id="{{group.kalpaIdentifier}}" 
          value="{{group.kalpaIdentifier}}" 
          [(ngModel)]="selectedPushGroup" 
          (change)="updateWizard()"
        >
        <span class="kbody-large">{{ group.name }}</span>
      </div>
    </div>
  
    <div class="select-requirement" *ngIf="currentScreen === 'select-requirement'">
      <span class="ktitle-tiny push-title">Group Push</span>
      <span class="klabel-huge">Select Requirement</span>
      <app-select
        [label]="'Select which requirement to apply this course to*'"
        [options]="formattedCourseRequirements"
        value="{{this?.coursePushPackage?.requirement?.id}}"
        [placeholder]="'Select a requirement'"
        (selectedValue)="updateCoursePushPackageParameter($event, 'requirement')"
        class="selecter"
      ></app-select>
    </div>
  
    <div class="select-buildings" *ngIf="currentScreen === 'select-buildings'">
      <span class="ktitle-tiny push-title">Group Push</span>
      <span class="klabel-huge">Select building(s)</span>
      <div class="detail">
        <span class="klabel-medium label">Select buildings this course can be applied to*</span>
        <div class="input-select-container">
          <input 
            #buildingsinput
            (change)="updateCoursePushPackageParameter($event, 'buildings')" 
            class="kinput kbody-medium" 
            type="text" 
            placeholder="Type or select a building name" 
            list="buildingtargets"> 
          <datalist id="buildingtargets" class="datalist">
            <option class="kbody-medium" *ngFor="let building of buildings">{{ building.buildingName }}</option>
          </datalist>
        </div>
        <span class="klabel-medium label" *ngIf="coursePushPackage?.buildings?.length > 0">Selections</span>
        <div class="chips">
          <app-chip 
            class="outlined" 
            (closeChip)="removeBuilding(building)" 
            [closable]="true" 
            *ngFor="let building of coursePushPackage.buildings" 
            [text]="building.buildingName" 
            ></app-chip>
        </div>
      </div>
    </div>
    
    <div class="select-positions" *ngIf="currentScreen === 'select-positions'">
      <span class="ktitle-tiny push-title">Group Push</span>
      <span class="klabel-huge">Select position(s)</span>
      <div class="detail">
        <span class="klabel-medium label">Select positions this course can be applied to*</span>
        <div class="input-select-container">
          <input 
            #positionsinput
            (change)="updateCoursePushPackageParameter($event, 'positions')" 
            class="kinput kbody-medium" 
            type="text" 
            placeholder="Type or select a position name" 
            list="positiontargets"> 
          <datalist id="positiontargets" class="datalist">
            <option class="kbody-medium" *ngFor="let position of positions">{{ position.positionTitle }}</option>
          </datalist>
        </div>
        <span class="klabel-medium label" *ngIf="coursePushPackage?.positions?.length > 0">Selections</span>
        <div class="chips">
          <app-chip 
            class="outlined" 
            (closeChip)="removePosition(position)" 
            [closable]="true" 
            *ngFor="let position of coursePushPackage.positions" 
            [text]="position.positionTitle" 
            ></app-chip>
        </div>
      </div>
    </div>
  
    <div class="send-message" *ngIf="currentScreen === 'send-message'">
      <span class="ktitle-tiny push-title">Group Push</span>
      <div class="details">
        <span class="klabel-huge">Send Message (optional)</span>
        <span class="klabel-medium label">Subject</span>
        <input [(ngModel)]="coursePushPackage.messageTitle" type="text" class="kinput msg-input">
        <span class="klabel-medium label">Message</span>
        <textarea [(ngModel)]="coursePushPackage.messageContent" rows="4" class="kinput msg-input"></textarea>
      </div>
    </div>
    
    <div class="complete" *ngIf="currentScreen === 'complete'">
      <span class="ktitle-tiny push-title">Group Push</span>
      <div *ngIf="!error" class="success">
        <mat-icon fontSet="material-icons-round" class="check-icon">check</mat-icon>
        <span class="ktitle-small">Course was pushed successfully.</span>
      </div>
      <span *ngIf="error">{{error.error.message}}</span>
    </div>
  </div>

  <app-loading-spinner *ngIf="isLoading" class="spinner"></app-loading-spinner>

  <div *ngIf="currentScreen === 'index' || currentScreen === 'complete'" class="nav-row">
    <app-button (click)="backToPrimaryPushIndex()" [text]="'Back to choose push type'" [class]="'white'" [icon]="{ materialIcon: 'arrow_back' }"></app-button>
    <app-button *ngIf="currentScreen === 'index'" (click)="closeModal()" [text]="'Exit Window'" [class]="'white'"></app-button>
    <app-button *ngIf="currentScreen === 'complete'" (click)="backToIndex()" [text]="'ok'" [icon]="{ materialIcon: 'arrow_forward' }"></app-button>
  </div>
  <div *ngIf="currentScreen !== 'index' && currentScreen !== 'complete'" class="nav-row">
    <app-button (click)="goBack()" [text]="'Back'" [class]="'white'" [icon]="{ materialIcon: 'arrow_back' }"></app-button>
    <app-button *ngIf="currentScreen !== 'send-message'" [disabled]="checkDisabled()" (click)="advanceScreen()" [text]="'Next Step'" [icon]="{ materialIcon: 'arrow_forward' }"></app-button>
    <app-button *ngIf="currentScreen === 'send-message'" [disabled]="checkDisabled()" (click)="submitPush()" [text]="'Finish'" [icon]="{ materialIcon: 'arrow_forward' }"></app-button>
  </div>
</div>