<div class="profile">
  <span class="ktitle-medium title">{{
    user?.fullName ? user?.fullName + "'s Profile" : ""
  }}</span>
  <div class="sub-nav">
    <div class="sub-tabs" *ngIf="!isLoading">
      <div
        *ngFor="let section of defaultSections"
        (click)="toggleSelectedSection(section)"
        class="sub-tab"
        [ngClass]="{ selected: selectedSection === section }"
      >
        <mat-icon class="icon" fontSet="material-icons-round">{{
          section.icon
        }}</mat-icon>
        <span class="klabel-large">{{ section.text }}</span>
      </div>
    </div>
    <div class="content special-course-list">
      <app-my-profile
        [viewingOthersProfile]="viewingOthersProfile"
        [user]="user"
        *ngIf="!isLoading && selectedSection.text === 'Overview'"
      ></app-my-profile>
      <app-my-pd-plan
        (refreshUser)="isLoading = true"
        [user]="user"
        *ngIf="!isLoading && selectedSection.text === 'PD Plan'"
      ></app-my-pd-plan>
      <app-my-course-requests
        *ngIf="selectedSection.text === 'Course Requests'"
      ></app-my-course-requests>
      <app-loading-spinner
        class="spinner"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </div>
  </div>
</div>
