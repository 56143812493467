<div class="batch-reassign-container">
  <div class="title-row">
    <div class="content">
      <mat-icon class="location-city-icon">location_city</mat-icon>
      <span>{{ titleText }}</span>
    </div>

    <mat-icon (click)="this.dialogRef.close()">cancel</mat-icon>
  </div>
  <div class="content-container">
    <div class="target-container">
      <span class="klabel-medium">{{ titleChoose }}</span>
      <div class="input-select-container">
        <input
          [(ngModel)]="selectedReassignTarget"
          class="kinput input-select"
          type="text"
          placeholder="Type or select a {{ systemSettingName }}"
          name="target"
          list="targets"
        />
        <datalist id="targets" class="datalist">
          <option *ngFor="let item of potentialReassignList">
            {{ item.name }}
          </option>
        </datalist>
        <mat-icon class="chevron">chevron_left</mat-icon>
      </div>
    </div>
    <div class="lists">
      <div class="list-container">
        <span class="klabel-huge list-label">Current assignment</span>
        <div class="list">
          <div class="top-row">
            <input
              type="checkbox"
              (change)="selectAllNeedToMove($event)"
              [checked]="
                needToMoveList.length === selectedNeedToMoveList.length &&
                selectedNeedToMoveList.length !== 0
              "
            />
            <span class="select-all kbody-large">Select All</span>
          </div>
          <div class="row" *ngFor="let item of needToMoveList">
            <input
              type="checkbox"
              (change)="handleNeedToMoveCheckboxChange($event, item)"
              [checked]="needIsChecked(item)"
            />
            <span class="name kbody-medium">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="actions">
        <mat-icon>arrow_forward</mat-icon>
        <div class="chevrons">
          <mat-icon (click)="transferSelectedToMovingList()" class="chevron"
            >chevron_right</mat-icon
          >
          <mat-icon
            (click)="transferSelectedToNeedToMoveList()"
            class="left"
            class="chevron"
            >chevron_left</mat-icon
          >
        </div>
      </div>
      <div class="list-container">
        <span class="klabel-huge list-label">New assignment</span>
        <div class="list">
          <div class="top-row">
            <input
              type="checkbox"
              (change)="selectAllMoving($event)"
              [checked]="
                movingList.length === potentialReassignList.length &&
                movingList.length !== 0
              "
            />
            <span class="select-all kbody-large">Select All</span>
          </div>
          <div class="row" *ngFor="let item of movingList">
            <input
              type="checkbox"
              (change)="handleMovingCheckboxChange($event, item)"
              [checked]="movingIsChecked(item)"
            />
            <span class="name kbody-medium">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="count"
        >{{ movingList.length }}/{{
          needToMoveList.length + movingList.length
        }}
        users selected for reassignment to new {{ systemSettingName }}</span
      >
      <div class="options">
        <div class="help">
          <!-- TODO - implement help tooltip -->
          <!-- <mat-icon>help</mat-icon> -->
          <span>Help/FAQ</span>
        </div>
        <div class="buttons">
          <app-button
            [text]="'Cancel'"
            [class]="'white'"
            (click)="this.dialogRef.close()"
          ></app-button>
          <app-button
            [icon]="{ type: 'success' }"
            [text]="'Move Staff'"
            (click)="submitReassign()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
