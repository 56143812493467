import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RolesService {
  private _rolesData$ = new BehaviorSubject<any>(null);
  public readonly rolesData$ = this._rolesData$.asObservable();

  _user$: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getRoles = () => {
    return this.http.get(`${environment.expressUrl}/roles`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': 1000, 'activeFlag': 1, includedAssociations: 'Permissions',
        },
      })
      .subscribe({
        next: (response: any) => {
          this._rolesData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  updateRole = (role: any) => {
    const permissionRequestObject: any = {
      'roleName': role.name,
      'accessLevel': role.formattedPermissions.ManageUserActivity ? role.formattedPermissions.UserManagementGroup === 'AllUsers' ? 'MANAGE_ALL_AUTHORITY' : role.formattedPermissions.AssignedUsersPermission === 'Primary' ? 'MANAGE_PRIMARY_AUTHORITY' : role.formattedPermissions.AssignedUsersPermission === 'Secondary' ? 'MANAGE_SECONDARY_AUTHORITY' : 'BASIC' : 'BASIC',
    }
    // if the access level is basic, we make sure that the associated child permissions are set to false
    if(permissionRequestObject.accessLevel === 'BASIC') {
      role.formattedPermissions.VALIDATE_ATTENDANCE = false;
      role.formattedPermissions.COURSE_REQUESTS = false;
      role.formattedPermissions.EXPORT_STATE_DOC = false;
      role.formattedPermissions.COURSE_PUSH = false;
    }
    // here we set the child permissions to false if the parent permission is false
    if (!role.formattedPermissions.ManageCourseCatalog) {
      role.formattedPermissions.COURSE_ADD = false;
      role.formattedPermissions.COURSE_EDIT = false;
      role.formattedPermissions.COURSE_SUGGESTIONS = false;
    }
    if (!role.formattedPermissions.SYS_SETTINGS_ACCESS) {
      role.formattedPermissions.SYS_SETTINGS_DEFAULTS = false;
      role.formattedPermissions.SYS_SETTINGS_BUILDINGS = false;
      role.formattedPermissions.SYS_SETTINGS_POSITIONS = false;
      role.formattedPermissions.SYS_SETTINGS_REQUIREMENTS = false;
      role.formattedPermissions.SYS_SETTINGS_TYPES = false;
      role.formattedPermissions.SYS_SETTINGS_CATEGORIES = false;
      role.formattedPermissions.SYS_SETTINGS_TAGS = false;
      role.formattedPermissions.SYS_SETTINGS_ROLES = false;
    }
    if(!role.formattedPermissions.USER_ACCOUNTS) {
      role.formattedPermissions.USER_ADD = false;
      role.formattedPermissions.USER_EDIT = false;
    }
    if (!role.formattedPermissions.ManageUserActivity) {
      role.formattedPermissions.VALIDATE_ATTENDANCE = false;
      role.formattedPermissions.COURSE_REQUESTS = false;
      role.formattedPermissions.EXPORT_STATE_DOC = false;
      role.formattedPermissions.COURSE_PUSH = false;
      permissionRequestObject.accessLevel = 'BASIC';
    }

    // here we make an array of added permissions by comparing the true role.formattedPermissions to the original permissions. we also exclude the permissions that were added just for ui and the permission that sets the accessLevel, which we set above
    const addedPermissionsArray: any = [];
    Object.keys(role.formattedPermissions).map((key) => {
      if (role.formattedPermissions[key] && !(role.originalRoleInfo.originalPermissions.find((role: any) => role.kalpaIdentifier === key) || key === 'AssignedUsersPermission' || key === 'ManageCourseCatalog' || key === 'ManageUserActivity' || key === 'UserManagementGroup' )) {
        addedPermissionsArray.push(key);
      }
    })

    // here we make an array of removed permissions by comparing the original permissions to the true role.formattedPermissions
    const removedPermissionsArray: any = [];
    role.originalRoleInfo.originalPermissions.map((permission: any) => {
      if (role.formattedPermissions[permission.kalpaIdentifier] === false) {
        removedPermissionsArray.push(permission.kalpaIdentifier);
      }
    })

    if(role.id) {
      return this.http.put(
        `${environment.expressUrl}/roles/${role.id}`,
        {
          'loginName': this._user$.loginName,
          ...permissionRequestObject,
          addPermissionKids: addedPermissionsArray,
          removePermissionKids: removedPermissionsArray,
        },
        { headers: { 'Content-Type': 'application/vnd.api+json' } },
      )
    }
    return this.http.post(
      `${environment.expressUrl}/roles`,
      {
        'loginName': this._user$.loginName,
        ...permissionRequestObject,
        addPermissionKids: addedPermissionsArray,
        roleDetails: 'We have no input for this field, but it is required by the API',
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )


  }

  deactivateRole = (role: any) => {
    return this.http.put(
      `${environment.expressUrl}/roles/${role.id}/deactivate`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
}
