<div class="modal">
  <div class="title-row">
    <div>
      <mat-icon fontSet="material-icons-round">view_list</mat-icon>
      <span class="ktitle-medium">{{data ? 'Edit' : 'Add New'}} Course Request</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()" fontSet="material-icons-round">close</mat-icon>
  </div>
  <div class="content">
    <app-input 
      [label]="'Course Name'"
      [value]="this?.newCourse?.name"
      [placeholder]="'Enter Course Name'"
      (updatedValue)="updateCourseParameter($event, 'courseName')"
    ></app-input>
    <app-input 
      [label]="'Credit Hours'"
      [value]="this?.newCourse?.creditHours"
      [placeholder]="'Enter credit hours'"
      (updatedValue)="updateCourseParameter($event, 'creditHours')"
    ></app-input>
    <app-input 
      [label]="'Course Type'"
      [value]="this?.newCourse?.courseType"
      [placeholder]="'Enter or select course type'"
      (updatedValue)="updateCourseParameter($event, 'courseType')"
    ></app-input>
  </div>
  <div class="button-row">
    <div class="buttons">
      <app-button 
        (click)="closeDialog()" 
        [class]="'white'" 
        [text]="'Cancel'"
      ></app-button>
      <app-button 
        [icon]="{ materialIcon: 'done' }" 
        (click)="updateCourse()"
        *ngIf="this?.newCourse?.activeFlag !== 0" 
        class="button" 
        [text]="data ? 'Save Changes' : 'Submit Request'"
      ></app-button>
    </div>
  </div>
</div>