import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, min } from 'rxjs';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';
import Course from '../../course.class';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: [ './finish.component.scss' ],
})
export class FinishComponent implements OnInit, OnDestroy {
  @Output() updateCourseObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Output() completeCourseSessionDetails = new EventEmitter<any>();
  @Output() isLoadingStatus = new EventEmitter<boolean>(); // this is used to disable the admin button in the details page until the session data is loaded
  @Input() isCourseDetails = false;
  @Input() course: Course;
  coursesSubscription: Subscription;
  courseSessionDataSubscription: Subscription;
  error: string;
  isLoading = false;
  courseDataSubscription: Subscription;
  permissions: any;
  permissionsSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private coursesService: CoursesService,
    private authService: AuthService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    // here we get the users permissions
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
    })
  }

  updateLocalCourseParameter = (event: any, parameter: string) => {
    // this.course[parameter] = event;
  }

  populateDate(date: string | Date | undefined) {
    if (!date) return '-';

    if (date instanceof Date) {
      return date.toLocaleDateString();
    }

    return date;
  }

  populateTime(time: string | Date | undefined) {
    if (!time) return '';
    if (time instanceof Date) {
      return time.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      }) ;
    }

    let [ hours, minutes ]: (number | string)[] = time.split(':').map(Number);
    let amPm = 'AM';

    if (Number(hours) > 12) {
      hours = hours - 12;
      amPm = 'PM';
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes} ${amPm}`;
  }

  saveCourse = () => {
    // final validation check before sending request
    const missingProps: string[] = [];
    if (this.course.courseName === '') {
      missingProps.push('Course Name');
    }
    if (this.course.trackingValue === '') {
      missingProps.push('Tracking Value');
    }
    if (this.course.courseType === '') {
      missingProps.push('Course Type');
    }
    if (!this.course.courseSize) {
      missingProps.push('Course Size');
    }
    if (this.course.courseDescription === '') {
      missingProps.push('Course Description');
    }
    if (this.course.location === '') {
      missingProps.push('Location');
    }

    if (this.course.isAnytime) {
      if (!this.course.dateOffered) {
        missingProps.push('Anytime - Date Offered');
      }
    } else if (!this.course.isAsync) {
      if (!this.course.dateOffered) {
        missingProps.push('Norm - Date Offered');
      }
      if (!this.course.sessionStartDate) {
        missingProps.push('Norm - Session Start Date');
      }
      if (!this.course.sessionEndDate) {
        missingProps.push('Norm - Session End Date');
      }
    }

    if (!this.course.regStartDate) {
      missingProps.push('Registration Start Date');
    }
    if (!this.course.regEndDate) {
      missingProps.push('Registration End Date');
    }
    if (this.course?.requirementsList.length === 0) {
      missingProps.push('Requirements');
    }
    if (!this.course?.approvalId) {
      missingProps.push('Approval Level');
    }

    // if (missingProps.length > 0) {
    //   this.error = `Please go back and fill out all required fields: ${missingProps.join(', ')}`;
    //   return
    // }

    // here we do a final check on the users permissions to see if they are allowed to create a course
    if(this.permissions?.COURSE_ADD === 0) {
      this.dialog.closeAll();
      this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert', title: 'Oops, something went wrong', text: 'You do not have permission to create a course',
        },
      })
      return
    }

    // this.isLoading = true;
    const createCourse = this.course.buildCreateRequest();
    this.coursesSubscription = this.coursesService.addCourse(createCourse).subscribe({
      next: (response: any) => {
        this.toastService.setToast({
          text: 'Course successfully added', type: 'success', icon: true,
        })
        this.dialog.closeAll();
      },
      error: (error) => {
        console.log('error', error);
        this.isLoading = false;
        let message;
        if(error.error.statusCode === 4003) {
          message = 'Course name already exists, please choose another name'
        }
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong', text: message || error.error.errMsg || error.error.message,
          },
        })
      },
    })

  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  formatDate = (date: any) => {
    return date;
  }

  closeDialog = () => {
    this.closeDialogEmitter.emit();
  }

  ngOnDestroy(): void {
    this.coursesSubscription?.unsubscribe();
    this.courseSessionDataSubscription?.unsubscribe();
    this.courseDataSubscription?.unsubscribe();
  }

}
