import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-buildings-and-positions',
  templateUrl: './buildings-and-positions.component.html',
  styleUrls: [ './buildings-and-positions.component.scss' ],
})
export class BuildingsAndPositionsComponent implements OnInit {
  @ViewChild('buildinginput') buildinginput: any;
  @ViewChild('positioninput') positioninput: any;
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() editedUser: any;
  @Input() isEditMode: any;
  @Input() permissions: any;
  @Input() buildings: any;
  @Input() positions: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; };
  localEditedUser: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.localEditedUser = this.editedUser;
  }

  updateLocalUserParameter = (event: any, parameter: string) => {
    let selectedValue;
    if(parameter === 'buildings') {
      selectedValue = this.buildings.find((item: any) => item.buildingName === event.target.value);
      // if it is already added as the primary, then we don't add it to the normal buildings array
      if(this.localEditedUser.primaryBuilding === selectedValue) { return }
      //this handles the case where there are no buildings selected, and sets the first building as the primary
      if(!this.localEditedUser.primaryBuilding) {
        this.localEditedUser.primaryBuilding = selectedValue;
        this.buildinginput.nativeElement.value = '';
        return;
      }
    }
    if(parameter === 'positions') {
      selectedValue = this.positions.find((item: any) => item.positionTitle === event.target.value);
      // if it is already added as the primary, then we don't add it to the normal positions array
      if(this.localEditedUser.primaryPosition === selectedValue) { return }
      //this handles the case where there are no positions selected, and sets the first position as the primary
      if(!this.localEditedUser.primaryPosition) {
        this.localEditedUser.primaryPosition = selectedValue;
        this.positioninput.nativeElement.value = '';
        return;
      }
    }
    if(this.localEditedUser[parameter].includes(selectedValue)) { return }
    if(!selectedValue) { return }
    this.localEditedUser[parameter] = [ ...this.localEditedUser[parameter], selectedValue ];
    //this resets the input value to blank
    this.buildinginput.nativeElement.value = '';
    this.positioninput.nativeElement.value = '';

    this.step.modified = true;
  }

  //this adds the primary building to the normal buildings array if there is a primary, removes the new primary building from the normal buildings arry, then replaces the old primary building with the new one
  togglePrimaryBuilding = (building: any) => {
    if(this.isEditMode && !this.permissions.USER_EDIT) return;
    if(this.localEditedUser.primaryBuilding) {
      this.localEditedUser.buildings = [ this.localEditedUser.primaryBuilding, ...this.localEditedUser.buildings ];
    }
    this.localEditedUser.buildings = this.localEditedUser.buildings.filter((item: any) => item !== building);
    this.localEditedUser.primaryBuilding = building;
  }

  removeBuilding = (building: any, primary?: any) => {
    // if the primary is removed, then we make the first item in the buildings array the primary
    if(primary) {
      this.localEditedUser.primaryBuilding = this.localEditedUser.buildings[0] || '';
      this.localEditedUser.buildings.shift();
      return;
    }
    this.localEditedUser.buildings = this.localEditedUser.buildings.filter((item: any) => item !== building);
  }

  //this adds the primary position to the normal positions array if there is a primary, removes the new primary position from the normal positions arry, then replaces the old primary position with the new one
  togglePrimaryPosition = (position: any) => {
    if(this.isEditMode && !this.permissions.USER_EDIT) return;
    if(this.localEditedUser.primaryPosition) {
      this.localEditedUser.positions = [ this.localEditedUser.primaryPosition, ...this.localEditedUser.positions ];
    }
    this.localEditedUser.positions = this.localEditedUser.positions.filter((item: any) => item !== position);
    this.localEditedUser.primaryPosition = position;
  }

  removePosition = (position: any, primary?: any) => {
    // if the primary is removed, then we make the first item in the positions array the primary
    if(primary) {
      this.localEditedUser.primaryPosition = this.localEditedUser.positions[0] || '';
      this.localEditedUser.positions.shift();
      return;
    }
    this.localEditedUser.positions = this.localEditedUser.positions.filter((item: any) => item !== position);
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  updateUser = () => {
    if (!this.localEditedUser.primaryBuilding || !this.localEditedUser.primaryPosition) {
      return
    }
    this.updateUserObject.emit(this.localEditedUser);
  }

}
