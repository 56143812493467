import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCourseComponent } from './components/update-course/update-course.component';
import { CoursesService } from 'src/app/services/courses.service';
import { CourseFiltersComponent } from './components/course-filters/course-filters.component';
import { CatalogCourseDetailsComponent } from './components/catalog-course-details/catalog-course-details.component';
import { RegistrationRequirementModalComponent } from './components/registration-requirement-modal/registration-requirement-modal.component';
import { RegistrationWaitlistModalComponent } from './components/registration-waitlist-modal/registration-waitlist-modal.component';
import { EntityService } from 'src/app/services/entity.service';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';
import { CourseFilter } from './components/course-filters/courseFilter';

@Component({
  selector: 'app-course-catalog',
  templateUrl: './course-catalog.component.html',
  styleUrls: [ './course-catalog.component.scss' ],
})
export class CourseCatalogComponent implements OnInit, OnDestroy {
  meatballMenu: any;
  tableData: any;
  table: any;
  years: any;
  formattedYears: any;
  coursesDataSubscription: any;
  entitySubscription: any;
  usersSubscription: any;
  userSubscription: any;
  userWithRequirementsSubscription: any;
  isLoading = false;
  error: any;
  courseList: any;
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  totalCount: any;
  currentFilters: any;
  user: any;
  permissions: any;
  permissionSubscription: any;
  mainFilter: CourseFilter;
  userCurrentRegistrations: number[];

  constructor(
    public dialog: MatDialog,
    private coursesService: CoursesService,
    private entityService: EntityService,
    private authService: AuthService,
    private usersService: UsersService,
  ) { }

  //TODO: add pagination to the list of courses
  //TODO: update ui

  ngOnInit(): void {
    this.isLoading = true;
    this.mainFilter = new CourseFilter();
    // here we get the user permissions from the auth service
    this.permissionSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
    })
    // all of this is to get the users requirements in the case of trying to register for a course
    this.userSubscription = this.authService.user$.subscribe((user: any) => {
      if(user) {
        this.usersService.getUserById(user.id, { includedAssociations: 'Requirements, Registrations' });
      }
    })
    this.userWithRequirementsSubscription = this.usersService.selectedUserData$.subscribe((data: any) => {
      if(data) {
        this.user = data;
        this.userCurrentRegistrations = data?.Registrations?.map((registration: any) => registration.courseItemId);
      }
    })
    // we get all the entity years here
    this.entityService.getEntity('Years');
    this.entitySubscription = this.entityService.entityData$.subscribe((data: any) => {
      this.years = data?.Years;
      // format years for reusable select
      if(data?.Years?.length > 0) {
        this.formattedYears = data?.Years?.map((year: any) => {
          return {
            text: year.years,
            value: year.id,
          }
        })

      }
    })
    // we get all the course sessions here
    this.formatFilter(this.mainFilter);
    this.coursesService.getCourseSessions({ includedAssociations:'Tags, Items', ...this.currentFilters })
    this.coursesDataSubscription = this.coursesService.coursesData$.subscribe((data: any) => {
      if (!data) return;
      this.totalCount = data?.pagination?.totalItems;
      this.courseList = data?.rows;
      data?.error ? this.error = data?.error : this.error = null;
      const formatedTableData = data?.rows?.map((course: any) => {
        return {
          courseName: course.courseName,
          courseItemId: course.courseItemId,
          location: (course?.location?.slice(0, 13) + (course?.location?.length > 13 ? ' ...' : '')) || '',
          date: (course?.dateOffered ? (new Date(course?.dateOffered))?.toLocaleDateString() : ''),
          time: course.sessionStartHh24 ? (course?.sessionStartHh24 % 12 === 0 ? 12 : course?.sessionStartHh24 % 12) + ':' + (course.sessionStartMin === 0 ? '00' : course.sessionStartMin) + (course.sessionStartHh24 > 11 ? 'pm' : 'am') : '',
          sessionStartTime: new Date(course?.sessionStartDate)?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
          sessionEndTime: new Date(course?.sessionEndDate)?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
          hours: course?.trackingValue || '',
          tags: course?.tagIdColorNameArray || [],
          tagAgg: (course?.tagAgg && (course?.tagAgg[0] === null)) ? [] : course?.tagAgg || [],
          enrolement: `${course?.registeredCount || 0} of ${course?.courseSize}`,
          canRegister: (parseFloat(course.registeredCount) < parseFloat(course.courseSize) || !course.registeredCount) ? true : false,
          canWaitList: parseFloat(course.registeredCount) >= parseFloat(course.courseSize) ? true : false,
        }
      })

      this.table = {
        columnTitles: [
          {
            title: 'Course', class: 'wide', sortDirection: 'ASC', paramName: 'courseName',
          },
          {
            title: 'ID', class: 'skinny', sortDirection: 'ASC', paramName: 'courseItemId',
          },
          {
            title: 'Location', class: '', sortDirection: 'ASC', paramName:'location',
          },
          {
            title: 'Dates', class: 'skinny', sortDirection: 'ASC', paramName: 'dateOffered',
          },
          {
            title: 'Time', class: '', sortDirection: 'ASC', paramName: 'sessionStartHh24',
          },
          {
            title: 'Hours', class: 'skinny hours', sortDirection: 'ASC', paramName: 'trackingValue',
          },
          {
            title: 'Tags', class: 'no-sort tags', sortDirection: 'ASC', paramName: '', noSortFlag: true,
          },
          {
            title: 'Enrollment', class: 'skinny no-sort', sortDirection: 'ASC', paramName: '', noSortFlag: true,
          },
          {
            title: '', class: 'skinny actions no-sort', sortDirection: 'ASC', paramName: '', noSortFlag: true,
          },
        ],
        data: formatedTableData,
      }
      data ? this.isLoading = false : null;
    })
  }

  updateYearFilter = (newFilter: any) => {
    this.formatFilter(this.mainFilter);
    this.currentFilters = { ...this.currentFilters, yearId: newFilter }
    this.coursesService.getCourseSessions({ includedAssociations:'Tags, Items', ...this.currentFilters })
  }

  setMeatball = (event: Event, meatballMenu: any) => {
    event.stopPropagation();
    if(this.meatballMenu === meatballMenu) {
      this.meatballMenu = '';
      return;
    }
    this.meatballMenu = meatballMenu;
  }

  searchCourses = (event: any) => {
    // TODO: Course Catalog Filter by string endpoint really slow and needs to be optimized
    const value = event?.target?.value;

    if (value === this.mainFilter.courseNameFilter) return;

    this.mainFilter.courseNameFilter = value;
    setTimeout(() => {
      if (value !== this.mainFilter.courseNameFilter) return;
      this.isLoading = true;
      this.table.data = [];

      this.formatFilter(this.mainFilter);
      this.coursesService.getCourseSessions({ includedAssociations:'Tags, Items', ...this.currentFilters })
    }, 300)
  }

  popAddNewModal = () => {
    const dialog = this.dialog.open(UpdateCourseComponent, { disableClose: true })

    dialog.afterClosed().subscribe(() => {
      this.isLoading = true;
      this.formatFilter(this.mainFilter);
      this.coursesService.getCourseSessions({ includedAssociations: 'Tags, Items', ...this.currentFilters });
    });
  }

  openUpdateModal = (event: Event, course: any) => {
    event.stopPropagation();
    this.meatballMenu = '';
    const selectedCourse = this.courseList.find((courseItem: any) => courseItem.courseItemId === course.courseItemId);
    const dialog = this.dialog.open(UpdateCourseComponent, { data: selectedCourse, disableClose: true })

    dialog.afterClosed().subscribe(result => {
      if (result?.statusCode === 1000) {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.coursesService.getCourseSessions({ includedAssociations: 'Tags, Items', ...this.currentFilters });
        this.userCurrentRegistrations.push(result.courseItemId);
      }
    });
  }

  formatFilter = (filter: CourseFilter) => {
    const requirementIds = filter.requirements?.map((requirement: any) => { return parseFloat(requirement.id) });
    const requirementIdString = requirementIds?.join(', ');

    const tagIds = filter.tags?.map((tag: any) => { return parseFloat(tag.id) });
    const tagIdString = tagIds?.join(', ');

    const categoryIds = filter.categories?.map((category: any) => { return parseFloat(category.id) });
    const categoryIdString = categoryIds?.join(', ');

    const courseTypeIds = filter.courseTypes?.map((courseType: any) => { return parseFloat(courseType.id) });
    const courseTypeIdString = courseTypeIds?.join(', ');

    const buildingIds = filter.buildings?.map((building: any) => { return parseFloat(building.id) });
    const buildingIdString = buildingIds?.join(', ');


    // TODO: need to add the time range filter, once they are available in the api
    const formattedFilters: any = {
      includedAssociations:'Tags, Items',
      showCancelled: filter.showCancelledCoursesFlag ? 1 : 0,
      showAsync: filter.showAsyncFlag ? 1 : 0,
      showAnytime: filter.showAnytimeCoursesFlag ? 1 : 0,
      showVirtual: filter.showVirtualFlag ? 1 : 0,
      showOutsideCourse: filter.showOutsideCoursesFlag ? 1 : 0,
      ignoreRegistrationWindow: filter.ignoreRegistrationWindowFlag ? 1 : 0,
      specialRequestFlag: filter.specialRequestFlag ? 1 : 0,
      courseNameFilter: filter.courseNameFilter || null,
      targetValueMin: filter.hours,

      requirementIds: requirementIdString || null,
      tagIds: tagIdString || null,
      categoryIds: categoryIdString || null,
      buildingIds: buildingIdString || null,
      courseTypeIds: courseTypeIdString || null,

      startDateRange: filter?.startDate ? filter?.startDate?.toISOString() : null,
      endDateRange: filter?.endDate ? filter?.endDate?.toISOString() : null,
    };
    // this removes null and empty string values from formatted filters
    Object.keys(formattedFilters).forEach((key) => (formattedFilters[key] === null || formattedFilters[key] === '') && delete formattedFilters[key]);

    this.currentFilters = formattedFilters;

    return formattedFilters
  }

  openFiltersModal = () => {
    const dialogRef = this.dialog.open(CourseFiltersComponent, { data: this.mainFilter })
    dialogRef.afterClosed().subscribe(result => {
      if(result && !result.noChange) {
        this.isLoading = true;

        // TODO: need to add the time range filter, hours filter, buildings filter, categories filter, and course types filter once they are available in the api
        this.formatFilter(result.filter);

        this.coursesService.getCourseSessions({ includedAssociations: 'Tags, Items', ...this.currentFilters })
      } else if (result && result.noChange) {
        this.isLoading = false;
      }
    })
  }

  openCourseDetailsModal = ($event: Event, course: any) => {
    $event.stopPropagation();
    this.meatballMenu = '';
    const selectedCourse = this.courseList.find((courseItem: any) => courseItem.courseItemId === course.courseItemId);
    const dialogRef = this.dialog.open(CatalogCourseDetailsComponent, { data: { selectedCourse, userRequirements: this.user.Requirements } })


    dialogRef.afterClosed().subscribe(result => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.coursesService.getCourseSessions({ includedAssociations: 'Tags, Items', ...this.currentFilters });
      }
    })
  }

  sortTable = (column: any) => {
    if (column.paramName === '') return;
    this.currentFilters = this.formatFilter(this.mainFilter);

    this.isLoading = true;
    this.coursesService.getCourseSessions({
      sortColumn: column.paramName,
      sortDirection: this.sortDirection,
      includedAssociations: 'Tags, Items',
      ...this.currentFilters,
    })
    this.sortDirection === 'DESC' ? this.sortDirection = 'ASC' : this.sortDirection = 'DESC';
  }

  openRegistrationModal = (event: Event, course: any) => {
    event.stopPropagation();
    this.meatballMenu = '';
    const selectedCourse = this.courseList.find((courseItem: any) => courseItem.courseItemId === course.courseItemId);
    const dialog = this.dialog.open(RegistrationRequirementModalComponent, { data: { selectedCourse, userRequirements: this.user.Requirements } })

    dialog.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.coursesService.getCourseSessions({ includedAssociations: 'Tags, Items', ...this.currentFilters });
        this.usersService.getUserById(this.user.id, { includedAssociations: 'Requirements, Registrations' });
      }
    });
  }

  openWaitlistModal = (event: Event, course: any) => {
    event.stopPropagation();
    this.meatballMenu = '';
    const selectedCourse = this.courseList.find((courseItem: any) => courseItem.courseItemId === course.courseItemId);
    const dialog = this.dialog.open(RegistrationWaitlistModalComponent, { data: { selectedCourse } })

    dialog.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.coursesService.getCourseSessions({ includedAssociations: 'Tags, Items', ...this.currentFilters });
      }
    });
  }

  checkIfRegistered = (courseItemId: number) => {
    return this.userCurrentRegistrations.includes(courseItemId);
  }

  ngOnDestroy(): void {
    this?.coursesDataSubscription?.unsubscribe();
    this?.entitySubscription?.unsubscribe();
    this?.usersSubscription?.unsubscribe();
    this?.userSubscription?.unsubscribe();
    this?.userWithRequirementsSubscription?.unsubscribe();
    this?.permissionSubscription?.unsubscribe();
  }
}
