import {
  Component, OnDestroy, OnInit, Output, EventEmitter, Input,
  Inject,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/services/entity.service';
import { CourseFilter } from './courseFilter';

@Component({
  selector: 'app-course-filters',
  templateUrl: './course-filters.component.html',
  styleUrls: [ './course-filters.component.scss' ],
})
export class CourseFiltersComponent implements OnInit, OnDestroy {
  sideNavTitles = [
    'General', 'Buildings', 'Requirements', 'Categories', 'Tags', 'Course Types',
  ];
  selectedTab = 'General';
  mainFilter: CourseFilter = new CourseFilter();
  onOpenFilters: CourseFilter;
  buildingsList: Array<any>;
  requirementList: Array<any>;
  tagList: Array<any>;
  courseTypeList: Array<any>;
  categoryList: Array<any>;
  entitySubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CourseFiltersComponent>,
    private entityService: EntityService,
    @Inject(MAT_DIALOG_DATA) public data: CourseFilter,
  ) { }

  // TODO: receive previous filters from course catalog and show them
  ngOnInit(): void {
    this.entityService.getEntity('Buildings, Requirements, Tags, CourseTypes, Categories')
    this.entitySubscription = this.entityService.entityData$.subscribe((entity: any) => {
      this.buildingsList = entity?.Buildings || [];
      this.requirementList = entity?.Requirements || [];
      this.tagList = entity?.Tags || [];
      this.courseTypeList = entity?.CourseTypes || [];
      this.categoryList = entity?.Categories || [ { category: 'category1' }, { category: 'category2' } ];
    })

    if (this.data) {
      this.mainFilter = this.data;
      this.onOpenFilters = this.data.copyValues();
    }
  }

  updateMainFilter = (event: any) => {
    // this updates the filter for array items
    const param = event.parameter as keyof CourseFilter;
    let prop = this.mainFilter[param];
    if (prop instanceof Array) {

      if(event.value) {
        prop.push(event.arrayItem)
      } else {
        prop = prop.filter((item: any) => item !== event.arrayItem)
      }
    } else {
      // this updates the filter for non-array items
      prop = event.value;
    }
  }

  closeModal = () => {
    this.dialogRef.close({ noChange: true, filter: this.mainFilter.setValues(this.onOpenFilters) });
  }

  resetMainFilter = () => {
    this.mainFilter = this.mainFilter.resetAll();
  }

  saveMainFilter = () => {
    this.dialogRef.close({ noChange: false, filter: this.mainFilter });
  }

  ngOnDestroy(): void {
    this.entitySubscription.unsubscribe()
  }
}
