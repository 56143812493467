<div class="profile">
  <span class="ktitle-medium">{{ user?.fullName }} Overview</span>
  <div class="section hero-section">
    <div class="hero">
      <app-avatar size="xl" />
      <div class="name-column">
        <span class="ktitle-large">{{ user?.fullName }}</span>
        <span class="kbody-large">{{
          user?.activeFlag ? "Active" : "Inactive"
        }}</span>
      </div>
    </div>
    <div class="buttons">
      <!-- NOTE - add to ngIf Below once form has been implemented -->
      <app-button
        *ngIf="viewingOthersProfile && permissions.USER_EDIT"
        (click)="openUpdateModal()"
        [icon]="{ materialIcon: 'edit' }"
        text="Edit Profile"
        class="outlined"
      ></app-button>
      <app-button
        *ngIf="!viewingOthersProfile"
        text="Reset Password"
        (click)="openChangePasswordModal()"
        class="white"
      ></app-button>
    </div>
  </div>
  <div class="section">
    <span class="ktitle-tiny subtitle">General Information</span>
    <div class="details-row">
      <div class="details-column">
        <span class="klabel-medium label">District Email</span>
        <span class="kplaceholder">{{ user?.contactEmail || "-" }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Alternative Email</span>
        <span class="kplaceholder">{{ user?.altEmail || "-" }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">PIC Number</span>
        <span class="kplaceholder">{{ user?.stateIssuedId || "-" }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Position</span>
        <span class="kplaceholder">{{
          user?.PrimaryPosition?.positionTitle || "-"
        }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Building</span>
        <span class="kplaceholder">{{
          user?.PrimaryBuilding?.buildingName || "-"
        }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Role</span>
        <span class="kplaceholder">{{ user?.Roles[0].roleName || "-" }}</span>
      </div>
    </div>
  </div>
  <div class="section">
    <span class="ktitle-tiny subtitle">User Assignments</span>
    <div class="details-row lefty">
      <div class="details-column">
        <span class="klabel-medium label">Primary Approvers</span>
        <span *ngFor="let approver of user?.PrimaryApprovers">{{
          approver.fullName
        }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Secondary Approvers</span>
        <span *ngFor="let approver of user?.SecondaryApprovers">{{
          approver.fullName
        }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Requirements</span>
        <span *ngFor="let requirement of user?.Requirements">{{
          requirement?.requirement
        }}</span>
        <span *ngIf="!user?.Requirements || user?.Requirements === []"
          >no requirements assigned</span
        >
      </div>
    </div>
  </div>
  <!-- TODO - add back in once document support exists for users -->
  <!-- <div class="section">
    <span class="ktitle-tiny subtitle">Licenses & Certifications</span>
    <div class="details-row">
      <div class="details-column">
        <span class="klabel-medium label">Licenses/Certification</span>
        <span *ngFor="let certification of certificationList">{{
          certification.name
        }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Type</span>
        <span *ngFor="let certification of certificationList">{{
          certification.type
        }}</span>
      </div>
      <div class="details-column">
        <span class="klabel-medium label">Expiration Date</span>
        <span *ngFor="let certification of certificationList">{{
          certification.expirationDate
        }}</span>
      </div>
    </div>
  </div> -->
</div>
